import React from 'react';
import * as images from '../../constants/imagePath';
import './FieldInformation.css';

export default {
  'email-field':
  <div>
    The email address you enter here will be your username to log into your business page.
  </div>,
  'business-name-field':
  <div>
    Enter your Business Name that oversees this Program.
  </div>,
  'program-name-field':
  <div>
    Your Program Name should resemble the program or the service being offered.  It is typically a different name then the business name, unless you only have one program, then it may be the same, i.e. Business is Catholic Church, Program is <strong>Alcoholic Anonymous</strong>.
  </div>,
  'website-field':
  <div>
    Enter your program's website address/where a user would go to get more information regarding this program.
  </div>,
  'address-field':
  <div>
    Enter the physical address where the person will go to for this program.
  </div>,
  'phone-number-field':
  <div>
    Enter your Customer Service Phone Number where a person can call for more information and/or to make an appointment.
  </div>,
  'crisis-number-field':
  <div>
    If you offer a crisis phone number enter it here.
  </div>,
  'eligibility-field':
  <div>
    Enter any insurance plans you accept and any restrictions the programs has.
  </div>,
  'health-field':
   <div>
     Only choose a sub-category if there is an exact match. You may choose multiple sub-categories. Otherwise please leave it blank.
   </div>,
  'food-field':
  <div>
    Only choose a sub-category if there is an exact match. You may choose multiple sub-categories. Otherwise please leave it blank.
  </div>,
  'housing-field':
  <div>
    Only choose a sub-category if there is an exact match. You may choose multiple sub-categories. Otherwise please leave it blank.
  </div>,
  'county-field':
  <div>
    Enter the County your program's physical location is in. Only choose the ‘Online’ option if your program is 100% virtual. Ex: Dr. On Demand.
  </div>,
  'short-desc-field':
  <div>
    When a user is searching through all of the available programs, this is the initial description a user will see.
  </div>,
  'long-desc-field':
  <div>
    This is a good area to enter your business name, any special hours, and any additional information about your program.
  </div>,
  'services-field':
  <div>
    Enter the services your program will offer users. This information will appear on your programs page. When a user searches our website, we will use the keywords below to return results.
  </div>,
  'images-field':
  <div>
    Post photos of your location, program flyers, and other useful pictures that will better help users understand your program. Please make sure you comply with all <strong>HIPAA laws</strong>.
  </div>,
  'add-program-field':
  <div>
    You will need to complete a new form for each program you offer. After all your programs are added, you will be able to manage them using one username and password.
  </div>,
  'internal-notes-field':
  <div>
    Enter any notes you have about the program that you do not want to not appear on the programs listing page.
  </div>,
  'google-url-field':
    <div style={{width: '100%'}}>
      <ol>
        <li>
          <div className="list-div">
            <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer">Go to Google Maps.</a>
            <div className="list-img-div">
              <img src={images.step1} alt="" />
            </div>
          </div>
        </li>
        <li>
          <div className="list-div">
            Look up a Business and click the "Directions" button.
            <div className="list-img-div">
              <img src={images.step2} alt="" />
            </div>
          </div>
        </li>
        <li>
          <div className="list-div">
            Leave the Starting address blank and click the Hamburger in the top left corner.
            <div className="list-img-div">
              <img src={images.step3} alt="" />
            </div>
          </div>
        </li>
        <li>
          <div className="list-div">
            Click the "Share or embed map" option.
            <div className="list-img-div">
              <img src={images.step4} alt="" />
            </div>
          </div>
        </li>
        <li>
          <div className="list-div">
            Copy the link.
            <div className="list-img-div">
              <img src={images.step5} alt="" />
            </div>
          </div>
        </li>
        <li>
          <div className="list-div">
            And paste in this form!
          </div>
          <div className="list-img-div">
            <img src={images.step6} alt="" />
          </div>
        </li>
      </ol>
    </div>
}