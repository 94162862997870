import baseService from './baseService';

export const getAlerts = (entries, pageNum, order, sortBy, email) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  if(email)
    return baseService.get(`/admin/alerts?show=${entries}&page=${pageNum}&sortBy=${sortBy}&order=${order}&user=${email}`, config);
  else
    return baseService.get(`/admin/alerts?show=${entries}&page=${pageNum}&sortBy=${sortBy}&order=${order}`, config);
};

export const getNotificationAlerts = (entries, pageNum, order, sortBy, email) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  if(email)
    return baseService.get(`/admin/notificationalerts?show=${entries}&page=${pageNum}&sortBy=${sortBy}&order=${order}&user=${email}`, config);
  else
    return baseService.get(`/admin/notificationalerts?show=${entries}&page=${pageNum}&sortBy=${sortBy}&order=${order}`, config);
};

export const getAlertsByBusiness = (id) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.get(`/admin/alerts/${id}`, config);
};

export const removeAlert = (id) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.delete(`/admin/remove/alert/${id}`, config);
};
export const deleteAllAlert = (id,email) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.delete(`/admin/alert/delete-all?user=${email}&id=${id}`, config);
};

export const clearAlerts = (userId) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.put(`/admin/clear/alert/${userId}`, {}, config);
}

export const addAlert = (alert) => {
  return baseService.post('/add/alert', alert);
};