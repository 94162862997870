import baseService from './baseService';

export const addReview = review => {
  return baseService.post(`/add/review`, review);
};

export const approveReview = (id) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.put(`/admin/approve/review`,{id}, config);
};

export const removeReview = (review) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.put(`/admin/remove/review`,review, config);
};

export const removeFlyer = (id) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.put(`/admin/remove/flyer`,id, config);
};

export const postReply = (reply) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.put('/admin/reply/review', reply, config);
};