import * as services from '../service/question.service';
import * as types from '../constants/action.constants';

export const addQuestion = (question) => {
  return dispatch => {
    return services.addQuestion(question).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.ADD_QUESTION
        })
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.ADD_QUESTION_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const approveQuestion = (id) => {
  return dispatch => {
    return services.approveQuestion(id).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.APPROVE_QUESTION,
          id
        })
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.APPROVE_QUESTION_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const removeQuestion = (question) => {
  return dispatch => {
    return services.removeQuestion(question).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.REMOVE_QUESTION,
          question
        })
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.REMOVE_REVIEW_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const postAnswer = (answer) => {
  return dispatch => {
    return services.postAnswer(answer).then(resp=>{
      if(resp.status==200){
        // dispatch({
        //   type: types.POST_ANSWER,
        //   question: resp.data
        // });
        return resp.data;
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.POST_ANSWER_FAIL,
          error: err.response.data
        })
      }
    })
  }
};