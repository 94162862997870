/**
 * Created by lcomubuntu6 on 31/3/20.
 */
import * as types from '../constants/action.constants'
import { initState } from './initialState'

export default (state = initState.user, action) => {
  switch (action.type) {
    case types.GET_ALL_USERS:
      return {
        ...state,
        users: [...action.userListing],
        loadingUser: false,
      }
    case types.GET_BUSINESSES_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingUser: false,
      })
    case types.LOADING_USER:
      return {
        ...state,
        loadingUser: true,
      }
    case types.DELETE_USER:
      let user_del = state.users.filter((user) => user._id !== action.id)
      let allUsers_del = state.allUsers.filter((user) => user._id !== action.id)
      return {
        ...state,
        users: [...user_del],
        allUsers: allUsers_del,
        loadingUser: false,
        error: '',
        isDelete: true,
      }
    case types.DELETE_USER_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingUser: false,
      })
    case types.PAGINATED_USER_DATA:
      return {
        ...state,
        paginatedUserData: {
          userPageNum: action.page_no,
          userOrder: action.order,
          userSortBy: action.field_name,
          searchUser: action.search,
        },
        loadingUser: false,
      }
    case types.GET_USER_BY_ID:
      return {
        ...state,
        user: { ...action.user },
        loadingUser: false,
      }
    case types.GET_USER_BY_ID_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingUser: false,
      })
    case types.GET_UNFILTERED_USER:
      return {
        ...state,
        allUsers: [...action.userListing],
        loadingUser: false,
      }
    case types.GET_UNFILTERED_USER_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingUser: false,
      })

    case types.UPDATE_USER:
      let newUser = { ...action.users }
      return { ...state, users: { ...newUser }, loadingUser: false, error: '' }
    case types.UPDATE_USER_FAIL:
      return { ...state, error: action.error, loadingUser: false }

    case types.SET_LOGIN:
      return {
        ...state,
        user: { ...action.payload },
      }
    default:
      return state
  }
}
