import React, { Fragment } from 'react'
// import _ from 'lodash';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ResourceItem from './ResourceItem'
import {
  Divider,
  withStyles,
  Button,
  CircularProgress,
} from '@material-ui/core'
import ReviewerList from '../ReviewerList/ReviewerList'
import Review from '../Review/Review'
import PopUpYoutubeVideo from '../PopUpYoutubeVideo/PopUpYoutube'
import SignUp from '../SignUp/SignUp'
import Login from 'components/Login/Login'
import ContactForm from '../ContactForm/ContactForm'
import './ListResources.css'
import '../App.css'

import { faPhoneAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import OrganizationsMenu from './OrganizationsMenu'
import { focusedItem, openedItem } from '../../actions/common.action'
import Popover from '@material-ui/core/Popover'
import Content from '../Content/Content'

const styles = {
  popup: {
    top: '0!important',
    // overflow: 'hidden',
    left: '419px !important',
    maxHeight: '100vh',
    border: '1px solid #808080',
    borderRadius: 0,
    width: '500px',
  },
  mobilePopup: {
    width: '100%',
    top: '0!important',
    maxHeight: '100vh',
    maxWidth: 'unset',
    left: '0!important',
    border: '1px solid #808080',
    borderRadius: 0,
  },
  btn: {
    width: '100%',
    color: 'white',
    fontSize: '18px',
    fontWeight: 600,
  },
}

class ListResources extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rating: 0,
      openReview: false,
      openReviewer: false,
      resource: {},
      disclaimer: {},
      openContactForm: false,
      orgDropDown: false,
      isLearMoreVideo: false,
      open: false,
      openForgotPassword: false,
      openConfirmEmail: false,
      isWatchMoreVideo: false,
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.hoveredPin !== newProps.hoveredPin) {
      this.autoScrollToResource(newProps.hoveredPin)
    }
    if (this.props.selectedPin !== newProps.selectedPin) {
      this.autoOpenContentBox(newProps.selectedPin)
    }
  }

  changeRating = (rating, resource, currentRating) => {
    this.props.closeDetailBox()
    this.setState({ rating, openReview: true, resource })
  }

  openReviewerModal = (resource) => {
    this.setState({ openReviewer: true, resource }, () => {})
  }

  openConfirmEmail = (close, email = '') => {
    if (close) this.setState({ openConfirmEmail: false })
    else this.setState({ openConfirmEmail: true, confirmEmail: email })
  }
  closeSignUp = () => {
    this.setState({
      showSignUp: false,
    })
  }

  closeLogin = () => {
    this.setState({ open: false })
  }
  goToAdmin = () => {
    this.props.history.push('/my-listing')
  }

  goToSignUp = () => {
    this.setState({ showSignUp: true })
  }
  openForgotPassword = (close) => {
    if (close) this.setState({ openForgotPassword: false })
    else this.setState({ openForgotPassword: true })
  }

  autoScrollToResource = (hoveredPin) => {
    if (this.refs[hoveredPin]) {
      document.getElementById(`style-1`).scrollTop =
        this.refs[hoveredPin].offsetTop
    }
  }

  autoOpenContentBox = (selectedPin) => {
    if (this.refs[selectedPin]) {
      document.getElementById(`B${selectedPin}`).click()
    }
  }

  hideContactForm = () => {
    this.setState({ openContactForm: false })
  }

  handleFocus = (item) => () => {
    this.props.focusItem(item)
  }

  handleClick = (item) => () => {
    this.props.openedItem(item)
  }
  renderResources = (
    filteredResources,
    changeRating,
    classes,
    onPointerEnterResource,
    onPointerLeaveResource,
    openDetailBox,
    onHandleClick,
    loading,
    hoveredPin,
    closeDetailBox,
    selectedResource,
    history,
    showMessage,
    matches,
    hideMessage,
    selectedForm,
    removeIdAndForm,
    category
  ) => {
    const resoursess = this.props.common.businesses
    if (loading) {
      return (
        <div className="list-resource-loader">
          <CircularProgress className={classes.progress} />
        </div>
      )
    } else if (!resoursess || resoursess.length <= 0) {
      return (
        <div className="list-resource-loader" style={{ color: '#000' }}>
          Your search produced no results
        </div>
      )
    }

    return (
      <div>
        <div className=" ">
          <SignUp
            openModal={this.state.showSignUp}
            onClose={this.closeSignUp}
            openConfirmEmail={this.openConfirmEmail}
            goToAdmin={this.goToAdmin}
            matches={matches}
          />
          <Login
            open={this.state.open}
            onClose={this.closeLogin}
            openForgotPassword={this.openForgotPassword}
            openSignUp={this.goToSignUp}
            goToAdmin={this.goToAdmin}
            matches={matches}
          />
          <PopUpYoutubeVideo
            open={this.state.isLearMoreVideo}
            setOpen={() => {
              this.setState({ isLearMoreVideo: false })
            }}
            youtubeLink="https://www.youtube.com/embed/guiNmUF91Kw?autoplay=1"
          />
          <ContactForm
            open={this.state.openContactForm}
            onClose={this.hideContactForm}
            matches={matches}
          />
        </div>
        <div className="resource-list">
          {this.props.common.businesses.map((resource, idx) => (
            <ResourceItem
              key={resource.id}
              resource={resource}
              idx={idx}
              changeRating={changeRating}
              classes={classes}
              onPointerEnterResource={this.handleFocus(resource)}
              // onPointerLeaveResource={this.handleFocus(null)}
              openDetailBox={openDetailBox}
              onHandleClick={this.handleClick(resource)}
              hoveredPin={hoveredPin}
              closeDetailBox={closeDetailBox}
              selectedResource={selectedResource}
              history={history}
              showMessage={showMessage}
              matches={matches}
              hideMessage={hideMessage}
              selectedForm={selectedForm}
              removeIdAndForm={removeIdAndForm}
              category={category}
              openReviewerModal={this.openReviewerModal}
            />
          ))}
        </div>
      </div>
    )
  }

  render() {
    console.log('render Resourses')
    const {
      category,
      filteredResources,
      classes,
      onsidePanelChange,
      onPointerEnterResource,
      onPointerLeaveResource,
      openDetailBox,
      onHandleClick,
      closeDetailBox,
      selectedResource,
      loading,
      history,
      hoveredPin,
      matches,
      showMessage,
      hideMessage,
      selectedForm,
      removeIdAndForm,
    } = this.props
    const { resource, rating, openReview, openReviewer } = this.state
    return (
      <div style={{ flex: 1, overflow: 'auto' }}>
        <Fragment>
          <div
            className={
              category === 'All' ? 'searched-results' : 'list-resources'
            }
            id="style-1"
          >
            {this.renderResources(
              filteredResources,
              this.changeRating,
              classes,
              onPointerEnterResource,
              onPointerLeaveResource,
              openDetailBox,
              onHandleClick,
              loading,
              hoveredPin,
              closeDetailBox,
              selectedResource,
              history,
              showMessage,
              matches,
              hideMessage,
              selectedForm,
              removeIdAndForm,
              category
            )}
          </div>
        </Fragment>
      </div>
    )
  }
}

const Res = withStyles(styles)(ListResources)

const mapStateToProps = (state) => {
  return {
    common: state.common,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    focusItem: (item) => dispatch(focusedItem(item)),
    openedItem: (item) => dispatch(openedItem(item)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Res)
