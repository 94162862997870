/**
 * Created by lcomubuntu6 on 19/2/20.
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {TextField as MUITextField, Button as MUIButton, Snackbar as MUISnackbarContent,
  withStyles, InputAdornment, IconButton, Grid} from '@material-ui/core';
import{Info, CheckCircle} from '@material-ui/icons';
import CancelCircle from '@material-ui/icons/CancelRounded';
import {sendPasswordSetupEmail} from '../../../actions/business.action';
import { withRouter } from 'react-router-dom';
import FieldPopup from '../../FieldPopup/FieldPopup'
import '../../ProgramForm/ProgramForm.css'
import { findFlagUrlByCountryName } from "country-flags-svg";

const TextField = withStyles({
  root: {
    marginTop: '2%'
  }
})(MUITextField);

/*const CancelIcon = withStyles({
 root: {
 color: "#c70b1b"
 }
 })(CancelCircle);*/

const Snackbar = withStyles({
  root: {
    maxWidth: "700px"
  }
})(MUISnackbarContent);

const styles = () => ({
  pc: {
    width: '100%',
    height: '45px',
    borderColor: 'rgb(0, 0, 0) !important'
  },
  phone: {
    width: '100%',
    minHeight: '45px',
    borderColor: 'rgb(0, 0, 0)',
  },
  successNotif: {
    backgroundColor: '#43a047',
  },
  errorNotif: {
    backgroundColor: '#d32f2f',
  },
  btnMobile:{
    width: '25%',
  },
  checkCircle: {
    fontSize:115,
  },
  checkCirclePhone: {
    fontSize:70,
  }
});

const Button = withStyles({
  root: {
    background: '#fff',
    width: '12%',
    height: '40px',
    fontWeight: 'bolder',
    border: '3px solid #245397',
    color: '#245397',
    '&:hover': {
      background: '#fff',
      color: '#245497',
      borderColor: '#245497',
    }
  },
})(MUIButton);

class UserContactInfo extends Component {
  state={
    business: {
      firstName: "",
      lastName: "",
      userPhone: "",
      companyTitle: "",
      companyLogo: undefined,
      primaryEmail: "",
      // password: "",
      businessName: "",
      programName: "",
      programWebsite: "",
      financialInformation:"",
      address: {
        address_1: "",
        address_2: "",
        city: "",
        state: "AZ",
        postal_code: "",
        lat: null,
        lng: null,
        PlaceId: ''
      },
      programPhone: "",
      crisisFreePhone: "",
      programTollFreePhone: "",
      crisisPhone: "",
      timing: [
        {day:"Monday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
        {day: "Tuesday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
        {day: "Wednesday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
        {day: "Thursday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
        {day: "Friday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
        {day: "Saturday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
        {day: "Sunday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""}
      ],
      programEligibility: "",
      category: {
        category: [],
        sub_category: []
      },
      county: "",
      languages: ['English'],
      programShortDesc: "",
      programLongDesc: "",
      programServices: "",
      galleryImages: [],
      // programStatus: "",
      googleDirectionUrl: '',
      internalNotes: ""
    },
    categories: [],
    display_logo: '',
    display_images: [],
    valid: true,
    blankError: false,
    error:{
      Monday:false, Tuesday:false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false
    },
    notification: ``,
    open: false,
    success: false,
    validZip: true,
    anchorEl: undefined,
    anchor: '',
    editNumber: false,
    editCrisisNumber: false,
    numberError: {programPhone: false, crisisFreePhone: false, userPhone:false},
    link:false,
    linkObject:{},
    showAnotherProgramButton: false,
    editUserNumber:false,
    loading: false,
    activeStep: 0,
    steps: [0, 1, 2, 3, 4, 5],
    Days:['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
    daysActiveTab:0,
    showError:{},
    eachTabFields:[
      ['primaryEmail', 'firstName', 'lastName', 'businessName','userPhone'],
      ['programName','address.city'],
      ['programTiming'],['languages','programShortDesc'],
      ['category.category'],[]],
    mainError:false,
    customOrder:['health','food','housing', 'other'],
    flagUrl: findFlagUrlByCountryName("United States")
  };

  componentDidMount(){
    if(this.props.auth.token!==""){
      let {user} = this.props.auth;
      let isUser = user !== 'undefined' && Object.keys(user).length;
      let admin =  this.props.auth.accessType === "Admin" || this.props.auth.accessType === "Super Admin";
      this.setState(state=>({
        business: {...state.business, primaryEmail: admin ? "" : this.props.auth.email,
          firstName: isUser && !admin ? user.firstName : "", lastName: isUser && !admin ? user.lastName : "",
          userPhone: isUser && !admin ? user.userPhone : "", companyTitle: isUser && !admin ? user.companyTitle : "",
          businessName: isUser && !admin ? user.businessName : ""
        },
        eachTabFields:admin ? [[],...state.eachTabFields.slice(1,state.eachTabFields.length)]:[...state.eachTabFields]
      }));
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(this.props.auth.token!==newProps.auth.token){
      let {user} = newProps.auth;
      let notUser = Object.keys(user).length === 0;
      let admin =  this.props.auth.accessType === "Admin" || this.props.auth.accessType === "Super Admin";
      this.setState(state=>{
        return {
          blankError: false,
          numberError: {programPhone: false, crisisFreePhone: false, userPhone:false},
          error: {
            Monday:false, Tuesday:false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false
          },
          business: {...state.business, primaryEmail: newProps.auth.email,
            firstName: notUser && "", lastName: notUser && "", companyTitle: notUser && "",
            businessName: notUser && "", userPhone: notUser && ""},
          eachTabFields: admin ? [[],...state.eachTabFields.slice(1,state.eachTabFields.length)] : [...state.eachTabFields]
        };

      });
    }
  }

  handleContactSubmit = () => {
    let {auth} = this.props
    this.setState({...this.state, loading: true}, () => {
      if (auth.token === "")
        this.handleSubmit();
      else
        this.handleLoggedInUser();
    })
  };

  handleLoggedInUser = async () => {
    await this.handleNumberError('userPhone');
    // await this.handleNumberError('userPhone');
    // await this.handleNumberError('crisisFreePhone');
    let {business, numberError} = this.state;
    let {auth} = this.props;
    let isAdmin = auth.accessType === "Admin" || auth.access_type === "Super Admin";

    if((isAdmin || business.primaryEmail!=="")
      && business.firstName!=="" && business.lastName!==""
      && !numberError.userPhone && business.business_name !== ""
    ){
      let {sendPasswordSetupEmail} = this.props;
      let formData = new FormData();
      formData.append('email', business.primaryEmail.toLowerCase());

      formData.append('phone_num', business.userPhone);
      formData.append('firstName', business.firstName);
      formData.append('lastName', business.lastName);
      formData.append('businessName', business.business_name);

      sendPasswordSetupEmail(formData).then(() => {
        if(this.props.business.error===""){
          if(this.props.auth.accessType.toLowerCase()==="admin")
            this.setState({
              loading: false,
              // open: true,
              // notification: `Email sent successfully`,
              // success: true,
              showAnotherProgramButton: true
            });
          // else
          //   this.setState({
          //     loading: false,
          //     open: true,
          //     notification: 'Thank you for adding your program. If you have more programs to add,' +
          //     ' you will need to fill out a new program form for each one.',
          //     success: true,
          //     showAnotherProgramButton: true
          //   });
          this.resetForm();
          setTimeout(() => {
            this.props.history.push('/my-listing')
          }, 500)
        }
        else {
          this.setState({
            open: true,
            notification: `Something went wrong. Please try after some time.`,
            success: false
          });
        }
        localStorage.setItem('simple_thankyou_form', 1)
      });

    }
    else {
      this.setState({blankError: true, loading: false}, ()=>{
      });
    }
  };

  handleSubmit = async () => {
    await this.handleNumberError('userPhone');
    let {business, numberError} = this.state;
    let valid = this.checkValidEmail({target: {name: 'primaryEmail', value: business['primaryEmail']}});

    if( valid && business.primaryEmail !=="" && business.firstName!=="" && business.lastName!==""
      && !numberError.userPhone && business.business_name !== "" ){
      let {sendPasswordSetupEmail} = this.props;
      let formData = new FormData();
      formData.append('email', business.primaryEmail.toLowerCase());
      formData.append('phone_num', business.userPhone);
      formData.append('firstName', business.firstName);
      formData.append('lastName', business.lastName);
      formData.append('businessName', business.businessName);

      sendPasswordSetupEmail(formData).then(()=>{
        if(this.props.business.error===""){
          this.setState({
            loading: false,
            // open: true,
            // notification: 'Email sent successfully',
            // success: true,
            // showAnotherProgramButton: true
          });
          this.resetForm();
          setTimeout(() => {
            this.props.history.push('/my-listing')
          }, 500)
        }
        else {
          this.setState({
            loading: false,
            open: true,
            notification: `Something went wrong. Please try after some time.`,
            success: false
          });
        }
        // localStorage.setItem('simple_thankyou_form', 1)
      });
    }
    else {
      this.setState({blankError: true, loading: false}, ()=>{
      });
    }
  };

  handleSubCategoryChange = (sub, selected) => {
    this.setState(state=> {
      let {business} = state;
      if (selected) {
        business.category.sub_category = [...business.category.sub_category, sub]
      }
      else {
        business.category.sub_category = business.category.sub_category.filter(s => s !== sub);
      }
      return ({business});
    })
  };

  resetForm = (email) => {
    const { activeStep } = this.state;
    this.setState({
      business: {
        ...this.state.business,
        firstName: !email && "",
        lastName: !email && "",
        userPhone: !email && "",
        business_name: !email && "",
        primaryEmail: !email && "",
      },
      valid: true,
      blankError: false,
      activeStep:activeStep+1,
    });
  };

  removeImage = (file, image) => {
    let {business, display_images} = this.state;
    business.galleryImages = business.galleryImages.filter(img=>img.id!==file.id);
    display_images = display_images.filter(img=>img!==image);
    this.setState({business, display_images});
  };

  handleProgramStatus = (status) => {
    let {business} = this.state;
    business['programStatus'] = status;
    this.setState({business});
  };

  handleCategoryChange = (id, selected) => {
    this.setState(state=>{
      let newState;
      let {business} = state;
      if(selected){
        business.category.category = [...business.category.category, id];
        newState = {business, blankError: false};
      }
      else {
        let {categories} = state;
        business.category.category = business.category.category.filter(cat=>cat!==id);
        let category = categories.find(cat=>cat.category===id);
        category.sub_category.forEach(sub=>{
          business.category.sub_category = business.category.sub_category.filter(s=>s!==sub);
        });
        newState = {business};
      }
      return (newState);
    });
  };

  checkValidEmail = (e) => {
    let {business} = this.state;
    if(business.primaryEmail!==""){
      let valid = Boolean(business.primaryEmail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
      this.setState({valid});
      return valid;
    }
    else {
      if(e)
        this.checkEmptyField(e);
    }
  };

  checkEmptyField = (e) => {
    let {business} = this.state;
    if(business[e.target.name] && !business[e.target.name].trim()||!business[e.target.name]){
      this.setState({blankError: true});
    }
  };

  handleBusinessChange = (e) => {
    let {business} = this.state;
    let email=false;
    if(e.target.name==='primaryEmail')
      email = true;
    business[e.target.name] = e.target.value;
    this.setState(state=>{
      if(email)
        return ({business, blankError: false, valid: true});
      else
        return ({business, blankError: false});
    })
  };

  addAnotherProgram = async () => {
    this.setState({
      business: {
        companyLogo: undefined,
        businessName: "",
        programName: "",
        programWebsite: "",
        programTollFreePhone: "",
        crisisPhone: "",
        financialInformation:"",
        address: {
          address_1: "",
          address_2: "",
          city: ["Flagstaff","Online resources"],
          state: "AZ",
          postal_code: "",
          lat: null,
          lng: null,
          PlaceId: ''
        },
        programPhone: "",
        crisisFreePhone: "",
        timing: [
          {day:"Monday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
          {day: "Tuesday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
          {day: "Wednesday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
          {day: "Thursday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
          {day: "Friday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
          {day: "Saturday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""},
          {day: "Sunday", split_hours: false, full_day_open: false, timingSelected: true, open_time:"", close_time: ""}
        ],
        programEligibility: "",
        category: {
          category: [],
          sub_category: []
        },
        county: "",
        cities: ["Flagstaff","Online resources"],
        languages: ['English'],
        programShortDesc: "",
        programLongDesc: "",
        programServices: "",
        galleryImages: [],
        // programStatus: "",
        googleDirectionUrl: ""
      },
      display_logo: '',
      display_images: [],
      valid: true,
      blankError: false,
      validZip: true,
      error:{
        Monday:false, Tuesday:false, Wednesday: false, Thursday: false, Friday: false, Saturday: false, Sunday: false
      },
    }, ()=>{
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // })
    })
  };

  checkValidTime = async () => {
    let {error} = this.state;
    let {timing} = this.state.business;
    await this.setState(state => {
      timing.forEach(day=>{
        if(day.timingSelected){
          if(day.split_hours){
            if(day.shift_one.open_time===""||day.shift_one.close_time===""||day.shift_two.open_time===""||day.shift_two.close_time===""){
              error[day.day] = true;
            }
            else {
              error[day.day] = false;
            }
          }
          else {
            if (day.open_time===""||day.close_time===""){
              error[day.day] = true;
            }
            else {
              error[day.day] = false;
            }
          }
        }
        else {
          error[day.day] = false;
        }
      });
      return ({error: {...state.error, ...error}});
    });
    return (Object.values(this.state.error).includes(true));
  };

  handleChangeTime=(day, e) => {
    let {business, error} = this.state;
    let ind = business.timing.findIndex(t=>t.day===day);
    let timing = business.timing[ind];
    if (timing.split_hours){
      if(e.target.name.match('one')){
        if(e.target.name.match('open')){
          timing['shift_one'] = {
            ...timing['shift_one'],
            'open_time': e.target.value
          }
        }
        else if(e.target.name.match('close')){
          timing['shift_one'] = {
            ...timing['shift_one'],
            'close_time': e.target.value
          }
        }
      }
      else if (e.target.name.match('two')) {
        if (e.target.name.match('open')) {
          timing['shift_two'] = {
            ...timing['shift_two'],
            'open_time': e.target.value
          }
        }
        else if (e.target.name.match('close')) {
          timing['shift_two'] = {
            ...timing['shift_two'],
            'close_time': e.target.value
          }
        }
      }
    }
    else {
      if(e.target.name.match('open')){
        timing['open_time'] = e.target.value;
        timing['shift_one'] = {
          open_time: "", close_time: ""
        };
        timing['shift_two'] = {
          open_time: "", close_time: ""
        }
      }
      else if(e.target.name.match('close')){
        timing['close_time'] = e.target.value;
        timing['shift_one'] = {
          open_time: "", close_time: ""
        };
        timing['shift_two'] = {
          open_time: "", close_time: ""
        }
      }
    }
    error[day] = false;
    if(timing['open_time'] && timing['close_time'])
      this.changeActiveDaysTab(day)
    timing.timingSelected = true;
    business.timing[ind] = timing;
    this.setState({business, error});
  };

  handleClose = () => {
    this.setState({open: false, notification: '', link:false, linkObject:{}});
  };

  openPopup = (e) => {
    this.setState({anchorEl: e.target, anchor: e.target.id})
  };

  closePopup = () => {
    this.setState({anchorEl: undefined, anchor: ''});
  };

  phoneNumberFormatter = (phoneNumber) => {
    var match;
    if (phoneNumber) {
      if (phoneNumber.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)) {
        if (phoneNumber.toString().match(/^\d{10}$/)) {
          match = phoneNumber.toString().replace(/\D /g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          if (match) {
            return match;
          }
        }
        return phoneNumber;
      }
      return phoneNumber;
    }
  };

  toggleEditNumber = (e) => {
    let {value, name} = e.target;
    this.setState(state=> {
      if (value && (!value.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/))) {
        return ({
          editNumber: !state.editNumber,
          editUserNumber:!state.editUserNumber,
          numberError: {...state.numberError, [name]: true},
          valid: false
        })
      }
      else{
        return({editNumber: !state.editNumber, editUserNumber:!state.editUserNumber, valid: true});
      }
    });
  };

  handleNumberError = (name) => {
    let {business} = this.state;
    if(name === 'userPhone') {
      if (business[name] && (!business[name].toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/))) {
        this.setState(state => ({numberError: {...state.numberError, [name]: true}, blankError: false}))
      }
      if (business[name] && (!business[name].toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/))) {
        this.setState(state => ({numberError: {...state.numberError, [name]: true}}))
      }
    }
  };

  handlePhoneNumber = (e) => {
    let {name, value}= e.target;
    this.setState(state=>({
      business: {
        ...state.business, [name]: value
      },
      numberError: {
        ...state.numberError,
        [name]: false,
      }
    }))
  };

  handleNextStep = async() => {
    await this.validateFullForm()
    this.setState((prevState, props) => {
      let numError = Object.keys(prevState.numberError).filter((value) => {
        if(prevState.numberError[value])
          return value;
      })
      if(!prevState.mainError && prevState.valid && numError.length===0 && prevState.validZip) {
        return {...this.state, blankError:false , showError:{}, activeStep: this.state.activeStep + 1}
      }
      else{
        return {...this.state}
      }
    },()=>{
      window.scrollTo(0,0);
    });
  }

  handleBackStep = () => {
    this.setState({...this.state,activeStep : this.state.activeStep - 1})
  }

  componentDidUpdate(prevProps){
    window.scrollTo(0,0)
  }

  validateFullForm = async () => {
    let { activeStep, showError, eachTabFields, business } = this.state;
    let mainError = false;
    if(eachTabFields[activeStep][0]==='programTiming'){
      let error = await this.checkValidTime();
      if(error) {
        showError['programTiming'] = true;
        mainError = true;
      } else {
        showError['programTiming'] = false;
      }
    } else {
      eachTabFields[activeStep].forEach((value) => {
        let fetchValue = this.getValue(business,value);

        if(!Array.isArray(fetchValue) && fetchValue.trim('') === ''){
          value = value.split('.')
          let index = value.length > 1 ? value[1] : value[0];
          showError[index] = true;
          mainError = true;
        }
        else if(Array.isArray(fetchValue) && fetchValue.length===0) {
          value = value.split('.')
          let index = value.length > 1 ? value[1] : value[0];
          showError[index] = true;
          mainError = true;
        }
        else {
          showError[value] = false;
        }
      })
    }
    this.setState({...this.state, mainError, showError:{...this.state.showError,...showError}})
    return ;
  }

  getValue = (obj,path) => {
    let split = path.split('.');
    let finalValue = path === 'city' ? {...obj.address} : {...obj};
    split.forEach((value)=>{
      if(finalValue[value]) {
        finalValue = finalValue[value];
      }
      else {
        finalValue = '';
        return false;
      }
    })
    return finalValue
  };

  getStepContent = () => {
    const {
      business,valid, blankError,anchorEl, anchor, numberError,showError,editUserNumber } = this.state;
    const {matches, classes, auth} = this.props;
    const isAdmin = Object.keys(auth).length && (auth.accessType === "Admin" || auth.accessType === "Super Admin");
    const token = Object.keys(auth).length && auth.token !== "";

    return <div className="div-wrapper">
      <div className="title-each">Contact Information</div>
      <Grid container>
        <Grid item xs={12} sm={12} lg={12}>
          <div className="program-form-group">
            <div style={{display:'flex'}}>
              <label className="program-form-lable">Primary Email <span className="required-label">*</span>
              </label>
              <FieldPopup id="email-field-popup" open={anchor === "email-field"}
                          anchorEl={anchor === "email-field" ? anchorEl : undefined} onClose={this.closePopup}/>
              <Info className="info-icon" alt=""
                    aria-owns={anchor === "email-field" ? 'email-field-popup' : undefined}
                    onMouseEnter={this.openPopup} onMouseLeave={this.closePopup} id="email-field"/>
            </div>
            <TextField
               // InputProps={{
               //   readOnly: false,
               // }}
              onBlur={this.checkValidEmail}
              className="program-form-inputborder without-padding"
              classes={{root: (matches ? classes.phone : classes.pc)}}
              name="primaryEmail"
              onChange={this.handleBusinessChange}
              value={business.primaryEmail}
              type="email"
              variant="outlined"/>
            {!valid ?
              <p className="error-message" name="error">The email address you have entered is invalid.</p> : null}
            {token && (blankError || showError.primaryEmail) && business.primaryEmail =="" ?
              <p name="error" className="error-message">Please enter your email address.</p> : null}
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className="program-form-group">
            <div style={{display: "flex"}}>
              <label>Your Phone Number</label>
            </div>
            {editUserNumber ?
              <TextField
                name="userPhone"
                classes={{root: matches ? classes.phone : classes.pc}}
                type="text"
                onChange={this.handlePhoneNumber}
                onBlur={this.toggleEditNumber}
                variant="outlined"
                className="without-padding"
                value={business.userPhone || ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <div style={{display: 'flex', alignItems: "center"}}>
                        <img src={this.state.flagUrl} className="country-flag"></img>
                        <div style={{marginLeft: '5px'}}>+1</div>
                      </div>
                    </InputAdornment>
                  )
                }}
              />
              : <TextField
                readOnly
                classes={{root: matches ? classes.phone : classes.pc}}
                className="program-form-inputborder without-padding"
                type="text"
                variant="outlined"
                onFocus={this.toggleEditNumber}
                value={this.phoneNumberFormatter(business.userPhone) || ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <div style={{display: 'flex', alignItems: "center"}}>
                        <img src={this.state.flagUrl} className="country-flag"></img>
                        <div style={{marginLeft: '5px'}}>+1</div>
                      </div>
                    </InputAdornment>
                  )
                }}
              />}
              { (blankError || showError.userPhone) && business.userPhone =="" ?
                <p className="error-message" name="error">Please enter your phone number</p> : null}
              {numberError.userPhone ?
                <p name="error" className="error-message">This phone number is not valid</p> : null}
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} lg={12}>
          <div className="program-form-group">
            <div>
              <label>First Name <span className="required-label">*</span></label>
            </div>
            <TextField
              onBlur={this.checkEmptyField}
              classes={{root: matches ? classes.phone : classes.pc}}
              name="firstName"
              onChange={this.handleBusinessChange}
              className="without-padding"
              value={business.firstName}
              type="text"
              variant="outlined"/>
            {blankError && business.firstName === '' ?
            <p name="error" className="error-message">Please enter your First Name.</p>:null}
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className="program-form-group">
            <div>
              <label>Last Name <span className="required-label">*</span></label>
            </div>
            <TextField
              onBlur={!isAdmin ? this.checkEmptyField : () => {
              }}
              classes={{root: matches ? classes.phone : classes.pc}}
              name="lastName"
              className="without-padding"
              onChange={this.handleBusinessChange}
              value={business.lastName}
              type="text"
              variant="outlined"/>
            {blankError && business.lastName === '' ?
            <p name="error" className="error-message">Please enter your Last Name.</p> : null}
          </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} lg={12}>
          <div className="program-form-group">
            <div style={{display: 'flex'}}>
              <label>Company Name <span className="required-label">*</span></label>
            </div>
            <TextField
              onBlur={this.checkEmptyField}
              classes={{root: matches ? classes.phone : classes.pc}}
              name="business_name"
              className="without-padding"
              onChange={this.handleBusinessChange}
              value={business.business_name}
              type="text"
              variant="outlined"/>
            {blankError && (business.business_name === '' || business.business_name === undefined) ?
            <p name="error" className="error-message">Please enter the name of your business.</p>: null}
          </div>
        </Grid>
      </Grid>
    </div>
  }

  render() {

    const {open, notification, success, activeStep, steps} = this.state;
    const {classes, matches} = this.props;
    {activeStep === steps.length ? localStorage.setItem('is_thankyou_page_active', 1) :
      localStorage.setItem('is_thankyou_page_active', 0)}
    return (
      <div className="container" id="container-element">
        <div className={classes.root}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {root: success ? classes.successNotif : classes.errorNotif},
              style: {maxWidth: '631px'}
            }}
            message={<span>{Array.isArray(notification) ? notification.map((x, key) => <span
              key={key}>{x}</span>) : notification}
          </span>}
            action={
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CancelCircle />
              </IconButton>}
          />
        </div>
        <div className="form-container">
          <div style={{marginTop:'75px'}}>
            <div className="form">
              {activeStep === steps.length ? (
                <div className="outer-div-wrapper-submit">
                  <div className={classes.instructions}>
                    <p className="submit-title">Thank you!</p>
                    <p className="check-mark"><CheckCircle classes={{root:matches?classes.checkCirclePhone:classes.checkCircle}}/></p>
                    <p className="submit-message">You will be receiving an email shortly with a link to set up your password.
                      When you login, you will be able to manage and edit your listing, add any additional
                      programs, add special events, and more... If you have any questions please email
                      andrea.nazresources@gmail.com
                    </p>
                  </div>
                </div>
              ) : (
                <div className="outer-div-wrapper">
                  <div className={classes.instructions}>
                    {this.getStepContent()}
                  </div>
                  <div className={activeStep !== 0 ? "btn-weapper":"btn-single-weapper"}>
                    <Button onClick={this.handleContactSubmit} className={classes.button+" btnMobile"}>
                      Send
                    </Button>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  return {
    auth: state.auth,
    category: state.category,
    business: state.business
  }
};

const mapDispatch = (dispatch) => {
  return {
    // getAllCategories: bindActionCreators(getAllCategories, dispatch),
    sendPasswordSetupEmail: bindActionCreators(sendPasswordSetupEmail, dispatch)
  }
};

export default connect(mapState, mapDispatch)(withRouter(withStyles(styles)(UserContactInfo)));