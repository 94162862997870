import React, {Component} from 'react';
import {connect} from 'react-redux';
import CloseIcon from '@material-ui/icons/CloseRounded'
import StarRatings from 'react-star-ratings'
import Review from '../Review/Review';
import './ReviewerList.css'

import {
    Modal,
    withStyles,
    Button as MUIButton,
    IconButton
} from '@material-ui/core';
import QuestionMark from "../../assets/images/question_mark_icon.png";
import ChatIcon from "../../assets/images/chat_icon.png";

const Button = withStyles({
    root: {
        background: '#245497',
        color: '#fff',
        borderColor: '#245497',
        borderRadius: 1,
        '&:hover': {
            background: '#224989',
        }
    }
})(MUIButton);

const styles = () => ({
    reviewText: {
        minHeight: 69,
        maxHeight: 150,
        overflowY: 'scroll',
        alignItems: "flex-start",
        padding: '1%'
    },
    mobileModal: {
        margin: 'auto',
        width: 'auto',
        height: 'auto',
        padding: '40% 6% 6% 6%',
    },
    modal: {
        width: '60%',
        height: 'auto',
        padding: '6%',
    },
});

class ReviewerList extends Component {

    state = {
        openReview: false,
        review: {},
        notification: false,
        success: false,
        message: '',
    };

    closeReviewPopup = () => {
        this.setState({
            openReview: false
        })
    };

    openReviewPopup = () => {
        this.setState({
            openReview: true
        })
    };

    showMessage = (message, success) => {
        this.setState({message, success, notification: true})
    };

    render() {
        const {open, resource, matches, onClose, calculateRating, classes, showMessage} = this.props;
        let {openReview} = this.state;
        let reviewerList = Object.keys(resource).length && resource.reviews.map((review, key) => {
            return (
                <div key={key} className="single-review">
                    <h3>{review.review_subject}</h3>
                    <div className={'rating-review-count d-flex'}>
                        <p>{(review.rating).toFixed(1)}</p>
                        <StarRatings
                            rating={review.rating}
                            starDimension="13px"
                            starSpacing="2px"
                            starRatedColor="orange"
                            starEmptyColor="#cbd3e3"
                            name="starRating"
                            className="rating"
                        />
                    </div>

                    <div className="divider-line"/>
                    <p>{review.review_text}</p>
                    Reviewed by {review.name === "" ? review.email : review.name}
                    <div>
                        {review.business_name || ""}
                        {review.reply && review.reply.reply !== ""
                            ? <div>
                                {review.reply.reply}
                                <p className="by-line">Replied by {review.reply.user.email}</p>
                            </div>
                            : null}
                    </div>

                </div>
            )
        });
        return (
            <div>
                <Modal open={open} onClose={onClose}
                       classes={{root: matches ? classes.mobileModal : classes.modal + " modal-main-div"}}>
                    <div className="main-review-container review-div">
                        <div className="close-icon">
                            <IconButton onClick={onClose}><CloseIcon/></IconButton>
                        </div>
                        <div className="titlebar">
                            <div className="main-question-title-header">
                                <div className="main-title-header">
                                    <h2>{resource.program_name}</h2>
                                    <span>
                                      <div className="header-total-rating">
                                        <h3>{calculateRating(resource).toFixed(1)}</h3>
                                        <StarRatings
                                            rating={calculateRating(resource)}
                                            starDimension="13px"
                                            starSpacing="2px"
                                            starRatedColor="orange"
                                            starEmptyColor="#cbd3e3"
                                            name="starRating"
                                            className="rating review-rating-title"
                                        />
                                        <p>({resource.reviews.length || 0} {resource.reviews.length !== 1 ? 'Reviews' : 'Review'})</p>
                                      </div>
                                    </span>
                                </div>
                                <span className="review-button">
                                <div className={'div-wrap-img'} onClick={this.openReviewPopup}>
                                    <img src={ChatIcon} alt={'image'} className={'img-size'}/>
                                    ADD A REVIEW
                                </div>
                            </span>
                            </div>
                        </div>
                        <div className="list-container">
                            <div className="reviewers-total-list">
                                {reviewerList.length ? reviewerList :
                                    <center>Nothing to show.Be first to Review.</center>}
                            </div>
                        </div>
                    </div>
                </Modal>
                {openReview ? (
                    <Review
                        open={openReview}
                        onClose={this.closeReviewPopup}
                        resource={resource}
                        matches={matches}
                        showMessage={showMessage}
                        hideMessage={this.props.hideMessage}
                    />
                ) : (
                    <></>
                )}
            </div>
        )
    }
}


const mapState = (state) => ({});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(withStyles(styles)(ReviewerList));