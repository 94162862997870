import React from 'react'
import {
  FormControl,
  OutlinedInput,
  Select,
  withStyles,
  InputLabel,
  MenuItem,
  Checkbox as MUICheckbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import '../ProgramForm.css';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// import TimePicker from '@material-ui/lab/TimePicker';
const Checkbox = withStyles({
  root: {
    '&$checked': {
      color: '#245497',
    },
  },
  checked: {},
})(MUICheckbox)

const styles = () => ({
  root: {
    minWidth: '300px',
    padding: '0 10px 0 0',
  },
  mobile: {
    minWidth: '250px',
    padding: '0',
  },
})
const ProgramTiming = (props) => {
  let array = props.timing.timingSelected
    ? props.timing.split_hours
      ? ['one', 'two']
      : ['one']
    : []
  let x = 30
  let times = []
  let tt = 0
  let ap = ['AM', 'PM']
  let disabled = props.auth.accessType === 'Read Only' ? true : false;

  for (let i = 0; tt < 24 * 60; i++) {
    let hh = Math.floor(tt / 60)
    let mm = tt % 60
    times[i] = {
      label:
        '' +
        (hh === 0 || hh === 12 ? 12 : hh % 12) +
        ':' +
        ('0' + mm).slice(-2) +
        ' ' +
        ap[Math.floor(hh / 12)],
      value: hh + ':' + ('0' + mm).slice(-2),
    }
    tt = tt + x
  }

//  console.log('props.timing.split_hours',props.timing.open_time)

  return (
    <div className="timing-wrapper program-timing-wrap">
      <div className="timing-container">
        <div className="program-timing ">
          {array.map((_) => (
            <div className={'hr-operation'} key={_}>
              <div className={'from'}>
                <div className={'label-text'}>from</div>

                <FormControl variant="outlined" margin="dense">
                  {/* <TextField
                    id="time"
                    type="time"
                    name={`${props.day}-open-time${_}`}
                    onChange={(event) => props.handleChangeTime(props.day, event)}
                    // value={props.timing.open_time}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, 
                    }}
                  /> */}
                  {/* <input
                    className={'inputbox newTimePicker'}
                    disabled={disabled}
                    name={`${props.day}-open-time${_}`}
                    style={{ textAlign: 'left' }}
                    onChange={(event) =>
                      props.handleChangeTime(props.day, event)
                    }
                    type="time"
                    // defaultValue="13:00"
                    value={
                      props.timing.timingSelected
                        ? props.timing.split_hours
                          ? props.timing[`shift_${_}`].open_time
                          : new Date(`May 1,2019 ${props.timing.open_time}:00`)  
                        : new Date("May 1,2019 13:00:00") 
                    }
                    // input={<OutlinedInput style={{height: 47 }} id={`${props.day}-open-time${_}`}/>}
                    
                  ></input> */}
              
                  <Select
                      className={'inputbox'}
                      disabled={disabled}
                      name={`${props.day}-open-time${_}`}
                      style={{textAlign:'left'}}
                      onChange={(event)=>props.handleChangeTime(props.day, event)}
                      value={props.timing.timingSelected?(props.timing.split_hours?props.timing[`shift_${_}`].open_time:props.timing.open_time):""}
                      input={<OutlinedInput style={{height: 47 }} id={`${props.day}-open-time${_}`}/>}
                  >
                    <MenuItem value="">None</MenuItem>
                    {times.map((time,i)=>
                        <MenuItem key={i} value={time.value}>
                          {time.label}
                        </MenuItem>)}
                  </Select>
              
                </FormControl>
                {/*<RSelect*/}
                {/*  className={'inputbox'}*/}
                {/*  placeholder=""*/}
                {/*  name={`${props.day}-open-time${_}`}*/}
                {/*  options={times}*/}
                {/*  onChange={(event)=>props.handleRSelectChangeTime(props.day, event, `${props.day}-open-time${_}`)}*/}
                {/*/>*/}
              </div>
              <div className={'to'}>
                <div className={'label-text'}>to</div>
                <FormControl variant="outlined" margin="dense">
                  {/* <input
                    className={'inputbox newTimePicker'}
                    disabled={disabled}
                    style={{ textAlign: 'left', width: '100%' }}
                    name={`${props.day}-close-time${_}`}
                    onChange={(event) =>
                      props.handleChangeTime(props.day, event)
                    }
                    value={
                      props.timing.timingSelected
                        ? props.timing.split_hours
                          ? props.timing[`shift_${_}`].close_time
                          : props.timing.close_time
                        : ''
                    }
                    type="time"
                  ></input> */}
                  <Select
                      className={'inputbox'}
                      disabled={disabled}
                      style={{textAlign:'left', width: '100%'}}
                      name={`${props.day}-close-time${_}`}
                      onChange={(event)=>props.handleChangeTime(props.day, event)}
                      value={props.timing.timingSelected?(props.timing.split_hours?props.timing[`shift_${_}`].close_time:props.timing.close_time):""}
                      input={<OutlinedInput style={{height: 47 }} id={`${props.day}-close-time${_}`}/>}
                  >
                    <MenuItem value="">None</MenuItem>
                    {times.map((time, i)=>
                        <MenuItem key={i} value={time.value}>
                          {time.label}
                        </MenuItem>)}
                  </Select>
              
                </FormControl>
              </div>
            </div>
          ))}
        </div>
        <div className="program-split-hours">
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                onChange={(e, selected) =>
                  props.handleFullDayOpen(props.day, selected)
                }
                checked={props.full_day_open && !props.timingSelected}
              />
            }
            label="Open 24 hours today"
          />
        </div>
      </div>
    </div>
  )
}

const mapState = (state) => {
  return {
    auth: state.auth,
  }
}
export default connect(mapState, null)(withStyles(styles)(ProgramTiming))
