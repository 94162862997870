import React, { Component } from 'react'
import SidePanel from '../SidePanel/SidePanel'
import './PageTemplate.css'
import { connect } from 'react-redux'
import qs from 'query-string'
import { Snackbar, IconButton, withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import { bindActionCreators } from 'redux'
import * as businessActions from '../../actions/business.action'
import { getAllCategories } from '../../actions/category.action.js'
import * as AllCategory from '../../assets/images/all_cat.jpg'

const styles = () => ({
  error: {
    backgroundColor: '#d32f2f',
  },
  success: {
    backgroundColor: '#32b241',
    width: 'auto',
  },
  iconBtn: {
    marginLeft: 5,
  },
})
class PageTemplate extends Component {
  constructor(props) {
    super(props)
    this.childMap = React.createRef()
    this.state = {
      selectedSubCategories: [],
      openDetailBox: false,
      selectedResource: {},
      isFiltered: false,
      filteredData: [],
      markerHovered: {},
      hoveredPin: 0,
      selectedPin: 0,
      hovered: false,
      notification: false,
      message: '',
      success: false,
      isMobile: false,
      link: false,
      linkObject: {},
      selectedForm: '',
      selectedCategories: {
        category: [],
        sub_category: [],
      },
      categories: [],
      bannerImage: AllCategory,
      headerTitle: [],
      selectedCity: ['Flagstaff'],
      selectedNewCity: [],
      reopenListing: 0,
    }
  }
  withMap = this.props.history.location.pathname === '/all'

  componentDidMount() {
    const { openDetailBox } = this.state
    window.onpopstate = () => {
      const { pathname } = window.location
      if (!openDetailBox) {
        if (pathname.indexOf('all') > -1) {
          this.setState({ openDetailBox: '' })
        }
        if (pathname.indexOf('search') > -1) {
          this.setState({ openDetailBox: '' })
        }
      }
    }
    if (
      this.props.search !== '' ||
      (this.props.match && this.props.match.path.includes('search'))
    ) {
      const filters = this.props.match
        ? qs.parse(this.props.history.location.search)
        : qs.parse(this.props.search)
      let { selectedCategories, selectedCity } = this.state
      if (filters.sub_category) {
        if (typeof filters.sub_category === 'string') {
          filters.sub_category = [filters.sub_category]
        }
        selectedCategories.sub_category = [...filters.sub_category]
      } else {
        selectedCategories.sub_category = []
      }
      if (filters.category) {
        if (
          typeof filters.category === 'string' &&
          filters.category !== 'All'
        ) {
          filters.category = [filters.category]
        }
        selectedCategories.category = [...filters.category]
      } else {
        selectedCategories.category = []
      }
      if (filters.cities) {
        if (typeof filters.cities === 'string') {
          filters.cities = [filters.cities]
        }
        selectedCity = [...filters.cities]
      } else {
        selectedCity = []
      }
      this.setState({ selectedCategories, selectedCity }, () => {
        this.onFilter()
      })
    } else if (this.props.match && this.props.match.params.query) {
      this.onFilter()
    }
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    this.setState({ isMobile: isMobile.any() })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      ((!newProps.history.location.pathname.includes('/gallery') &&
        newProps.search !== this.props.search) ||
        newProps.queryFound) &&
      !this.props.history.location.search.includes('email') &&
      !this.props.search.includes('email')
    ) {
      if (newProps.search !== '') {
        const filters = qs.parse(newProps.search)
        let { selectedCategories, selectedCity } = this.state
        if (filters.sub_category) {
          if (typeof filters.sub_category === 'string') {
            filters.sub_category = [filters.sub_category]
          }
          selectedCategories.sub_category = [...filters.sub_category]
        } else {
          selectedCategories.sub_category = []
        }
        if (filters.category) {
          if (
            typeof filters.category === 'string' &&
            filters.category !== 'All'
          ) {
            filters.category = [filters.category]
          }
          selectedCategories.category = [...filters.category]
        } else {
          selectedCategories.category = []
        }
        if (filters.cities) {
          if (typeof filters.cities === 'string') {
            filters.cities = [filters.cities]
          }
          selectedCity = [...filters.cities]
        } else {
          selectedCity = []
        }
        this.setState({ selectedCategories, selectedCity }, () => {
          this.onFilter()
        })
      } else {
        let { selectedCategories, selectedCity } = this.state
        selectedCategories = {
          category: [],
          sub_category: [],
        }
        selectedCity = []

        this.setState({ selectedCategories, selectedCity }, () => {
          this.onFilter()
        })
      }
    } else if (
      newProps.match &&
      this.props.match.params.query !== newProps.match.params.query
    ) {
      this.onFilter()
    }
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    if (this.state.isMobile !== isMobile.any()) {
      this.setState({ isMobile: isMobile.any() })
    }
    if (newProps.initialSelectedResources && newProps.initialOpenDetailBox) {
      if (Array.isArray(newProps.resources) && newProps.resources.length) {
        let selectedResource = newProps.resources.filter((data) => {
          if (data.id === newProps.initialOpenDetailBox) return data
        })
        if (selectedResource.length) {
          this.setState({
            selectedResource: { ...newProps.initialSelectedResources },
            openDetailBox: newProps.initialOpenDetailBox,
            selectedForm: newProps.selectedForm,
          })
        } else {
          this.props.removeIdAndForm()
          let location = this.props.history.location.pathname
          if (location[0] === '/') location = location.slice(1)
          if (location[location.length - 1] === '/')
            location = location.slice(0, -1)
          location = location.split('/')
          let newLocation = []
          location.forEach((value, index) => {
            if (location.length - 2 > index) newLocation.push(value)
          })
          this.props.history.push('/' + newLocation.join('/'))
        }
      }
    } else {
      this.setState({
        selectedForm: '',
      })
    }
    if (
      this.props.history.location.state &&
      this.props.history.location.state.reopenState
    ) {
      this.setState({
        reopenListing: this.props.history.location.state.reopenState,
      })
    }
    if (
      this.state.reopenListing === 1 &&
      this.props.history.location.state &&
      newProps.history.location.state.resourceId
    ) {
      this.handleReopenResourceItem(newProps.history.location.state.resourceId)
    }
  }
  handleReopenResourceItem = (resourceId) => {}

  onPinClick = (selectedPin) => {}

  onRemoveFilter = (id) => {}

  onRemoveCategory = (id) => {}

  removeCityFilter = (id) => {}

  changeRating = (newRating) => {}
  onFilter = () => {}

  handleCityChange = (city, selected) => {
    let { selectedCity } = this.state
    this.setState(() => {
      if (selected) {
        return { selectedCity: [...selectedCity, city] }
      } else {
        selectedCity = selectedCity.filter((c) => c !== city)
        return { selectedCity }
      }
    })
  }

  onPointerLeaveResource = () => {
    this.props.onPointerLeaveResource()
  }

  toggleTooltip = () => {
    this.props.toggleToolTip(true)
  }

  showMessage = (message, success, link, linkObject) => {
    if (!link) this.setState({ message, success, notification: true })
    else
      this.setState({
        message,
        success,
        link,
        linkObject: { ...linkObject },
        notification: true,
      })
  }

  hideMessage = () => {
    this.setState({ notification: false, link: false, linkObject: {} })
  }

  onHandleClick = async (resource) => {}

  closeDetailBox = () => {
    this.setState({
      openDetailBox: false,
      selectedPin: 0,
      hoveredPin: 0,
    })
  }

  removeAllFilters = () => {}

  render() {
    // console.log(this.withMap,{history:this.props.history.location.pathname})
    let {
      icon,
      category,
      resources,
      subcategories,
      loading,
      matches,
      classes,
      toggleTip,
      history,
      auth,
    } = this.props
    let {
      selectedCity,
      selectedSubCategories,
      openDetailBox,
      selectedResource,
      isFiltered,
      filteredData,
      markerHovered,
      selectedPin,
      hovered,
      notification,
      message,
      success,
      isMobile,
      link,
      linkObject,
      selectedForm,
      selectedCategories,
      bannerImage,
      headerTitle,
      categories,
    } = this.state
    let markers = []
    if (resources.length > 0) {
      if (isFiltered) {
        filteredData.forEach((res) => {
          if (res.address.lat) {
            markers.push({
              id: res.id,
              name: res.program_name,
              category: res.category ? res.category.category[0] : '',
              position: {
                lat: parseFloat(res.address.lat),
                lng: parseFloat(res.address.lng),
              },
            })
          }
        })
      } else {
        resources.forEach((res) => {
          if (res.address && res.address.lat) {
            markers.push({
              id: res.id,
              name: res.program_name,
              position: {
                lat: parseFloat(res.address.lat),
                lng: parseFloat(res.address.lng),
              },
            })
          }
        })
      }
    }
    return (
      <div className={`pageTemplate-container`}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ width: 'auto' }}
          open={notification}
          // autoHideDuration={8000}
          onClose={this.hideMessage}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: { root: success ? classes.success : classes.error },
          }}
          message={
            <div className="notification-content">
              <span id="message-id">
                {message}
                <br />
                {link ? (
                  <a
                    target="blank"
                    style={{ color: 'white', textDecoration: 'underline' }}
                    href={'mailto:' + linkObject.link}
                  >
                    {linkObject.message}
                  </a>
                ) : (
                  ''
                )}
              </span>
              <IconButton
                classes={{ root: classes.iconBtn }}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.hideMessage}
              >
                <CloseIcon />
              </IconButton>
            </div>
          }
        />
        <div className="sidePanel-container">
          <SidePanel
            matches={matches}
            category={category}
            icon={icon}
            resources={isFiltered ? filteredData : resources}
            subcategories={subcategories}
            selectedCity={selectedCity}
            selectedSubCategories={selectedSubCategories}
            handleSubCategoriesChange={this.handleSubCategoriesChange}
            handleCityChange={this.handleCityChange}
            changeRating={this.changeRating}
            removeCityFilter={this.removeCityFilter}
            onRemoveFilter={this.onRemoveFilter}
            filteredResources={isFiltered ? filteredData : resources}
            onPointerEnterResource={this.onPointerEnterResource}
            onPointerLeaveResource={this.onPointerLeaveResource}
            onHandleClick={this.onHandleClick}
            openDetailBox={openDetailBox}
            closeDetailBox={this.closeDetailBox}
            selectedResource={selectedResource}
            onFilter={this.onFilter}
            loading={loading}
            markerHovered={markerHovered}
            selectedPin={selectedPin}
            // hoveredPin={hoveredPin}
            auth={auth}
            showMessage={this.showMessage}
            hideMessage={this.hideMessage}
            isMobile={isMobile}
            markers={markers}
            selectedForm={selectedForm}
            removeIdAndForm={this.props.removeIdAndForm}
            handleCategoryChange={this.handleCategoryChange}
            selectedCategories={selectedCategories}
            onRemoveCategory={this.onRemoveCategory}
            bannerImage={bannerImage}
            headerTitle={headerTitle}
            categories={categories}
            toggleTip={toggleTip}
            toggleTooltip={this.toggleTooltip}
            removeAllFilters={this.removeAllFilters}
          />
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  const { business } = state
  return {
    business,
    categories: state.category,
    auth: state.auth,
  }
}

const mapDispatch = (dispatch) => {
  return {
    businessActions: bindActionCreators(businessActions, dispatch),
    getAllCategories: bindActionCreators(getAllCategories, dispatch),
  }
}
export default connect(mapState, mapDispatch)(withStyles(styles)(PageTemplate))
