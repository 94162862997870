import React, { Component } from 'react'
import Question from '../Question/Question'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import copy from 'copy-to-clipboard'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  withStyles,
  Snackbar,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import QuestionList from '../QuestionList/QuestionList'
import BusinessAccess from '../BusinessAccess/BusinessAccess'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as businessActions from '../../actions/business.action'
import parse from 'html-react-parser'
import './Content.css'
import '../App.css'
import ReactPlayer from 'react-player'
import moment from 'moment-timezone'
import QuestionMark from '../../assets/images/question_mark_icon.png'
import Pen from '../../assets/images/pen.png'
import gray_time_icon from '../../assets/images/gray_time_icon.png'
import donation from '../../assets/images/donation.png'
import default_youtube from '../../assets/images/new_default_youtube.jpg'
import { BaseUrl } from '../../utils/Const'
import _ from 'lodash'
import ContactForm from '../ContactForm/ContactForm'
import {
  faPhoneAlt,
  faHandsHelping,
  faShareAlt,
  faMap,
  faTimes,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons'
import HeaderMenu from '../Menu/Menu'
import { openedItem } from '../../actions/common.action'
import Gallery from '../Gallery/Gallery'

const styles = {
  root: {
    boxShadow: 'none',
    width: '100%',
    '&::before': {
      top: '-1px',
      right: 0,
      left: 'unset !important',
      height: '0px',
      content: "''",
      opacity: 1,
      position: 'absolute',
      transition:
        'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  content: {
    margin: 0,
  },
  expanded: {
    margin: '0!important',
  },
  btn: {
    color: 'white',
    fontSize: '18px',
    fontWeight: 600,
  },
  iconRoot: {
    padding: '0!important',
  },
  contentDivider: {
    marginTop: '20px',
    backgroundColor: 'lightgrey',
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.12)',
  },
  error: {
    backgroundColor: '#d32f2f',
  },
  success: {
    backgroundColor: '#32b241',
  },
  iconBtn: {
    marginLeft: 5,
  },
}

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
class Content extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      resource: {},
      isMobile: false,
      review: false,
      question: false,
      claimListing: false,
      notification: false,
      success: false,
      message: '',
      editProgram: false,
      unClaim: false,
      openReviewer: false,
      readMore: false,
    }
  }
  componentDidMount() {
    if (this.props.openedItem) {
      this.setState({ resource: this.props.openedItem })
    }

    if (this.props.selectedForm) {
      if (this.props.selectedForm === 'claim') {
        this.claimBusiness()
      }
    }

    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    this.setState({ isMobile: isMobile.any() })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.openedItem._id !== this.props.openedItem._id) {
      this.setState({ resource: this.props.openedItem })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    if (this.props.match && this.props.match.params) {
      this.props.history.push({
        pathname: '/all?cities=Flagstaff&',
        state: { rid: parseInt(this.props.match.params.rid), reopenState: 1 },
      })
    }
    if (this.state.isMobile !== isMobile.any()) {
      this.setState({ isMobile: isMobile.any() })
    }
    if (
      this.props.resource &&
      nextProps.business.email !== this.props.resource.email
    )
      this.setState({ resource: nextProps.resource })
  }

  addQuestion = () => {
    this.setState({ question: true })
  }

  questionClose = () => {
    this.setState({ question: false })
  }

  claimBusiness = () => {
    this.setState({ claimListing: true })
  }

  claimBusinessClose = () => {
    this.setState({ claimListing: false }, () => {
      if (this.props.selectedForm) {
        this.props.removeIdAndForm()
        let location = this.props.history.location.pathname
        if (location[0] === '/') location = location.slice(1)
        if (location[location.length - 1] === '/')
          location = location.slice(0, -1)
        location = location.split('/')
        let newLocation = []
        location.forEach((value, index) => {
          if (location.length - 2 > index) newLocation.push(value)
        })
        this.props.history.push('/' + newLocation.join('/'))
      }
    })
  }

  editProgramOpen = () => {
    this.setState({ editProgram: true })
  }

  editProgramClose = () => {
    this.setState({ editProgram: false })
  }

  sortDays = (timing) => {
    const sortDays = {
      sunday: 1,
      monday: 2,
      tuesday: 3,
      wednesday: 4,
      thursday: 6,
      friday: 6,
      saturday: 7,
    }
    if (!timing) {
      return []
    }
    return timing.sort((a, b) => {
      if (!(a.day || b.day)) {
        return 0
      }
      let day1 = a.day.toLowerCase()
      let day2 = b.day.toLowerCase()
      return sortDays[day1] - sortDays[day2]
    })
  }

  handleGalleryClick = () => {
    this.setState({ openGallery: true })
    const { resource, isMobile } = this.state
    // this.props.history.push({
    //   pathname: `/gallery`,
    //   state: {
    //     resource,
    //     isMobile,
    //     listId: this.props.openDetailBox,
    //     reopenState: 1,
    //   },
    // })
  }

  phoneNumberFormatter = (phoneNumber) => {
    var match
    if (phoneNumber) {
      if (phoneNumber.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)) {
        if (phoneNumber.toString().match(/^\d{10}$/)) {
          match = phoneNumber
            .toString()
            .replace(/\D /g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          if (match) {
            return match
          }
        }
        return phoneNumber
      }
      return phoneNumber
    }
  }

  handleExpandClick = (e, expanded) => {
    this.setState({
      expanded,
    })
  }

  goBack = () => {
    this.props.closeModal()
  }

  showMessage = (message, success) => {
    this.setState({ message, success, notification: true })
  }

  hideMessage = () => {
    this.setState({ notification: false })
  }

  copyToClipBoard = () => {
    const { resource } = this.state
    const el = BaseUrl + '/details/' + resource._id

    copy(el)
    this.setState({
      notification: true,
      message: 'Link copied to clipboard',
      success: true,
    })
  }
  setTitleCase = (title) => {
    return title
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }
  render() {
    if (!this.props.openedItem) {
      return null
    }

    const { props } = this
    const { matches } = this.props
    const {
      expanded,
      resource,
      question,
      isMobile,
      claimListing,
      rating,
      notification,
      success,
      message,
      editProgram,
    } = this.state
    let pattern = /^((http|https|ftp):\/\/)/
    let finanacialInfo = ''
    let eligibility = ''
    let long_description = ''
    if (
      resource.financialInformation &&
      resource.financialInformation.length > 0
    ) {
      var financialInformation = resource.financialInformation
      finanacialInfo = financialInformation.replace(/<[^>]*>/g, '')
    }
    if (
      resource.program_eligibility &&
      resource.program_eligibility.length > 0
    ) {
      var eligibility1 = resource.program_eligibility
      eligibility = eligibility1.replace(/<[^>]*>/g, '')
    }
    if (resource.long_description && resource.long_description.length > 0) {
      var long_description1 = resource.long_description
      long_description = long_description1.replace(/<[^>]*>/g, '')
    }

    let showMessage = props.showMessage
    let hideMessage = props.hideMessage

    let gallery_images = []
    if (resource.gallery_images) {
      gallery_images = resource.gallery_images.filter(
        (img) => img && img.approved
      )
    }

    return (
      resource && (
        <div className={'res_det'}>
          {claimListing ? (
            <BusinessAccess
              open={claimListing}
              onClose={this.claimBusinessClose}
              resource={resource}
              matches={props.matches}
              showMessage={showMessage}
            />
          ) : (
            <></>
          )}
          {editProgram ? (
            <ContactForm
              open={editProgram}
              onClose={this.editProgramClose}
              matches={matches}
            />
          ) : (
            <></>
          )}
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ width: 'auto' }}
            open={notification}
            onClose={this.hideMessage}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {
                root: success ? props.classes.success : props.classes.error,
              },
            }}
            message={
              <div className="notification-content">
                <span id="message-id">{message}</span>
                <IconButton
                  classes={{ root: props.classes.iconBtn }}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.hideMessage}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            }
          />
          <div className="content-container">
            <div
              className="header-wrap d-flex header-display"
              style={{ background: 'transparent' }}
            >
              <HeaderMenu
                matches={matches}
                svgSize="25"
                paddingReduce={true}
                contentForm={true}
                closeDetailBox={props.closeDetailBox}
              />
            </div>
            <div className="content-header">
              <button className={'close_1'}
              >
                <IconButton
                    onClick={() => {
                      this.props.closeModal()
                    }}
                >
                  <p className={'cross'}>
                    <FontAwesomeIcon icon={faTimes} />
                  </p>
                </IconButton>
              </button>

              <div
                className={'image-container'}
                // onClick={this.handleGalleryClick}
              >
                {(_.compact(resource.youtubeVideos).length > 0 &&
                  !resource.youtubeVideos.includes('false') && (
                    <ReactPlayer
                      url={_.compact(resource.youtubeVideos)[0]}
                      playing={false}
                      controls={true}
                      width="100%"
                      height="100%"
                    />
                  )) || (
                  <img
                    className="company-img"
                    alt="Company Logo"
                    src={default_youtube}
                  />
                )}
              </div>
            </div>
            <div className="content-middle">
              <div style={{ overflow: 'hidden' }}>
                <div className="content-middle-child-shadow">
                  <div className="title-track">
                    {resource.program_name && (
                      <p className={'business-name'}>
                        <b>{this.setTitleCase(resource.program_name)}</b>
                      </p>
                    )}
                  </div>
                </div>

                <div className={'content-middle-child icon-container-div'}>
                  {resource.phone_num ? (
                    <a
                      href={`tel:${resource.phone_num}`}
                      className={'globe-icon text-color-style text-align-div'}
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faPhoneAlt} />
                      {/*<img src={call_icon} alt={'image'} className={'img-icon-flyer'}/>*/}
                      <p
                        hidden={
                          !resource.phone_num || resource.phone_num === ''
                        }
                        className="globe-icon"
                      >
                        CALL
                      </p>
                    </a>
                  ) : (
                    ''
                  )}
                  {resource.crisis_phone_num ? (
                    <a
                      href={`tel:${resource.crisis_phone_num}`}
                      className={'globe-icon text-color-style text-align-div'}
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faHandsHelping} />
                      {/*<img src={helpline} alt={'image'} className={'img-icon-flyer'}/>*/}
                      <p
                        hidden={
                          !resource.crisis_phone_num ||
                          resource.crisis_phone_num === ''
                        }
                        className="globe-icon"
                      >
                        HELPLINE
                      </p>
                    </a>
                  ) : (
                    ''
                  )}
                  {console.log('resource.pdf_file', resource.pdf_file)}
                  {resource.pdf_file && resource.pdf_file.length > 0 ? (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={resource.pdf_file}
                      className="globe-icon text-color-style text-align-div"
                    >
                      <FontAwesomeIcon
                        icon={faMap}
                        className={'text-color-style'}
                      />
                      <p
                        hidden={!resource.pdf_file || resource.pdf_file === ''}
                        className="globe-icon"
                      >
                        FLYER
                      </p>
                    </a>
                  ) : (
                    ''
                  )}
                  {console.log('gallery_images', gallery_images)}
                  {gallery_images && gallery_images.length > 0 && (
                    <p className="globe-icon">
                      <div
                        className="globe-icon text-color-style"
                        onClick={this.handleGalleryClick}
                      >
                        <span>
                          <FontAwesomeIcon
                            icon="bookmark"
                            className={'text-color-style'}
                          />
                        </span>
                        PHOTOS
                      </div>
                    </p>
                  )}
                  <p
                    hidden={resource.program_website === ''}
                    className="globe-icon-p"
                  >
                    <a
                      href={
                        !pattern.test(resource.program_website) &&
                        resource.program_website !== ''
                          ? 'https://' + resource.program_website
                          : resource.program_website
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="globe-icon text-color-style"
                    >
                      <span>
                        <FontAwesomeIcon
                          icon="globe-americas"
                          className={'text-color-style'}
                        />
                      </span>
                      WEBSITE
                    </a>
                  </p>
                </div>
                {resource.short_description &&
                  resource.short_description.length > 0 && (
                    <div className="description-section">
                      <h4>
                        <b className={'more-info-header'}>
                          What is this service?
                        </b>
                      </h4>
                      <div
                        className={'font-family-roboto'}
                        dangerouslySetInnerHTML={{
                          __html: resource.short_description,
                        }}
                      />
                    </div>
                  )}
                <div>
                  {eligibility !== '' ? (
                    <div className={'div-seprator content-middle-child'}>
                      <h4>
                        <b className={'more-info-header'}>
                          Who is this service for?
                        </b>
                      </h4>
                      <div className="info-content">
                        {parse(resource.program_eligibility)}
                      </div>
                    </div>
                  ) : null}

                  {long_description !== '' ? (
                    <div className={'div-seprator content-middle-child'}>
                      <h4>
                        <b className={'more-info-header'}>
                          What is the first step?
                        </b>
                      </h4>
                      <div className="info-content">
                        {parse(resource.long_description)}
                      </div>
                    </div>
                  ) : null}

                  {finanacialInfo !== '' && finanacialInfo !== 'undefined' ? (
                    <div className={'div-seprator content-middle-child'}>
                      <h4>
                        <b className={'more-info-header'}>
                          What is the cost, do you accept insurance?
                        </b>
                      </h4>
                      <div className="info-content">
                        {parse(resource.financialInformation)}
                      </div>
                    </div>
                  ) : null}
                  {resource.languages && resource.languages.length > 0 ? (
                    <div className={'div-seprator content-middle-child'}>
                      <h4>
                        <b className={'more-info-header'}>Languages</b>
                      </h4>
                      <div style={{ display: 'flex', marginTop: '5px' }}>
                        {resource.languages.map((lan, index) => (
                          <div className={'language-div'} key={index}>
                            {lan}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}

                  <div className={'content-middle-child'}>
                    <div
                      hidden={!resource.organization_name}
                      className="address"
                    >
                      <FontAwesomeIcon
                        icon="building"
                        className="contact-detail-icons"
                      />
                      <p className="text-color-style">
                        <span>{resource.organization_name}</span>
                      </p>
                    </div>

                    <div
                      hidden={!resource.address || !resource.address.lat}
                      className="address"
                    >
                      <FontAwesomeIcon
                        icon="map-marker-alt"
                        className="contact-detail-icons"
                      />
                      {resource.address && (
                        <p className="text-color-style">
                          <span>
                            {resource.address.address_1
                              ? resource.address.address_1.concat(
                                  ', ',
                                  resource.address.city,
                                  ', ',
                                  resource.address.state,
                                  ' ',
                                  resource.address.postal_code
                                )
                              : ''}
                          </span>
                        </p>
                      )}
                    </div>

                    <div
                      hidden={!resource.phone_num && !resource.crisis_phone_num}
                      className="contact-detail"
                      style={{ alignItems: 'center' }}
                    >
                      <FontAwesomeIcon
                        icon="phone"
                        className="contact-detail-icons"
                        rotation={90}
                      />
                      <div style={{ display: 'inline' }}>
                        {resource.phone_num && (
                          <div hidden={!resource.phone_num}>
                            <a
                              href={`tel:${resource.phone_num}`}
                              className={'text-color-style'}
                            >
                              Phone:{' '}
                              {this.phoneNumberFormatter(resource.phone_num)}
                            </a>
                          </div>
                        )}
                        {resource.crisis_phone_num && (
                          <div hidden={!resource.crisis_phone_num}>
                            <a
                              href={`tel:${resource.crisis_phone_num}`}
                              className={'text-color-style'}
                            >
                              Crisis:{' '}
                              {this.phoneNumberFormatter(
                                resource.crisis_phone_num
                              )}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        !expanded
                          ? 'alignment contact-detail'
                          : 'contact-detail'
                      }
                    >
                      <img
                        src={gray_time_icon}
                        alt={'image'}
                        style={{
                          width: '15px',
                          height: '15px',
                          marginRight: '20px',
                        }}
                      />
                      <ExpansionPanel
                        className={props.classes.root}
                        classes={{ expanded: props.classes.expanded }}
                        onChange={this.handleExpandClick}
                      >
                        <ExpansionPanelSummary
                          style={{ padding: '0', minHeight: 0 }}
                          classes={{
                            content: props.classes.content,
                            expanded: props.classes.expanded,
                          }}
                        >
                          Open hours
                          {expanded ? (
                            <ExpandLessIcon
                              classes={{ root: props.classes.iconRoot }}
                            />
                          ) : (
                            <ExpandMoreIcon
                              classes={{ root: props.classes.iconRoot }}
                            />
                          )}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ padding: '0' }}>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            {this.sortDays(resource.timing).map((sh, ind) => {
                              console.log('sh', sh)
                              const { day, full_day_open, split_hours } = sh
                              let openTime1,
                                closeTime1,
                                openTime2,
                                closeTime2,
                                closed
                              if (split_hours) {
                                const { shift_one, shift_two } = sh
                                openTime1 = new Date(
                                  `${new Date().toDateString()} ${
                                    shift_one.open_time.split(':')[0]
                                  }:${shift_one.open_time.split(':')[1]}:00`
                                ).toLocaleString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })
                                closeTime1 = new Date(
                                  `${new Date().toDateString()} ${
                                    shift_one.close_time.split(':')[0]
                                  }:${shift_one.close_time.split(':')[1]}:00`
                                ).toLocaleString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })
                                openTime2 = new Date(
                                  `${new Date().toDateString()} ${
                                    shift_two.open_time.split(':')[0]
                                  }:${shift_two.open_time.split(':')[1]}:00`
                                ).toLocaleString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })
                                closeTime2 = new Date(
                                  `${new Date().toDateString()} ${
                                    shift_two.close_time.split(':')[0]
                                  }:${shift_two.close_time.split(':')[1]}:00`
                                ).toLocaleString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })
                              } else if (sh.open_time && sh.close_time) {
                                const { open_time, close_time } = sh
                                openTime1 = new Date(
                                  `${new Date().toDateString()} ${
                                    open_time.split(':')[0]
                                  }:${open_time.split(':')[1]}:00`
                                ).toLocaleString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })

                                closeTime1 = new Date(
                                  `${new Date().toDateString()} ${
                                    close_time.split(':')[0]
                                  }:${close_time.split(':')[1]}:00`
                                ).toLocaleString('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                })
                              } else if (full_day_open) {
                                openTime1 = 'Open 24 hours'
                              } else {
                                closed = true
                              }
                              let weekday = moment().tz('America/Phoenix').day()
                              // console.log(new Date(new Date().toLocaleString("en-US", { timeZone: "America/Phoenix" })).getDay());
                              let _day = weekDays[weekday]
                              return (
                                <div key={ind}>
                                  {!closed ? (
                                    <div className={'day-div'}>
                                      <div className={'dot-div'} />
                                      <div style={{ width: '115px' }}>
                                        <span
                                          className={
                                            day === _day ? 'bold-today' : ''
                                          }
                                        >
                                          {day}
                                        </span>
                                      </div>
                                      <div>
                                        <span
                                          className={
                                            day === _day ? 'bold-today' : ''
                                          }
                                        >
                                          {openTime1 && openTime1}{' '}
                                          {closeTime1 && `- ${closeTime1}`}{' '}
                                          {openTime2 && 'and'}{' '}
                                          {openTime2 && openTime2}{' '}
                                          {closeTime2 && `- ${closeTime2}`}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className={'day-div'}>
                                      <div className={'dot-div dot-red-div'} />
                                      <div style={{ width: '115px' }}>
                                        <span
                                          className={
                                            day === _day ? 'bold-today' : ''
                                          }
                                        >
                                          {day}
                                        </span>
                                      </div>
                                      <div>
                                        <span
                                          className={
                                            day === _day ? 'bold-today' : ''
                                          }
                                        >
                                          Closed on this day
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </div>
                  </div>
                  {resource.donation_info &&
                  resource.donation_info.length > 0 ? (
                    <div
                      className={'div-seprator content-middle-child'}
                      style={{ display: 'flex' }}
                    >
                      <a
                        style={{ borderLeft: '0' }}
                        className="info-content-donation"
                        href={resource.donation_info}
                        target="_blank"
                      >
                        <img
                          src={donation}
                          alt={'image'}
                          className={'img-icon-donation'}
                        />
                        DONATION INFO
                      </a>
                    </div>
                  ) : null}
                </div>

                {/*<div className="edit-section edit-section-last">*/}
                {/*  Suggest Edit*/}
                {/*  <div*/}
                {/*    className="edit-sub-section"*/}
                {/*    onClick={this.editProgramOpen}*/}
                {/*  >*/}
                {/*    <div className="edit-section-icon">*/}
                {/*      <img src={Pen} alt={'image'} />*/}
                {/*    </div>*/}
                {/*    <div className="edit-content">*/}
                {/*      <p>Change name or other details</p>*/}
                {/*      <p>Edit name, location, hours, etc.</p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          {this.state.openGallery && (
            <Gallery
              resource={this.state.resource}
              onClose={() => {
                this.setState({ openGallery: false })
              }}
            />
          )}
        </div>
      )
    )
  }
}

const mapState = (state) => ({
  business: state.business,
  auth: state.auth,
})

const mapDispatch = (dispatch) => {
  return {
    businessActions: bindActionCreators(businessActions, dispatch),
    closeModal: bindActionCreators(openedItem, dispatch),
  }
}

const Comp = connect(mapState, mapDispatch)(withStyles(styles)(Content))

const ContentHOC = (props) => {
  const openedItem = useSelector((state) => state.common.opened)

  return openedItem ? <Comp openedItem={openedItem} {...props} /> : null
}
export default ContentHOC
