import React, { Component } from 'react'
// import _ from 'lodash'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import PageTemplate from 'components/PageTemplate/PageTemplate'
import * as businessActions from '../../actions/business.action'
import * as categoryActions from '../../actions/category.action'

const CATEGORY = 'Food';

class Food extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      subcategories: [],
      search: '',
      selectedID:'',
      selectedForm:'',
      initialOpenDetailBox:false,
      initialSelectedResources:{}
    }
  }

  componentDidMount() {
    let { categoryActions: { getCategories }, businessActions: { getBusinessByCategory }, location, match:{ params = {}} } = this.props;
    getCategories(CATEGORY).then((categories) => {
      this.setState({
        subcategories: categories.sub_category,
      });
    });
    const { business: { categoryWiseBusiness }, businessActions: { getBusinessById } } = this.props;
    if (Object.keys(categoryWiseBusiness).length <= 0 || !categoryWiseBusiness[CATEGORY] || categoryWiseBusiness[CATEGORY].length <= 0) {
      getBusinessByCategory(CATEGORY).then((filteredBusinesses) => {
        this.setState({
          resources: filteredBusinesses,
        });
      });
    } else {
      this.setState({
        resources: categoryWiseBusiness[CATEGORY],
      });
    }
    if(params.id && params.form){
      if(params.form==="claim" || params.form==="review") {
        getBusinessById(parseInt(params.id)).then((res)=>{
          if(res && Object.keys(res).length) {
            this.setState({
              selectedID: params.id,
              selectedForm: params.form,
              initialSelectedResources: {...res},
              initialOpenDetailBox: +params.id
            })
          }else {
            this.props.history.push("/food")
          }
        }).catch((err)=>{
          console.log('Error',err);
        });
      }
    }
    this.setState({search: location.search});
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(this.props.location.search !== newProps.location.search){
      if(newProps.location.search!=="")
        this.setState({search: newProps.location.search});
    }
  }

  removeIdAndForm = () => {
    this.setState({...this.state,
      selectedID: '',
      selectedForm: '',
      initialSelectedResources: {},
      initialOpenDetailBox: false});
  }

  render() {
    const { resources, search, selectedID, selectedForm, initialSelectedResources, initialOpenDetailBox  } = this.state;
    const { business, matches, toggleTip, toggleToolTip } = this.props;
    const subcategories = [
      {
        category: 'Food Boxes',
        icon: 'boxes'
    },{
        category: 'Hot Meals',
        icon: 'drumstick-bite'
    },{
        category: 'WIC',
        icon: 'female'
    }];
    return (
      <PageTemplate
        category={CATEGORY}
        icon="utensils"
        resources={resources}
        subcategories={subcategories}
        search={search}
        history={this.props.history}
        loading={business.loadingBusiness}
        matches={matches}
        selectedID={selectedID}
        selectedForm={selectedForm}
        initialSelectedResources={initialSelectedResources}
        initialOpenDetailBox={initialOpenDetailBox}
        removeIdAndForm={this.removeIdAndForm}
        selectedCategory="Food"
        toggleTip={toggleTip}
        toggleToolTip={toggleToolTip}
      />
    )
  }
}

const mapState = (state) => {
  const { business, category } = state;
  return {
    business, category
  }
};

const mapDispatch = (dispatch) => {
  return {
    businessActions: bindActionCreators(businessActions, dispatch),
    categoryActions: bindActionCreators(categoryActions, dispatch)
  }
};

export default connect(mapState, mapDispatch)(Food)
