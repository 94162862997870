import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import {
  Modal, withStyles, InputBase,
  Button as MUIButton,
  Collapse, IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded'
import * as questionActions from '../../actions/question.action';
import './Question.css';

const Input = withStyles({
  root: {
    border: '1px solid #d2d2d2',
    boxShadow: 'inset 0 0 1px #000',
    padding: '0 1%',
  }
})(InputBase);

const Button = withStyles({
  root: {
    background: "#245497",
    color: '#fff',
    width: 'fit-content',
    padding: '1%',
    marginTop: '1%',
    '&:hover': {
      background: "#224684",
    }
  }
})(MUIButton);

const styles = () => ({
  question: {
    minHeight: 69,
    maxHeight: 150,
    overflowY: 'scroll',
    alignItems: "flex-start",
    padding: '1%'
  },
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '0% 6% 6% 6%',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    padding: '6%',
    marginLeft: '420px',
  },
});

class Question extends Component {

  state = {
    name: '',
    email: '',
    question: '',
    business: null,
    valid: true,
    blankError: {error: false, question: false},
    addQuestion: true
  };

  componentDidMount(){
    let {resource} = this.props;
    if(this.props.auth.token){
      let {user} = this.props.auth;
      let isUser = Object.keys(user).length;
      this.setState({
        email: this.props.auth.email || "",
        name: isUser ? user.firstName+" "+user.lastName : "",
        business: resource.id,
        program_name: resource.program_name
      })
    }
    else {
      this.setState({
        business: resource.id,
        program_name: resource.program_name
      })
    }
  }

  toggleReviews = () => {
    this.setState(state=>({addQuestion: !state.addQuestion}))
  };

  checkValidMail = () => {
    let {email, valid} = this.state;
    if(email!==""){
      valid = Boolean(email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
      this.setState({valid});
    }
    else {
      this.handleBlankError("email");
    }
  };

  handleBlankError = (name) => {
    this.setState(state=>({blankError: {...state.blankError, [name]: true}}))
  };

  submitQuestion = async () => {
    await this.checkValidMail();
    let {valid, question} = this.state;
    let {questionActions, showMessage} = this.props;
    if(question===""){
      await this.handleBlankError("question");
    }
    const {blankError} = this.state;
    if(valid && !blankError.email && !blankError.question){
      questionActions.addQuestion({...this.state, email:this.state.email.toLowerCase()}   ).then(()=>{
        if(this.props.business.error===""){
          this.setState({
            name: '',
            email: '',
            question: '',
            blankError: {question: false, email: false}
          });
          // showMessage("Question submitted successfully", true);
        }
        else {
          showMessage("Something went wrong", false);
        }
      });
    }
  };

  handleChange = (e) => {
    let {name, value} = e.target;
    this.setState(state=>{
      if(name==="email"){
        return ({email: value, blankError: {...state.blankError, email: false}, valid: true});
      }
      else if(name==="name") {
        return ({[name]: value});
      }
      else {
        return ({[name]: value, blankError: {...state.blankError, [name]: false}});
      }
    });
  };

  handleClose = () => {
    this.setState({
      name: '',
      email: '',
      question: '',
      business: null,
      valid: true,
      blankError: {question: false, email: false},
      addQuestion: true
    }, ()=>{
      this.props.onClose();
    })
  };


  render(){
    const {open, classes, resource, matches} = this.props;
    const {
      question, email, name,
      valid, blankError, addQuestion
    } = this.state;
    return (
        <Modal open={open} onClose={this.handleClose} classes={{root: matches?classes.mobileModal:classes.modal + " modal-main-div" }}>
          <div className="question-main-container">
            <div className={'modal-title'}>
              <h2>Ask a Question</h2>
            </div>
            <div className="close-icon">
            <IconButton onClick={this.handleClose}>
              <CloseIcon/>
            </IconButton>
            </div>

            <div className="question-container">
              <Collapse in={addQuestion}>
                <div className="field-container">
                  <label>Your Name</label>
                  <Input type="text" onChange={this.handleChange} name="name" value={name}/>
                </div>
                <div className="field-container">
                  <label>Your Email Address</label>
                  <Input
                    onBlur={this.checkValidMail}
                    type="email"
                    onChange={this.handleChange}
                    name="email"
                    value={email}
                  />
                  {!valid && <p className="failed">The email address you entered is not valid.</p>}
                  {blankError.email && <p className="failed">Please enter your email address.</p>}
                </div>
                <div className="field-container">
                  <label>Your Question</label>
                  <Input classes={{root: classes.question}} type="text" onChange={this.handleChange} name="question" value={question} multiline/>
                  {blankError.question && <p className="failed">Your question cannot be blank.</p>}
                </div>
                <div className={"btn-section"}>
                <Button  onClick={this.submitQuestion}>Submit</Button></div>
            </Collapse>
            </div>
          </div>
        </Modal>
    )
  }
}

const mapState = (state) => ({
  business: state.business,
  auth: state.auth
});

const mapDispatch = (dispatch) => ({
  questionActions: bindActionCreators(questionActions, dispatch)
});

export default connect(mapState, mapDispatch)(withStyles(styles)(Question));