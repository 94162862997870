import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  TextField as MUITextField,
  Button as MUIButton,
  Snackbar,
  withStyles,
  InputAdornment,
  IconButton,
  CircularProgress,
  Grid,
  Typography,
  Modal,
} from '@material-ui/core'
import { withRouter, Link } from 'react-router-dom'
import { Info } from '@material-ui/icons/'
import FieldPopup from '../FieldPopup/FieldPopup'
import '../AdminPanel/ProgramInfo/ProgramInfo.css'
import { findFlagUrlByCountryName } from 'country-flags-svg'
import * as authActions from '../../actions/auth.action'
import CancelCircle from '@material-ui/icons/CancelRounded'
import './SignUp.css'
import { Close } from '@material-ui/icons'
import CheckCircle from '@material-ui/icons/CheckCircleOutlineTwoTone'
import { createUser } from '../../service/user.services'

const CheckIcon = withStyles({
  root: {
    background: '#fff',
    color: '#32B241',
    fontSize: 70,
  },
})(CheckCircle)

const TextField = withStyles({
  root: {
    marginTop: '2%',
  },
})(MUITextField)

const styles = () => ({
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '0% 6% 6% 6%',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    padding: '6%',
    marginLeft: '420px',
  },
  pc: {
    width: '100%',
    height: '45px',
    boxShadow:
      '0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
  },
  phone: {
    width: '100%',
    height: '45px',
  },
  successNotif: {
    backgroundColor: '#43a047',
  },
  errorNotif: {
    backgroundColor: '#d32f2f',
  },
})

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  )
}

class SignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      finished: false,
      business: {
        firstName: '',
        lastName: '',
        userPhone: '',
        companyTitle: '',
        company_logo: undefined,
        email: '',
        password: '',
        business_name: '',
        program_name: '',
        program_website: '',
        financialInformation: '',
        program_eligibility: '',
        category: {
          category: [],
          sub_category: [],
        },
        county: '',
        languages: [],
        short_description: '',
        long_description: '',
        services: '',
        gallery_images: [],
        // program_status: "",
        google_direction_url: '',
      },
      categories: [],
      display_logo: '',
      display_images: [],
      blankError: false,
      error: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      },
      notification: ``,
      open: false,
      success: false,
      validZip: true,
      valid: true,
      anchorEl: undefined,
      anchor: '',
      openDialog: false,
      editNumber: false,
      editCrisisNumber: false,
      numberError: { userPhone: false },
      unClaim: false,
      clearFields: false,
      editUserNumber: false,
      loading: false,
      activeTab: 0,
      showError: {},
      validationField: [
        'email',
        'userPhone',
        'firstName',
        'lastName',
        'businessName',
        'password',
      ],
      validationFieldAdmin: [
        'business_name',
        'program_name',
        'address.city',
        'languages',
        'short_description',
        'category.category',
        'password',
      ],
      validationError: {},
      daysActiveTab: 0,
      flagUrl: findFlagUrlByCountryName('United States'),
      viewEdits: false,
      review: {},
      question: {},
      openQuestion: false,
      openpop: false,
      url: '',
      openReview: false,
      editUserPhone: false,
      userData: {
        email: '',
        firstName: '',
        lastName: '',
        userPhone: '',
        businessName: '',
        companyTitle: '',
        password: '',
      },
      successModal: false,
    }
  }

  checkEmptyField = (e) => {
    let { userData } = this.state
    if (userData[e.target.name] === '') {
      this.setState({ blankError: true })
    }
  }

  showOnFinish = (show) => () => {
    this.setState((state) => ({ ...state, finished: show }))
  }

  handleUserChange = (e) => {
    let { userData } = this.state
    userData[e.target.name] = e.target.value
    this.setState((state) => {
      return { userData, blankError: false }
    })
  }

  phoneNumberFormatter = (phoneNumber) => {
    var match
    if (phoneNumber) {
      if (phoneNumber.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)) {
        if (phoneNumber.toString().match(/^\d{10}$/)) {
          match = phoneNumber
            .toString()
            .replace(/\D /g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          if (match) {
            return match
          }
        }
        return phoneNumber
      }
      return phoneNumber
    }
  }

  createAccount = async (approval_status) => {
    const { auth } = this.props
    let valid = this.props.newUser || this.validateEmptyField()

    await this.handleNumberError('userPhone')
    let { userData, numberError } = this.state

    if (this.props.newUser) {
      createUser(userData)
        .then((res) => {
          this.props.callback && this.props.callback()
          this.showOnFinish(true)()
          this.props.onClose()
          this.showOnFinish(false)()
        })
        .catch((err) => {
          this.setState({
            ...this.state,
            loading: false,
            notification: err.err || 'Something went wrong',
            open: true,
            success: false,
          })
        })
    } else {
      if (!numberError.userPhone && valid) {
        this.setState({ ...this.state, loading: true })
        this.props.authActions.signup(userData).then((res) => {
          if (res.success) {
            this.showOnFinish(true)()
            this.setState({
              ...this.state,
              loading: false,
              userData: {
                firstName: '',
                lastName: '',
                userPhone: '',
                companyTitle: '',
                businessName: '',
              },
            })
            setTimeout(() => {
              this.props.onClose()
              this.showOnFinish(false)()
            }, 1000)
          } else {
            this.setState({
              ...this.state,
              loading: false,
              notification: res.err || 'Something went wrong',
              open: true,
              success: false,
            })
          }
        })
      }
    }
  }

  closeNotification = () => {
    this.setState({
      notification: ``,
      open: false,
    })
  }

  toggleEditUserPhone = () => {
    let { userPhone } = this.state.userData
    this.setState((state) => {
      if (
        userPhone &&
        !userPhone.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        return {
          editUserPhone: !state.editUserPhone,
          numberError: { ...state.numberError, userPhone: true },
        }
      } else {
        return { editUserPhone: !state.editUserPhone }
      }
    })
  }

  handleNumberError = (name) => {
    let { userData } = this.state
    if (name === 'userPhone') {
      if (
        userData[name] &&
        !userData[name].toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        this.setState((state) => ({
          numberError: { ...state.numberError, [name]: true },
        }))
      }
    }
    if (
      userData[name] &&
      !userData[name].toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
    ) {
      this.setState((state) => ({
        numberError: { ...state.numberError, [name]: true },
      }))
    }
  }

  handlePhoneNumber = (e) => {
    let { name, value } = e.target
    this.setState((state) => ({
      userData: {
        ...state.userData,
        [name]: value,
      },
      numberError: {
        ...state.numberError,
        [name]: false,
      },
    }))
  }

  openUnClaimDialog = () => {
    this.setState({ ...this.state, unClaim: true })
  }

  closeUnClaimDialog = () => {
    this.setState({ ...this.state, unClaim: false })
  }

  checkValidEmail = (e) => {
    let { userData } = this.state
    if (userData.email !== '') {
      let valid = Boolean(
        userData.email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      )
      this.setState({ valid })
      return valid
    }
  }

  getValue = (obj, path) => {
    let split = path.split('.')
    let finalValue = path === 'city' ? { ...obj.address } : { ...obj }
    split.forEach((value) => {
      if (
        finalValue[value] &&
        finalValue[value].replace(/^\s+/g, '').length > 0
      ) {
        finalValue = finalValue[value]
      } else {
        finalValue = ''
        return false
      }
    })
    return finalValue
  }

  openPopup = (e) => {
    this.setState({ anchorEl: e.target, anchor: e.target.id })
  }

  closePopup = () => {
    this.setState({ anchorEl: undefined, anchor: '' })
  }

  validateEmptyField = () => {
    const { validationField, userData, validationError } = this.state

    validationField.forEach((value) => {
      let errorKey = value.split('.')[1]
        ? value.split('.')[1]
        : value.split('.')[0]
      if (value === 'email') {
        validationError[errorKey] = !this.getValue(userData, value)
        if (!validationError[errorKey]) {
          validationError[errorKey] = !this.checkValidEmail()
        }
      } else {
        validationError[errorKey] = !this.getValue(userData, value)
      }
    })

    this.setState({ validationError: { ...validationError } })
    return !Object.keys(validationError).filter((value) => {
      if (validationError[value]) return value
    }).length
  }

  closeEdits = () => {
    this.setState({ viewEdits: false })
  }

  getMobileComponents = () => {
    const {
      business,
      blankError,
      valid,
      numberError,
      anchorEl,
      anchor,
      editUserNumber,
      validationError,
      userData,
      editUserPhone,
    } = this.state
    const { matches, classes, auth } = this.props
    const isAdmin =
      Object.keys(auth).length &&
      (auth.accessType === 'Admin' || auth.access_type === 'Super Admin')
    const token = Object.keys(auth).length && auth.token !== ''

    return (
      <div className="div-wrapper">
        <Grid container>
          <Grid item xs={12} sm={12} lg={12}>
            <div className="program-form-group">
              <div style={{ display: 'flex' }}>
                <label className="program-form-lable">
                  Primary Email <span className="required-label">*</span>
                </label>
              </div>
              <TextField
                placeholder={'hello@yourmail.com'}
                className="without-padding"
                onBlur={this.checkValidEmail}
                classes={{ root: matches ? classes.phone : classes.pc }}
                name="email"
                onChange={this.handleUserChange}
                value={userData.email}
                type="email"
                variant="outlined"
              />
              {!valid ? (
                <p className="error-message" name="error">
                  The email address you have entered is invalid.
                </p>
              ) : null}
              {/*{blankError && business.email === "" ?
             <p name="error" className="error-message">Please enter your email address.</p> : null}*/}
              {validationError.email && (
                <p name="error" className="error-message">
                  Please enter a valid email address.
                </p>
              )}
              {/*{blankError&&business.email===""?<p name="error" className="error-message">Please enter your email address.</p>:null}*/}
            </div>
          </Grid>

          <Grid item xs={12} lg={12}>
            <div className="program-form-group">
              <div style={{ display: 'flex' }}>
                <label>
                  Your Phone Number <span className="required-label">*</span>
                </label>
              </div>
              {editUserPhone ? (
                <TextField
                  name="userPhone"
                  classes={{ root: matches ? classes.phone : classes.pc }}
                  type="text"
                  onChange={this.handlePhoneNumber}
                  onBlur={this.toggleEditUserPhone}
                  variant="outlined"
                  className="without-padding"
                  value={userData.userPhone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={this.state.flagUrl}
                            style={{ width: '29px' }}
                            alt="image"
                          />
                          <div style={{ marginLeft: '5px' }}>+1</div>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  readOnly
                  name="userPhone"
                  classes={{ root: matches ? classes.phone : classes.pc }}
                  type="text"
                  variant="outlined"
                  className="without-padding"
                  onFocus={this.toggleEditUserPhone}
                  value={this.phoneNumberFormatter(userData.userPhone)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={this.state.flagUrl}
                            style={{ width: '29px' }}
                            alt="image"
                          />
                          <div style={{ marginLeft: '5px' }}>+1</div>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {numberError.userPhone ? (
                <p name="error" className="error-message">
                  This phone number is not valid
                </p>
              ) : null}
              {validationError.userPhone && (
                <p name="error" className="error-message">
                  Please enter a valid phone number.
                </p>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} lg={12}>
            <div className="program-form-group">
              <div>
                <label>
                  First Name <span className="required-label">*</span>
                </label>
              </div>
              <TextField
                onBlur={!isAdmin ? this.checkEmptyField : () => {}}
                classes={{ root: matches ? classes.phone : classes.pc }}
                name="firstName"
                onChange={this.handleUserChange}
                className="without-padding"
                value={userData.firstName}
                type="text"
                variant="outlined"
              />
              {validationError.firstName && (
                <p name="error" className="error-message">
                  Please enter your First Name.
                </p>
              )}
            </div>
          </Grid>
          <Grid item xs={12} lg={12}>
            <div className="program-form-group">
              <div>
                <label>
                  Last Name <span className="required-label">*</span>
                </label>
              </div>
              <TextField
                onBlur={!isAdmin ? this.checkEmptyField : () => {}}
                classes={{ root: matches ? classes.phone : classes.pc }}
                name="lastName"
                className="without-padding"
                onChange={this.handleUserChange}
                value={userData.lastName}
                type="text"
                variant="outlined"
              />
              {validationError.lastName && (
                <p name="error" className="error-message">
                  Please enter your Last Name.
                </p>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} lg={12}>
            <div className="program-form-group">
              <div style={{ display: 'flex' }}>
                <label>
                  Name of Organization <span className="required-label">*</span>
                </label>
              </div>
              <TextField
                onBlur={this.checkEmptyField}
                classes={{ root: matches ? classes.phone : classes.pc }}
                name="businessName"
                className="without-padding"
                onChange={this.handleUserChange}
                value={userData.businessName}
                type="text"
                variant="outlined"
              />
              {validationError.businessName && (
                <p name="error" className="error-message">
                  Please enter the name of your business.
                </p>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderProgramForm = () => {
    const {
      blankError,
      valid,
      numberError,
      clearFields,
      anchorEl,
      anchor,
      validationError,
      userData,
      editUserPhone,
    } = this.state
    const { matches, classes, auth, clearFormFields } = this.props
    const isAdmin =
      Object.keys(auth).length &&
      (auth.accessType === 'Admin' || auth.access_type === 'Super Admin')
    return (
      <div className="program-form-group">
        <div className="schedule-pc-sign-up">
          <TabContainer className="misc-wrapper">
            <div className="div-wrapper">
              <Grid container>
                <Grid item xs={12} sm={12} lg={12}>
                  <div className="program-form-group">
                    <div style={{ display: 'flex' }}>
                      <label className="program-form-lable">
                        Primary Email
                        <span className="required-label">*</span>
                      </label>
                    </div>
                    <TextField
                      placeholder={'abc@yourmail.com'}
                      className="without-padding input-boxes"
                      onBlur={this.checkValidEmail}
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      name="email"
                      onChange={this.handleUserChange}
                      value={userData.email}
                      type="email"
                      variant="outlined"
                    />
                    {!valid ? (
                      <p className="error-message" name="error">
                        The email address you have entered is invalid.
                      </p>
                    ) : null}

                    {blankError && userData.email === '' && !isAdmin ? (
                      <p name="error" className="error-message">
                        Please enter your email address.
                      </p>
                    ) : null}
                    {validationError.email && (
                      <p name="error" className="error-message">
                        Please enter a valid email address.
                      </p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <div className="program-form-group">
                    <div style={{ display: 'flex' }}>
                      <label>
                        Your Phone Number{' '}
                        <span className="required-label">*</span>
                      </label>
                    </div>
                    {editUserPhone ? (
                      <TextField
                        name="userPhone"
                        classes={{ root: matches ? classes.phone : classes.pc }}
                        type="text"
                        onChange={this.handlePhoneNumber}
                        onBlur={this.toggleEditUserPhone}
                        variant="outlined"
                        className="without-padding input-boxes"
                        value={userData.userPhone}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={this.state.flagUrl}
                                  style={{ width: '29px' }}
                                  alt="image"
                                />
                                <div style={{ marginLeft: '5px' }}>+1</div>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <TextField
                        readOnly
                        name="userPhone"
                        classes={{ root: matches ? classes.phone : classes.pc }}
                        type="text"
                        variant="outlined"
                        className="without-padding input-boxes"
                        onFocus={this.toggleEditUserPhone}
                        value={this.phoneNumberFormatter(userData.userPhone)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={this.state.flagUrl}
                                  style={{ width: '29px' }}
                                  alt="image"
                                />
                                <div style={{ marginLeft: '5px' }}>+1</div>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    {numberError.userPhone ? (
                      <p name="error" className="error-message">
                        This phone number is not valid
                      </p>
                    ) : null}
                    {validationError.userPhone && (
                      <p name="error" className="error-message">
                        Please enter a valid phone number.
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} lg={12}>
                  <div className="program-form-group">
                    <div>
                      <label>
                        First Name <span className="required-label">*</span>
                      </label>
                    </div>
                    <TextField
                      onBlur={!isAdmin ? this.checkEmptyField : () => {}}
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      name="firstName"
                      onChange={this.handleUserChange}
                      className="without-padding input-boxes"
                      value={userData.firstName}
                      type="text"
                      variant="outlined"
                    />
                    {validationError.firstName && (
                      <p name="error" className="error-message">
                        Please enter your First Name.
                      </p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <div className="program-form-group">
                    <div>
                      <label>
                        Last Name <span className="required-label">*</span>
                      </label>
                    </div>
                    <TextField
                      onBlur={!isAdmin ? this.checkEmptyField : () => {}}
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      name="lastName"
                      className="without-padding input-boxes"
                      onChange={this.handleUserChange}
                      value={userData.lastName}
                      type="text"
                      variant="outlined"
                    />
                    {validationError.lastName && (
                      <p name="error" className="error-message">
                        Please enter your Last Name.
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
              {!this.props.newUser && (
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <div className="program-form-group">
                      <div style={{ display: 'flex' }}>
                        <label>
                          Password <span className="required-label">*</span>
                        </label>
                      </div>
                      <TextField
                        onBlur={this.checkEmptyField}
                        classes={{ root: matches ? classes.phone : classes.pc }}
                        name="password"
                        className="without-padding"
                        onChange={this.handleUserChange}
                        value={userData.password}
                        type="password"
                        variant="outlined"
                      />
                      {validationError.password && (
                        <p name="error" className="error-message">
                          Please enter the password.
                        </p>
                      )}
                    </div>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <div className="program-form-group">
                    <div style={{ display: 'flex' }}>
                      <label>
                        Name of Organization{' '}
                        <span className="required-label">*</span>
                      </label>
                    </div>
                    <TextField
                      onBlur={this.checkEmptyField}
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      name="businessName"
                      className="without-padding input-boxes"
                      onChange={this.handleUserChange}
                      value={userData.businessName}
                      type="text"
                      variant="outlined"
                    />
                    {validationError.businessName && (
                      <p name="error" className="error-message">
                        Please enter the name of your business.
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
              {/* <p className={'validation-div'}><span className="required-label">*</span> These fields are required</p> */}
            </div>
          </TabContainer>
        </div>
        {/*<div className="schedule-phone">*/}
        {/*  {this.getMobileComponents()}*/}
        {/*</div>*/}
      </div>
    )
  }

  render() {
    const { loading, success, notification, open } = this.state
    const { matches, classes, openModal } = this.props
    return (
      <div>
        <Modal
          open={openModal}
          onClose={this.props.onClose}
          classes={{
            root: matches
              ? classes.mobileModal
              : classes.modal + ' modal-main-div',
          }}
        >
          {!this.state.finished ? (
            <div className="signup-center-div">
              <div className={'modal-title'}>
                <h2>Sign Up</h2>
              </div>
              <div className="close-icon">
                <IconButton onClick={this.props.onClose}>
                  <Close />
                </IconButton>
              </div>
              <div className="signup-admin-form-container">
                {this.renderProgramForm()}
                <div className="signup-admin-program-form-group">
                  <div className="signup-btn">
                    <MUIButton
                      onClick={() => this.createAccount()}
                      variant="outlined"
                      size="medium"
                      color="primary"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            height: '25px',
                            width: '25px',
                            color: 'white',
                          }}
                        />
                      ) : (
                        'Create Account'
                      )}
                    </MUIButton>
                  </div>
                </div>
              </div>
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                // autoHideDuration={3000}
                open={open}
                onClose={this.closeNotification}
                ContentProps={{
                  'aria-describedby': 'message-id',
                  classes: {
                    root: success ? classes.successNotif : classes.errorNotif,
                  },
                }}
                message={notification}
                action={
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={this.closeNotification}
                  >
                    <CancelCircle />
                  </IconButton>
                }
              />
            </div>
          ) : (
            <div className="login">
              <div className="user-login">
                <CheckIcon />
                <h3>Signed up successfully</h3>
              </div>
            </div>
          )}
        </Modal>
      </div>
    )
  }
}

const mapState = (state) => {
  return {
    auth: state.auth,
    user: state.user,
  }
}

const mapDispatch = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  }
}

export default connect(
  mapState,
  mapDispatch
)(withRouter(withStyles(styles)(SignUp)))
