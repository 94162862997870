import React, {Component} from 'react';
import {
  Button as MUIButton, withStyles, InputBase,
  InputAdornment, IconButton, Snackbar, CircularProgress
} from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/CloseRounded';
import './SetPassword.css';
import {connect} from 'react-redux';
import qs from 'query-string';
import {bindActionCreators} from 'redux';
import {setPassword, login, changePassword, checkPassword} from '../../actions/auth.action';

const Input = withStyles({
  root: {
    width: '100%',
    border: '1px solid #aaa',
    margin: 12,
    padding: 3,
    borderRadius: 1,
    '&:hover':{
      borderColor: '#000'
    },
    '&$focused':{
      borderColor: '#245497'
    },
  },
  focused: {},
})(InputBase);

const Button = withStyles({
  root: {
    background: '#245497',
    color: '#fff',
    borderColor: '#245497',
    borderRadius: 1,
    '&:hover': {
      background: '#224989',
    }
  }
})(MUIButton);

const styles = () => ({
  error: {
    backgroundColor: '#d32f2f'
  },
  success: {
    backgroundColor: '#32b241',
  }
});

class SetPassword extends Component {
  state={
    email: "",
    otp:"",
    password: "",
    confirmPassword: "",
    showPassword: {otp:false, password: false, confirmPassword: false},
    blankError: {otp:false,password: false, confirmPassword: false},
    matchError:false,
    showMessage: false,
    message: "",
    success: false,
    loading: false,
    wrongPassword:false,
  };

  componentDidMount () {
    if(this.props.location.search){
      let code = qs.parse(this.props.location.search).code;
      let email = qs.parse(this.props.location.search).email;
      this.setState({
        email,
        otp:code
      })
    }
  }

  handleChange = (e) => {
    const {name, value} = e.target;
    this.setState(state => {
        return ({[name]: value, blankError:{...state.blankError, [name]: false}, matchError: false})
    })
  };

  handleBlankError = async (field) => {
    if(this.state[field]==="")
    this.setState(state =>
      ({blankError: {...state.blankError, [field]:true}}));
    else {
      await this.handleMatchError();
    }
  };

  handleMatchError = () => {
    const {password, confirmPassword} = this.state;
    if(confirmPassword && password!==confirmPassword) {
      this.setState({matchError: true});
    }
  };
  setPassword = async () => {
    const {email, otp, password} = this.state;
    await this.handleBlankError('otp');
    await this.handleBlankError('password');
    await this.handleBlankError('confirmPassword');
    const {blankError, matchError} = this.state;
    if(!blankError.otp && !blankError.password && !blankError.confirmPassword && !matchError){
      this.props.setPassword({email, currentPassword:otp, password:password}).then(()=>{
        if(this.props.auth.error===""){
          this.props.login({email, password});
          this.setState({
            // showMessage: true,
            // success: true,
            // message: "Your password has been set successfully",
            password: "",
            confirmPassword: ""
          },() => {
            this.props.history.push('/my-listing')
          })
        }
        else {
          this.setState({
            showMessage: true,
            success: false,
            message: "Something went wrong"
          })
        }
      })
    }
  };

  checkPassword = async () => {
    const {otp, email, blankError} = this.state;
    if(otp!=="") {
      let match = await this.props.checkPassword(email, {password: otp});
      if(!match)
        await this.setState({wrongPassword: true});
      else
        await this.setState({wrongPassword: false});
    }
    else{
      this.setState({ blankError: {blankError, otp: true}})
    }
  };

  ResetPassword = async() => {
    await this.checkPassword();
    await this.handleBlankError('otp');
    await this.handleBlankError('password');
    await this.handleBlankError('confirmPassword');
    const {email,otp ,password, wrongPassword} = this.state;
    const {blankError, matchError} = this.state;
    if(!wrongPassword && !blankError.otp && !blankError.password && !blankError.confirmPassword && !matchError){
      this.props.changePassword(email, {currentPassword:otp, newPassword:password}).then(async ()=>{
        const {auth} = this.props;
        if(auth.error===""){
          await this.props.login({email, password});
          this.setState({
            // showMessage: true,
            otp: "",
            password: "",
            confirmPassword: "",
            // message: "Password changed successfully",
            currentPassword: "",
            newPassword: "",
            wrongPassword: false,
            // success: true,
            blankError: {currentPassword: false, newPassword: false}
          },() => {
            this.props.history.push("/all");
          })
        }
        else {
          this.setState({
            showMessage: true,
            message: "Something went wrong",
            success: false
          });
        }
      })
    }
  };

  toggleVisibility = (field) => {
    this.setState(state=>({showPassword: {...state.showPassword, [field]: !state.showPassword[field]}}))
  };

  hideNotification = () => {
    const {success} = this.state;
    this.setState({showMessage: false, loading: success}, ()=>{
      if(success) {
        setTimeout(()=>this.props.history.push("/my-listing"), 2000);
      }
    });
  };

  render(){
    const {email, password,confirmPassword,
      showPassword, blankError, matchError,
      showMessage, message, success, loading, wrongPassword, otp} = this.state;
    const {classes, matches} = this.props;
    return(
      <div className="main-container">
        {loading
        && <div className="overlay">
          <div className="spinner"><CircularProgress style={{color:'white'}} /></div>
          <br/>
          <h1>Redirecting to Admin page...</h1>
        </div>}
        <Snackbar
          open={showMessage}
          message={<span>{message}</span>}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          ContentProps={{
            classes: {root: success?classes.success:classes.error}
          }}
          autoHideDuration={success ? 200 : 5000}
          onClose={this.hideNotification}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.hideNotification}
            >
              <CloseIcon/>
            </IconButton>
          ]}
        />
        {!loading && <div className="set-password-container">
          <h2>SET YOUR PASSWORD</h2>
          <Input
            readOnly
            variant="outlined"
            name="email"
            placeholder="Email"
            type="email"
            value={email}
          />
          {/*{this.props.reset &&*/}
          {/*  <React.Fragment>*/}
          {/*  <Input*/}
          {/*    name="otp"*/}
          {/*    value={otp}*/}
          {/*    type={showPassword.otp?"text":"password"}*/}
          {/*    variant="outlined"*/}
          {/*    placeholder="Reset Code"*/}
          {/*    onChange={this.handleChange}*/}
          {/*    onBlur={()=>{this.handleBlankError('otp')}}*/}
          {/*    endAdornment={*/}
          {/*      <InputAdornment>*/}
          {/*        <IconButton onClick={()=>this.toggleVisibility("otp")}>*/}
          {/*          {showPassword.otp?<Visibility/>:<VisibilityOff/>}*/}
          {/*        </IconButton>*/}
          {/*      </InputAdornment>*/}
          {/*    }/>*/}
          {/*{wrongPassword && <p className="password-error">The code you entered wasn't correct</p>}*/}
          {/*  </React.Fragment>*/}
          {/*}*/}

          <Input
            name="password"
            value={password}
            type={showPassword.password?"text":"password"}
            variant="outlined"
            placeholder="Password"
            onChange={this.handleChange}
            onBlur={()=>{this.handleBlankError('password')}}
            endAdornment={
              <InputAdornment>
                <IconButton onClick={()=>this.toggleVisibility("password")}>
                  {showPassword.password?<Visibility/>:<VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }/>
          {blankError['password']&&<p className="set-password-error">Please enter your password.</p>}
          <Input
            name="confirmPassword"
            value={confirmPassword}
            type={showPassword.confirmPassword?"text":"password"}
            variant="outlined"
            placeholder="Confirm Password"
            onChange={this.handleChange}
            onBlur={()=>{this.handleBlankError('confirmPassword')}}
            endAdornment={
              <InputAdornment>
                <IconButton onClick={() => this.toggleVisibility("confirmPassword")}>
                  {showPassword.confirmPassword?<Visibility/>:<VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }/>
          {blankError['confirmPassword']&&<p className="set-password-error">Please confirm your password.</p>}
          <Button className={"btn"} variant="contained" onClick={this.props.reset?this.ResetPassword:this.setPassword}>SET PASSWORD</Button>
          {matchError && <p className="set-password-error">Your passwords don't match</p>}
        </div>}
      </div>
    )
  }
}

const mapState = ({auth}) => {
  return {
    auth
  }
};

const mapDispatch = (dispatch) => ({
  setPassword: bindActionCreators(setPassword, dispatch),
  login: bindActionCreators(login, dispatch),
  checkPassword: bindActionCreators(checkPassword, dispatch),
  changePassword: bindActionCreators(changePassword, dispatch)
});

export default connect(mapState, mapDispatch)(withStyles(styles)(SetPassword));