import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import {
  TextField as MUITextField,
  Button as MUIButton,
  Snackbar,
  withStyles,
  InputAdornment,
  IconButton,
  CircularProgress,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Modal,
} from '@material-ui/core'
import { Info } from '@material-ui/icons/'
import FieldPopup from '../../../FieldPopup/FieldPopup'
import './BusinessProfile.css'
import { findFlagUrlByCountryName } from 'country-flags-svg'
import * as userActions from '../../../../actions/user.action'
import ImagePopup from '../../ImagePopup/ImagePopup'
import ReviewPopup from '../../ReviewPopup/ReviewPopup'
import QuestionPopup from '../../QuestionPopup/QuestionPopup'
import EditsPopup from '../../EditsPopup/EditsPopup'
import CancelCircle from '@material-ui/icons/CancelRounded'
import _ from 'lodash'
import CloseIcon from '@material-ui/icons/CloseRounded'
import { updateUser } from '../../../../service/user.services'

const TextField = withStyles({
  root: {
    marginTop: '2%',
  },
})(MUITextField)

const styles = () => ({
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '40% 6% 6% 6%',
  },
  modal: {
    width: '60%',
    height: 'auto',
    padding: '6%',
    margin: 'auto',
  },
  pc: {
    width: '100%',
    height: '45px',
  },
  phone: {
    width: '100%',
    height: '45px',
  },
  successNotif: {
    backgroundColor: '#43a047',
  },
  errorNotif: {
    backgroundColor: '#d32f2f',
  },
  sendBtn: {
    background: '#245497',
    textTransform: 'none',
    color: '#fff',
    margin: '10px',
    fonSize: 12,
    '&:hover': {
      background: '#1f447e',
    },
  },
})

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  )
}

class BusinessProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blankError: false,
      notification: ``,
      open: false,
      success: false,
      valid: true,
      anchorEl: undefined,
      anchor: '',
      editNumber: false,
      numberError: {
        phone_num: false,
        crisis_toll_free_num: false,
        userPhone: false,
      },
      clearFields: false,
      editUserNumber: false,
      loading: false,
      validationField: [
        'email',
        'userPhone',
        'firstName',
        'lastName',
        'businessName',
      ],
      validationError: {},
      flagUrl: findFlagUrlByCountryName('United States'),
      viewEdits: false,
      review: {},
      question: {},
      openQuestion: false,
      openpop: false,
      url: '',
      openReview: false,
      userData: {},
      editUserPhone: false,
      accessType: ['Read Only', 'User', 'Super Admin'],
      access_type: '',
    }
  }

  componentDidMount() {
    this.props.userActions.getAdminUser()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.auth.accessType === 'Super Admin') {
      console.log('user -admin',this.props.user)
      if (!_.isEqual(prevProps.userId, this.props.userId)) {
        this.setState({ userData: this.props.userId })
      }
    } else {
      if (!_.isEqual(prevProps.user, this.props.user)) {
        console.log('user ',this.props.user)
        const { access_type, ...rest } = this.props.user
        this.setState({ userData: rest })
      }
    }
  }

  toggleEditUserPhone = () => {
    let { userPhone } = this.state.userData
    this.setState((state) => {
      if (
        userPhone &&
        !userPhone.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        return {
          editUserPhone: !state.editUserPhone,
          numberError: { ...state.numberError, userPhone: true },
        }
      } else {
        return { editUserPhone: !state.editUserPhone }
      }
    })
  }

  validateEmptyField = () => {
    const { validationField, userData, validationError } = this.state
    validationField.forEach((value) => {
      let errorKey = value.split('.')[1]
        ? value.split('.')[1]
        : value.split('.')[0]
      if (value === 'email') {
        validationError[errorKey] = !this.getValue(userData, value)
        if (!validationError[errorKey]) {
          validationError[errorKey] = !this.checkValidEmail()
        }
      } else {
        validationError[errorKey] = !this.getValue(userData, value)
      }
    })

    this.setState({ validationError: { ...validationError } })
    return !Object.keys(validationError).filter((value) => {
      if (validationError[value]) return value
    }).length
  }

  checkEmptyField = (e) => {
    let { userData } = this.state
    if (userData[e.target.name] === '') {
      this.setState({ blankError: true })
    }
  }

  handleUserChange = (e) => {
    let { userData } = this.state
    // if (e.target.name === 'access_type') {
    //   console.log('SETSTATE', e.target.value)
    //   this.setState({
    //     ...this.state,
    //     userData: {
    //       ...userData,
    //       access_type: e.target.value,
    //     },
    //   })
    // } else {
    //   userData[e.target.name] = e.target.value
    // }
    // userData[e.target.name] = e.target.value
    console.log('CHANGE SELECT', e, e.target.value, e.target)
    const name = e.target.name
    const value = e.target.value
    console.log('CHANGE afterrr', name, value)
    this.setState((pervState) => {
      return {
        ...pervState,
        userData: { ...userData, [name]: value },
        blankError: false,
      }
    })
  }
  handleFocusPassword = (e) => {
    if (e.target.value === '******') {
      this.setState({
        userData: {
          ...this.state.userData,
          password: '',
        },
      })
    }
    if (e.target.value === '') {
      this.setState({
        userData: {
          ...this.state.userData,
          password: '******',
        },
      })
    }
  }
  openPopup = (e) => {
    this.setState({ anchorEl: e.target, anchor: e.target.id })
  }

  closePopup = () => {
    this.setState({ anchorEl: undefined, anchor: '' })
  }

  phoneNumberFormatter = (phoneNumber) => {
    var match
    if (phoneNumber) {
      if (phoneNumber.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)) {
        if (phoneNumber.toString().match(/^\d{10}$/)) {
          match = phoneNumber
            .toString()
            .replace(/\D /g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          if (match) {
            return match
          }
        }
        return phoneNumber
      }
      return phoneNumber
    }
  }

  updateProgram = async () => {
    this.setState({ ...this.state, loading: true })
    await this.handleNumberError('userPhone')
    let { userData, numberError, blankError, access_type } = this.state
    let valid = this.validateEmptyField()

    if (!numberError.userPhone && !blankError && valid) {
      let user = { ...userData }

      user['id'] = this.props.auth.id
      const req = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        userPhone: userData.userPhone,
        businessName: userData.businessName,
        companyTitle: userData.companyTitle,
        password: userData.password,
        id: userData._id,
      }
      console.log('Request ------ userData', userData)
      if (this.props.auth.accessType === 'Super Admin') {
        updateUser({ ...req, access_type: userData.access_type })
          .then((res) => {
            this.setState(
              {
                notification: `Updated Successfully.`,
                open: true,
                success: true,
                loading: false,
              },
              () => {
                setTimeout(() => {
                  this.handleClose()
                  this.props.callBack && this.props.callBack()
                }, 1000)
              }
            )
          })
          .catch((err) => {
            this.setState({
              notification: 'Something went wrong',
              open: true,
              success: false,
              loading: false,
            })
          })
      } else {
        this.props.userActions.updateUser(req).then((user) => {
          if (user.firstName) {
            this.setState(
              {
                notification: `Updated Successfully.`,
                open: true,
                success: true,
                loading: false,
              },
              () => {
                setTimeout(() => {
                  this.handleClose()
                }, 500)
              }
            )
          } else {
            const res = Object.values(user.response.data || {})
            const errorMessage = res[0] ? res[0][0] : 'Something went wrong'
            this.setState({
              notification: errorMessage,
              open: true,
              success: false,
              loading: false,
            })
          }
        })
      }
    } else {
      this.setState({
        ...this.state,
        loading: false,
        notification: `Please fill out all the required fields.`,
        open: true,
        success: false,
      })
    }
  }

  closeNotification = () => {
    this.setState({
      notification: ``,
      open: false,
    })
  }

  handleNumberError = (name) => {
    let { userData } = this.state
    if (name === 'userPhone') {
      if (
        userData[name] &&
        !userData[name].toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        this.setState((state) => ({
          numberError: { ...state.numberError, [name]: true },
        }))
      }
    }
  }

  handlePhoneNumber = (e) => {
    let { name, value } = e.target
    this.setState((state) => ({
      userData: {
        ...state.userData,
        [name]: value,
      },
      numberError: {
        ...state.numberError,
        [name]: false,
      },
    }))
  }

  checkValidEmail = (e) => {
    let { userData } = this.state
    if (userData.email !== '') {
      let valid = Boolean(
        userData.email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      )
      this.setState({ valid })
      return valid
    }
  }

  renderInputValue = (data) => {
    if (data === 'null' || !data) {
      return ''
    } else {
      return data
    }
  }
  getValue = (obj, path) => {
    let split = path.split('.')
    let finalValue = { ...obj }
    split.forEach((value) => {
      if (finalValue[value]) {
        finalValue = finalValue[value]
      } else {
        finalValue = ''
        return false
      }
    })
    return finalValue
  }

  closeQuestionPopup = () => {
    this.setState({
      openQuestion: false,
    })
  }

  closeReviewPopup = () => {
    this.setState({
      openReview: false,
    })
  }

  closeEdits = () => {
    this.setState({ viewEdits: false })
  }

  closeImagePopup = () => {
    this.setState({ openpop: false })
  }

  renderProgramForm = () => {
    const {
      blankError,
      valid,
      numberError,
      clearFields,
      anchorEl,
      anchor,
      validationError,
      userData,
      editUserPhone,
      access_type,
      accessType,
    } = this.state
    const { matches, classes, auth, clearFormFields } = this.props
    const isAdmin =
      Object.keys(auth).length &&
      (auth.accessType === 'Admin' || auth.access_type === 'Super Admin')
    const clearForm = clearFormFields || clearFields
    const token = Object.keys(auth).length && auth.token !== ''
    if (
      userData &&
      userData.userPhone &&
      userData.userPhone.length === 11 &&
      userData.userPhone[0] === '1'
    ) {
      userData.userPhone = userData.userPhone.substr(1)
    }

    return (
      <div className="">
        {console.log('userData',userData)}
        <div className="business-profile-header">Business Profile</div>
        <div className="close-icon">
          <IconButton onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="schedule-pc">
          <TabContainer className="misc-wrapper">
            <div className="div-wrapper">
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <div className="program-form-group">
                    <div>
                      <label>
                        First Name <span className="required-label">*</span>
                      </label>
                    </div>
                    <TextField
                      onBlur={!isAdmin ? this.checkEmptyField : () => {}}
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      name="firstName"
                      onChange={this.handleUserChange}
                      className="input-box"
                      value={this.renderInputValue(userData.firstName)}
                      type="text"
                      variant="outlined"
                    />
                    {validationError.firstName && (
                      <p name="error" className="error-message">
                        Please enter your First Name.
                      </p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="program-form-group">
                    <div>
                      <label>
                        Last Name <span className="required-label">*</span>
                      </label>
                    </div>
                    <TextField
                      onBlur={!isAdmin ? this.checkEmptyField : () => {}}
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      name="lastName"
                      className="input-box"
                      onChange={this.handleUserChange}
                      value={this.renderInputValue(userData.lastName)}
                      type="text"
                      variant="outlined"
                    />
                    {validationError.lastName && (
                      <p name="error" className="error-message">
                        Please enter your Last Name.
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} lg={6}>
                  <div className="program-form-group">
                    <div style={{ display: 'flex' }}>
                      <label className="program-form-lable">
                        Primary Email <span className="required-label">*</span>
                      </label>
                      <FieldPopup
                        id="email-field-popup"
                        open={anchor === 'email-field'}
                        anchorEl={
                          anchor === 'email-field' ? anchorEl : undefined
                        }
                        onClose={this.closePopup}
                      />
                    </div>
                    <TextField
                      className="input-box"
                      onBlur={this.checkValidEmail}
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      name="email"
                      onChange={this.handleUserChange}
                      value={this.renderInputValue(userData.email)}
                      type="email"
                      variant="outlined"
                    />
                    {!valid ? (
                      <p className="error-message" name="error">
                        The email address you have entered is invalid.
                      </p>
                    ) : null}

                    {blankError && userData.email === '' && !isAdmin ? (
                      <p name="error" className="error-message">
                        Please enter your email address.
                      </p>
                    ) : null}
                    {validationError.email && (
                      <p name="error" className="error-message">
                        Please enter a valid email address.
                      </p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="program-form-group">
                    <div style={{ display: 'flex' }}>
                      <label>
                        Your Phone Number
                        <span className="required-label">*</span>
                      </label>
                    </div>
                    {editUserPhone ? (
                      <TextField
                        name="userPhone"
                        classes={{ root: matches ? classes.phone : classes.pc }}
                        type="text"
                        onChange={this.handlePhoneNumber.bind(this)}
                        onBlur={this.toggleEditUserPhone}
                        variant="outlined"
                        className="input-box"
                        value={this.renderInputValue(userData.userPhone)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={this.state.flagUrl}
                                  style={{ width: '29px' }}
                                  alt="image"
                                />
                                <div style={{ marginLeft: '5px' }}>+1</div>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <TextField
                        readOnly
                        name="userPhone"
                        classes={{ root: matches ? classes.phone : classes.pc }}
                        type="text"
                        variant="outlined"
                        className="input-box"
                        onFocus={this.toggleEditUserPhone}
                        value={
                          !clearForm
                            ? this.phoneNumberFormatter(userData.userPhone) ||
                              ''
                            : ''
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={this.state.flagUrl}
                                  style={{ width: '29px' }}
                                  alt="image"
                                />
                                <div style={{ marginLeft: '5px' }}>+1</div>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    {numberError.userPhone ? (
                      <p name="error" className="error-message">
                        This phone number is not valid
                      </p>
                    ) : null}
                    {validationError.userPhone && (
                      <p name="error" className="error-message">
                        Please enter a valid phone number.
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <div className="program-form-group">
                    <div style={{ display: 'flex' }}>
                      <label>
                        Company Name <span className="required-label">*</span>
                      </label>
                    </div>
                    <TextField
                      onBlur={this.checkEmptyField}
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      name="businessName"
                      className="input-box"
                      onChange={this.handleUserChange}
                      value={this.renderInputValue(userData.businessName)}
                      type="text"
                      variant="outlined"
                    />
                    {validationError.businessName && (
                      <p name="error" className="error-message">
                        Please enter the name of your company.
                      </p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <div className="program-form-group">
                    <div>
                      <label>Company Title</label>
                    </div>
                    <TextField
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      name="companyTitle"
                      onChange={this.handleUserChange}
                      value={this.renderInputValue(userData.companyTitle)}
                      type="text"
                      className="input-box"
                      variant="outlined"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  {auth.accessType === 'Super Admin' && (
                    <div className="user-level-div program-form-group">
                      <div>
                        <label className="user-level-label">User Level:</label>
                      </div>
                      <FormControl
                        className={'input-box'}
                        style={{ textAlign: 'left' }}
                      >
                        <Select
                          name="access_type"
                          onChange={this.handleUserChange}
                          value={this.state.userData.access_type}
                          classes={{
                            selectMenu: 'without-padding-select-cities',
                            root: 'select-div',
                          }}
                          input={
                            <OutlinedInput
                              className="select-input"
                              id="state-input"
                              labelWidth={0}
                            />
                          }
                        >
                          {accessType.map((u) => (
                            <MenuItem key={u} value={u}>
                              {u}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <div className="program-form-group">
                    <div>
                      <label>
                        Password <span className="required-label">*</span>
                      </label>
                    </div>
                    <TextField
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      name="password"
                      onChange={this.handleUserChange}
                      value={this.renderInputValue(userData.password)}
                      type="password"
                      onFocus={this.handleFocusPassword}
                      onBlur={this.handleFocusPassword}
                      className="input-box"
                      variant="outlined"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </TabContainer>
        </div>
      </div>
    )
  }

  handleClose = () => {
    this.setState(
      {
        blankError: false,
        notification: ``,
        open: false,
        success: false,
        valid: true,
        anchorEl: undefined,
        anchor: '',
        editNumber: false,
        numberError: {
          phone_num: false,
          crisis_toll_free_num: false,
          userPhone: false,
        },
        clearFields: false,
        editUserNumber: false,
        loading: false,
        validationError: {},
        flagUrl: findFlagUrlByCountryName('United States'),
        viewEdits: false,
        review: {},
        question: {},
        openQuestion: false,
        openpop: false,
        url: '',
        openReview: false,
        editUserPhone: false,
        access_type: '',
      },
      () => {
        this.props.onCloseModal()
      }
    )
  }

  render() {
    const {
      loading,
      openpop,
      success,
      notification,
      viewEdits,
      review,
      openReview,
      question,
      url,
      openQuestion,
      open,
      userData,
      access_type,
      accessType,
    } = this.state
    const { matches, classes, auth, openModal } = this.props
    let alertNew = {}

    return (
      <Modal
        open={openModal}
        onClose={this.handleClose}
        classes={{
          root: matches
            ? classes.mobileModal
            : classes.modal + ' modal-main-div business-modal',
        }}
      >
        <div
          className="business-profile-admin-container"
          id="container-element"
        >
          {/*{ userData.email !== '' && auth.id !== this.props.match.params.id &&*/}
          {/*<MUIButton onClick={this.sendPasswordSetupEmail} classes={{root: classes.sendBtn}}>*/}
          {/*  Send Password Setup Email*/}
          {/*</MUIButton>*/}
          {/*}*/}
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            // autoHideDuration={3000}
            open={open}
            onClose={this.closeNotification}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {
                root: success ? classes.successNotif : classes.errorNotif,
              },
            }}
            message={notification}
            action={
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.closeNotification}
              >
                <CancelCircle />
              </IconButton>
            }
          />

          <EditsPopup
            open={viewEdits}
            alert={alertNew}
            matches={matches}
            onClose={this.closeEdits}
          />
          <ReviewPopup
            review={review}
            open={openReview}
            onClose={this.closeReviewPopup}
            auth={auth}
            matches={matches}
          />
          <QuestionPopup
            question={question}
            open={openQuestion}
            onClose={this.closeQuestionPopup}
            auth={auth}
            matches={matches}
          />
          <ImagePopup
            open={openpop}
            url={url}
            onClose={this.closeImagePopup}
            matches={matches}
          />

          <div className="business-profile-admin-form-container">
            <div className={'title-box'}>{this.renderProgramForm()}</div>
            <div className="business-profileadmin-program-form-group">
              <div className="business-profileadmin-program-button-group">
                <MUIButton
                  onClick={() => this.updateProgram('Published')}
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ height: '25px', width: '25px', color: 'white' }}
                    />
                  ) : (
                    'Update Profile'
                  )}
                </MUIButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapState = (state) => {
  console.log('To state', state.user)
  return {
    auth: state.auth,
    user: state.user.user,
  }
}

const mapDispatch = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  }
}

export default connect(
  mapState,
  mapDispatch
)(withRouter(withStyles(styles)(BusinessProfile)))
