import baseService from './baseService'
import { setAuthHeader } from './business.service'

export const googleLogin = (email) => {
  return baseService.get(`/google/login/${email}`)
}

export const login = (credentials) => {
  return baseService.post(`public/auth/login`, credentials)
}

export const checkPassword = (email, password) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  return baseService.put(`/check/password/${email}`, password, config)
}

export const signup = (userInfo) => {
  return baseService.post(`/public/auth/registration`, userInfo)
}

export const logout = (userInfo) => {
  return baseService.post(`/private/auth/logout`, true, setAuthHeader())
}

export const changePassword = (passwords) => {
  return baseService.post(`/public/auth/change-password`, passwords)
}

export const sendOTP = (email) => {
  return baseService.post(`/public/code-confirmation/send`, {
    email,
    type: 'recovery',
  })
}

export const codeConfirm = (data) => {
  return baseService.post(`/public/code-confirmation/confirm`, data)
}

export const setPassword = (credentials) => {
  return baseService.post(`/set-password`, credentials)
}
