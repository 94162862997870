import { combineReducers } from 'redux'
import business from './business.reducer'
import category from './category.reducer'
import auth from './auth.reducer'
import alert from './alert.reducer'
import user from './user.reducer'
import common from './common.reducer'

export default combineReducers({
  business,
  category,
  auth,
  alert,
  user,
  common,
})
