import React, { useEffect, useState } from 'react'
import './AboutUs.css'
import HeaderMenu from '../Menu/Menu'

function AboutUs(props) {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    setMobile(isMobile.any())
  }, [])

  return (
    <div className="main-wrap-about-us">
      <div
        className="header-wrap d-flex header-display"
        style={{ background: 'transparent' }}
      >
        <HeaderMenu matches={props.matches} svgSize="25" paddingReduce={true} />
      </div>
      <div className={'about-us-title-div'}>LEGAL DISCLAIMER</div>
      <div className="custom-container">
        <div>
          <h3>
            <b>Legal Disclaimer</b>
          </h3>
          <div>
            <p className="text-indent">
              NAZ Mental Health Matters, Inc, a 501(c)3. NAZ Mental Health
              Matters, Inc. does not provide any diagnosis or treatment.
              Moreover, NAZ Mental Health Matters, Inc. does not provide any
              medical advice whatsoever.{' '}
            </p>
            <p className="text-indent">
              This website contains educational, reference, and local reference
              information intended for Northern Arizona residents. All of the
              services on this website are provided by external resources. While
              NAZ Mental Health Matters, Inc. intends to keep all of the content
              on this website as accurate as possible, we make no guarantees or
              warranties of any kind, either expressed or implied, regarding
              information contained on this website.{' '}
            </p>
            <p className="text-indent">
              In an effort to provide our users with helpful information and
              references, the www.FlagstaffResource.com website contains links
              to other external websites.We are not responsible for such third
              party websites, nor do we control such websites. These links
              arenot intended as specific endorsements of any care, treatment,
              or services provided through those external websites.{' '}
            </p>
          </div>
          <iframe
            width="100%"
            height="414"
            src="https://www.youtube.com/embed/gf-POrZVABs"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
