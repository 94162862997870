import React, { useEffect, useState } from 'react'
import './ContactUs.css'
import HeaderMenu from "../Menu/Menu";

function ContactUs(props) {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function () {
        return Boolean(isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };
    setMobile(isMobile.any())
  }, [])

  return (
    <div className="main-wrap-about-us">
      <div className="header-wrap d-flex header-display" style={{ background: 'transparent' }}>
        <HeaderMenu matches={props.matches} svgSize="25" paddingReduce={true} />
      </div>
      <div className={'about-us-title-div'}>ABOUT US</div>
      <div className="custom-container">
        <div>
          <h3><b>Contact:</b></h3>
          <div>
            <p className="text-indent">              
            For general questions or supplies, 
            please email &nbsp;
            <a href = "mailto: Andrea.NAZResources@gmail.com">Andrea.NAZResources@gmail.com</a>
            &nbsp;
            or 
            &nbsp;
            <a href = "tel: 9286996523">call (928) 699-6523</a>
            . 
              {/* For General Questions please contact,

              Andrea Labelle

              Andrea.Labelle@me.com

              (928) 699-6523 */}
          </p>

          </div>
          <iframe width="80%" height="300" src="https://www.youtube.com/embed/BXToxOjfOwU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<h2 style={{paddingTop:10}}>Testimonials from local organizations:</h2>
          <iframe width="80%" height="300" src="https://www.youtube.com/embed/3vcpPHRnV74" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  )
}

export default ContactUs