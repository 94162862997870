import React, { Component } from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  withStyles,
  Select,
  IconButton,
  MenuItem,
  Input,
  Paper as MUIPaper,
  TableFooter,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar as MUISnackbarContent,
  CircularProgress,
} from '@material-ui/core'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import {
  CancelRounded,
  Delete,
  FileCopy,
  Search,
  Visibility,
} from '@material-ui/icons'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ExportToCsv } from 'export-to-csv'
import 'react-quill/dist/quill.snow.css'
import './MainWindow.css'
import * as userActions from '../../../actions/user.action'
import SignUp from '../../SignUp/SignUp'
import BusinessProfile from './BusinessProfile/BusinessProfile'
import HeaderMenu from '../../Menu/Menu'
import export_to_csv from '../../../assets/images/export_to_csv.png'
import {
  faEye,
  faTrashAlt,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import add_new_program from '../../../assets/images/add_new_program.png'
import * as alertActions from '../../../actions/alert.action'

var _ = require('lodash')

const styles = () => ({
  csvButton: {
    fontWeight: 'bold',
    background: '#245497',
    color: '#fff',
    marginRight: '30px',
    '&:hover': {
      background: '#1f447e',
    },
  },
  viewBtn: {
    color: '#fff',
    background: '#32B241',
    marginRight: '5%',
    '&:hover': {
      background: '#2f973e',
    },
  },
  deleteBtn: {
    color: '#fff',
    background: '#bf1a05',
    '&:hover': {
      background: '#aa1905',
    },
  },
  successNotif: {
    backgroundColor: '#43a047',
  },
  errorNotif: {
    backgroundColor: '#d32f2f',
  },
})

const TableHeadCell = withStyles({
  root: {
    fontSize: 14,
    color: '#3c3c3c',
    fontWeight: 800,
  },
})(TableCell)

const Paper = withStyles({
  root: {
    padding: '1%',
    width: '100%',
    marginTop: '2%',
    borderRadius: 0,
  },
})(MUIPaper)

const Snackbar = withStyles({
  root: {
    maxWidth: '700px',
  },
})(MUISnackbarContent)

class LoginNSecurity extends Component {
  state = {
    order: 1,
    sortBy: 'business_name',
    openDialog: false,
    businessId: -1,
    notification: ``,
    open: false,
    success: false,
    users: [],
    userCount: 0,
    userPageNum: 1,
    userEntries: 10,
    userOrder: 1,
    userSortBy: 'email',
    userId: -1,
    dialogText: '',
    allUsers: [],
    businessUsers: [],
    userSelectedValue: [],
    searchUser: '',
    searchingUser: false,
    showSignUp: false,
    showBusinessProfile: false,
    selectedId: null,
    isMobile: false,
    searchValue: '',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let allUsers
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    if (prevState.isMobile !== isMobile.any()) {
      return {
        isMobile: isMobile.any(),
      }
    }
    if (prevState.searchUser !== '' && nextProps.user.users._id) {
      let userId = prevState.allUsers.filter(
        (b) => b._id === nextProps.user.users._id
      )
      const userIdData = [
        { ...userId[0], approval_status: nextProps.user.users.approved },
      ]
      allUsers = nextProps.user.allUsers.filter(
        (b) =>
          Boolean(
            b.email.toUpperCase().match(prevState.searchUser.toUpperCase())
          ) ||
          Boolean(
            b.user &&
              b.user.businessName &&
              b.user.businessName
                .toUpperCase()
                .match(prevState.searchUser.toUpperCase())
          ) ||
          Boolean(
            b.user &&
              b.user.lastName &&
              b.user.lastName
                .toUpperCase()
                .match(prevState.searchUser.toUpperCase())
          ) ||
          Boolean(
            b.user &&
              b.user.firstName &&
              b.user.firstName
                .toUpperCase()
                .match(prevState.searchUser.toUpperCase())
          )
      )
      let filteredData = allUsers.filter((el) => {
        return el._id !== userIdData[0]._id
      })
      return {
        searchUser: prevState.searchUser,
        users: filteredData,
        allUsers: filteredData,
        searchingUser: true,
      }
    }
    if (prevState.searchUser !== '') {
      allUsers = nextProps.user.allUsers.filter(
        (b) =>
          Boolean(
            b.email.toUpperCase().match(prevState.searchUser.toUpperCase())
          ) ||
          Boolean(
            b.user &&
              b.user.businessName &&
              b.user.businessName
                .toUpperCase()
                .match(prevState.searchUser.toUpperCase())
          ) ||
          Boolean(
            b.user &&
              b.user.lastName &&
              b.user.lastName
                .toUpperCase()
                .match(prevState.searchUser.toUpperCase())
          ) ||
          Boolean(
            b.user &&
              b.user.firstName &&
              b.user.firstName
                .toUpperCase()
                .match(prevState.searchUser.toUpperCase())
          )
      )
      return {
        searchUser: prevState.searchUser,
        users: allUsers,
        allUsers,
        searchingUser: true,
      }
    }
    return {}
  }

  componentDidMount() {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    this.setState({ isMobile: isMobile.any() })
    if (this.props.auth.token !== '') {
      const { userPageNum, userOrder, userSortBy, searchUser, searchValue } =
        this.props.user.paginatedUserData
      const { userEntries } = this.state
      const { auth } = this.props
      if (
        auth.accessType === 'Admin' ||
        auth.accessType === 'Super Admin' ||
        auth.accessType === 'Read Only'
      ) {
        this.props.userActions
          .getAllUser(
            userEntries,
            userPageNum,
            userOrder,
            userSortBy,
            searchValue
          )
          .then((data) => {
            if (data) {
              this.setState({
                users: data.userListing,
                userCount: data.count,
              })
            }
          })
        this.props.userActions.getUnfilteredUser().then((data) => {
          if (data) {
            this.setState({
              allUsers: data.userListing,
              businessUsers: data.userListing,
            })
          }
        })
      }

      if (searchUser.trim()) {
        let allUsers = this.props.user.allUsers.filter(
          (b) =>
            Boolean(b.email.toUpperCase().match(searchUser.toUpperCase())) ||
            Boolean(
              b.user &&
                b.user.businessName &&
                b.user.businessName
                  .toUpperCase()
                  .match(searchUser.toUpperCase())
            ) ||
            Boolean(
              b.user &&
                b.user.lastName &&
                b.user.lastName.toUpperCase().match(searchUser.toUpperCase())
            ) ||
            Boolean(
              b.user &&
                b.user.firstName &&
                b.user.firstName.toUpperCase().match(searchUser.toUpperCase())
            )
        )
        this.setState((prevState) => {
          return {
            ...prevState,
            users: allUsers,
            allUsers,
          }
        })
      }
      if (
        this.props.auth.accessType === 'Admin' ||
        this.props.auth.accessType === 'Super Admin'
      ) {
        if (!searchUser && !searchUser.trim()) {
          this.props.userActions.getUnfilteredUser().then((data) => {
            if (data) {
              this.setState({
                allUsers: data.userListing,
                businessUsers: data.userListing,
              })
            }
          })
        }
      } else {
        this.props.userActions.getUnfilteredUser().then((data) => {
          this.setState({
            allUsers: data.userListing,
            businessUsers: data.userListing,
          })
        })
        this.props.userActions
          .getAllUser(
            userEntries,
            userPageNum,
            userOrder,
            userSortBy,
            searchValue
          )
          .then((data) => {
            this.setState({
              users: data.userListing,
              businessUsers: data.userListing,
              userCount: data.count,
            })
          })
      }
    } else {
      this.props.history.push('/')
    }
  }

  updateCallBack = () => {
    const { userEntries } = this.state
    const { userPageNum, userOrder, userSortBy, searchValue } =
      this.props.user.paginatedUserData

    return () =>
      this.props.userActions
        .getAllUser(
          userEntries,
          userPageNum,
          userOrder,
          userSortBy,
          searchValue
        )
        .then((data) => {
          this.setState({
            users: data.userListing,
            businessUsers: data.userListing,
            userCount: data.count,
          })
        })
  }

  confirmDelete = (businessId, dialogText) => {
    this.setState({ openDialog: true, businessId, dialogText })
  }

  confirmDeleteUser = (userId, dialogText) => {
    this.setState({ openDialog: true, userId, dialogText })
  }

  closeDialog = () => {
    this.setState({ openDialog: false, businessId: -1 })
  }

  renderUserPagination = (pageNum, pages) => {
    const { isMobile } = this.state
    if (pages.length <= (isMobile ? 3 : 6)) {
      return pages.map((a, i) => (
        <div
          style={{ backgroundColor: pageNum === i + 1 ? '#ececec' : null }}
          onClick={() => this.changeUserPage(i + 1)}
          className="page-btn"
          key={i}
        >
          {i + 1}
        </div>
      ))
    } else {
      let pagination = ''
      if (isMobile) {
        if (pageNum < 2) {
          pagination = (
            <div className="d-flex">
              {pages.map((a, i) =>
                i < 2 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changeUserPage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
              <div className="page-btn">...</div>
              <div
                style={{
                  backgroundColor: pageNum === pages.length ? '#ececec' : null,
                }}
                onClick={() => this.changeUserPage(pages.length)}
                className="page-btn"
              >
                {pages.length}
              </div>
            </div>
          )
        } else if (pageNum >= 2 && pageNum < pages.length - 1) {
          pagination = (
            <div>
              <div
                style={{ backgroundColor: pageNum === 1 ? '#ececec' : null }}
                onClick={() => this.changeUserPage(1)}
                className="page-btn"
              >
                {1}
              </div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i > pageNum - 2 && i < pageNum + 1 && i < pages.length - 1 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changeUserPage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
              <div className="page-btn">...</div>
              <div
                style={{
                  backgroundColor: pageNum === pages.length ? '#ececec' : null,
                }}
                onClick={() => this.changeUserPage(pages.length)}
                className="page-btn"
              >
                {pages.length}
              </div>
            </div>
          )
        } else if (pageNum >= pages.length - 2) {
          pagination = (
            <div>
              <div
                style={{ backgroundColor: pageNum === 1 ? '#ececec' : null }}
                onClick={() => this.changeUserPage(1)}
                className="page-btn"
              >
                {1}
              </div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i >= pages.length - 5 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changeUserPage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
            </div>
          )
        }
      } else {
        if (pageNum < 5) {
          pagination = (
            <div className="d-flex">
              {pages.map((a, i) =>
                i < 5 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changeUserPage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
              <div className="page-btn">...</div>
              <div
                style={{
                  backgroundColor: pageNum === pages.length ? '#ececec' : null,
                }}
                onClick={() => this.changeUserPage(pages.length)}
                className="page-btn"
              >
                {pages.length}
              </div>
            </div>
          )
        } else if (pageNum >= 5 && pageNum < pages.length - 4) {
          pagination = (
            <div>
              <div
                style={{ backgroundColor: pageNum === 1 ? '#ececec' : null }}
                onClick={() => this.changeUserPage(1)}
                className="page-btn"
              >
                {1}
              </div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i > pageNum - 2 && i < pageNum + 3 && i < pages.length - 1 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changeUserPage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
              <div className="page-btn">...</div>
              <div
                style={{
                  backgroundColor: pageNum === pages.length ? '#ececec' : null,
                }}
                onClick={() => this.changeUserPage(pages.length)}
                className="page-btn"
              >
                {pages.length}
              </div>
            </div>
          )
        } else if (pageNum >= pages.length - 5) {
          pagination = (
            <div>
              <div
                style={{ backgroundColor: pageNum === 1 ? '#ececec' : null }}
                onClick={() => this.changeUserPage(1)}
                className="page-btn"
              >
                {1}
              </div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i >= pages.length - 5 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changeUserPage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
            </div>
          )
        }
      }
      return pagination
    }
  }

  changeUserPage = (page) => {
    let { userEntries, userPageNum, userOrder, userSortBy, searchValue } =
      this.state
    let { auth } = this.props
    let email = null
    if (auth.accessType === 'User') email = auth.email
    if (userPageNum !== page) {
      this.setState(
        {
          userPageNum: page,
        },
        () => {
          this.props.userActions
            .getAllUser(userEntries, page, userOrder, userSortBy, searchValue)
            .then((data) => {
              this.setState({
                users: data.userListing,
                userCount: data.count,
              })
            })
        }
      )
    }
  }

  sortUsers = (orderArg, sortByArg) => {
    const {
      userOrder,
      userSortBy,
      userEntries,
      userPageNum,
      allUsers,
      searchValue,
    } = this.state
    let { auth } = this.props
    let email = null
    if (auth.accessType === 'User') email = auth.email
    if (userOrder !== orderArg || userSortBy !== sortByArg) {
      this.setState(
        {
          userOrder: orderArg,
          userSortBy: sortByArg,
        },
        () => {
          this.props.userActions
            .getAllUser(
              userEntries,
              userPageNum,
              orderArg,
              sortByArg,
              searchValue
            )
            .then((data) => {
              this.setState({
                users: data.userListing,
                userCount: data.count,
              })
            })

          this.props.userActions
            .getAllUser(allUsers.length, 1, orderArg, sortByArg, searchValue)
            .then((data) => {
              this.setState({
                allUsers: data.userListing,
              })
            })
        }
      )
    }
  }

  onUserChangeEntries = (e) => {
    let {
      userEntries,
      userPageNum,
      userCount,
      userOrder,
      userSortBy,
      searchValue,
    } = this.state
    let { auth } = this.props
    let email = null
    if (auth.accessType === 'User') email = auth.email
    let pages = Math.ceil(userCount / e.target.value)
    if (userPageNum > pages) {
      userPageNum = pages
    }
    if (userEntries !== e.target.value) {
      this.setState(
        {
          userEntries: e.target.value,
          userPageNum,
        },
        () => {
          this.props.userActions
            .getAllUser(
              e.target.value,
              this.state.userPageNum,
              userOrder,
              userSortBy,
              searchValue
            )
            .then((data) => {
              this.setState({
                users: data.userListing,
                userCount: data.count,
              })
            })
        }
      )
    }
  }

  searchProgramSubmit = () => {
    let { userEntries, userPageNum, userOrder, userSortBy, searchValue } =
      this.state
    this.props.userActions
      .getAllUser(userEntries, userPageNum, userOrder, userSortBy, searchValue)
      .then((data) => {
        this.setState({
          users: data.userListing,
          userCount: data.count,
          searchingUser: false,
        })
      })
  }

  searchUser = (e) => {
    let { value } = e.target
    this.setState((prevState) => ({
      ...prevState,
      searchValue: value,
    }))
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.searchProgramSubmit()
    }
  }

  viewUserDetails = (userFull) => {
    const { email, user, ...userTosend } = userFull
    this.setState({
      showBusinessProfile: true,
      selectedUser: { ...userTosend, ...user, email },
    })
    // this.props.history.push(`/my-listing/user-details/${id}`);
  }

  deleteUser = (id) => {
    this.closeDialog()
    this.props.userActions.deleteUser(id).then(() => {
      if (this.props.user.error === '') {
        this.setState({
          users: this.props.user.users,
        })
      }
    })
  }

  exportToCSVUser = (data) => {
    let formattedData = []
    const { userSelectedValue } = this.state
    const keys = [
      'businessName',
      'firstName',
      'lastName',
      ...userSelectedValue.map((value) => value.id),
    ]
    data.forEach((data1) => {
      Object.keys(data1)
        .map((value) => {
          if (!Array.isArray(data1[value]) && typeof data1[value] === 'object')
            data1 = { ...data1, ...data1[value] }
        })
        .filter((key) => !keys.includes(key))
        .forEach((key) => delete data1[key])

      data1.category = data1.category ? data1.category.join(',') : []
      console.log('data1.user',data1.user)
      let orderedData = {}

      keys.forEach((key) => {
        if (data1.user) {
          orderedData[key] =
            data1.user[key] === undefined ? '' : data1.user[key]
        } else {
          orderedData[key] = ''
        }
      })

      orderedData['Email'] =
        data1['email'] !== 'andrea.labelle@me.com' ? data1['email'] : ''
      formattedData.push(orderedData)
    })

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      filename: 'User Listing',
      title: 'User Listing',
      useTextFile: false,
      useBom: true,
      headers: [
        'Company Name',
        'firstName',
        'lastName',
        'Email',
        ...userSelectedValue.map((value) => value.value),
      ],
    }
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(formattedData)
  }

  handleClose = () => {
    this.setState({ open: false, notification: '' })
  }

  closeSignUp = () => {
    this.setState({ showSignUp: false })
  }

  closeBusinessProfile = () => {
    this.setState({ showBusinessProfile: false })
  }

  render() {
    const { classes, auth, matches, user } = this.props
    const {
      order,
      sortBy,
      isMobile,
      openDialog,
      businessId,
      notification,
      open,
      success,
      dialogText,
      userId,
      users,
      userPageNum,
      userEntries,
      allUsers,
      searchUser,
      userCount,
      searchingUser,
      businessUsers,
      showSignUp,
      showBusinessProfile,
      selectedUser,
    } = this.state
    const userData = searchingUser ? allUsers : users
    let userPages = new Array(Math.ceil(userCount / userEntries)).fill('a')
    if (user.loadingUser) {
      return (
        <div className="loader">
          <CircularProgress
            className={classes.progress}
            style={{ color: '#FFFFFF' }}
          />
        </div>
      )
    }
    return (
      <div className="admin-main-container">
        <SignUp
          openModal={showSignUp}
          onClose={this.closeSignUp}
          newUser={true}
          callback={this.updateCallBack()}
        />

        <BusinessProfile
          openModal={showBusinessProfile}
          userId={selectedUser}
          onCloseModal={this.closeBusinessProfile}
          callBack={this.updateCallBack()}
        />

        <div className={classes.root}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {
                root: success ? classes.successNotif : classes.errorNotif,
              },
              style: { maxWidth: '631px' },
            }}
            message={
              <span>
                {Array.isArray(notification)
                  ? notification.map((x, key) => <span key={key}>{x}</span>)
                  : notification}
              </span>
            }
            action={
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CancelRounded />
              </IconButton>
            }
          />
        </div>
        <div className="admin-main">
          <div
            className="header-wrap d-flex header-display"
            style={{ background: 'transparent' }}
          >
            <HeaderMenu matches={matches} svgSize="25" paddingReduce={true} />
          </div>
          <Dialog open={openDialog} onClose={this.closeDialog}>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this {dialogText}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={this.closeDialog} color="primary">
                No
              </Button>
              <Button
                onClick={() => {
                  dialogText === 'program'
                    ? this.deleteBusiness(businessId)
                    : this.deleteUser(userId)
                }}
                color="primary"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <div className={'my-account-text'}>MY ACCOUNT</div>
          {auth.accessType === 'Super Admin' && (
            <Paper style={{ padding: 0, borderRadius: '5px' }}>
              <div className={'data-title'}>
                {' '}
                <h4 className={'program-entries-header'}>Login & Security</h4>
                <div className="export-wrap">
                  <Button
                    classes={{ root: classes.csvButton + ' web-btn' }}
                    onClick={() => {
                      this.exportToCSVUser(allUsers)
                    }}
                  >
                    <img
                      src={export_to_csv}
                      className={'btn-img'}
                      alt={'image'}
                    />
                    {'EXPORT TO CSV'}
                  </Button>
                  <Button
                    classes={{ root: classes.csvButton + ' m-btn' }}
                    onClick={() => {
                      this.exportToCSVUser(allUsers)
                    }}
                  >
                    <img
                      src={export_to_csv}
                      className={'btn-img'}
                      alt={'image'}
                    />
                    {'EXPORT'}
                  </Button>
                  <Button
                    classes={{ root: classes.csvButton + ' web-btn' }}
                    onClick={() => this.setState({ showSignUp: true })}
                  >
                    <img
                      src={add_new_program}
                      className={'btn-img'}
                      alt={'image'}
                    />
                    {'Add New User'}
                  </Button>
                  <Button
                    classes={{ root: classes.csvButton + ' m-btn' }}
                    onClick={() => this.setState({ showSignUp: true })}
                  >
                    <img
                      src={add_new_program}
                      className={'btn-img'}
                      alt={'image'}
                    />
                    {'Add'}
                  </Button>
                  <label className={'show-page-label'}>
                    Show per Page
                    <Select
                      disabled={searchingUser}
                      onChange={this.onUserChangeEntries}
                      value={userEntries}
                      input={
                        <Input type="number" style={{ fontSize: '14px' }} />
                      }
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </label>
                  <div className={'search-box'}>
                    <Input
                      onChange={this.searchUser}
                      value={this.state.searchValue}
                      onKeyDown={this.handleKeyDown}
                      endAdornment={<Search />}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
              <div className="data-table">
                {userData.length > 0 ? (
                  <Table style={{ fontFamily: 'Roboto' }}>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell className={'number-font-color'}>
                          #
                        </TableHeadCell>
                        <TableHeadCell>
                          <div className="header-sort">
                            Email
                            <div className="sort-icon-group">
                              <FontAwesomeIcon
                                className={
                                  order === 1 && sortBy === 'email'
                                    ? 'active-sort-icon'
                                    : 'sort-icon'
                                }
                                icon={'long-arrow-alt-up'}
                                onClick={() => this.sortUsers(1, 'email')}
                              />
                              <FontAwesomeIcon
                                className={
                                  order === -1 && sortBy === 'email'
                                    ? 'active-sort-icon'
                                    : 'sort-icon'
                                }
                                icon={'long-arrow-alt-down'}
                                onClick={() => this.sortUsers(-1, 'email')}
                              />
                            </div>
                          </div>
                        </TableHeadCell>
                        <TableHeadCell className={'m-hide'}>
                          <div className="header-sort">
                            Business Name
                            <div className="sort-icon-group">
                              <FontAwesomeIcon
                                className={
                                  order === 1 && sortBy === 'company_name'
                                    ? 'active-sort-icon'
                                    : 'sort-icon'
                                }
                                icon={'long-arrow-alt-up'}
                                onClick={() =>
                                  this.sortUsers(1, 'company_name')
                                }
                              />
                              <FontAwesomeIcon
                                className={
                                  order === -1 && sortBy === 'company_name'
                                    ? 'active-sort-icon'
                                    : 'sort-icon'
                                }
                                icon={'long-arrow-alt-down'}
                                onClick={() =>
                                  this.sortUsers(-1, 'company_name')
                                }
                              />
                            </div>
                          </div>
                        </TableHeadCell>
                        <TableHeadCell className={'mobile-view-hide'}>
                          <div className="header-sort">
                            First Name
                            <div className="sort-icon-group">
                              <FontAwesomeIcon
                                className={
                                  order === 1 && sortBy === 'first_name'
                                    ? 'active-sort-icon'
                                    : 'sort-icon'
                                }
                                icon={'long-arrow-alt-up'}
                                onClick={() => this.sortUsers(1, 'first_name')}
                              />
                              <FontAwesomeIcon
                                className={
                                  order === -1 && sortBy === 'first_name'
                                    ? 'active-sort-icon'
                                    : 'sort-icon'
                                }
                                icon={'long-arrow-alt-down'}
                                onClick={() => this.sortUsers(-1, 'first_name')}
                              />
                            </div>
                          </div>
                        </TableHeadCell>
                        <TableHeadCell className={'mobile-view-hide'}>
                          <div className="header-sort">
                            Last Name
                            <div className="sort-icon-group">
                              <FontAwesomeIcon
                                className={
                                  order === 1 && sortBy === 'last_name'
                                    ? 'active-sort-icon'
                                    : 'sort-icon'
                                }
                                icon={'long-arrow-alt-up'}
                                onClick={() => this.sortUsers(1, 'last_name')}
                              />
                              <FontAwesomeIcon
                                className={
                                  order === -1 && sortBy === 'last_name'
                                    ? 'active-sort-icon'
                                    : 'sort-icon'
                                }
                                icon={'long-arrow-alt-down'}
                                onClick={() => this.sortUsers(-1, 'last_name')}
                              />
                            </div>
                          </div>
                        </TableHeadCell>
                        <TableHeadCell align={'center'}>Action</TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.map((userItem, i) => (
                        <TableRow key={userItem._id}>
                          <TableCell
                            className={'tableCell-font-style number-font-color'}
                          >
                            {i + 1 + (userPageNum - 1) * userEntries}
                          </TableCell>
                          <TableCell className={'tableCell-font-style'}>
                            {(userItem.email && userItem.email.toLowerCase()) ||
                              ''}
                          </TableCell>
                          <TableCell className={'tableCell-font-style m-hide'}>
                            {(userItem.user && userItem.user.businessName) ||
                              ''}
                          </TableCell>
                          <TableCell
                            className={'tableCell-font-style mobile-view-hide'}
                          >
                            {(userItem.user && userItem.user.firstName) || ''}
                          </TableCell>
                          <TableCell
                            className={'tableCell-font-style mobile-view-hide'}
                          >
                            {(userItem.user && userItem.user.lastName) || ''}
                          </TableCell>
                          <TableCell align="center">
                            <div className={'btn-section'}>
                              <div style={{ display: 'flex' }}>
                                {' '}
                                <Button
                                  className={
                                    isMobile
                                      ? 'btn-view mobile-view-hide'
                                      : 'btn-view'
                                  }
                                  onClick={() => this.viewUserDetails(userItem)}
                                  classes={{ root: classes.viewBtn }}
                                >
                                  <FontAwesomeIcon icon={faEye} />{' '}
                                  {isMobile ? '' : 'View'}
                                </Button>
                              </div>
                              {auth.accessType === 'Super Admin' && (
                                <div
                                  onClick={() =>
                                    this.confirmDeleteUser(userItem._id, 'user')
                                  }
                                  className={'delete-icon-div'}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />{' '}
                                </div>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter hidden={searchingUser}>
                      {/*<TableRow >*/}
                      {/*  <TableCell component="td" align="left" colSpan={isMobile ? 0 : 3}>*/}
                      {/*    <div className={'showing-page-div'}>*/}
                      {/*      Showing {1+((userPageNum-1)*userEntries)} to {userEntries*userPageNum<userCount?userEntries*userPageNum:userCount} of {userCount} entries*/}
                      {/*    </div>*/}
                      {/*  </TableCell>*/}
                      {/*  <TableCell component="td" colSpan={isMobile ? 1 : 7} align="right">*/}
                      {/*    <div className="pagination">*/}
                      {/*      {this.renderUserPagination(userPageNum, userPages)}*/}
                      {/*      <div*/}
                      {/*          onClick={()=>this.changeUserPage(userPageNum!==1?userPageNum-1:1)}*/}
                      {/*          className={userPageNum === 1 ? "disabled-p-btn" : "p-btn"}>*/}
                      {/*        <FontAwesomeIcon icon={faArrowLeft} />*/}
                      {/*      </div>*/}
                      {/*      <div*/}
                      {/*          onClick={()=>this.changeUserPage(userPageNum!==userPages.length?userPageNum+1:userPages.length)}*/}
                      {/*          className={userPageNum === userPages.length ? "disabled-page-btn" : "n-btn"}>*/}
                      {/*        <FontAwesomeIcon icon={faArrowRight} />*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*  </TableCell>*/}
                      {/*</TableRow>*/}
                      <TableRow>
                        <TableCell
                          component="td"
                          align="left"
                          colSpan={isMobile ? userData.length + 1 : 6}
                        >
                          <div className={'pagination-div'}>
                            <div className={'pagination-showpage'}>
                              <div className={'showing-page-div'}>
                                Showing {1 + (userPageNum - 1) * userEntries} to{' '}
                                {userEntries * userPageNum < userCount
                                  ? userEntries * userPageNum
                                  : userCount}{' '}
                                of {userCount} entries
                              </div>
                            </div>
                            <div className={'pagination-pagenumber'}>
                              {/*<TableCell component="td" colSpan={isMobile ? 2 : 7} align="right">*/}
                              <div className="pagination">
                                {this.renderUserPagination(
                                  userPageNum,
                                  userPages
                                )}
                                <div
                                  onClick={() =>
                                    this.changeUserPage(
                                      userPageNum !== 1 ? userPageNum - 1 : 1
                                    )
                                  }
                                  className={
                                    userPageNum === 1
                                      ? 'disabled-p-btn'
                                      : 'p-btn'
                                  }
                                >
                                  <FontAwesomeIcon icon={faArrowLeft} />
                                </div>
                                <div
                                  onClick={() =>
                                    this.changeUserPage(
                                      userPageNum !== userPages.length
                                        ? userPageNum + 1
                                        : userPages.length
                                    )
                                  }
                                  className={
                                    userPageNum === userPages.length
                                      ? 'disabled-page-btn'
                                      : 'n-btn'
                                  }
                                >
                                  <FontAwesomeIcon icon={faArrowRight} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                ) : (
                  <div className={'no-data-div'}>No data to display</div>
                )}
              </div>
            </Paper>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    alert: state.alert,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    getAlerts: bindActionCreators(alertActions.getAlerts, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginNSecurity))
