export const initState = {
  business: {
    business: {},
    businesses: [],
    successfullyFetchBusiness: '',
    categoryWiseBusiness: {},
    filteredBusiness: [],
    error: '',
    isFiltered: false,
    isDelete: false,
    loadingBusiness: false,
    allBusinesses: [],
    disclaimer: {},
    paginatedData: {
      pageNum: 1,
      order: 1,
      sortBy: 'program_name',
      search: '',
    },
  },
  category: {
    allCategories: [],
    categories: {},
    error: '',
  },
  auth: {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    id: localStorage.getItem('id') ? localStorage.getItem('id') : '',
    email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
    error: '',
    accessType: localStorage.getItem('accessType')
      ? localStorage.getItem('accessType')
      : '',
    user:
      localStorage.getItem('user') !== 'undefined'
        ? JSON.parse(localStorage.getItem('user'))
        : {},
    reset: {
      email: '',
      modal: null,
    },
    modal: null,
  },
  alert: {
    alerts: [],
    notificationAlerts: [],
    alert: {},
    filteredAlert: {},
    clearAlert: [],
    loadingAlert: false,
  },
  user: {
    user: {},
    error: '',
    users: [],
    allUsers: [],
    paginatedUserData: {
      userPageNum: 1,
      userOrder: 1,
      userSortBy: 'email',
      searchUser: '',
    },
    loadingUser: false,
  },
}
