import './App.css'
import React, { Component, useState, useRef } from 'react'
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import store from '../store'
import Home from 'components/Home/Home'
import Health from 'components/Health/Health'
import Food from 'components/Food/Food'
import SignUp from './SignUp/SignUp'
import Housing from 'components/Housing/Housing'
import Content from 'components/Content/Content'
import Gallery from 'components/Gallery/Gallery'
import ProgramForm from 'components/ProgramForm/ProgramForm'
import AdminUserDetailsWindow from './AdminPanel/MainWindow/BusinessProfile/BusinessProfile'
import SimpleProgramForm from 'components/ProgramForm/SimpleProgramForm'
import ProgramApproval from 'components/ProgramApproval/ProgramApproval'
import AdminDetailsWindow from './AdminPanel/DetailsWindow/DetailsWindow'
import AdminUserContactInfo from './AdminPanel/DetailsWindow/UserContactInfo'
import SetPassword from './SetPassword/SetPassword'
import AboutUs from './AboutUs/AboutUs'
import LearnMore from './LearnMore/LearnMore'
import ContactUs from './ContactUs/ContactUs'
import Map from './Map'
import Alerts from './../components/AdminPanel/MainWindow/Alerts'
import MyListing from './../components/AdminPanel/MainWindow/MyListing'
import LoginNSecurity from './../components/AdminPanel/MainWindow/LoginNSecurity'
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery'
import container_background from '../assets/images/container_background.png'
import { HeaderBar } from './HeaderBar'
import ContentComponent from './Content/Content'

const App = () => {
  const matches = useMediaQuery('(max-width: 770px)')
  const tabletScreen = useMediaQuery('(max-width: 770px)')
  const [toggleTip, toggleToolTip] = useState(false)

  const childMap = useRef()

  return (
    <Provider store={store}>
      <Router>
        <>
          <HeaderBar />
          <div>
            <div className="resource-details">
              <ContentComponent />
            </div>
          </div>
          <div className={'wrapper'}>
            <div className={'abc'}>
              {/*<div id="google_translate_element"></div>*/}
              <Home
                matches={matches}
                toggleTip={toggleTip}
                toggleToolTip={toggleToolTip}
                childMapRef={childMap}
              />
            </div>
            <div
              className={'main-screen'}
              style={{ backgroundImage: `url(${container_background})` }}
            >
              <Switch>
                <Route
                  exact
                  path="/health/:id?/:form?"
                  render={(props) => (
                    <Health
                      {...props}
                      matches={matches}
                      toggleTip={toggleTip}
                      toggleToolTip={toggleToolTip}
                    />
                  )}
                />
                <Route
                  exact
                  path="/food/:id?/:form?"
                  render={(props) => (
                    <Food
                      {...props}
                      matches={matches}
                      toggleTip={toggleTip}
                      toggleToolTip={toggleToolTip}
                    />
                  )}
                />
                <Route
                  exact
                  path="/housing/:id?/:form?"
                  render={(props) => (
                    <Housing
                      {...props}
                      matches={matches}
                      toggleTip={toggleTip}
                      toggleToolTip={toggleToolTip}
                    />
                  )}
                />
                <Route
                  exact
                  path="/search/:query?"
                  render={(props) => (
                    <Map {...props} childMapRef={childMap} matches={matches} />
                  )}
                />
                <Route
                  exact
                  path="/gallery"
                  render={(props) => <Gallery {...props} />}
                  toggleTip={toggleTip}
                  toggleToolTip={toggleToolTip}
                />
                <Route
                  exact
                  path="/new/program"
                  render={(props) => (
                    <ProgramForm
                      {...props}
                      matches={matches}
                      toggleTip={toggleTip}
                      toggleToolTip={toggleToolTip}
                    />
                  )}
                />
                <Route
                  exact
                  path="/simple/program"
                  render={(props) => (
                    <SimpleProgramForm
                      {...props}
                      matches={matches}
                      toggleTip={toggleTip}
                      toggleToolTip={toggleToolTip}
                    />
                  )}
                />
                <Route
                  exact
                  path="/my-listing/user-details/:id"
                  render={(props) => (
                    <AdminUserDetailsWindow {...props} matches={matches} />
                  )}
                />
                <Route
                  exact
                  path="/approve-program/:id"
                  render={(props) => (
                    <ProgramApproval {...props} matches={matches} />
                  )}
                />
                <Route
                  exact
                  path="/my-listing/details/:id"
                  render={(props) => (
                    <AdminDetailsWindow {...props} matches={matches} />
                  )}
                />
                <Route
                  exact
                  path="/my-listing/contact-info"
                  render={(props) => (
                    <AdminUserContactInfo {...props} matches={matches} />
                  )}
                />
                <Route
                  exact
                  path="/set-password"
                  render={(props) => <SetPassword {...props} />}
                />
                <Route
                  exact
                  path="/reset-password"
                  render={(props) => <SetPassword {...props} reset={true} />}
                />
                <Route
                  exact
                  path="/alerts"
                  render={(props) => <Alerts {...props} reset={true} />}
                />
                <Route
                  exact
                  path="/my-listing"
                  render={(props) => <MyListing {...props} reset={true} />}
                />
                <Route
                  exact
                  path="/login-n-security"
                  render={(props) => <LoginNSecurity {...props} reset={true} />}
                />
                <Route
                  exact
                  path="/sign-up"
                  render={(props) => <SignUp {...props} />}
                />
                <Route
                  exact
                  path="/legal-disclaimer"
                  render={(props) => <AboutUs {...props} matches={matches} />}
                />
                <Route
                  exact
                  path="/learn-more"
                  render={(props) => <LearnMore {...props} matches={matches} />}
                />
                <Route
                  exact
                  path="/contact-us"
                  render={(props) => <ContactUs {...props} matches={matches} />}
                />

                <Route
                  path="/all"
                  render={(props) => (
                    <Map {...props} childMapRef={childMap} matches={matches} />
                  )}
                />
                <Redirect from="/" to="/all?cities=Flagstaff" />
              </Switch>
            </div>
          </div>
        </>
      </Router>
    </Provider>
  )
}

export default App
