import * as services from '../service/review.service';
import * as types from '../constants/action.constants';

export const addReview = (review) => {
  return dispatch => {
    return services.addReview(review).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.ADD_REVIEW
        })
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.ADD_REVIEW_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const approveReview = (id) => {
  return dispatch => {
    return services.approveReview(id).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.APPROVE_REVIEW,
          id
        })
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.APPROVE_REVIEW_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const removeReview = (review) => {
  return dispatch => {
    return services.removeReview(review).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.REMOVE_REVIEW,
          review
        })
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.REMOVE_REVIEW_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const removeFlyer = (id) => {
  return dispatch => {
    return services.removeFlyer(id).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.REMOVE_FLYER,
          id
        })
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.REMOVE_FLYER_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const postReply = (reply) => {
  return dispatch => {
    return services.postReply(reply).then(resp=>{
      if(resp.status==200){
        // console.log("reply posted",resp)
        // dispatch({
        //   type: types.POST_REPLY,
        //   review: resp.data
        // });
        return resp.data;
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.POST_REPLY_FAIL,
          error: err.response.data
        })
      }
    })
  }
};