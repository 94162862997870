import React from 'react'
import { withStyles } from '@material-ui/core'
import HeaderMenu from '../Menu/Menu'
import { useSelector } from 'react-redux'
import './SidePanelHeader.css'

const styles = () => ({
  root: {
    color: '#1fb400',
    fontWeight: 'bolder',
  },
  collapseWrapper: {
    // display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
    color: 'black',
    fontWeight: 'bold',
  },
})

const SidePanelHeader = ({ isMobile, matches, closeDetailBox }) => {
  const isMenuOpened = useSelector((state) => state.common.menu)

  return (
    <div className="map-text">
      {isMobile ? (
        <HeaderMenu
          matches={matches}
          svgSize="25"
          paddingReduce={true}
          closeDetailBox={closeDetailBox}
          isMenuOpened={isMenuOpened}
        />
      ) : (
        <HeaderMenu
          matches={matches}
          closeDetailBox={closeDetailBox}
          isMenuOpened={isMenuOpened}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(SidePanelHeader)
