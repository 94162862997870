import * as types from '../constants/action.constants'
import {initState} from './initialState';

export default (state = initState.category, action) => {
    switch (action.type) {
      case types.GET_CATEGORIES:
            return {
              ...state,
              categories: {...action.categories},
              error: ""
            };
      case types.GET_ALL_CATEGORIES:
        return {
          ...state,
          allCategories: [...action.categories],
          error: ""
        };
      case types.GET_CATEGORIES_FAIL:
          return Object.assign({}, state, { error: action.error });

      case types.GET_ALL_CATEGORIES_FAIL:
        return Object.assign({}, state, { error: action.error });
      default:
            return { ...state }
    }
}