import baseService from './baseService'
import { setAuthHeader } from './business.service'

export const getAllUsers = (entries, pageNum, order, sortBy, email) => {
  if (email)
    return baseService.get(
      `/private/users?show=${entries}&page=${pageNum}&sortBy=${sortBy}&order=${order}&search=${email}`,
      setAuthHeader()
    )
  else
    return baseService.get(
      `/private/users?show=${entries}&page=${pageNum}&sortBy=${sortBy}&order=${order}`,
      setAuthHeader()
    )
}

export const deleteUser = (id) => {
  return baseService.delete(`private/user/${id}`, setAuthHeader())
}

export const adminUser = (id) => {
  return baseService.get(`/private/user`, setAuthHeader())
}

export const updateUser = (user) => {
  return baseService.put(`/private/user`, user, setAuthHeader())
}

export const createUser = (user) => {
  return baseService.post(`/private/user`, user, setAuthHeader())
}
