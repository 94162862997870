import React, { useEffect, useState } from 'react'

export const useWindowWidth = () => {
  const [width, setWidth] = useState('mobile')

  useEffect(() => {
    const handleResize = () => {
      const innerWidth = window.innerWidth
      if (innerWidth > 1200) {
        setWidth('desktop')
      } else if (innerWidth >= 768) {
        setWidth('tablet')
      } else {
        setWidth('mobile')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return width
}
