import React, { useEffect, useState } from 'react'
import './AboutUs.css'
import HeaderMenu from '../Menu/Menu'
import SignUp from '../SignUp/SignUp';
import ConfirmEmail from "../SignUp/ConfirmEmail";
import Login from '../Login/Login';
import ForgotPassword from '../ForgotPassword/ForgotPassword';


function LearnMore({matches,history}) {
  const [mobile, setMobile] = useState(false)
  const [state, setState] = useState({showSignUp:false,confirmEmail:"",openConfirmEmail:false,openLogin:false,openForgotPassword:false})
// console.log("props in learn more",props)

  useEffect(() => {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    setMobile(isMobile.any())
  }, [])
  const openSignUp = () => {
    setState((prev)=>({...prev,showSignUp: true}))
  }
  const closeSignUp = () => {
    setState((prev)=>({...prev,showSignUp: false}))
  }
  const goToAdmin = () => {
    history.push('/my-listing');
  };
  const closeLogin = () => {
    setState((prev)=>({...prev,openLogin: false}))
  };
  const openLogin = () => {
    setState((prev)=>({...prev,openLogin: true}))
  };

  const openConfirmEmail = (close, email = '') => {
    if (close)
    setState((prev)=>({...prev,openConfirmEmail: false}))
    else
    setState((prev)=>({...prev,openConfirmEmail: true, confirmEmail: email }))
  }
  const openForgotPassword = (close) => {
    if (close)
    setState((prev)=>({...prev,openForgotPassword: false}))
    else
    setState((prev)=>({...prev,openForgotPassword: true}))
  };
  return (
    <div className="main-wrap-about-us">
      <div
        className="header-wrap d-flex header-display"
        style={{ background: 'transparent' }}
      >
        <HeaderMenu matches={matches} svgSize="25" paddingReduce={true} />
      </div>
      <div className={'about-us-title-div'}>LEARN MORE</div>
      <div className="custom-container">
        <div>
          <h3>
            <b>Flagstaff Resources is a Free Living Directory</b>
          </h3>
          <div>
            <p className="text-indent">
            Listings include Therapists, Housing Assistance, Assisted Living, Food Centers, Financial Aid, Transportation, Childcare, Preschools, Mental Health Support, Physical Health Services, Local and National Hotlines, Elderly, Veteran and Teen specific services, and more.  Most services listed on the directory are provided by Non Profits and through Government Programs, others are provided through Public and Private School Programs, and Independent Therapy offices.  Services range from free, sliding scale, accepting insurance, and/or fee based.
            </p>
            <p className="text-indent">
            If you work for an Organization, Government or Company, and provide a service that can be helpful to the community, please <a onClick={openSignUp}>sign up</a> to add your service to this Free Living Directory.
            </p>
            <p className="text-indent">
            Please help keep Flagstaff Resources a Living Directory by keeping the information on your listing updated, and by removing your listing when your service is no longer available.  You can do this in real time by simply <a onClick={openLogin}>logging in</a> to your account and making those changes. 
            </p>
            <p className="text-indent">
            To list your service on Flagstaff Resources free local directory, you must be located in or close proximity to Flagstaff Residents, or you must offer your service virtually.
            </p>
          </div>
          <div className="iframe-container">
              <iframe
              className="responsive-iframe"
            src="https://www.youtube.com/embed/LxIh182D1LA"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          </div>
          <br/><br/>
          <div className="iframe-container">
              <iframe
              className="responsive-iframe"
              src="https://www.youtube.com/embed/tLKlBvUre0M"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <SignUp openModal={state.showSignUp} onClose={closeSignUp} openConfirmEmail={openConfirmEmail} goToAdmin={goToAdmin} matches={matches} />
          <ConfirmEmail open={state.openConfirmEmail} onClose={openConfirmEmail} matches={matches} email={state.confirmEmail} />
          <Login open={state.openLogin} onClose={closeLogin} openForgotPassword={openForgotPassword} openSignUp={openSignUp}
          goToAdmin={goToAdmin} matches={matches} />
          <ForgotPassword open={state.openForgotPassword} onClose={openForgotPassword} matches={matches} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LearnMore
