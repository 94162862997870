import React, { Component } from 'react'
import {
  GridList,
  GridListTile,
  withStyles,
  IconButton,
  Typography,
  Divider,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import AddPhoto from '@material-ui/icons/AddAPhotoOutlined'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { uploadImage } from '../../../actions/business.action'
import './GalleryGrid.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const styles = () => ({
  imageTile: {
    cursor: 'pointer',
    border: '1px solid #7b7b7b',
  },
  icon: {
    color: '#1890ff',
  },
})

class GalleryGrid extends Component {
  onFileOpenDialog = () => {
    document.getElementById('file-input').click()
  }

  onFileSelect = ({ target }, program) => {
    const { files } = target
    const { uploadImage, showMessage, auth } = this.props
    const formData = new FormData()
    for (let i = 0; i < files.length; i += 1) {
      formData.append('file', files[i])
    }
    console.log('AUTH', auth)
    formData.append('program', program)
    auth.accessType === 'Admin'
      ? formData.append('approved', 'true')
      : formData.append('approved', 'false')
    uploadImage(formData).then(() => {
      if (this.props.business.error === '') {
        showMessage('Image submitted successfully', true)
      } else {
        showMessage('Something went wrong', false)
      }
    })
  }

  render() {
    let {
      galleryImages,
      resource,
      classes,
      handleBackClick,
      handleImageClick,
      displayImageIdx,
      cols,
    } = this.props
    if (!(resource && resource.id)) {
      return <></>
    }
    return (
      <div className="gallery-grid">
        <div className="gallery-header">
          <input
            type="file"
            onChange={(e) => this.onFileSelect(e, resource.id)}
            style={{ display: 'none' }}
            id="file-input"
          />
          <IconButton onClick={this.onFileOpenDialog}>
            <AddPhoto className={classes.icon} />
          </IconButton>
          <Typography style={{ width: '70%' }}>
            {resource.program_name.toUpperCase()}
          </Typography>
          <IconButton onClick={handleBackClick}>
            <ArrowBack />
          </IconButton>
        </div>
        <Divider />
        <div className="gallery-grid-list">
          {galleryImages.length > 0 ? (
            <GridList cellHeight={180} cols={cols}>
              {galleryImages.map((image, idx) => {
                if (image.approved === true)
                  return (
                    <GridListTile
                      style={{
                        border:
                          displayImageIdx === idx && cols === 1
                            ? '3px solid #1890ff'
                            : '',
                      }}
                      onClick={() => handleImageClick(idx)}
                      className={classes.imageTile}
                      key={idx}
                      cols={1}
                    >
                      <img className="thumbnail-image" src={image.url} alt="" />
                    </GridListTile>
                  )
              })}
            </GridList>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <span>
                <h3>No Images found</h3>
              </span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapState = (state) => ({
  business: state.business,
  auth: state.auth,
})

const mapDispatch = (dispatch) => ({
  uploadImage: bindActionCreators(uploadImage, dispatch),
})
export default connect(mapState, mapDispatch)(withStyles(styles)(GalleryGrid))
