import React from 'react'
import { withStyles, IconButton } from '@material-ui/core'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight'
import ArrowBack from '@material-ui/icons/ArrowBack'
import './GalleryImage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const styles = () => ({
  icon: {
    fontSize: 30,
    color: '#7b7b7b',
  },
})

class GalleryImage extends React.Component {
  state = {
    clientX: 100,
  }

  touchMove = (e) => {
    let { clientX } = e.targetTouches[0]
    let { handleImageClick, displayImageIdx, resource, galleryImages } =
      this.props
    if (clientX < this.state.clientX + 150) {
      if (displayImageIdx + 1 < galleryImages.length)
        handleImageClick(displayImageIdx + 1)
    } else if (clientX > this.state.clientX - 150) {
      if (displayImageIdx - 1 >= 0) handleImageClick(displayImageIdx - 1)
    }
  }

  render() {
    let {
      classes,
      resource,
      displayImageIdx,
      handleImageClick,
      handleArrowClick,
      handleBackClick,
      galleryImages,
    } = this.props
    return (
      <div className="image-container-wrap">
        {galleryImages.length > 0 && (
          <div className="slider-container">
            <IconButton className={'closegallery'} onClick={handleBackClick}>
              <p className={'cross'}>
                <FontAwesomeIcon icon={faTimes} />
              </p>
            </IconButton>
            <img
              onTouchMove={this.touchMove}
              className="image-dim"
              src={galleryImages[displayImageIdx].url}
              alt=""
            />
          </div>
        )}
        <div className="nav-icons">
          <IconButton
            disabled={displayImageIdx === 0}
            onClick={() => handleImageClick(displayImageIdx - 1)}
          >
            <ArrowLeft className={classes.icon} />
          </IconButton>

          <IconButton
            disabled={displayImageIdx === galleryImages.length - 1}
            onClick={() => handleImageClick(displayImageIdx + 1)}
          >
            <ArrowRight className={classes.icon} />
          </IconButton>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GalleryImage)
