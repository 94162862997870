import baseService from './baseService';

export const getCategories = (category) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.get(`/categories/${category}`, config)
};

export const getAllCategories = () => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.get(`/categories`, config)
};
