import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Geocode from 'react-geocode'
import {
  TextField as MUITextField,
  Button as MUIButton,
  Snackbar as MUISnackbarContent,
  withStyles,
  Checkbox as MUICheckbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Collapse,
  Grid,
} from '@material-ui/core'
import { Info, CheckCircle } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import CancelCircle from '@material-ui/icons/CancelRounded'
import { getAllCategories } from '../../actions/category.action.js'
import { saveBusiness } from '../../actions/business.action'
import FieldPopup from '../FieldPopup/FieldPopup'
import './ProgramForm.css'
import { findFlagUrlByCountryName } from 'country-flags-svg'
import { GOOGLE_KEY } from '../../utils/Const'

const Checkbox = withStyles({
  root: {
    '&$checked': {
      color: '#245497',
    },
  },
  checked: {},
})(MUICheckbox)

const TextField = withStyles({
  root: {
    marginTop: '2%',
  },
})(MUITextField)

/*const CancelIcon = withStyles({
 root: {
 color: "#c70b1b"
 }
 })(CancelCircle);*/

const Snackbar = withStyles({
  root: {
    maxWidth: '700px',
  },
})(MUISnackbarContent)

const styles = () => ({
  pc: {
    width: '100%',
    height: '45px',
    borderColor: 'rgb(0, 0, 0) !important',
  },
  phone: {
    width: '100%',
    minHeight: '45px',
    borderColor: 'rgb(0, 0, 0)',
  },
  successNotif: {
    backgroundColor: '#43a047',
  },
  errorNotif: {
    backgroundColor: '#d32f2f',
  },
  btnMobile: {
    width: '25%',
  },
  checkCircle: {
    fontSize: 115,
  },
  checkCirclePhone: {
    fontSize: 70,
  },
})

const Button = withStyles({
  root: {
    background: '#fff',
    width: '12%',
    height: '40px',
    fontWeight: 'bolder',
    border: '3px solid #245397',
    color: '#245397',
    '&:hover': {
      background: '#fff',
      color: '#245497',
      borderColor: '#245497',
    },
  },
})(MUIButton)

const GreenCheckbox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

class SimpleProgramForm extends Component {
  state = {
    business: {
      firstName: '',
      lastName: '',
      userPhone: '',
      companyTitle: '',
      companyLogo: undefined,
      primaryEmail: '',
      // password: "",
      businessName: '',
      programName: '',
      programWebsite: '',
      financialInformation: '',
      address: {
        address_1: '',
        address_2: '',
        city: '',
        state: 'AZ',
        postal_code: '',
        lat: null,
        lng: null,
        PlaceId: '',
      },
      programPhone: '',
      crisisFreePhone: '',
      programTollFreePhone: '',
      crisisPhone: '',
      timing: [
        {
          day: 'Monday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Tuesday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Wednesday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Thursday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Friday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Saturday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Sunday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
      ],
      programEligibility: '',
      category: {
        category: [],
        sub_category: [],
      },
      county: '',
      languages: ['English'],
      programShortDesc: '',
      programLongDesc: '',
      programServices: '',
      galleryImages: [],
      // programStatus: "",
      googleDirectionUrl: '',
      internalNotes: '',
    },
    categories: [],
    display_logo: '',
    display_images: [],
    valid: true,
    blankError: false,
    error: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
    notification: ``,
    open: false,
    success: false,
    validZip: true,
    anchorEl: undefined,
    anchor: '',
    editNumber: false,
    editCrisisNumber: false,
    numberError: {
      programPhone: false,
      crisisFreePhone: false,
      userPhone: false,
    },
    link: false,
    linkObject: {},
    showAnotherProgramButton: false,
    editUserNumber: false,
    loading: false,
    activeStep: 0,
    steps: [0, 1, 2, 3, 4, 5],
    Days: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    daysActiveTab: 0,
    showError: {},
    eachTabFields: [
      ['primaryEmail', 'firstName', 'lastName', 'businessName', 'userPhone'],
      ['programName', 'address.city'],
      ['programTiming'],
      ['languages', 'programShortDesc'],
      ['category.category'],
      [],
    ],
    mainError: false,
    customOrder: ['health', 'food', 'housing', 'other'],
    flagUrl: findFlagUrlByCountryName('United States'),
  }

  componentDidMount() {
    this.props.getAllCategories().then(() => {
      if (this.props.category.error === '') {
        this.setState({ categories: this.props.category.allCategories })
      }
    })
    if (this.props.auth.token !== '') {
      let { user } = this.props.auth
      let isUser = user !== 'undefined' && Object.keys(user).length
      let admin =
        this.props.auth.accessType === 'Admin' ||
        this.props.auth.accessType === 'Super Admin'
      this.setState((state) => ({
        business: {
          ...state.business,
          primaryEmail: admin ? '' : this.props.auth.email,
          firstName: isUser && !admin ? user.firstName : '',
          lastName: isUser && !admin ? user.lastName : '',
          userPhone: isUser && !admin ? user.userPhone : '',
          companyTitle: isUser && !admin ? user.companyTitle : '',
          businessName: isUser && !admin ? user.businessName : '',
        },
        eachTabFields: admin
          ? [[], ...state.eachTabFields.slice(1, state.eachTabFields.length)]
          : [...state.eachTabFields],
      }))
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.auth.token !== newProps.auth.token) {
      let { user } = newProps.auth
      let notUser = Object.keys(user).length === 0
      let admin =
        newProps.auth.accessType === 'Admin' ||
        newProps.auth.accessType === 'Super Admin'
      this.setState((state) => {
        return {
          blankError: false,
          numberError: {
            programPhone: false,
            crisisFreePhone: false,
            userPhone: false,
          },
          error: {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false,
          },
          business: {
            ...state.business,
            primaryEmail: newProps.auth.email,
            firstName: notUser && '',
            lastName: notUser && '',
            companyTitle: notUser && '',
            businessName: notUser && '',
            userPhone: notUser && '',
          },
          eachTabFields: admin
            ? [[], ...state.eachTabFields.slice(1, state.eachTabFields.length)]
            : [...state.eachTabFields],
        }
      })
    }
  }

  checkValidZip = () => {
    let { business } = this.state
    let validZip = true
    if (business.address.postal_code !== '') {
      validZip = Boolean(
        business.address.postal_code.match(/^[0-9]{5}(?:-[0-9]{4})?$/)
      )
    }
    this.setState({ validZip })
    return validZip
  }

  handleGetAddressAndPhone = () => {
    let { auth } = this.props
    let { business } = this.state
    if (
      business.address.address_1 !== '' &&
      !business.address.city === 'Online resources'
    ) {
      let address = `${business.address.address_1}, ${
        business.address.address_2
      }, ${
        Array.isArray(business.address.city)
          ? business.address.city[0]
          : business.address.city
      }, ${business.address.state}`
      Geocode.setApiKey(GOOGLE_KEY)
      let newAddress = address.replace(/[^a-zA-Z0-9 ]/g, '')
      Geocode.fromAddress(newAddress)
        .then((response) => {
          console.log('GEo resp', response)
          if (response.results.length > 0) {
            const { geometry, place_id } = response.results[0]
            business.address = {
              ...business.address,
              lat: parseFloat(geometry.location.lat),
              lng: parseFloat(geometry.location.lng),
              PlaceId: place_id,
            }
          }
          this.setState({ business }, () => {
            if (auth.token === '') this.handleSubmit()
            else this.handleLoggedInUser()
          })
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      this.setState({ ...this.state, loading: true }, () => {
        if (auth.token === '') this.handleSubmit()
        else this.handleLoggedInUser()
      })
    }
  }

  handleLoggedInUser = async () => {
    await this.handleNumberError('programPhone')
    // await this.handleNumberError('userPhone');
    // await this.handleNumberError('crisisFreePhone');
    let { business, numberError } = this.state
    let { auth } = this.props
    let isAdmin =
      auth.accessType === 'Admin' || auth.accessType === 'Super Admin'
    // let error = await this.checkValidTime();
    // let validZip = this.checkValidZip();
    if (
      (isAdmin || business.primaryEmail !== '') &&
      business.category.category.length !== 0 &&
      !numberError.programPhone &&
      business.programShortDesc !== '' &&
      business.programWebsite !== ''
    ) {
      let { saveBusiness } = this.props
      let formData = new FormData()
      formData.append('email', business.primaryEmail.toLowerCase())
      formData.append('program_website', business.programWebsite)
      formData.append('phone_num', business.programPhone)
      formData.append('category', JSON.stringify(business.category))
      formData.append('short_description', business.programShortDesc)
      formData.append('simpleForm', 'true')
      formData.append('login', 'true')

      saveBusiness(formData).then(() => {
        if (this.props.business.error === '') {
          if (this.props.auth.accessType.toLowerCase() === 'admin')
            this.setState({
              loading: false,
              // open: true,
              // notification: `The program has been successfully added`,
              // success: true,
              showAnotherProgramButton: true,
            })
          else
            this.setState({
              loading: false,
              // open: true,
              // notification: 'Thank you for adding your program. If you have more programs to add,' +
              // ' you will need to fill out a new program form for each one.',
              // success: true,
              showAnotherProgramButton: true,
            })
          this.resetForm(business.primaryEmail)
        } else {
          this.setState({
            open: true,
            notification: `Something went wrong. Please try after some time.`,
            success: false,
          })
        }
        localStorage.setItem('simple_thankyou_form', 1)
      })
    } else {
      this.setState({ blankError: true, loading: false }, () => {})
    }
  }

  handleSubmit = async () => {
    await this.handleNumberError('programPhone')
    let { business, numberError } = this.state
    let valid = this.checkValidEmail({
      target: { name: 'primaryEmail', value: business['primaryEmail'] },
    })
    if (
      valid &&
      business.primaryEmail !== '' &&
      business.category.category.length !== 0 &&
      !numberError.programPhone &&
      business.programPhone !== '' &&
      business.programShortDesc !== ''
    ) {
      let { saveBusiness } = this.props
      let formData = new FormData()
      formData.append('email', business.primaryEmail.toLowerCase())
      formData.append('program_website', business.programWebsite)
      formData.append('phone_num', business.programPhone)
      formData.append('category', JSON.stringify(business.category))
      formData.append('short_description', business.programShortDesc)
      formData.append('simpleForm', 'true')

      saveBusiness(formData).then(() => {
        if (this.props.business.error === '') {
          this.setState({
            loading: false,
            // open: true,
            // notification: ['Thank you for adding your program, you will receive an email shortly to set up your password.',
            //   <div><br/></div>,
            //   'If you have more programs, you will need to fill out a ',
            //   <Link to="/simple/program" style={{color:'white',textDecoration:'underline'}} onClick={() => window.scrollTo(0, 0)}>New Program Form</Link>,
            //   ' for each one.'],
            // success: true,
            showAnotherProgramButton: true,
          })
          this.resetForm()
        } else {
          this.setState({
            loading: false,
            open: true,
            notification: `Something went wrong. Please try after some time.`,
            success: false,
          })
        }
        localStorage.setItem('simple_thankyou_form', 1)
      })
    } else {
      this.setState({ blankError: true, loading: false }, () => {})
    }
  }

  handleSubCategoryChange = (sub, selected) => {
    this.setState((state) => {
      let { business } = state
      if (selected) {
        business.category.sub_category = [
          ...business.category.sub_category,
          sub,
        ]
      } else {
        business.category.sub_category = business.category.sub_category.filter(
          (s) => s !== sub
        )
      }
      return { business }
    })
  }

  resetForm = (email) => {
    const { activeStep } = this.state
    this.setState({
      business: {
        ...this.state.business,
        firstName: !email && '',
        lastName: !email && '',
        companyTitle: !email && '',
        userPhone: !email && '',
        businessName: !email && '',
        companyLogo: undefined,
        primaryEmail: email || '',
        // password: "",
        programName: '',
        programWebsite: '',
        financialInformation: '',
        programTollFreePhone: '',
        crisisPhone: '',
        address: {
          address_1: '',
          address_2: '',
          city: ['Flagstaff', 'Online resources'],
          state: 'AZ',
          postal_code: '',
          lat: null,
          lng: null,
          PlaceId: '',
        },
        programPhone: '',
        crisisFreePhone: '',
        timing: [
          {
            day: 'Monday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Tuesday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Wednesday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Thursday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Friday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Saturday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Sunday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
        ],
        programEligibility: '',
        category: {
          category: [],
          sub_category: [],
        },
        county: '',
        cities: ['Flagstaff', 'Online resources'],
        languages: ['English'],
        programShortDesc: '',
        programLongDesc: '',
        programServices: '',
        internalNotes: '',
        galleryImages: [],
        // programStatus: "",
        googleDirectionUrl: '',
      },
      display_logo: '',
      display_images: [],
      valid: true,
      blankError: false,
      validZip: true,
      error: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      },
      activeStep: activeStep + 1,
    })
  }

  removeImage = (file, image) => {
    let { business, display_images } = this.state
    business.galleryImages = business.galleryImages.filter(
      (img) => img.id !== file.id
    )
    display_images = display_images.filter((img) => img !== image)
    this.setState({ business, display_images })
  }

  handleProgramStatus = (status) => {
    let { business } = this.state
    business['programStatus'] = status
    this.setState({ business })
  }

  handleCategoryChange = (id, selected) => {
    this.setState((state) => {
      let newState
      let { business } = state
      if (selected) {
        business.category.category = [...business.category.category, id]
        newState = { business, blankError: false }
      } else {
        let { categories } = state
        business.category.category = business.category.category.filter(
          (cat) => cat !== id
        )
        let category = categories.find((cat) => cat.category === id)
        category.sub_category.forEach((sub) => {
          business.category.sub_category =
            business.category.sub_category.filter((s) => s !== sub)
        })
        newState = { business }
      }
      return newState
    })
  }

  checkValidEmail = (e) => {
    let { business } = this.state
    if (business.primaryEmail !== '') {
      let valid = Boolean(
        business.primaryEmail.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      )
      this.setState({ valid })
      return valid
    } else {
      if (e) this.checkEmptyField(e)
    }
  }

  checkEmptyField = (e) => {
    let { business } = this.state
    if (
      business[e.target.name]
        ? !business[e.target.name].trim()
        : !business[e.target.name]
    ) {
      this.setState({ blankError: true })
    }
  }

  handleBusinessChange = (e) => {
    let { business } = this.state
    let email = false
    if (e.target.name === 'primaryEmail') email = true
    business[e.target.name] = e.target.value
    this.setState((state) => {
      if (email) return { business, blankError: false, valid: true }
      else return { business, blankError: false }
    })
  }

  addAnotherProgram = async () => {
    this.setState(
      {
        business: {
          companyLogo: undefined,
          businessName: '',
          programName: '',
          programWebsite: '',
          programTollFreePhone: '',
          crisisPhone: '',
          financialInformation: '',
          address: {
            address_1: '',
            address_2: '',
            city: ['Flagstaff', 'Online resources'],
            state: 'AZ',
            postal_code: '',
            lat: null,
            lng: null,
            PlaceId: '',
          },
          programPhone: '',
          crisisFreePhone: '',
          timing: [
            {
              day: 'Monday',
              split_hours: false,
              full_day_open: false,
              timingSelected: true,
              open_time: '',
              close_time: '',
            },
            {
              day: 'Tuesday',
              split_hours: false,
              full_day_open: false,
              timingSelected: true,
              open_time: '',
              close_time: '',
            },
            {
              day: 'Wednesday',
              split_hours: false,
              full_day_open: false,
              timingSelected: true,
              open_time: '',
              close_time: '',
            },
            {
              day: 'Thursday',
              split_hours: false,
              full_day_open: false,
              timingSelected: true,
              open_time: '',
              close_time: '',
            },
            {
              day: 'Friday',
              split_hours: false,
              full_day_open: false,
              timingSelected: true,
              open_time: '',
              close_time: '',
            },
            {
              day: 'Saturday',
              split_hours: false,
              full_day_open: false,
              timingSelected: true,
              open_time: '',
              close_time: '',
            },
            {
              day: 'Sunday',
              split_hours: false,
              full_day_open: false,
              timingSelected: true,
              open_time: '',
              close_time: '',
            },
          ],
          programEligibility: '',
          category: {
            category: [],
            sub_category: [],
          },
          county: '',
          cities: ['Flagstaff', 'Online resources'],
          languages: ['English'],
          programShortDesc: '',
          programLongDesc: '',
          programServices: '',
          galleryImages: [],
          // programStatus: "",
          googleDirectionUrl: '',
        },
        display_logo: '',
        display_images: [],
        valid: true,
        blankError: false,
        validZip: true,
        error: {
          Monday: false,
          Tuesday: false,
          Wednesday: false,
          Thursday: false,
          Friday: false,
          Saturday: false,
          Sunday: false,
        },
      },
      () => {
        // window.scrollTo({
        //   top: 0,
        //   behavior: 'smooth',
        // })
      }
    )
  }

  checkValidTime = async () => {
    let { error } = this.state
    let { timing } = this.state.business
    await this.setState((state) => {
      timing.forEach((day) => {
        if (day.timingSelected) {
          if (day.split_hours) {
            if (
              day.shift_one.open_time === '' ||
              day.shift_one.close_time === '' ||
              day.shift_two.open_time === '' ||
              day.shift_two.close_time === ''
            ) {
              error[day.day] = true
            } else {
              error[day.day] = false
            }
          } else {
            if (day.open_time === '' || day.close_time === '') {
              error[day.day] = true
            } else {
              error[day.day] = false
            }
          }
        } else {
          error[day.day] = false
        }
      })
      return { error: { ...state.error, ...error } }
    })
    return Object.values(this.state.error).includes(true)
  }

  handleChangeTime = (day, e) => {
    let { business, error } = this.state
    let ind = business.timing.findIndex((t) => t.day === day)
    let timing = business.timing[ind]
    if (timing.split_hours) {
      if (e.target.name.match('one')) {
        if (e.target.name.match('open')) {
          timing['shift_one'] = {
            ...timing['shift_one'],
            open_time: e.target.value,
          }
        } else if (e.target.name.match('close')) {
          timing['shift_one'] = {
            ...timing['shift_one'],
            close_time: e.target.value,
          }
        }
      } else if (e.target.name.match('two')) {
        if (e.target.name.match('open')) {
          timing['shift_two'] = {
            ...timing['shift_two'],
            open_time: e.target.value,
          }
        } else if (e.target.name.match('close')) {
          timing['shift_two'] = {
            ...timing['shift_two'],
            close_time: e.target.value,
          }
        }
      }
    } else {
      if (e.target.name.match('open')) {
        timing['open_time'] = e.target.value
        timing['shift_one'] = {
          open_time: '',
          close_time: '',
        }
        timing['shift_two'] = {
          open_time: '',
          close_time: '',
        }
      } else if (e.target.name.match('close')) {
        timing['close_time'] = e.target.value
        timing['shift_one'] = {
          open_time: '',
          close_time: '',
        }
        timing['shift_two'] = {
          open_time: '',
          close_time: '',
        }
      }
    }
    error[day] = false
    if (timing['open_time'] && timing['close_time'])
      this.changeActiveDaysTab(day)
    timing.timingSelected = true
    business.timing[ind] = timing
    this.setState({ business, error })
  }

  handleClose = () => {
    this.setState({
      open: false,
      notification: '',
      link: false,
      linkObject: {},
    })
  }

  openPopup = (e) => {
    this.setState({ anchorEl: e.target, anchor: e.target.id })
  }

  closePopup = () => {
    this.setState({ anchorEl: undefined, anchor: '' })
  }

  phoneNumberFormatter = (phoneNumber) => {
    var match
    if (phoneNumber) {
      if (phoneNumber.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)) {
        if (phoneNumber.toString().match(/^\d{10}$/)) {
          match = phoneNumber
            .toString()
            .replace(/\D /g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          if (match) {
            return match
          }
        }
        return phoneNumber
      }
      return phoneNumber
    }
  }

  toggleEditNumber = (e) => {
    let { value, name } = e.target
    this.setState((state) => {
      if (
        value &&
        !value.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        return {
          editNumber: !state.editNumber,
          editUserNumber: !state.editUserNumber,
          numberError: { ...state.numberError, [name]: true },
          valid: false,
        }
      } else {
        return {
          editNumber: !state.editNumber,
          editUserNumber: !state.editUserNumber,
          valid: true,
        }
      }
    })
  }

  handleNumberError = (name) => {
    let { business } = this.state
    if (name === 'userPhone') {
      if (
        business[name] &&
        !business[name].toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        this.setState((state) => ({
          numberError: { ...state.numberError, [name]: true },
          blankError: false,
        }))
      }
      if (
        business[name] &&
        !business[name].toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        this.setState((state) => ({
          numberError: { ...state.numberError, [name]: true },
        }))
      }
    }
  }

  handlePhoneNumber = (e) => {
    let { name, value } = e.target
    this.setState((state) => ({
      business: {
        ...state.business,
        [name]: value,
      },
      numberError: {
        ...state.numberError,
        [name]: false,
      },
    }))
  }

  hideButton = () => {
    this.setState({
      ...this.state,
      showAnotherProgramButton: false,
    })
  }

  handleNextStep = async () => {
    await this.validateFullForm()
    this.setState(
      (prevState, props) => {
        let numError = Object.keys(prevState.numberError).filter((value) => {
          if (prevState.numberError[value]) return value
        })
        if (
          !prevState.mainError &&
          prevState.valid &&
          numError.length === 0 &&
          prevState.validZip
        ) {
          return {
            ...this.state,
            blankError: false,
            showError: {},
            activeStep: this.state.activeStep + 1,
          }
        } else {
          return { ...this.state }
        }
      },
      () => {
        window.scrollTo(0, 0)
      }
    )
  }

  handleBackStep = () => {
    this.setState({ ...this.state, activeStep: this.state.activeStep - 1 })
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0)
  }

  validateFullForm = async () => {
    let { activeStep, showError, eachTabFields, business } = this.state
    let mainError = false
    if (eachTabFields[activeStep][0] === 'programTiming') {
      let error = await this.checkValidTime()
      if (error) {
        showError['programTiming'] = true
        mainError = true
      } else {
        showError['programTiming'] = false
      }
    } else {
      eachTabFields[activeStep].forEach((value) => {
        let fetchValue = this.getValue(business, value)

        if (!Array.isArray(fetchValue) && fetchValue.trim('') === '') {
          value = value.split('.')
          let index = value.length > 1 ? value[1] : value[0]
          showError[index] = true
          mainError = true
        } else if (Array.isArray(fetchValue) && fetchValue.length === 0) {
          value = value.split('.')
          let index = value.length > 1 ? value[1] : value[0]
          showError[index] = true
          mainError = true
        } else {
          showError[value] = false
        }
      })
    }
    this.setState({
      ...this.state,
      mainError,
      showError: { ...this.state.showError, ...showError },
    })
    return
  }

  getValue = (obj, path) => {
    let split = path.split('.')
    let finalValue = path === 'city' ? { ...obj.address } : { ...obj }
    split.forEach((value) => {
      if (finalValue[value]) {
        finalValue = finalValue[value]
      } else {
        finalValue = ''
        return false
      }
    })
    return finalValue
  }

  getStepContent = () => {
    const {
      business,
      categories,
      valid,
      blankError,
      anchorEl,
      anchor,
      numberError,
      editNumber,
      showError,
      customOrder,
    } = this.state
    const { matches, classes, auth } = this.props
    const isAdmin =
      Object.keys(auth).length &&
      (auth.accessType === 'Admin' || auth.accessType === 'Super Admin')
    const token = Object.keys(auth).length && auth.token !== ''

    return (
      <div className="div-wrapper">
        <Grid container>
          <Grid item xs={12} sm={12} lg={12}>
            <div className="program-form-group">
              <div style={{ display: 'flex' }}>
                <label className="program-form-lable">
                  Primary Email <span className="required-label">*</span>
                </label>
                <FieldPopup
                  id="email-field-popup"
                  open={anchor === 'email-field'}
                  anchorEl={anchor === 'email-field' ? anchorEl : undefined}
                  onClose={this.closePopup}
                />
                <Info
                  className="info-icon"
                  alt=""
                  aria-owns={
                    anchor === 'email-field' ? 'email-field-popup' : undefined
                  }
                  onMouseEnter={this.openPopup}
                  onMouseLeave={this.closePopup}
                  id="email-field"
                />
              </div>
              <TextField
                InputProps={{
                  readOnly: auth.token ? true : false,
                }}
                onBlur={this.checkValidEmail}
                className="program-form-inputborder without-padding"
                classes={{ root: matches ? classes.phone : classes.pc }}
                name="primaryEmail"
                onChange={this.handleBusinessChange}
                value={isAdmin ? '' : business.primaryEmail}
                type="email"
                variant="outlined"
              />
              {!valid ? (
                <p className="error-message" name="error">
                  The email address you have entered is invalid.
                </p>
              ) : null}
              {(!isAdmin || token) &&
              !isAdmin &&
              (blankError || showError.primaryEmail) &&
              business.primaryEmail == '' ? (
                <p name="error" className="error-message">
                  Please enter your email address.
                </p>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} lg={12}>
            <div className="program-form-group">
              <div style={{ display: 'flex' }}>
                <label>
                  Phone Number<span className="required-label">*</span>
                </label>
              </div>
              {editNumber ? (
                <TextField
                  name="programPhone"
                  classes={{ root: matches ? classes.phone : classes.pc }}
                  className="without-padding"
                  type="text"
                  onChange={this.handlePhoneNumber}
                  onBlur={this.toggleEditNumber}
                  variant="outlined"
                  value={business.programPhone || ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={this.state.flagUrl}
                            className="country-flag"
                            alt="image"
                          />
                          <div style={{ marginLeft: '5px' }}>+1</div>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  readOnly
                  classes={{ root: matches ? classes.phone : classes.pc }}
                  type="text"
                  className="without-padding"
                  variant="outlined"
                  onFocus={this.toggleEditNumber}
                  value={this.phoneNumberFormatter(business.programPhone) || ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={this.state.flagUrl}
                            className="country-flag"
                            alt="image"
                          />
                          <div style={{ marginLeft: '5px' }}>+1</div>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {(blankError || showError.programPhone) &&
              business.programPhone == '' ? (
                <p className="error-message" name="error">
                  Please enter program's phone number
                </p>
              ) : null}
              {numberError.programPhone ? (
                <p name="error" className="error-message">
                  This phone number is not valid
                </p>
              ) : null}
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} lg={12}>
            <div className="program-form-group">
              <div style={{ display: 'flex' }}>
                <label>
                  Website<span className="required-label">*</span>
                </label>
              </div>
              <TextField
                type="text"
                className="without-padding"
                onChange={this.handleBusinessChange}
                onBlur={this.checkEmptyField}
                name="programWebsite"
                variant="outlined"
                value={business.programWebsite}
                classes={{ root: matches ? classes.phone : classes.pc }}
              />
              {(blankError || showError.programWebsite) &&
              business.programWebsite === '' ? (
                <p name="error" className="error-message">
                  Please enter your website.
                </p>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} lg={12}>
            <div className="program-form-group">
              <div style={{ display: 'flex', minHeight: '40px' }}>
                <label>
                  Please Provide a Description of your Program{' '}
                  <span className="required-label">*</span>
                </label>
              </div>
              <TextField
                multiline={true}
                onBlur={this.checkEmptyField}
                style={{ height: 'auto', width: '100%' }}
                type="text"
                rows={3}
                onChange={this.handleBusinessChange}
                name="programShortDesc"
                variant="outlined"
                value={business.programShortDesc}
                inputProps={{ maxLength: 245 }}
              />
              {business.programShortDesc.length === 245 ? (
                <p name="error" className="error-message">
                  You have reached to maximum characters limit allowed.
                </p>
              ) : null}
              {(blankError || showError.programShortDesc) &&
              business.programShortDesc === '' ? (
                <p name="error" className="error-message">
                  Please enter a Short Description that explains your program
                  best.
                </p>
              ) : null}
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} lg={12}>
            <div className="program-form-group">
              <div style={{ display: 'flex', minHeight: '40px' }}>
                <label>
                  Choosing filter <span className="required-label">*</span>
                </label>
              </div>
              <div className="composite-field">
                {categories
                  .sort((a, b) => {
                    a = a.category.toLowerCase()
                    b = b.category.toLowerCase()
                    return customOrder.indexOf(a) > customOrder.indexOf(b)
                      ? 1
                      : -1
                  })
                  .map((cat, index) => (
                    <div className="program-category">
                      <FormControlLabel
                        className={'program-type-' + index}
                        key={cat.category}
                        label={
                          <span className="category-title">{cat.category}</span>
                        }
                        control={
                          <GreenCheckbox
                            // className={'program-type-checkbox'}
                            checked={business.category.category.includes(
                              cat.category
                            )}
                            onChange={(e, selected) =>
                              this.handleCategoryChange(cat.category, selected)
                            }
                          />
                        }
                      />
                      <div
                        key={cat.category + index}
                        className="composite-field"
                      >
                        <Collapse
                          in={business.category.category.includes(cat.category)}
                        >
                          <div
                            className={`subcategory-container subcategory${index}`}
                          >
                            {cat.sub_category
                              .sort((a, b) => {
                                a = a.toLowerCase()
                                b = b.toLowerCase()
                                return a > b ? 1 : -1
                              })
                              .map((sub) => (
                                <FormControlLabel
                                  key={sub}
                                  label={<span>{sub}</span>}
                                  control={
                                    <Checkbox
                                      checked={business.category.sub_category.includes(
                                        sub
                                      )}
                                      onChange={(e, selected) =>
                                        this.handleSubCategoryChange(
                                          sub,
                                          selected
                                        )
                                      }
                                    />
                                  }
                                />
                              ))}
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  ))}
                {(blankError || showError.category) &&
                business.category.category.length === 0 ? (
                  <p name="error" className="error-message">
                    Please choose at least one of the above options.
                  </p>
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    const { open, notification, success, activeStep, steps } = this.state
    const { classes, matches } = this.props
    {
      activeStep === steps.length
        ? localStorage.setItem('is_thankyou_page_active', 1)
        : localStorage.setItem('is_thankyou_page_active', 0)
    }
    return (
      <div className="container" id="container-element">
        <div className={classes.root}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {
                root: success ? classes.successNotif : classes.errorNotif,
              },
              style: { maxWidth: '631px' },
            }}
            message={
              <span>
                {Array.isArray(notification)
                  ? notification.map((x, key) => <span key={key}>{x}</span>)
                  : notification}
              </span>
            }
            action={
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CancelCircle />
              </IconButton>
            }
          />
        </div>
        <div className="form-container">
          <div style={{ marginTop: '75px' }}>
            <div className="form">
              {activeStep === steps.length ? (
                <div className="outer-div-wrapper-submit">
                  <div className={classes.instructions}>
                    <p className="submit-title">Thank you!</p>
                    <p className="check-mark">
                      <CheckCircle
                        classes={{
                          root: matches
                            ? classes.checkCirclePhone
                            : classes.checkCircle,
                        }}
                      />
                    </p>
                    <p className="submit-message">
                      You will be receiving an email shortly with a link to set
                      up your password. When you login, you will be able to
                      manage and edit your listing, add any additional programs,
                      add special events, and more... If you have any questions
                      please email andrea.nazresources@gmail.com
                    </p>
                  </div>
                </div>
              ) : (
                <div className="outer-div-wrapper">
                  <div className={classes.instructions}>
                    {this.getStepContent()}
                  </div>
                  <div
                    className={
                      activeStep !== 0 ? 'btn-weapper' : 'btn-single-weapper'
                    }
                  >
                    <Button
                      onClick={this.handleGetAddressAndPhone}
                      className={classes.button + ' btnMobile'}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="bottom-title">
              This information is private and will not be published.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  return {
    auth: state.auth,
    category: state.category,
    business: state.business,
  }
}

const mapDispatch = (dispatch) => {
  return {
    getAllCategories: bindActionCreators(getAllCategories, dispatch),
    saveBusiness: bindActionCreators(saveBusiness, dispatch),
  }
}

export default connect(
  mapState,
  mapDispatch
)(withStyles(styles)(SimpleProgramForm))
