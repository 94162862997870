import React, { Component } from 'react'
import {
  Button,
  Paper as MUIPaper,
  IconButton,
  withStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  Collapse,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { connect } from 'react-redux'
// import {reorder} from 'react-reorder';
import { bindActionCreators } from 'redux'
import * as alertActions from '../../../actions/alert.action'
import * as businessActions from '../../../actions/business.action'
import * as reviewActions from '../../../actions/review.action'
import * as questionActions from '../../../actions/question.action'
import ProgramInfo from '../ProgramInfo/ProgramInfo'
import './DetailsWindow.css'
import EditsPopup from '../EditsPopup/EditsPopup'
import ReviewPopup from '../ReviewPopup/ReviewPopup'
import QuestionPopup from '../QuestionPopup/QuestionPopup'
import ImagePopup from '../ImagePopup/ImagePopup'
import HeaderMenu from '../../Menu/Menu'
import moment from 'moment'

const Paper = withStyles({
  root: {
    padding: '1%',
    width: '100%',
    marginTop: '2%',
    borderRadius: 0,
    marginBottom: '4px',
  },
})(MUIPaper)

const styles = () => ({
  clearBtn: {
    color: '#fff',
    background: '#aaa',
    marginLeft: '1%',
    textTransform: 'none',
    fonSize: 12,
    '&:hover': {
      background: '#9E9E9E',
    },
  },
  btn: {
    color: '#fff',
    background: '#aaa',
    textTransform: 'none',
    margin: '1% 1% 1% 0',
    fonSize: 12,
    '&:hover': {
      background: '#9E9E9E',
    },
  },
  sendBtn: {
    background: '#245497',
    textTransform: 'none',
    color: '#fff',
    margin: '1% 1% 1% 0',
    fonSize: 12,
    '&:hover': {
      background: '#1f447e',
    },
  },
  imageActions: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 700,
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  actionCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  unpublish: {
    color: '#fff',
    background: '#32B241',
    margin: '1% 1% 1% 0',
    '&:hover': {
      background: '#2f973e',
    },
  },
  publish: {
    color: '#fff',
    background: '#f71700',
    margin: '1% 1% 1% 0',
    '&:hover': {
      background: '#DE1700',
    },
  },
  approve: {
    color: '#32b241',
    background: '#e3f9f1',
    '&:hover': {
      background: 'rgba(50,178,65,0.08)',
    },
  },
  reject: {
    color: '#d32f2f',
    background: '#f9e5ef',
    marginLeft: '10px',
    '&:hover': {
      background: '#f9eff0',
    },
  },
  error: {
    backgroundColor: '#d32f2f',
  },
  success: {
    backgroundColor: '#32b241',
  },
  user: {
    border: '1px solid #aaa',
  },
  tableCell: {
    paddingLeft: '8px',
    paddingRight: 0,
  },
})

class DetailsWindow extends Component {
  state = {
    business: {},
    alert: {},
    open: false,
    url: '',
    openReview: false,
    review: {},
    question: {},
    openQuestion: false,
    notification: false,
    message: '',
    success: false,
    showUser: false,
    viewEdits: false,
    showApproveButton: true,
    showRejectButton: true,
    clearForm: false,
    isMobile: false,
    title: '',
  }

  componentDidMount() {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    // changed
    // this.setState({
    //   isMobile: isMobile.any(),
    //   title: 'CRISIS BILL ASSISTANCE PROGRAM',
    // })
    if (this.props.auth.token === '') {
      this.props.history.push('/')
    } else {
      this.props.businessActions
        .getAdminBusiness(this.props.match.params.id, this.props.auth.email)
        .then((business) => {
          this.setState({ business: business })
        })
      if (this.props.location.state) {
        this.setState({ alert: this.props.location.state.alert })
      }
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    this.setState({ isMobile: isMobile.any(), title: '' })
    if (this.props.auth.token === '') {
      this.props.history.push('/')
    } else {
      if (this.props.location.state !== nextProps.location.state) {
        this.props.businessActions
          .getAdminBusiness(nextProps.match.params.id, nextProps.auth.email)
          .then((business) => {
            this.setState({ business, alert: nextProps.location.state.alert })
          })
      }
    }
  }

  // openImagePopup = (url) => {
  //   this.setState({open: true, url })
  // };

  closeImagePopup = () => {
    this.setState({ open: false })
  }

  sendPasswordSetupEmail = () => {
    this.props.history.push('/my-listing/contact-info')
  }

  handleProgramStatus = (id, email, program_name, status) => {
    this.props.businessActions
      .updateApproval(id, {
        approval_status: status,
        email: email,
        program_name: program_name,
      })
      .then(() => {
        if (this.props.business.error === '') {
          this.setState((state) => ({
            business: { ...state.business, ...this.props.business.business },
          }))
        } else {
          this.setState({
            notification: true,
            message: 'Something went wrong.',
            success: false,
          })
        }
      })
  }

  removeImage = (key, id) => {
    this.props.businessActions.removeImage({ key }, id).then(() => {
      if (this.props.business.error === '') {
        let { gallery_images } = this.state.business
        gallery_images = gallery_images.filter((img) => img._id !== key)
        this.setState((state) => ({
          business: { ...state.business, gallery_images: [...gallery_images] },
        }))
      }
    })
  }

  approveImage = (key, id) => {
    this.props.businessActions.approveImage({ key }, id)
  }

  approveReview = (id) => {
    this.props.reviewActions.approveReview(id)
  }

  removeReview = (reviewId, businessId) => {
    this.props.reviewActions.removeReview({ reviewId, businessId }).then(() => {
      if (this.props.business.error === '') {
        let { reviews } = this.state.business
        reviews = reviews.filter((review) => review._id !== reviewId)
        this.setState((state) => ({
          business: { ...state.business, reviews: [...reviews] },
        }))
      }
    })
  }

  openReviewPopup = (review) => {
    this.setState({
      review,
      openReview: true,
    })
  }

  approveQuestion = (id) => {
    this.props.questionActions.approveQuestion(id)
  }

  removeQuestion = (questionId, businessId) => {
    this.props.questionActions
      .removeQuestion({ questionId, businessId })
      .then(() => {
        if (this.props.business.error === '') {
          let { questions } = this.state.business
          questions = questions.filter(
            (question) => question._id !== questionId
          )
          this.setState((state) => ({
            business: { ...state.business, questions: [...questions] },
          }))
        }
      })
  }

  openQuestionPopup = (question) => {
    this.setState({
      question,
      openQuestion: true,
    })
  }

  closeQuestionPopup = () => {
    this.setState({
      openQuestion: false,
    })
  }

  closeReviewPopup = () => {
    this.setState({
      openReview: false,
    })
  }

  hideMessage = () => {
    this.setState({
      notification: false,
      message: '',
    })
  }

  clearAlert = (alertId) => {
    this.props.alertActions.removeAlert(alertId).then(() => {
      if (this.props.alert.error === '') {
        this.setState({ alert: {} })
      }
    })
  }

  approveClaim = (user, id) => {
    this.props.history.replace({
      ...this.props.history.location,
      state: null,
    })

    let payload = {
      email: '',
    }
    if (user)
      payload = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        companyTitle: user.company_title,
        userPhone: user.phone_number,
      }
    if (user.reject === true) {
      payload = { ...payload, reject: user.reject }
    }
    this.props.businessActions.approveClaim(id, { ...payload }).then(() => {
      if (this.props.business.error === '') {
        this.setState({
          // notification: true,
          // message: (payload.reject === true) ? 'Request was rejected successfully' : 'Request was approved successfully',
          // success: true,
          showApproveButton: false,
          showRejectButton: false,
          clearForm: payload.reject === true ? true : false,
        })
      } else {
        this.setState({
          notification: true,
          message: this.props.business.error,
          success: false,
          showApproveButton: true,
          showRejectButton: true,
          clearForm: payload.email.trim() === '',
        })
      }
    })
  }

  updateProgram = (business) => {
    business.reviews = [...this.state.business.reviews]
    business.questions = [...this.state.business.questions]
    this.setState((state) => ({ business: { ...state.business, ...business } }))
  }

  deleteProgram = (id) => {
    this.props.businessActions.deleteBusiness(id).then(() => {
      this.props.history.goBack()
    })
  }

  toggleUser = () => {
    this.setState((state) => ({ showUser: !state.showUser }))
  }

  // onReorder = (event, previousIndex, nextIndex) => {
  //   let {gallery_images, id} = this.state.business;
  //   let list = reorder(gallery_images, previousIndex, nextIndex);
  //   this.props.businessActions.reorderImages(id, list);
  //   this.setState(state=>({business: {...state.business, gallery_images: [...list]}}));
  // };
  //
  viewEdits = () => {
    this.setState({ viewEdits: true })
  }

  closeEdits = () => {
    this.setState({ viewEdits: false })
  }
  setTitleForEdit = (title) => {
    this.setState({ title })
  }
  render() {
    const {
      open,
      url,
      business,
      title,
      review,
      openReview,
      alert,
      openQuestion,
      question,
      viewEdits,
      isMobile,
      notification,
      message,
      success,
      showUser,
      showApproveButton,
      showRejectButton,
      clearForm,
    } = this.state
    const { classes, auth, matches, history } = this.props
    // console.log("alertalert",alert)
    let filteredAlert = this.props.alert.filteredAlert
    let alertNew = Object.keys(alert).length > 0 ? alert : filteredAlert

    if (!business) {
      return null
    }

    return (
      <div className="main-div">
        <div
          className="header-wrap d-flex header-display"
          style={{ background: 'transparent', paddingTop: '15px' }}
        >
          <HeaderMenu matches={matches} svgSize="25" paddingReduce={true} />
        </div>
        <div className={'newlist-title'}>{title}</div>
        {business.updatedAt ? (
          <div className={'newsublist-title'}>
            Last Edited:{' '}
            {moment.utc(business.updatedAt).local().format('MM/DD/YYYY')}
          </div>
        ) : (
          ''
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={notification}
          // autoHideDuration={4000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: { root: success ? classes.success : classes.error },
          }}
          message={<span id="message-id">{message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.hideMessage}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <EditsPopup
          open={viewEdits}
          alert={alertNew}
          matches={matches}
          onClose={this.closeEdits}
        />
        <ReviewPopup
          review={review}
          open={openReview}
          onClose={this.closeReviewPopup}
          auth={auth}
          matches={matches}
        />
        <QuestionPopup
          question={question}
          open={openQuestion}
          onClose={this.closeQuestionPopup}
          auth={auth}
          matches={matches}
        />
        <ImagePopup
          open={open}
          url={url}
          onClose={this.closeImagePopup}
          matches={matches}
        />
        {/*<span>Logged in as {auth.accessType}</span>*/}
        {/*{ business.email !== '' &&*/}
        {/*<Button onClick={this.sendPasswordSetupEmail} classes={{root: classes.sendBtn}}>*/}
        {/*Send Password Setup Email*/}
        {/*</Button>*/}
        {/*}*/}
        <div className="alert-div">
          {/*{alert._id && auth.accessType !== "Read Only" && <div className="sub-alert-div">*/}
          {/*      <span style={{color: '#f71700', fontWeight: 700}}>*/}
          {/*        *Alert* New {alert.alert_type.match('Edits') ? alert.alert_type.split(" ")[0] : alert.alert_type.split(" ")[1]}*/}
          {/*        {Boolean(alert.alert_type.match('Claimed')) ? " Claimed" : null}*/}
          {/*      </span>*/}
          {/*  <Button onClick={() => this.clearAlert(alert._id)} classes={{root: classes.clearBtn}}>Clear Alert</Button>*/}
          {/*</div>*/}
          {/*}*/}
          {/*{*/}
          {/*  Boolean((alertNew._id && alertNew.alert_type.match('Claimed'))) && showApproveButton && auth.accessType !== "Read Only" &&*/}
          {/*  <div>*/}
          {/*    <div onClick={this.toggleUser} className="claim-user">*/}
          {/*      <div>{alertNew.user.firstName.concat(" " + alertNew.user.lastName)} has claimed this business and*/}
          {/*        requests access*/}
          {/*      </div>*/}
          {/*      <div>{showUser ? <ExpandLess/> : <ExpandMore/>}</div>*/}
          {/*    </div>*/}
          {/*    <Collapse in={showUser}>*/}
          {/*      <Paper classes={{root: classes.user}}>*/}
          {/*        <Table>*/}
          {/*          <TableBody>*/}
          {/*            <TableRow>*/}
          {/*              <TableCell>*/}
          {/*                Name*/}
          {/*              </TableCell>*/}
          {/*              <TableCell>*/}
          {/*                {alertNew.user.firstName.concat(" " + alertNew.user.lastName)}*/}
          {/*              </TableCell>*/}
          {/*            </TableRow>*/}
          {/*            <TableRow>*/}
          {/*              <TableCell>*/}
          {/*                Email*/}
          {/*              </TableCell>*/}
          {/*              <TableCell>*/}
          {/*                {alertNew.user.email}*/}
          {/*              </TableCell>*/}
          {/*            </TableRow>*/}
          {/*            <TableRow>*/}
          {/*              <TableCell>*/}
          {/*                Company Title*/}
          {/*              </TableCell>*/}
          {/*              <TableCell>*/}
          {/*                {alertNew.user.company_title}*/}
          {/*              </TableCell>*/}
          {/*            </TableRow>*/}
          {/*            <TableRow>*/}
          {/*              <TableCell>*/}
          {/*                Phone Number*/}
          {/*              </TableCell>*/}
          {/*              <TableCell>*/}
          {/*                {alertNew.user.phone_number}*/}
          {/*              </TableCell>*/}
          {/*            </TableRow>*/}
          {/*            {alertNew.user.notes !== "" && <TableRow>*/}
          {/*              <TableCell>*/}
          {/*                Notes*/}
          {/*              </TableCell>*/}
          {/*              <TableCell>*/}
          {/*                {alertNew.user.notes}*/}
          {/*              </TableCell>*/}
          {/*            </TableRow>}*/}
          {/*          </TableBody>*/}
          {/*        </Table>*/}
          {/*      </Paper>*/}
          {/*    </Collapse>*/}
          {/*    <div style={{display: 'flex', width: '100%'}}>*/}
          {/*      {*/}
          {/*        showApproveButton ?*/}
          {/*          <Button classes={{root: classes.approve}}*/}
          {/*                  onClick={() => this.approveClaim(alertNew.user, alertNew.business.id)}>*/}
          {/*            Approve*/}
          {/*          </Button> : null*/}
          {/*      }*/}
          {/*      {*/}
          {/*        showRejectButton ?*/}
          {/*          <Button classes={{root: classes.reject}} onClick={() => this.approveClaim({*/}
          {/*            ...alertNew.user,*/}
          {/*            'reject': true*/}
          {/*          }, alertNew.business.id)}>*/}
          {/*            Reject*/}
          {/*          </Button> : null*/}
          {/*      }*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*}*/}
          {/*<div>*/}
          {/*  {business && business.approval_status === "Needs Approval" &&*/}
          {/*  <span>Program Status - <span style={{color: '#f71700', fontWeight: 700}}>New | Draft</span></span>}*/}
          {/*  {business && business.approval_status === "Draft" &&*/}
          {/*  <span>Program Status - <span style={{color: '#f71700', fontWeight: 700}}>Draft</span></span>}*/}
          {/*  {business && business.approval_status === "Published" &&*/}
          {/*  <span>Program Status - <span style={{color: '#2f973e', fontWeight: 700}}>Published</span></span>}*/}
          {/*  {business && business.approval_status === "Unpublished" &&*/}
          {/*  <span>Program Status - <span style={{color: '#cc7a00', fontWeight: 700}}>Unpublished</span></span>}*/}
          {/*</div>*/}
        </div>

        {/*{auth.accessType !== "Read Only" && (business && business.approval_status==="Published"*/}
        {/*  ?<Button onClick={()=>this.handleProgramStatus(business.id, business.email, business.program_name, "Draft")} classes={{root: classes.unpublish}}>Unpublish</Button>*/}
        {/*  :<Button onClick={()=>this.handleProgramStatus(business.id, business.email,business.program_name,"Published")} classes={{root: classes.publish}}>Publish</Button>)}*/}

        {/*{
        Boolean((alertNew._id && alertNew.alert_type.match('Claimed'))) &&
         <ListingManagerProfile
         user={alertNew.user}
         matches={matches}
         />
        }*/}

        {Boolean(alert._id && alert.alert_type.match('Edits')) &&
          auth.accessType !== 'Read Only' && (
            <Paper>
              Suggested Edits
              <div className="edits">
                <div className="edit-text">{alert && alert.name}</div>
                <div className="edit-text">{alert && alert.edit_email}</div>
                <div className="edit-text">{alert.edits}</div>
                <div>
                  <Button
                    onClick={() => this.clearAlert(alert._id)}
                    style={{ color: 'blue' }}
                    classes={{ root: classes.imageActions }}
                  >
                    Remove
                  </Button>
                  {/* <Button
                  style={{color: 'blue'}}
                  classes={{root: classes.imageActions}}
                  onClick={this.viewEdits}
                >
                  View
                </Button> */}
                </div>
              </div>
            </Paper>
          )}

        <ProgramInfo
          business={business}
          alert={alert}
          updateProgram={this.updateProgram}
          deleteProgram={this.deleteProgram}
          matches={matches}
          clearFormFields={clearForm}
          isMobile={isMobile}
          setTitleForEdit={this.setTitleForEdit}
        />
      </div>
    )
  }
}

const mapState = (state) => ({
  alert: state.alert,
  business: state.business,
  auth: state.auth,
})

const mapDispatch = (dispatch) => ({
  alertActions: bindActionCreators(alertActions, dispatch),
  businessActions: bindActionCreators(businessActions, dispatch),
  reviewActions: bindActionCreators(reviewActions, dispatch),
  questionActions: bindActionCreators(questionActions, dispatch),
})

export default connect(mapState, mapDispatch)(withStyles(styles)(DetailsWindow))
