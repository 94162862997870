import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import StarRating from 'react-star-ratings';
import {
  Modal,
  withStyles,
  InputBase,
  Button as MUIButton,
  Collapse,
  IconButton, Grid
} from '@material-ui/core';
import {Close, Share} from '@material-ui/icons'
import StarRatings from 'react-star-ratings';
import copy from 'copy-to-clipboard';
import * as reviewActions from '../../actions/review.action';
import {BaseUrl} from '../../utils/Const'
import './Review.css';

const Input = withStyles({
  root: {
    border: '1px solid #d2d2d2',
    boxShadow: 'inset 0 0 1px #000',
    padding: '0 1%',
  }
})(InputBase);

const Button = withStyles({
  root: {
    background: "#245497",
    color: '#fff',
    width: 'fit-content',
    padding: '1%',
    marginTop: '1%',
    '&:hover': {
      background: "#224684",
    }
  }
})(MUIButton);

const styles = () => ({
  reviewText: {
    minHeight: 69,
    maxHeight: 150,
    overflowY: 'scroll',
    alignItems: "flex-start",
    padding: '1%'
  },
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '0% 6% 6% 6%',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    padding: '6%',
    marginLeft: '420px',
  },
});

class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      review_subject: '',
      review_text: '',
      rating: 0,
      business: null,
      addReview: true,
      valid: true,
      blankError: {email: false, review_subject: false, review_text: false, rating: false},
      program_name:''
    };
  }

  componentDidMount(){
    let {resource} = this.props;
    if(this.props.auth.token){
      let {user} = this.props.auth;
      let isUser = Object.keys(user).length;
      this.setState({
        email: this.props.auth.email ? this.props.auth.email : "",
        name: isUser ?  ((user.firstName ?user.firstName : '')+" "+ (user.lastName ?user.lastName : '' ) ): "",
        business: resource.id,
        program_name: resource.program_name
      })
    }
    else{
      this.setState({
        business: resource.id,
        program_name: resource.program_name
      })
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(newProps.rating!==-1 && this.props.rating!==newProps.rating){
      this.setState({rating: newProps.rating, business: newProps.resource.id, program_name: newProps.resource.program_name})
    }
    else {
      this.setState({business: newProps.resource.id, program_name: newProps.resource.program_name})
    }
  }

  checkValidMail = () => {
    let {email, valid} = this.state;
    if(email!=="")  {
      valid = Boolean(email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
      this.setState({valid});
    }
    else {
      this.handleBlankError("email");
    }
  };

  handleBlankError = (name) => {
    this.setState(state=>({
      blankError: {...state.blankError, [name]: true}
    }))
  };

  submitReview = async () => {
    await this.checkValidMail();
    let {valid, review_subject, review_text, rating} = this.state;
    let {reviewActions, showMessage} = this.props;
    if(review_text===""){
      await this.handleBlankError("review_text");
    }
    if (review_subject==="") {
      await this.handleBlankError("review_subject");
    }
    if (rating===0) {
      await this.handleBlankError("rating");
    }
    const {blankError} = this.state;
    if(valid && !blankError.email && !blankError.review_text && !blankError.review_subject && !blankError.rating){
      reviewActions.addReview({...this.state, email:this.state.email.toLowerCase()}).then(()=>{
        if(this.props.business.error===""){
          this.setState({
            name: '',
            email: '',
            review_subject: '',
            review_text: '',
            rating: 0,
            blankError: {email: false, review_subject: false, review_text: false, rating: false}
          });
          // showMessage("Review submitted successfully", true);
          setTimeout(() => {
            this.handleClose();
          }, 500)
        }
        else {
          showMessage("Something went wrong", false);
        }
      });
    }

  };

  changeRating = (rating) => {
    this.setState({rating, blankError: {...this.state.blankError, rating: rating === 0 ? true : false}});
  };

  handleChange = (e) => {
    let {name, value} = e.target;
    this.setState(state=>{
      if(name==="email"){
        return ({email: value, blankError: {...state.blankError, email: false}, valid: true});
      }
      else if(name==="name") {
        return ({[name]: value});
      }
      else {
        return ({[name]: value, blankError: {...state.blankError, [name]: false}});
      }
    });
  };

  toggleReviews = () => {
    this.setState(state=>({addReview: !state.addReview}))
  };

  handleClose = () => {
    this.setState({
      name: '',
      email: '',
      review_subject: '',
      review_text: '',
      rating: 0,
      business: null,
      addReview: true,
      valid: true,
      blankError: {email: false, review_subject: false, review_text: false}
    }, ()=>{
      this.props.hideMessage();
      setTimeout(() => {
        this.props.onClose();
      }, 200)
    })
  };

  copyShareableLink = (text,resource) => {
    let URL = text + "/" + resource.category.category[0].toLowerCase() + "/" + resource.id + "/review"
    copy(URL)
    // this.props.showMessage("Link Copied !",
    //   true
    // );
  }

  render(){
    const {open, classes, resource, matches} = this.props;
    const {
      review_subject,
      review_text,
      rating, email,
      name, addReview,
      valid, blankError
    } = this.state;
    return (
        <Modal
            open={open}
            classes={{ root: matches ? classes.mobileModal : classes.modal + " modal-main-div"  }}
        >
        <div className="main-review-container review-box">
          <div className="titlebar">
            <h2>Add a Review</h2>
            {/*<IconButton onClick={()=>this.copyShareableLink(BaseUrl,resource)}><Share/></IconButton>*/}
          </div>
          <div className={'close-box'}>
            <IconButton onClick={this.handleClose}><Close/></IconButton>
          </div>
          <div className="review-container">
            <Collapse in={addReview}>
              <div className="field-container">
                <label>Rate</label>
                <StarRatings
                    rating={rating}
                    starDimension="30px"
                    starSpacing="6px"
                    starRatedColor="orange"
                    changeRating={this.changeRating}
                />
                {(blankError.rating) && <p className="failed">Please enter Rating.</p>}
              </div>
              <div className="field-container">
                <label>Your Name</label>
                <Input type="text" onChange={this.handleChange} name="name" value={name}/>
              </div>
              <div className="field-container">
                <label>Your Email Address</label>
                <Input
                  onBlur={this.checkValidMail}
                  type="email"
                  onChange={this.handleChange}
                  name="email"
                  value={email}
                />
                {!valid && <p className="failed">The email address you entered is not valid.</p>}
                {blankError.email && <p className="failed">Please enter your email address.</p>}
              </div>
              <div className="field-container">
                <label>Subject</label>
                <Input type="text" onChange={this.handleChange} name="review_subject" value={review_subject}/>
                {blankError.review_subject && <p className="failed">Please enter the subject for your review.</p>}
              </div>
              <div className="field-container">
                <label>Review</label>
                <Input classes={{root: classes.reviewText}} type="text" onChange={this.handleChange} name="review_text" value={review_text} multiline/>
                {blankError.review_text && <p className="failed">Your review cannot be blank.</p>}
              </div>
              <div className={"btn-submit"}>
                <Button onClick={this.submitReview}>Submit</Button>
              </div>

            </Collapse>
            {/*<div className="field-container">*/}
            {/*  <div*/}
            {/*    className="review-label"*/}
            {/*    onClick={this.toggleReviews}*/}
            {/*  >*/}
            {/*    Reviews {addReview?<ExpandMore/>:<ExpandLess/>}*/}
            {/*  </div>*/}
            {/*  <Collapse in={!addReview}>*/}
            {/*    <div style={{width: '100%'}}>*/}
            {/*      {resource.reviews&&resource.reviews.length>0?resource.reviews.map((review, i)=>(*/}
            {/*        <div key={i} className="user-review">*/}
            {/*          <h3>{review.review_subject}</h3>*/}
            {/*          <StarRating*/}
            {/*            rating={review.rating}*/}
            {/*            starDimension="30px"*/}
            {/*            starSpacing="6px"*/}
            {/*            starRatedColor="orange"*/}
            {/*            disabled={true}*/}
            {/*          />*/}
            {/*          <p>{review.review_text}</p>*/}
            {/*          <p className="by-line">Reviewed by {review.name===""?review.email:review.name}</p>*/}
            {/*          {review.reply && review.reply.reply!==""*/}
            {/*            ?<div className="reply">*/}
            {/*              {review.reply.reply}*/}
            {/*              <p className="by-line">Replied by {review.reply.user.email}</p>*/}
            {/*            </div>*/}
            {/*            :null}*/}
            {/*        </div>*/}
            {/*      )) :<div>*/}
            {/*        <p align="center">Nothing to show. Be the first to review.</p>*/}
            {/*      </div>}*/}
            {/*    </div>*/}
            {/*  </Collapse>*/}
            {/*</div>*/}
          </div>
        </div>
      </Modal>
    )
  }
}

const mapState = (state) => ({
  business: state.business,
  auth: state.auth
});

const mapDispatch = (dispatch) => ({
  reviewActions: bindActionCreators(reviewActions, dispatch)
});

export default connect(mapState, mapDispatch)(withStyles(styles)(Review));