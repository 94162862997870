import React, { Component } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Button,
  withStyles,
  IconButton,
  Paper as MUIPaper,
  Snackbar as MUISnackbarContent,
  TableHead,
  TableFooter,
  Select,
  Input,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Checkbox,
  ListItemText,
  CircularProgress,
  DialogContent,
  DialogContentText,
  DialogActions, Dialog,
} from '@material-ui/core';
import moment from "moment"
import { CancelRounded, Delete, FileCopy, Visibility } from '@material-ui/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-quill/dist/quill.snow.css';
import * as businessActions from '../../../actions/business.action';
import * as alertActions from '../../../actions/alert.action';
import './MainWindow.css';
import './Alets.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import container_background from "../../../assets/images/container_background.png";
import HeaderMenu from "../../Menu/Menu";
import BusinessProfile from "./BusinessProfile/BusinessProfile";
import { faEye, faTrashAlt, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import ImagePopup from "../ImagePopup/ImagePopup";
import ReviewPopup from "../ReviewPopup/ReviewPopup";
import QuestionPopup from "../QuestionPopup/QuestionPopup";

const styles = () => ({
  linkButton: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 700,
    color: 'blue',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline'
    }
  },
  successNotif: {
    backgroundColor: '#43a047',
  },
  errorNotif: {
    backgroundColor: '#d32f2f',
  },
});

const Paper = withStyles({
  root: {
    padding: '1%',
    width: '100%',
    marginTop: '2%',
    borderRadius: 0
  }
})(MUIPaper);

const Snackbar = withStyles({
  root: {
    maxWidth: "700px"
  }
})(MUISnackbarContent);

const TableHeadCell = withStyles({
  root: {
    fontSize: 14,
    color: "#3c3c3c",
    fontWeight: 800
  }
})(TableCell);

class Alerts extends Component {

  state = {
    businesses: [],
    pageNum: 1,
    order: 1,
    sortBy: 'alert_type',
    search: "",
    alerts: [],
    notification: ``,
    open: false,
    success: false,
    entries: 10,
    count: 0,
    searching: false,
    isMobile: false,
    showBusinessProfile: false,
    selectedId: '',
    openDialog: false,
    deleteUserId: -1,
    openpop: false,
    selectedAlert: {},
    openReview: false,
    review: {},
    openQuestion: false,
    question: {},
    showDeleteAllAlert:false

  };

  componentDidMount() {
    const { entries, pageNum, order, sortBy } = this.state;
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function () {
        return Boolean(isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };
    this.setState({ isMobile: isMobile.any() }, () => {
      if (this.props.auth.token !== "") {
        this.fetchAlertData();
      }
      else {
        this.props.history.push('/');
      }
    });
  }

  fetchAlertData = () => {
    const { entries, pageNum, order, sortBy } = this.state;
    const { auth } = this.props;
    if (auth.accessType === "Admin" || auth.accessType === "Super Admin") {
      this.props.getAlerts(entries, pageNum, order, sortBy).then(alerts => {
        if (alerts)
          this.setState({ alerts: alerts.alerts, count: alerts.count });
      });
    }
    else {
      this.props.getAlerts(entries, pageNum, order, sortBy, this.props.auth.email).then(alerts => {
        if (alerts)
          this.setState({ alerts: alerts.alerts, count: alerts.count });
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function () {
        return Boolean(isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };
    if (this.state.isMobile !== isMobile.any()) {
      this.setState({
        isMobile: isMobile.any()
      })
    }
    if (this.props.alert && this.props.alert.alerts !== nextProps.alert.alerts) {
      if (nextProps.alert.alerts)
        this.setState({ alerts: nextProps.alert.alerts.alerts, count: nextProps.alert.alerts.count });
    }
  }

  onChangeEntries = (e) => {
    let { entries, pageNum, count, order, sortBy } = this.state;
    let pages = Math.ceil(count / e.target.value);
    if (pageNum > pages) {
      pageNum = pages;
    }
    if (entries !== e.target.value) {
      this.setState({
        entries: e.target.value,
        pageNum
      }, () => {
        if (this.props.auth.token !== "") {
          if (this.props.auth.accessType === "Admin" || this.props.auth.accessType === "Super Admin") {
            this.props.getAlerts(e.target.value, pageNum, order, sortBy).then(alerts => {
              if (alerts)
                this.setState({ alerts: alerts.alerts, count: alerts.count });
            });
          }
          else {
            this.props.getAlerts(e.target.value, pageNum, order, sortBy, this.props.auth.email).then(alerts => {
              if (alerts)
                this.setState({ alerts: alerts.alerts, count: alerts.count });
            });
          }
        }
      })
    }
  };

  viewUserDetails = (id,alert) => {
    this.setState({ showBusinessProfile: true, selectedId: id })
    this.props.alertActions.removeAlert(alert._id)
    // this.props.history.push(`/my-listing/user-details/${id}`);
  };

  viewDetails = (id, alert) => {
    const { pageNum, order, sortBy, search } = this.state;
    if (alert) {
      this.props.history.push({ pathname: `/my-listing/details/${id}`, state: { alert } });
    }
    else {
      // this.props.businessActions.setPaginatedData(pageNum, order, sortBy, search);
      this.props.history.push(`/my-listing/details/${id}`);
    }
  };

  handleClose = () => {
    this.setState({ open: false, notification: '' });
  };

  clearAlert = (alert) => {
    this.closeDialog();
    this.props.alertActions.removeAlert(alert._id).then(() => {
      this.fetchAlertData();
    });
  };
  deleteAllAlert = () =>{
    let {user} = this.props;
    this.closeDialog();
    // console.log("useruser",)
    this.props.alertActions.deleteAllAlert(user.user._id,user.user.email).then(() => {
      // this.fetchAlertData();
    });
  }

  sortPrograms = (orderArg, sortByArg) => {
    const { order, sortBy, entries, pageNum, count } = this.state;
    if (order !== orderArg || sortBy !== sortByArg) {
      this.setState({
        order: orderArg,
        sortBy: sortByArg
      }, () => {
        if (this.props.auth.accessType === "Admin" || this.props.auth.accessType === "Super Admin") {
          this.props.getAlerts(entries, pageNum, orderArg, sortByArg).then(alerts => {
            if (alerts)
              this.setState({ alerts: alerts.alerts, count: alerts.count });
          });
        }
        else {
          this.props.getAlerts(entries, pageNum, orderArg, sortByArg, this.props.auth.email).then(alerts => {
            if (alerts)
              this.setState({ alerts: alerts.alerts, count: alerts.count });
          });
        }

        this.props.getAlerts(count, 1, orderArg, sortByArg).then(alerts => {
          this.setState({ alerts: alerts.alerts });
        });
      })
    }
  };

  changePage = (page) => {
    let { entries, pageNum, order, sortBy } = this.state;
    if (pageNum !== page) {
      this.setState({
        pageNum: page
      }, () => {
        if (this.props.auth.accessType === "Admin" || this.props.auth.accessType === "Super Admin") {
          this.props.getAlerts(entries, page, order, sortBy).then(alerts => {
            if (alerts)
              this.setState({ alerts: alerts.alerts, count: alerts.count });
          });
        }
        else {
          this.props.getAlerts(entries, page, order, sortBy, this.props.auth.email).then(alerts => {
            if (alerts)
              this.setState({ alerts: alerts.alerts, count: alerts.count });
          });
        }
      })
    }
  };

  renderPagination = (pageNum, pages) => {
    const { isMobile } = this.state;
    if (pages.length <= (isMobile ? 3 : 6)) {
      return (
        pages.map((a, i) =>
          <div style={{ backgroundColor: pageNum === (i + 1) ? "#ececec" : null }} onClick={() => this.changePage(i + 1)} className="page-btn" key={i}>{i + 1}</div>))
    }
    else {
      let pagination = '';
      if (isMobile) {
        if (pageNum < 2) {
          pagination = (<div>
            {pages.map((a, i) =>
              i < 2
                ? <div style={{ backgroundColor: pageNum === (i + 1) ? "#ececec" : null }} onClick={() => this.changePage(i + 1)} className="page-btn" key={i}>{i + 1}</div>
                : null
            )}
            <div className="page-btn">...</div>
            <div style={{ backgroundColor: pageNum === (pages.length) ? "#ececec" : null }}
              onClick={() => this.changePage(pages.length)} className="page-btn">{pages.length}</div>
          </div>)
        } else if (pageNum >= 2 && pageNum < pages.length - 1) {
          pagination = (
            <div>
              <div style={{ backgroundColor: pageNum === (1) ? "#ececec" : null }} onClick={() => this.changePage(1)}
                className="page-btn">{1}</div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i > pageNum - 2 && i < pageNum + 1 && i < pages.length - 1
                  ? <div style={{ backgroundColor: pageNum === (i + 1) ? "#ececec" : null }}
                    onClick={() => this.changePage(i + 1)} className="page-btn" key={i}>{i + 1}</div>
                  : null
              )}
              <div className="page-btn">...</div>
              <div style={{ backgroundColor: pageNum === (pages.length) ? "#ececec" : null }}
                onClick={() => this.changePage(pages.length)} className="page-btn">{pages.length}</div>
            </div>
          )
        } else if (pageNum >= pages.length - 2) {
          pagination = (
            <div>
              <div style={{ backgroundColor: pageNum === (1) ? "#ececec" : null }} onClick={() => this.changePage(1)}
                className="page-btn">{1}</div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i >= pages.length - 2
                  ? <div style={{ backgroundColor: pageNum === (i + 1) ? "#ececec" : null }}
                    onClick={() => this.changePage(i + 1)} className="page-btn" key={i}>{i + 1}</div>
                  : null
              )}
            </div>
          )
        }
      } else {
        if (pageNum < 5) {
          pagination = (<div>
            {pages.map((a, i) =>
              i < 5
                ? <div style={{ backgroundColor: pageNum === (i + 1) ? "#ececec" : null }}
                  onClick={() => this.changePage(i + 1)} className="page-btn" key={i}>{i + 1}</div>
                : null
            )}
            <div className="page-btn">...</div>
            <div style={{ backgroundColor: pageNum === (pages.length) ? "#ececec" : null }}
              onClick={() => this.changePage(pages.length)} className="page-btn">{pages.length}</div>
          </div>)
        } else if (pageNum >= 5 && pageNum < pages.length - 4) {
          pagination = (
            <div>
              <div style={{ backgroundColor: pageNum === (1) ? "#ececec" : null }} onClick={() => this.changePage(1)}
                className="page-btn">{1}</div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i > pageNum - 2 && i < pageNum + 3 && i < pages.length - 1
                  ? <div style={{ backgroundColor: pageNum === (i + 1) ? "#ececec" : null }}
                    onClick={() => this.changePage(i + 1)} className="page-btn" key={i}>{i + 1}</div>
                  : null
              )}
              <div className="page-btn">...</div>
              <div style={{ backgroundColor: pageNum === (pages.length) ? "#ececec" : null }}
                onClick={() => this.changePage(pages.length)} className="page-btn">{pages.length}</div>
            </div>
          )
        } else if (pageNum >= pages.length - 5) {
          pagination = (
            <div>
              <div style={{ backgroundColor: pageNum === (1) ? "#ececec" : null }} onClick={() => this.changePage(1)}
                className="page-btn">{1}</div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i >= pages.length - 5
                  ? <div style={{ backgroundColor: pageNum === (i + 1) ? "#ececec" : null }}
                    onClick={() => this.changePage(i + 1)} className="page-btn" key={i}>{i + 1}</div>
                  : null
              )}
            </div>
          )
        }
      }
      return (pagination)
    }
  };

  closeBusinessProfile = () => {
    this.setState({ showBusinessProfile: false })
  }

  closeDialog = () => {
    this.setState({ openDialog: false,showDeleteAllAlert:false, deleteUserId: -1 });
  };


  /**
   * IMAGE POP UP ALERT**/
  openImagePopup = (alert) => {
    this.setState({ openpop: true, url: alert['image']['url'], selectedAlert: alert })
  };
  closeImagePopup = () => {
    this.setState({ openpop: false })
  };
  /**
   * IMAGE POP UP ALERT**/
  onhandleViewAction = (alert) => {
    switch (alert.alert_type) {
      case "New Review Submitted":
        this.openReviewPopup(alert)
        break;
      case "New Photo Submitted":
        console.log("image alert openImagePopup", alert)
        this.openImagePopup(alert)
        break;
      // return <div className='notification-item' onClick={()=>this.openImagePopup(alert)} >
      //   {alert.alert_type} for {alert.business.program_name}<br />
      //   {/* {this.renderSeeMoreHere(alert)} */}
      // </div>
      case "New Question Submitted":
        this.openQuestionPopup(alert)
        break;
      default:
        if (alert.business) {
          this.viewDetails(alert.business && alert.business.id, alert)
          break;
        } else if (alert && alert.users && alert.users._id) {
          this.viewUserDetails(alert.users._id,alert)
          break;
        } else {
          return null
        }
    }

  }

   /**
   * REVIEW POP UP ALERT**/
    openReviewPopup = (alert) => {
      console.log("review", alert)
      this.setState({
        review: alert['review'], openReview: true
      })
    };
  
    closeReviewPopup = () => {
      this.setState({
        openReview: false
      })
    };
/**
   * QUESTION POP UP ALERT**/
 openQuestionPopup = (alert) => {
  this.setState({
    question: alert['question'], openQuestion: true
  })
};

closeQuestionPopup = () => {
  this.setState({
    openQuestion: false
  })
};

showDeleteAlert = () => {
  this.setState({
    showDeleteAllAlert: true
  })
}
  render() {
    const { classes, matches, alert } = this.props;
    const { entries, count, pageNum, searching, notification, open, success, alerts, search, order,
      sortBy, isMobile, selectedId, showBusinessProfile, openDialog, deleteUserId,showDeleteAllAlert } = this.state;
    let pages = count && new Array(Math.ceil(count / entries)).fill('a') || new Array(Math.ceil(0 / entries)).fill('a');
    if (alert.loadingAlert) {
      return <div className="loader"><CircularProgress className={classes.progress} style={{ color: '#FFFFFF' }} /></div>
    }
    return (
      <div className="alert-admin-main-container">
        <BusinessProfile openModal={showBusinessProfile} userId={selectedId} onCloseModal={this.closeBusinessProfile} />
        <div className={classes.root}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: { root: success ? classes.successNotif : classes.errorNotif },
              style: { maxWidth: '631px' }
            }}
            message={<span>{Array.isArray(notification) ? notification.map((x, key) => <span
              key={key}>{x}</span>) : notification}
            </span>}
            action={
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CancelRounded />
              </IconButton>}
          />
        </div>
        <div className="alert-admin-main">
          <div className="header-wrap d-flex header-display" style={{ background: 'transparent' }}>
            <HeaderMenu matches={matches} svgSize="25" paddingReduce={true} />
          </div>
          <Dialog
            open={showDeleteAllAlert}
            onClose={this.closeDialog}
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete all the alerts?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={this.closeDialog}
                color="primary">
                No
                </Button>
              <Button onClick={() => this.deleteAllAlert()} color="primary">
                Yes
                </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDialog}
            onClose={this.closeDialog}
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this alert?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={this.closeDialog}
                color="primary">
                No
                </Button>
              <Button onClick={() => this.clearAlert(deleteUserId)} color="primary">
                Yes
                </Button>
            </DialogActions>
          </Dialog>
          <div className={'my-account-text'}>MY ACCOUNT</div>
          <Paper style={{
            padding: 0, marginTop: '11px',
            borderRadius: '5px'
          }}>
            <div className={'data-title'}>
              <h4 className={'program-entries-header'}>Manage Alerts</h4>
              
              <div className='delete-all-alerts delete-icon-div' onClick={this.showDeleteAlert}>
              Clear alerts&nbsp;&nbsp; <FontAwesomeIcon icon={faTrashAlt} />
              </div>
              <div className="table-header">
                <div className="export-wrap">
                  <label className={'show-page-label'}>Show per Page<Select disabled={searching} onChange={this.onChangeEntries} value={entries}
                    input={<Input type="number" style={{ fontSize: '14px' }} />}>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                  </label>
                </div>
              </div>
            </div>
            <div className={'data-table'}>
              {alerts && alerts.length > 0 ? <Table className={"table-responsive"} style={{ fontFamily: 'Roboto' }}>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>
                      <div className="header-sort">
                        Status
                        </div>
                    </TableHeadCell>
                    <TableHeadCell className={'m-view-hide'}>
                      <div className="header-sort">
                        Name of Organization
                          <div className="sort-icon-group">
                          <FontAwesomeIcon
                            className={order === 1 && sortBy === "business_name" ? "active-sort-icon" : "sort-icon"}
                            icon={"long-arrow-alt-up"}
                            onClick={() => this.sortPrograms(1, 'program_name')} />
                          <FontAwesomeIcon
                            className={order === -1 && sortBy === "business_name" ? "active-sort-icon" : "sort-icon"}
                            icon={"long-arrow-alt-down"}
                            onClick={() => this.sortPrograms(-1, 'program_name')} />
                        </div>
                      </div>
                    </TableHeadCell>
                    <TableHeadCell>
                      <div className="header-sort">
                        Program/Event/Service
                          <div className="sort-icon-group">
                          <FontAwesomeIcon
                            className={order === 1 && sortBy === "program_name" ? "active-sort-icon" : "sort-icon"}
                            icon={"long-arrow-alt-up"}
                            onClick={() => this.sortPrograms(1, 'approval_status')} />
                          <FontAwesomeIcon
                            className={order === -1 && sortBy === "program_name" ? "active-sort-icon" : "sort-icon"}
                            icon={"long-arrow-alt-down"}
                            onClick={() => this.sortPrograms(-1, 'approval_status')} />
                        </div>
                      </div>
                    </TableHeadCell>
                    {/* className={'mobile-view-hide'} */}
                    <TableHeadCell >
                      <div className="header-sort">
                        Email
                          <div className="sort-icon-group">
                          <FontAwesomeIcon
                            className={order === 1 && sortBy === "email" ? "active-sort-icon" : "sort-icon"}
                            icon={"long-arrow-alt-up"}
                            onClick={() => this.sortPrograms(1, 'email')} />
                          <FontAwesomeIcon
                            className={order === -1 && sortBy === "email" ? "active-sort-icon" : "sort-icon"}
                            icon={"long-arrow-alt-down"}
                            onClick={() => this.sortPrograms(-1, 'email')} />
                        </div>
                      </div>
                    </TableHeadCell>
                    <TableHeadCell className={'mobile-view-hide'}>
                      <div className="header-sort">
                        Request
                        </div>
                    </TableHeadCell>
                    <TableHeadCell>
                      <div className="header-sort">
                        Date
                        </div>
                    </TableHeadCell>
                    <TableHeadCell>
                      <div className="header-sort">
                        Action
                        </div>
                    </TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody className={'table-body'}>
                  {alerts.length > 0 && alerts.map((a, i) => (
                    <TableRow key={i}>
                      <TableCell>{a.alert_type}</TableCell>
                      <TableCell className={'program-font-color m-view-hide'}>{(a.business && a.business.business_name) || (a.users && a.users.user && a.users.user.businessName) || (a && a.name ? a.name : null)}</TableCell>
                      <TableCell className={'program-font-color'}>{a.business && a.business.program_name}</TableCell>
                      {/*{Boolean(a.alert_type.match('Program')) && <TableCell>{(a.business && a.business.email) || (a.users && a.users.user &&  a.users.user.email)}</TableCell>}*/}
                      {/* className={'mobile-view-hide'} */}
                      {<TableCell >{(a.business && a.business.email.toLowerCase()) || (a.users && a.users.user && a.users.user.email.toLowerCase()) || (a.email && a.email.toLowerCase() ? a.email.toLowerCase() : null)} </TableCell>}
                      <TableCell className={'mobile-view-hide'}>
                        {Boolean(a.alert_type.match('Photo')) && <div>{a.image && a.image.name}</div>}
                        {Boolean(a.alert_type.match('Review')) && <div>{a.review && a.review.review_subject}</div>}
                        {Boolean(a.alert_type.match('Question')) && <div>{a.question.question.substring(0, 74)}</div>}
                        {Boolean(a.alert_type.match('Claimed')) && <div>{a.users && a.users.user && a.users.user.email}</div>}
                        {a.request ? <div>{a.request}</div> : null}
                        {Boolean(a.alert_type.match('Edits')) && <div>{a.edits && a.edits.substring(0, 74)}</div>}
                        </TableCell>

                        <TableCell className={'program-font-color'}>
                        {moment(new Date(a.createdAt)).format('MM-DD-YYYY') }
                        {/* {moment(testDate).format('MM/DD/YYYY')} */}
                        </TableCell>

                      <TableCell style={{ alignItems: 'center' }}>
                        <div className={'btn-section'}>
                          <div style={{ display: 'flex' }}>
                            <Button className={isMobile ? 'btn-view mobile-view-hide' : 'btn-view'}

                              onClick={() => {
                                this.onhandleViewAction(a);
                              }}
                              classes={{ root: classes.viewBtn }}>
                              <FontAwesomeIcon icon={faEye} />
                                  View</Button>
                          </div>
                          <div
                            onClick={() => this.setState({
                              openDialog: true,
                              deleteUserId: a
                            })} className={'delete-icon-div'}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter hidden={searching}>
                  <TableRow>
                    <TableCell component="td" align="left" colSpan={isMobile ? (alerts.length + 2) : 6}>
                      <div className={"pagination-div"}>
                        <div className={"pagination-showpage"}>
                          <div className={'showing-page-div'}>
                            Showing {1 + ((pageNum - 1) * entries)} to {entries * pageNum < count ? entries * pageNum : count} of {count} entries
                            </div>
                        </div>
                        <div className={"pagination-pagenumber"}>
                          {/*<TableCell component="td" colSpan={isMobile ? 2 : 7} align="right">*/}
                          <div className="pagination">
                            {this.renderPagination(pageNum, pages)}
                            <div
                              onClick={() => this.changePage(pageNum !== 1 ? pageNum - 1 : 1)}
                              className={pageNum === 1 ? "disabled-p-btn" : "p-btn"}>
                              <FontAwesomeIcon icon={faArrowLeft} />
                            </div>
                            <div
                              onClick={() => this.changePage(pageNum !== pages.length ? pageNum + 1 : pages.length)}
                              className={pageNum === pages.length ? "disabled-n-btn" : "n-btn"}>
                              <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table> : <div className={'no-data-div'}>
                No New Alerts
                </div>}
            </div>
            <ImagePopup open={this.state.openpop} url={this.state.url} onClose={this.closeImagePopup}
              matches={this.props.matches}
              approveImage={this.props.businessActions.approveImage}
              removeImage={this.props.businessActions.removeImage}
              alert={this.state.selectedAlert} />

            <ReviewPopup review={this.state.review} open={this.state.openReview} onClose={this.closeReviewPopup} auth={this.props.auth} matches={this.props.matches} />
            <QuestionPopup question={this.state.question}
              open={this.state.openQuestion}
              onClose={this.closeQuestionPopup}
              auth={this.props.auth}
              matches={this.props.matches} />
          </Paper>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return ({
    business: state.business,
    auth: state.auth,
    user: state.user,
    alert: state.alert
  })
};

const mapDispatchToProps = (dispatch) => {
  return ({
    alertActions: bindActionCreators(alertActions, dispatch),
    businessActions: bindActionCreators(businessActions, dispatch),
    getAlerts: bindActionCreators(alertActions.getAlerts, dispatch),
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Alerts));