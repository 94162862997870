import React from 'react'
import { Dialog } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './popUp.css'

const PopUpReactYoutube = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.setOpen()
      }}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        style={{ textAlign: 'end', fontSize: '25px', color: 'white' }}
        onClick={() => {
          props.setOpen()
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div class="video-container">
        <iframe
          width="100%"
          height="100%"
          src={props.youtubeLink}
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </Dialog>
  )
}

export default PopUpReactYoutube
