import * as types from '../constants/action.constants'
import * as services from '../service/business.service'
import { getBusinesses } from '../service/business.service'

export const focusedItem = (item) => ({
  type: types.FOCUSED_ITEM,
  payload: item,
})

export const openedItem = (item) => ({
  type: types.OPEN_RESOURCE,
  payload: item,
})

export const changeView = (isMap) => ({
  type: types.CHANGE_VIEW,
  payload: isMap,
})

export const toggleMenu = (open) => ({
  type: types.MENU_TOGGLE,
  payload: open,
})

export const searchBusinesses = (search) => async (dispatch) => {
  dispatch({ type: types.LOADING_BUSINESS })
  try {
    const res = await getBusinesses(search)
    dispatch({
      type: types.SET_BUSINESSES,
      payload: res.data.data || null,
    })
    dispatch({ type: types.LOADED_BUSINESS })
  } catch (err) {
    console.log('err', err)
  }
}

export const clearSearch = () => async (dispatch, getState) => {
  try {
    const state = getState()
    dispatch({
      type: types.SET_BUSINESSES,
      payload: state.business.businesses || null,
    })
    dispatch({ type: types.LOADED_BUSINESS })
  } catch (err) {
    console.log('err', err)
  }
}

export const openItemFromModal = (id) => async (dispatch, getState) => {
  try {
    const state = getState()
    const modalItem = state.common.businesses.find((el) => el._id === id)
    dispatch({
      type: types.OPEN_RESOURCE,
      payload: modalItem,
    })
    dispatch({ type: types.LOADED_BUSINESS })
  } catch (err) {
    console.log('err', err)
  }
}
