import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  IconButton,
  MenuItem,
  withStyles,
  Popover,
  Button,
  MenuList,
  Popper,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PopUpYoutubeVideo from '../PopUpYoutubeVideo/PopUpYoutube'
import Login from 'components/Login/Login'
import PasswordReset from 'components/PasswordReset/PasswordReset'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import * as authActions from '../../actions/auth.action'
import * as businessActions from '../../actions/business.action'
import * as alertActions from '../../actions/alert.action'
import './Menu.css'
import ContactForm from '../ContactForm/ContactForm'
import BusinessProfile from '../AdminPanel/MainWindow/BusinessProfile/BusinessProfile'
import SignUp from '../SignUp/SignUp'
import ConfirmEmail from '../SignUp/ConfirmEmail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faBell,
  faListAlt,
  faUserCog,
  faEdit,
  faSignOutAlt,
  faUserFriends,
  faUserPlus,
  faBars,
  faTimes,
  faPhoneAlt,
  faUser,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons'
import ImagePopup from '../AdminPanel/ImagePopup/ImagePopup'
import ReviewPopup from '../AdminPanel/ReviewPopup/ReviewPopup'
import QuestionPopup from '../AdminPanel/QuestionPopup/QuestionPopup'
import { toggleMenu } from '../../actions/common.action'

const styles = () => ({
  menuIcon: {
    fontSize: 40,
    color: '#fff',
  },
})

const Popper1 = withStyles({
  paper: {
    position: 'absolute',
    top: 0,
    background: 'white',
    zIndex: '1111',
  },
})(Popper)

const Popover1 = withStyles({
  paper: {
    top: '60px !important',
    left: '15px !important',
    minHeight: 'auto',
    background: '#fff',
    overflow: 'unset',
    maxHeight: '270px',
  },
})(Popover)

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: undefined,
      alertanchorEl: undefined,
      open: false,
      disclaimer: {},
      showPasswordModal: false,
      openForgotPassword: false,
      openContactForm: false,
      showBusinessProfile: false,
      showSignUp: false,
      openConfirmEmail: false,
      confirmEmail: '',
      isMobile: false,
      selectedId: '',
      orgmenu: false,
      isLearnMoreChannel: false,
      isWatchMoreVideo: false,
      openpop: false,
      selectedAlert: {},
      openReview: false,
      review: {},
      openQuestion: false,
      question: {},
    }
  }

  componentDidMount() {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    this.setState({ isMobile: isMobile.any() })

    // if (this.props.auth.token !== '') {
    //   if (
    //     this.props.auth.accessType === 'Admin' ||
    //     this.props.auth.accessType === 'Super Admin'
    //   ) {
    //     this.props
    //       .getNotificationAlerts(5, 1, -1, 'alert_type')
    //       .then((alerts) => {
    //         // if(alerts)
    //         // this.setState({alerts: alerts.alerts, count: alerts.count});
    //       })
    //   } else {
    //     this.props
    //       .getNotificationAlerts(5, 1, -1, 'alert_type', this.props.auth.email)
    //       .then((alerts) => {
    //         // if(alerts)
    //         // this.setState({alerts: alerts.alerts, count: alerts.count});
    //       })
    //   }
    // }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { category } = newProps
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    if (this.state.isMobile !== isMobile.any()) {
      return {
        isMobile: isMobile.any(),
      }
    }
    if (this.state.disclaimer !== newProps.business.disclaimer) {
      this.setState({ disclaimer: newProps.business.disclaimer })
    }
  }

  toggleMenu = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleMenuAction(false)
    }
  }

  handleLogin = () => {
    // this.setState({ open: true })
    this.props.authModal('IN')
  }

  closeLogin = () => {
    this.props.authModal(null)
  }

  openForgotPassword = (open) => () => {
    this.props.authModal(open)
  }

  openConfirmEmail = (close, email = '') => {
    if (close) this.setState({ openConfirmEmail: false })
    else this.setState({ openConfirmEmail: true, confirmEmail: email })
  }

  handleLogout = () => {
    this.props.logout()
    this.props.history.push('/all')
    this.toggleMenu()
    setTimeout(() => {
      this.setState({ anchorEl: undefined })
    }, 100)
  }

  goToAdmin = () => {
    this.props.history.push('/my-listing')
    this.toggleMenu()
  }

  goToHome = () => {
    this.props.history.push('/all?cities=Flagstaff')
    this.toggleMenu()
    setTimeout(() => {
      this.setState({ anchorEl: undefined })
    }, 100)
  }

  showContactForm = () => {
    this.setState({ openContactForm: true })
  }

  hideContactForm = () => {
    this.setState({ openContactForm: false })
  }

  closeBusinessProfile = () => {
    this.setState({ showBusinessProfile: false, selectedId: '' })
  }

  showPasswordModal = () => {
    this.setState({ showPasswordModal: true })
  }

  hidePasswordModal = () => {
    this.props.authModal(null)
    this.props.resetModal('')
  }

  goToSignUp = () => {
    this.props.authModal('UP')
  }

  handleAlerts = () => {
    this.setState({ alertanchorEl: undefined })
    const { isMobile } = this.state
    if (isMobile) {
      this.setState({
        anchorEl: undefined,
      })
    }
    this.props.history.push('/alerts')
    this.toggleMenu()
  }

  handleMyListing = () => {
    const { isMobile } = this.state
    if (isMobile) {
      this.setState({
        anchorEl: undefined,
      })
    }
    this.props.history.push('/my-listing')
    this.toggleMenu()
  }

  handleLoginNSecurity = () => {
    const { isMobile } = this.state
    if (isMobile) {
      this.setState({
        anchorEl: undefined,
      })
    }
    this.props.history.push('/login-n-security')
    this.toggleMenu()
  }

  handleClose = (e) => {
    this.setState(
      {
        anchorEl: Boolean(e) ? e.target : undefined,
      },
      () => {
        this.props.history.push(`/all`)
        this.toggleMenu()
      }
    )
  }

  homeMenu = () => {
    let { history } = this.props
    return (
      <div
        className={
          history.location.pathname.includes('all')
            ? 'd-flex wrap-notification active-menu'
            : 'd-flex wrap-notification'
        }
      >
        <FontAwesomeIcon icon={faSearch} />
        <MenuItem className="wrap-font" onClick={this.goToHome}>
          Home
        </MenuItem>
      </div>
    )
  }

  manageAlertMenu = () => {
    let { history } = this.props
    return <></>
  }

  manageListingMenu = () => {
    let { history } = this.props
    return (
      <div
        className={
          history.location.pathname.includes('my-listing')
            ? 'd-flex wrap-notification active-menu'
            : 'd-flex wrap-notification'
        }
      >
        <FontAwesomeIcon icon={faListAlt} />
        <MenuItem className="wrap-font" onClick={this.handleMyListing}>
          My Listings
        </MenuItem>
      </div>
    )
  }

  loginNSecurityMenu = () => {
    let { history } = this.props
    return (
      <div
        className={
          history.location.pathname.includes('login-n-security')
            ? 'd-flex wrap-notification active-menu'
            : 'd-flex wrap-notification'
        }
      >
        <FontAwesomeIcon icon={faUserCog} />
        <MenuItem className="wrap-font" onClick={this.handleLoginNSecurity}>
          Profile
        </MenuItem>
      </div>
    )
  }

  manageSignUp = () => {
    return (
      <div className=" d-flex wrap-notification">
        <FontAwesomeIcon icon={faUserPlus} />
        <MenuItem className="wrap-font" onClick={this.goToSignUp}>
          Sign Up
        </MenuItem>
      </div>
    )
  }

  changePassword = () => {
    return (
      <div className="d-flex wrap-notification">
        <FontAwesomeIcon icon={faBell} />
        <MenuItem className="wrap-font" onClick={this.showPasswordModal}>
          Change Your Password
        </MenuItem>
      </div>
    )
  }

  suggestEdit = () => {
    return (
      <div className="d-flex wrap-notification">
        <FontAwesomeIcon icon={faEdit} />
        <MenuItem className="wrap-font" onClick={this.showContactForm}>
          Suggest a New Listing
          {/* Suggest an Edit */}
        </MenuItem>
      </div>
    )
  }

  loginMenu = () => {
    return (
      <div className="d-flex wrap-notification" style={{ border: 'none' }}>
        <FontAwesomeIcon icon={faSignOutAlt} />
        <MenuItem className="wrap-font" onClick={this.handleLogin}>
          Log In
        </MenuItem>
      </div>
    )
  }

  logoutMenu = () => {
    return (
      <div className="d-flex wrap-notification" style={{ border: 'none' }}>
        <FontAwesomeIcon icon={faSignOutAlt} />
        <MenuItem className="wrap-font" onClick={this.handleLogout}>
          Logout
        </MenuItem>
      </div>
    )
  }

  openAboutUs = () => {
    const { isMobile } = this.state
    if (isMobile) {
      this.setState({
        anchorEl: undefined,
      })
    }
    this.props.history.push('/legal-disclaimer')
    this.toggleMenu()
  }
  openLegalDetails = () => {
    const { isMobile } = this.state
    if (isMobile) {
      this.setState({
        anchorEl: undefined,
      })
    }
    this.props.history.push('/learn-more')
    this.toggleMenu()
  }

  openContactUs = () => {
    const { isMobile } = this.state
    if (isMobile) {
      this.setState({
        anchorEl: undefined,
      })
    }
    this.props.history.push('/contact-us')
    this.toggleMenu()
  }

  aboutUsNLegal = () => {
    let { history, auth } = this.props
    return (
      <div
        className={
          auth.token === ''
            ? 'd-flex noauth'
            : history.location.pathname.includes('legal-disclaimer')
            ? 'd-flex wrap-notification noauth active-menu'
            : 'd-flex wrap-notification'
        }
      >
        <FontAwesomeIcon icon={faUserFriends} />
        <MenuItem className="wrap-font" onClick={this.openAboutUs}>
          Legal Disclaimer
        </MenuItem>
      </div>
    )
  }

  contactUs = () => {
    let { history, auth } = this.props
    return (
      <div
        className={
          auth.token === ''
            ? 'd-flex noauth'
            : history.location.pathname.includes('contact-us')
            ? 'd-flex wrap-notification noauth active-menu'
            : 'd-flex wrap-notification'
        }
      >
        <FontAwesomeIcon icon={faUserFriends} />
        <MenuItem className="wrap-font" onClick={this.openContactUs}>
          Contact Us
        </MenuItem>
      </div>
    )
  }

  youtubeChannel = () => {
    const { disclaimer, isWatchMoreVideo } = this.state
    const { auth } = this.props
    return (
      <div
        className={
          auth.token !== ''
            ? 'd-flex wrap-notification border-last-div'
            : 'd-flex wrap-notification'
        }
      >
        <p className="youtube-menu-icon">
          {' '}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="youtube"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="svg-inline--fa fa-youtube"
          >
            <path
              fill="currentColor"
              d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
              className=""
            ></path>
          </svg>
        </p>
        <PopUpYoutubeVideo
          open={isWatchMoreVideo}
          setOpen={() => {
            this.setState({ isWatchMoreVideo: false })
          }}
          // youtubeLink={disclaimer && disclaimer.youtube_channel}
          // not loged in https://www.youtube.com/embed/guiNmUF91Kw?rel=0&enablejsapi=1
          // logged in  https://www.youtube.com/embed/LxIh182D1LA?rel=0&enablejsapi=1
          youtubeLink={
            auth && auth.token !== ''
              ? 'https://www.youtube.com/embed/LxIh182D1LA?rel=0&enablejsapi=1'
              : 'https://www.youtube.com/embed/tLKlBvUre0M'
          }
        />
        <MenuItem
          className="wrap-font"
          onClick={() => {
            this.setState({ isWatchMoreVideo: true })
          }}
        >
          Watch Tutorial
        </MenuItem>
      </div>
    )
  }

  frYoutubeChannel = () => {
    const { disclaimer } = this.state
    const { auth } = this.props
    return (
      <div
        className={
          auth.token !== ''
            ? 'd-flex wrap-notification border-last-div'
            : 'd-flex wrap-notification'
        }
      >
        <p className="youtube-menu-icon">
          {' '}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="youtube"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="svg-inline--fa fa-youtube"
          >
            <path
              fill="currentColor"
              d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
              className=""
            ></path>
          </svg>
        </p>

        <MenuItem className="wrap-font">
          <a
            href={
              'https://www.youtube.com/channel/UCEwIyx6VVRr9wrGsa6gDwhw/videos?view=0&sort=dd&shelf_id=1 '
            }
            target="_blank"
            className="youtube-channel"
          >
            FR’s YouTube Channel
          </a>
        </MenuItem>
      </div>
    )
  }
  userProfile = () => {
    const { disclaimer } = this.state
    const { auth } = this.props
    return <div>{this.profileIconList()}</div>
  }

  LearnMoreChannel = () => {
    // let { history, auth } = this.props;
    // return (<div className={(auth.token === "") ? "d-flex noauth" : history.location.pathname.includes('legal-disclaimer') ? "d-flex wrap-notification noauth active-menu" : "d-flex wrap-notification"}>
    //   <FontAwesomeIcon icon={faUserFriends} />
    //   <MenuItem className="wrap-font" onClick={this.openLegalDetails}>Learn More</MenuItem>
    // </div>)

    const { disclaimer, isLearnMoreChannel } = this.state
    const { auth } = this.props
    return (
      <div
        className={
          auth.token !== ''
            ? 'd-flex wrap-notification border-last-div'
            : 'd-flex wrap-notification'
        }
      >
        <p className="youtube-menu-icon">
          {' '}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="youtube"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="svg-inline--fa fa-youtube"
          >
            <path
              fill="currentColor"
              d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
              className=""
            ></path>
          </svg>
        </p>
        {/* 
      <PopUpYoutubeVideo
        open={isLearnMoreChannel}
        setOpen={() => {
          this.setState({ isLearnMoreChannel: false })
        }}
        youtubeLink="https://www.youtube.com/embed/gf-POrZVABs?rel=0&enablejsapi=1"
      /> */}
        <MenuItem
          className="wrap-font"
          onClick={() => {
            this.openLegalDetails()
          }}
        >
          Learn More
        </MenuItem>
      </div>
    )
  }
  closeSignUp = () => {
    this.props.authModal(null)
  }

  notificationIcon = () => {
    const { anchorEl } = this.state
    return (
      <IconButton
        className={'icon-button-div'}
        aria-owns={anchorEl ? 'menu' : undefined}
        onClick={this.handleNotificationClick}
      >
        <p>
          <FontAwesomeIcon icon={faBell} />
        </p>
      </IconButton>
    )
  }
  handleNotificationClick = (event) => {
    if (this.props.auth.token !== '') {
      if (
        this.props.auth.accessType === 'Admin' ||
        this.props.auth.accessType === 'Super Admin'
      ) {
        this.props
          .getNotificationAlerts(5, 1, -1, 'alert_type')
          .then((alerts) => {
            // if(alerts)
            // this.setState({alerts: alerts.alerts, count: alerts.count});
          })
      } else {
        this.props
          .getNotificationAlerts(5, 1, -1, 'alert_type', this.props.auth.email)
          .then((alerts) => {
            // if(alerts)
            // this.setState({alerts: alerts.alerts, count: alerts.count});
          })
      }
    }
    this.setState({
      alertanchorEl: event.currentTarget,
    })
  }
  closeNotificationClick = () => {
    this.setState({
      alertanchorEl: undefined,
    })
  }

  renderNotificationList = (alert) => {
    if (
      alert &&
      alert.notificationAlerts &&
      Array.isArray(alert.notificationAlerts) &&
      alert.notificationAlerts.length > 0
    ) {
      return (
        alert &&
        alert.notificationAlerts.length > 0 &&
        alert.notificationAlerts.map((notificationAlert, index) => {
          if (index < 5) {
            return (
              <div key={index} className={'noti-div-wrap'}>
                {/* // <div key={index} className={(index === 4 || (alert.notificationAlerts.length - 1) === index) ? 'noti-div-wrap-last' : 'noti-div-wrap'}> */}
                <FontAwesomeIcon icon={faBell} />
                {
                  // alert.alert_type === 'Edits Suggested' && <div className='notification-item'>{alert.alert_type} for {alert.business.program_name}</div> || <div className='notification-item'>{alert.alert_type}</div>
                  // alert.alert_type === 'Edits Suggested' && <div className='notification-item'>{alert.alert_type} for {alert.business.program_name}</div>
                  this.renderAlertType(notificationAlert)
                }
              </div>
            )
          }
        })
      )
    } else if (
      alert &&
      alert.notificationAlerts &&
      alert.notificationAlerts.length === 0
    ) {
      return <div className="notification-item">No New Alerts</div>
    }
  }
  renderAlertType = (alert) => {
    let alert_type = ''
    let program_name = ''
    if (alert) {
      program_name =
        alert && alert.business && alert.business.program_name
          ? alert.business.program_name
          : ''
      alert_type = alert && alert.alert_type ? alert.alert_type : ''
    }
    switch (alert.alert_type) {
      case 'Edits Suggested':
      // case "New User Created":
      //   return <div className='notification-item' onClick={() => this.openReviewPopup(alert)}>
      //     {alert.alert_type} for {alert.business.program_name}<br />
      //   </div>
      //   break;
      case 'New Review Submitted':
        return (
          <div
            className="notification-item"
            onClick={() => this.openReviewPopup(alert)}
          >
            {alert_type} for {program_name}
            <br />
            {/* {this.renderSeeMoreHere(alert)} */}
          </div>
        )
        break
      case 'New Photo Submitted':
        return (
          <div
            className="notification-item"
            onClick={() => this.openImagePopup(alert)}
          >
            {alert_type} for {program_name}
            <br />
          </div>
        )
        break
      case 'New Question Submitted':
        return (
          <div
            className="notification-item"
            onClick={() => this.openQuestionPopup(alert)}
          >
            {alert_type} for {program_name}
            <br />
          </div>
        )
        break
      case 'Program Unpublished':
        return (
          <div
            className="notification-item"
            onClick={() => this.renderSeeMoreHere(alert)}
          >
            {alert_type} from {program_name}
            <br />
            {/* {this.renderSeeMoreHere(alert)} */}
          </div>
        )
        break
      case 'New Program Submitted':
        return (
          <div
            className="notification-item"
            onClick={() => this.renderSeeMoreHere(alert)}
          >
            {alert_type} by {program_name}
            <br />
            {/* {this.renderSeeMoreHere(alert)} */}
          </div>
        )
        break
      default:
        return (
          <div
            className="notification-item"
            onClick={() => this.renderSeeMoreHere(alert)}
          >
            {alert_type}
            <br />
            {/* {this.renderSeeMoreHere(alert)} */}
          </div>
        )
        break
    }
  }
  renderSeeMoreHere = (alert) => {
    if (alert.business) {
      this.viewNotificationAlertDetails(alert.business.id, alert)
    } else {
      this.viewUserDetails(alert.users._id, alert)
    }
    // return <span style={{ color: '#A1A1A1' }}>See more <label
    //   onClick={() => {
    //     if (alert.business) {
    //       this.viewNotificationAlertDetails(alert.business.id, alert)
    //     } else {
    //       this.viewUserDetails(alert.users._id);
    //     }
    //   }}
    //   style={{ color: '#29ABE2', cursor: 'pointer' }}>here</label></span>
  }
  viewUserDetails = (id, alert) => {
    console.log('viewUserDetails', alert)
    this.setState({
      showBusinessProfile: true,
      selectedId: id,
      alertanchorEl: undefined,
    })
    // this.props.history.push(`/my-listing/user-details/${id}`);
    this.props.alertActions.removeAlert(alert._id)
  }
  viewNotificationAlertDetails = (id, alert) => {
    this.setState({ alertanchorEl: undefined })
    if (alert) {
      this.props.history.push({
        pathname: `/my-listing/details/${id}`,
        state: { alert },
      })
    }
  }
  renderNotificationListFooter = (alert) => {
    if (alert.notificationAlerts && alert.notificationAlerts.length >= 0) {
      return (
        <div className={'notification-footer'}>
          <div className="manage-alert-div-wrap" onClick={this.handleAlerts}>
            <span>
              {alert.notificationAlerts.length > 5
                ? 5
                : alert.notificationAlerts.length}{' '}
              <span className={'new-alert-label'}> new alerts </span>
            </span>
            <Button>MANAGE ALERTS</Button>
          </div>
        </div>
      )
    }
  }

  profileIcon = () => {
    const { anchorEl } = this.state
    return (
      <IconButton
        className={'icon-button-div'}
        aria-owns={anchorEl ? 'menu' : undefined}
        onClick={() => this.setState({ showBusinessProfile: true })}
      >
        <p>
          <FontAwesomeIcon icon={faUser} />
        </p>
      </IconButton>
    )
  }

  profileIconList = () => {
    return (
      <div
        className={
          this.props.history.location.pathname.includes('all')
            ? 'd-flex wrap-notification '
            : 'd-flex wrap-notification'
        }
      >
        <FontAwesomeIcon icon={faUser} />
        <MenuItem
          className="wrap-font"
          onClick={() => this.setState({ showBusinessProfile: true })}
        >
          Profiles
        </MenuItem>
      </div>

      // <IconButton
      //   className={'icon-button-div'}
      //   aria-owns={anchorEl ? 'menu' : undefined}
      //   onClick={() => this.setState({ showBusinessProfile: true })}>

      //   <MenuItem className="wrap-font">
      //   <p><FontAwesomeIcon icon={faUser} /></p>
      //     Profiles

      //   </MenuItem>
      // </IconButton>
    )
  }

  /**
   * IMAGE POP UP ALERT**/
  openImagePopup = (alert) => {
    console.log('image alert openImagePopup', alert)
    this.setState({
      openpop: true,
      url: alert['image']['url'],
      selectedAlert: alert,
    })
  }
  closeImagePopup = () => {
    this.setState({ openpop: false })
  }

  /**
   * REVIEW POP UP ALERT**/
  openReviewPopup = (alert) => {
    console.log('review', alert)
    this.setState({
      review: alert['review'],
      openReview: true,
    })
  }

  closeReviewPopup = () => {
    this.setState({
      openReview: false,
    })
  }

  /**
   * QUESTION POP UP ALERT**/
  openQuestionPopup = (alert) => {
    this.setState({
      question: alert['question'],
      openQuestion: true,
    })
  }

  closeQuestionPopup = () => {
    this.setState({
      openQuestion: false,
    })
  }

  render() {
    const {
      anchorEl,
      alertanchorEl,
      open,
      openContactForm,
      showPasswordModal,
      openForgotPassword,
      selectedId,
      showBusinessProfile,
      showSignUp,
      openConfirmEmail,
      confirmEmail,
      isMobile,
    } = this.state
    let { matches, history, auth, alert, contentForm } = this.props
    let popup_open =
      history.location.pathname.includes('my-listing') ||
      history.location.pathname.includes('alerts') ||
      history.location.pathname.includes('login-n-security') ||
      history.location.pathname.includes('legal-disclaimer') ||
      history.location.pathname.includes('new/program')
        ? true
        : !!anchorEl

    return (
      <div
        className={
          isMobile
            ? history.location.pathname.includes('/all')
              ? 'said-icon'
              : history.location.pathname.includes('/search')
              ? 'said-icon'
              : 'said-icon-wh'
            : 'said-icon'
        }
      >
        <ForgotPassword
          onClose={this.openForgotPassword(null)}
          matches={matches}
          open={this.props.auth.modal === 'F'}
        />
        <Login
          open={this.props.auth.modal === 'IN'}
          onClose={this.closeLogin}
          openForgotPassword={this.openForgotPassword('F')}
          openSignUp={this.goToSignUp}
          goToAdmin={this.goToAdmin}
          matches={matches}
        />
        <ContactForm
          open={openContactForm}
          onClose={this.hideContactForm}
          matches={matches}
        />
        <PasswordReset
          open={this.props.auth.modal === 'R'}
          onClose={this.hidePasswordModal}
          matches={matches}
          location={this.props.location}
        />
        <BusinessProfile
          openModal={showBusinessProfile}
          userId={!selectedId ? this.props.auth.id : selectedId}
          onCloseModal={this.closeBusinessProfile}
        />
        <SignUp
          openModal={this.props.auth.modal === 'UP'}
          onClose={this.closeSignUp}
          openConfirmEmail={this.openConfirmEmail}
          goToAdmin={this.goToAdmin}
          matches={matches}
        />
        {true && <ConfirmEmail open={true} onClose={this.openConfirmEmail} />}

        <Popover1
          open={Boolean(alertanchorEl)}
          anchorEl={alertanchorEl}
          onClose={this.closeNotificationClick}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={'mobile-pop-up'}
        >
          <div className="notification-popover">
            <h3>Notifications</h3>
            <div className={'notification-wrap-div'}>
              {this.renderNotificationList(alert)}
            </div>
          </div>
          {this.renderNotificationListFooter(alert)}
        </Popover1>
        <ImagePopup
          open={this.state.openpop}
          url={this.state.url}
          onClose={this.closeImagePopup}
          matches={this.props.matches}
          approveImage={this.props.businessActions.approveImage}
          removeImage={this.props.businessActions.removeImage}
          alert={this.state.selectedAlert}
        />
        <ReviewPopup
          review={this.state.review}
          open={this.state.openReview}
          onClose={this.closeReviewPopup}
          auth={this.props.auth}
          matches={this.props.matches}
        />
        <QuestionPopup
          question={this.state.question}
          open={this.state.openQuestion}
          onClose={this.closeQuestionPopup}
          auth={this.props.auth}
          matches={this.props.matches}
        />

        <Popper1
          open={this.props.isMenuOpened}
          className={`menu-popover ${!!anchorEl ? '' : 'menu-hide-div'}`}
        >
          <MenuList
            autoFocusItem={!!anchorEl}
            id="menu"
            className={`menu-view div-wrap`}
            anchorEl={anchorEl}
          >
            {/*<div className="menuItem-div">*/}
            {/*  {auth.token !== '' &&*/}
            {/*    !history.location.pathname.includes(*/}
            {/*      '/my-listing/user-details'*/}
            {/*    ) &&*/}
            {/*    this.profileIcon()}*/}
            {/*  /!*{auth.token !== '' && this.notificationIcon()}*!/*/}
            {/*</div>*/}
            <div className="business-wrap">
              {auth.token === '' && (
                <div style={{ margin: '2px 0px' }}>
                  <div className="menu-wrap">
                    {this.homeMenu()}
                    {this.youtubeChannel()}
                    {/* {this.suggestEdit()} */}
                    {this.aboutUsNLegal()}
                  </div>
                  <div className={'menu-last-div'}>
                    <div className=" d-flex wrap-notification">
                      {/* <FontAwesomeIcon icon={faUserFriends} /> */}
                      <MenuItem className="wrap-font">
                        ADD A LISTING{' '}
                        <FontAwesomeIcon icon={faArrowDown} rotation={135} />
                      </MenuItem>
                    </div>
                    {/* {this.state.orgmenu=== true && } */}
                    <div style={{ margin: '2px 0px' }}>
                      {this.LearnMoreChannel()}
                      {this.manageSignUp()}
                      {this.loginMenu()}
                      {/* <CustomizedAccordions/> */}
                    </div>
                  </div>
                </div>
              )}
              {auth.token !== '' &&
                (auth.accessType === 'Read Only' ||
                  auth.accessType === 'Admin' ||
                  auth.accessType === 'User') && (
                  <div style={{ margin: '2px 0px' }}>
                    <div className="menu-wrap">
                      {this.homeMenu()}
                      {this.youtubeChannel()}
                      {this.manageAlertMenu()}
                      {this.manageListingMenu()}
                      {/*my Account*/}
                      {this.userProfile()}

                      {this.contactUs()}

                      {this.frYoutubeChannel()}
                      {this.aboutUsNLegal()}
                      {this.logoutMenu()}
                    </div>
                    {/* <div className={'menu-last-div'}>
                    {this.aboutUsNLegal()}
                   
                  </div> */}
                  </div>
                )}
              {auth.token !== '' && auth.accessType === 'Super Admin' && (
                <div style={{ margin: '2px 0px' }}>
                  <div className="menu-wrap">
                    {this.homeMenu()}
                    {/*  */}
                    {this.youtubeChannel()}
                    {this.manageAlertMenu()}
                    {this.manageListingMenu()}
                    {this.loginNSecurityMenu()}
                    {/*  */}
                    {this.contactUs()}
                    {this.frYoutubeChannel()}
                    {this.aboutUsNLegal()}
                    {this.logoutMenu()}
                  </div>
                  {/* <div className={'menu-last-div'}>
                    {this.aboutUsNLegal()}
                    {this.youtubeChannel()}
                  </div> */}
                </div>
              )}
            </div>

            <div className={'copy-right-div'}>
              Copyright © Flagstaff Resources HUB. All rights reserved.
            </div>
          </MenuList>
        </Popper1>
      </div>
    )
  }
}

const mapState = (state) => ({
  auth: state.auth,
  category: state.category,
  business: state.business,
  alert: state.alert,
})

const mapDispatch = (dispatch) => ({
  authModal: bindActionCreators(authActions.setAuthModal, dispatch),
  resetModal: bindActionCreators(authActions.resetModal, dispatch),
  logout: bindActionCreators(authActions.logout, dispatch),
  getDisclaimer: bindActionCreators(businessActions.getDisclaimer, dispatch),
  businessActions: bindActionCreators(businessActions, dispatch),
  alertActions: bindActionCreators(alertActions, dispatch),
  getNotificationAlerts: bindActionCreators(
    alertActions.getNotificationAlerts,
    dispatch
  ),
  toggleMenuAction: bindActionCreators(toggleMenu, dispatch),
})

export default connect(
  mapState,
  mapDispatch
)(withRouter(withStyles(styles)(Menu)))
