import {initState} from './initialState';
import * as types from '../constants/action.constants';

export default (state=initState.alert, action) => {
  switch(action.type){
    case types.GET_ALERTS:
      return ({...state, alerts: action.alerts, loadingAlert: false, error: ""});
    case types.GET_ALERTS_BUSINESS:
      return ({...state, alerts: action.alerts, loadingAlert: false, error: ""});
    case types.GET_ALERTS_FAIL:
      return ({...state, error: action.error});
    case types.GET_ALERTS_BUSINESS_FAIL:
      return ({...state, error: action.error});
    case types.GET_NOTIFICATION_ALERTS:
      return ({...state, notificationAlerts: action.alerts, loadingAlert: false, error: ""});
    case types.GET_NOTIFICATION_ALERTS_FAIL:
      return ({...state, error: action.error});
    case types.LOADING_ALERT:
      return {
        ...state,
        loadingAlert: true
      };
    case types.REMOVE_ALERT:
      let {alerts} = state;
      let ind = alerts.findIndex(a=>a._id===action.alert._id);
      let deletedAlert = alerts[ind];
      alerts.splice(ind,1);
      return {...state, alerts, filteredAlert:{...deletedAlert}, error: ""};
    case types.REMOVE_ALERT_FAIL:
      return ({...state, error: action.error});
    case types.CLEAR_ALERT:
      return {...state, notifications: [], error: ""};
    case types.CLEAR_ALERT_FAIL:
      return ({...state, error: action.error});
    case types.ADD_ALERT:
      return {...state, alert: {...action.alert}, error: ""};
    case types.ADD_ALERT_FAIL:
      return {...state, error: action.error};
    default: return state
  }
}