import baseService from './baseService'

export const setAuthHeader = () => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
}

export const getBusinesses = (search = '') => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }

  return baseService.post('/public/businesses/search', { search }, config)
}

export const saveBusiness = (business) => {
  const { headers } = setAuthHeader()

  return baseService.post('/private/business', business, {
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

export const getBusinessesByCategory = (category) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  // return baseService.get(`/business?category=${category}`, config)
}

export const getBusinessDetails = (id) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  return baseService.get(`/business/${id}`, config)
}

export const getBusinessWithFilters = (filters) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  let params = []
  Object.keys(filters).forEach((key) => {
    if (
      key === 'sub_category' ||
      key === 'county' ||
      key === 'cities' ||
      key === 'category'
    ) {
      filters[key].forEach((f) => {
        params.push(`${key}=${encodeURIComponent(f)}`)
      })
    } else {
      params.push(`${key}=${encodeURIComponent(filters[key])}`)
    }
  })
  let query = params.map((p) => p).join('&')
  return baseService.get(`/business?${query}`, config)
}

export const searchBusiness = (keyword, filters) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  let params = []
  let query = ''
  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (
        key === 'sub_category' ||
        key === 'county' ||
        key === 'cities' ||
        key === 'category'
      ) {
        filters[key].forEach((f) => {
          params.push(`${key}=${encodeURIComponent(f)}`)
        })
      } else {
        params.push(`${key}=${encodeURIComponent(filters[key])}`)
      }
    })
    query = params.map((p) => p).join('&')
  }
  if (keyword) {
    if (keyword.startsWith('/')) {
      return baseService.get(`/business/search${keyword}${'?' + query}`, config)
    }
    return baseService.get(`/business/search/${keyword}${'?' + query}`, config)
  } else {
    return baseService.get(`/business?${query}`, config)
  }
}

export const uploadImages = (images) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  return baseService.post(`/business/image`, images, config)
}

export const updateApproval = (id, approval_status) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  return baseService.put(
    `/business/update-status/${id}`,
    approval_status,
    config
  )
}

export const getAllBusinesses = (
  entries,
  pageNum,
  order,
  sortBy,
  search = ''
) => {
  const header = setAuthHeader()
  return baseService.get(
    `/private/businesses?show=${entries}&page=${pageNum}&sortBy=${sortBy}&order=${order}&search=${search}`,
    header
  )
}
export const searchAllBusinesses = (search = '') => {
  const header = setAuthHeader()
  return baseService.get(
    `/private/businesses?show=${9999}&page=${0}&search=${search}`,
    header
  )
}

export const approveImage = (image, id) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  return baseService.put(`/admin/approveImage/${id}`, image, config)
}

export const deleteBusiness = (id) => {
  return baseService.delete(`/private/business/${id}`, setAuthHeader())
}

export const removeImage = (image, id) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  return baseService.put(`/admin/removeImage/${id}`, image, config)
}

export const adminBusiness = (id, email) => {
  return baseService.get(`/private/business/${id}`, setAuthHeader())
}

export const updateBusiness = (id, business) => {
  return baseService.post(`/private/business/${id}`, business, setAuthHeader())
}

export const editDisclaimer = (id, disclaimer) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  return baseService.put(
    `/admin/update/legalDisclaimer/${id}`,
    disclaimer,
    config
  )
}

export const getDisclaimer = () => {
  return baseService.get(`/admin/legalDisclaimer`)
}

export const approveClaim = (id, email) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  return baseService.post(`/admin/claim/${id}`, email, config)
}

export const reorderImages = (id, gallery_images) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  return baseService.put(
    `/admin/order/images/${id}`,
    { gallery_images },
    config
  )
}

export const sendContactForm = (form) => {
  // const config = {
  //   'headers': {
  //     'authorization': localStorage.getItem('token')}
  // };
  return baseService.post('/send-contact-info', form)
}

export const sendPasswordSetupEmail = (form) => {
  const config = {
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }
  return baseService.post('/send-password-setup-email', form, config)
}
