import React from 'react';
import {Popover, withStyles} from '@material-ui/core';
import fieldInformation from './FieldInformation';

const styles = () => ({
  paper: {
    backgroundColor: "rgba(113, 113, 113, 0.90)",
    maxWidth: 300,
    color: "#fff",
    padding: '8px',
    marginLeft: '8px',
  },
  google: {
    backgroundColor: "rgba(113, 113, 113, 0.90)",
    color: "#fff",
    padding: '8px',
    marginLeft: '8px',
    '&::-webkit-scrollbar': {
      borderRight: "1px solid #fff"
    }
  }
});

const FieldPopup = ({anchorEl, open, onClose, classes}) => {
  return (
    <Popover
      classes={{paper: anchorEl && anchorEl.id==="google-url-field"?classes.google:classes.paper}}
      style={{
        pointerEvents:anchorEl && anchorEl.id==="google-url-field"?'all':'none'
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
        {anchorEl && fieldInformation[anchorEl.id]}
    </Popover>
  )
};

export default withStyles(styles)(FieldPopup);