import React, {Component} from 'react';
import {
  Modal, withStyles, InputBase,
  Button as MUIButton, IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded'
import {Share} from '@material-ui/icons';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addAlert} from '../../actions/alert.action';
import {BaseUrl} from '../../utils/Const';
import copy from 'copy-to-clipboard';
import './BusinessAccess.css'

const styles = () => ({
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '40% 6% 6% 6%',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    padding: '6%',
    marginLeft: '420px',
  },
  textArea: {
    height: 69,
    overflowY: 'scroll',
    alignItems: "flex-start",
    padding: '1%'
  },
  inputRoot:{
    width:'100%'
  }
});

const Button = withStyles({
  root: {
    background: "#245497",
    color: '#fff',
    width: 'fit-content',
    height: 'auto',
    padding: '1%',
    margin: '1% 1% 0 0',
    '&:hover': {
      background: "#224684",
    }
  }
})(MUIButton);

const Input = withStyles({
  root: {
    border: '1px solid #d2d2d2',
    boxShadow: 'inset 0 0 1px #000',
    padding: '0 1%',
    minHeight: 30
  }
})(InputBase);

class BusinessAccess extends Component {
  state={
    firstName: "",
    lastName: "",
    company_title: "",
    phone_number: "",
    notes: "",
    email: '',
    blankError: {email: false, firstName: false, lastName: false, company_title: false, phone_number: false},
    valid: true,
    validPhone: true
  };

  componentDidMount(){
    if(this.props.auth.token){
      let {user} = this.props.auth;
      let isUser = Object.keys(user).length;
      this.setState({
        firstName: isUser ? user.firstName : "",
        lastName: isUser ? user.lastName : "",
        company_title: isUser ? user.companyTitle : "",
        phone_number: isUser ? user.userPhone : "",
        email: this.props.auth.email || ""
      })
    }
  }

  handleChange = (e) => {
    let {name, value} = e.target;
    this.setState(state=>{
      if(name==="email"){
        return ({email: value, blankError: {...state.blankError, email: false}, valid: true});
      }
      if(name==="phone_number"){
        return ({phone_number: value, blankError: {...state.blankError, phone_number: false}, validPhone: true});
      }
      else if(name==="notes") {
        return ({[name]: value});
      }
      else {
        return ({[name]: value, blankError: {...state.blankError, [name]: false}});
      }
    });
  };

  checkValidEmail = () => {
    let {email, valid} = this.state;
    if(email!==""){
      valid = Boolean(email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
      this.setState({valid});
    }
    else {
      this.handleBlankError("email")
    }
  };

  checkValidPhoneNumber = () => {
    let {phone_number, validPhone} = this.state;
    if(phone_number!==""){
      validPhone = phone_number.toString().length === 10;
      this.setState({validPhone});
    }
    else {
      this.handleBlankError("phone_number")
    }
  };
  handleBlankError = (name) => {
    if(this.state[name]===""){
      this.setState(state => ({blankError: {...state.blankError, [name]: true}}))
    }
  };

  claimBusiness = async () => {
    const {valid, email, phone_number, company_title, firstName, lastName, notes, blankError} = this.state;
    const {resource, addAlert, showMessage} = this.props;
    if(this.props.auth.token === ""){
      showMessage("Please logged into Flagstaff Resources", false);
    } else {
      await this.checkValidEmail();
      await this.checkValidPhoneNumber();
      await this.handleBlankError("firstName");
      await this.handleBlankError("lastName");
      await this.handleBlankError("company_title");
      await this.handleBlankError("phone_number");
      if(valid && !blankError.email
        && !blankError.firstName
        && !blankError.lastName
        && !blankError.company_title
        && !blankError.phone_number){
        addAlert({
          alert_type: "New Business Claimed",
          business: resource._id,
          user: {
            email:email.toLowerCase(), phone_number, company_title, firstName, lastName, notes
          }
        }).then(()=>{
          if(this.props.alert.error===""){
            this.setState({
              firstName: "",
              lastName: "",
              company_title: "",
              phone_number: "",
              notes: "",
              // email: '',
              blankError: {email: false, firstName: false, lastName: false, company_title: false, phone_number: false},
              valid: true,
              validPhone: true
            });
            showMessage("Thank you for claiming your listing,  if you have more programs to claim," +
              " you will need to repeat this step for each one. You will receive an email shortly to set up your password.",
              true
            );
          }
          else {
            showMessage("Something went wrong", false);
          }
        });
      }
    }
  };
  closeModal = () => {
    this.setState({
      firstName: "",
      lastName: "",
      company_title: "",
      phone_number: "",
      notes: "",
      email: '',
      blankError: {email: false, firstName: false, lastName: false, company_title: false, phone_number: false},
      valid: true,
    }, ()=>{
      this.props.onClose();
    })
  };

  copyShareableLink = (text,resource) => {
    let URL = text + "/" + resource.category.category[0].toLowerCase() + "/" + resource.id + "/claim"
    copy(URL)
    this.props.showMessage("Link Copied !",
      true
    );
  }

  render(){
    const {classes, open, matches, resource} = this.props;
    const {email, blankError, valid, validPhone,
      firstName, lastName, company_title, phone_number, notes} = this.state;
    return(
      <Modal open={open} onClose={this.closeModal} classes={{root: matches?classes.mobileModal:classes.modal + " modal-main-div" }}>
        <div className="claim-business-main-container" id="main-container">
          <div className="close-icon-claim">
            <IconButton onClick={()=>this.copyShareableLink(BaseUrl,resource)}><Share/></IconButton>
          <IconButton onClick={this.closeModal}><CloseIcon/></IconButton>
          </div>
          <div className="claim-business-container">
            <div className="business-form">
              <div style={{display: 'flex', width: '100%'}}>
                <div className="name-field">
                  <label>First Name</label>
                  <Input
                    type="text"
                    name="firstName"
                    value={firstName}
                    classes={{root:classes.inputRoot}}
                    onChange={this.handleChange}
                    onBlur={()=>this.handleBlankError("firstName")}
                  />
                </div>
                <div className="name-field">
                  <label>Last Name</label>
                  <Input
                    type="text"
                    name="lastName"
                    value={lastName}
                    classes={{root:classes.inputRoot}}
                    onChange={this.handleChange}
                    onBlur={()=>this.handleBlankError("lastName")}
                  />
                </div>
              </div>
              <div>{(blankError.firstName || blankError.lastName) && <p className="error-message-modal">Please enter your full name.</p>}</div>
              <div className="field">
                <label>Company Title</label>
                <Input
                  type="text"
                  name="company_title"
                  value={company_title}
                  onChange={this.handleChange}
                  onBlur={()=>this.handleBlankError("company_title")}
                />
                {blankError.company_title && <p className="error-message-modal">Please enter your company title</p>}
              </div>
              <div className="field">
                <label>Enter your Email Address</label>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  onBlur={this.checkValidEmail}
                />
                {blankError.email && <p className="error-message-modal">Please enter your email address</p>}
                {!valid && <p className="error-message-modal">The email address you have entered is invalid</p>}
              </div>
              <div className="field">
                <label>Your Phone Number</label>
                <Input
                  type="text"
                  name="phone_number"
                  value={phone_number}
                  onChange={this.handleChange}
                  onBlur={this.checkValidPhoneNumber}
                />
                {blankError.phone_number && <p className="error-message-modal">Please enter your phone number</p>}
                {!validPhone && <p className="error-message-modal">The Phone Number you have entered is invalid</p>}
              </div>
              <div className="field">
                <label>Additional Notes</label>
                <Input
                  classes={{root: classes.textArea}}
                  type="text"
                  name="notes"
                  multiline={true}
                  value={notes}
                  onChange={this.handleChange}
                />
              </div>
              <Button onClick={this.claimBusiness}>Manage This Listing</Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapState = (state) => ({
  alert: state.alert,
  auth: state.auth
});

const mapDispatch = (dispatch) => ({
  addAlert: bindActionCreators(addAlert, dispatch)
});

export default connect(mapState, mapDispatch)(withStyles(styles)(BusinessAccess))
