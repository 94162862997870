import axios from 'axios'


const local = 'http://localhost:8190/api'
const dev = "https://flagstaff-api-dev.oneplusone.dev/api"
const prod = 'https://api.flagstaffresources.com/api'
const baseService = axios.create({
  baseURL: prod,
})

export default baseService
