import React, { useState } from 'react'
import './dropdown.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faUserPlus,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setAuthModal } from '../../actions/auth.action'

export const DropdownCustom = () => {
  const [active, setActive] = useState(false)
  const dispatch = useDispatch()

  const handleClick = (value) => () => {
    setActive(false)
    dispatch(setAuthModal(value))
  }

  const handleOpen = () => {
    setActive((prevState) => !prevState)
  }

  console.log(active)
  return (
    <div className="drop_custom">
      <p className="drop_custom_label" onClick={handleOpen}>
        Add a Listing
        <FontAwesomeIcon icon={faChevronDown} color="#04346C" />
      </p>

      <ul className={active ? 'drop_custom_list' : ''}>
        <li onClick={handleClick('UP')}>
          <FontAwesomeIcon icon={faUserPlus} color="#04346C" />
          <span>Sign Up</span>
        </li>
        <li onClick={handleClick('IN')}>
          <FontAwesomeIcon icon={faSignInAlt} color="#04346C" />
          <span>Log In</span>
        </li>
        <li>
          <Link to={'/learn-more'}>Learn More</Link>
        </li>
      </ul>
    </div>
  )
}
