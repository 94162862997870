import React, { Component } from 'react'
import Map from './Mapv2'
import './../PageTemplate/PageTemplate.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as businessActions from '../../actions/business.action'
import { MapListSwitch } from '../MapListSwitch'

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDetailBox: false,
      isFiltered: true,
      filteredData: [],
      hoveredPin: 0,
      selectedPin: 0,
      hovered: false,
      search: '',
    }
  }

  componentDidMount() {
    this.props.businessActions.getBusinesses()
  }

  UNSAFE_componentWillReceiveProps(newProps) {}

  onPinClick = (selectedPin) => {
    this.setState({ selectedPin, hoveredPin: 0 })
  }

  onPinHover = (resourceId) => {
    let { hoveredPin, selectedPin } = this.state
    if (hoveredPin !== resourceId && selectedPin === 0)
      this.setState({ hoveredPin: resourceId, hovered: false })
  }

  render() {
    const { business, childMapRef } = this.props
    let { openDetailBox, isFiltered, filteredData, hovered } = this.state
    let markers = []
    if (business && business.length > 0) {
      business.forEach((res) => {
        if (res.address && res.address.lat) {
          markers.push({
            _id: res._id,
            id: res.id,
            name: res.program_name,
            address:res.address,
            phone_num: res.phone_num,
            donation_info: res.donation_info,
            business_name: res.business_name,
            category: res.category ? res.category.category[0] : '',
            position: {
              lat: parseFloat(res.address.lat),
              lng: parseFloat(res.address.lng),
            },
          })
        }
      })
    }
    return (
      <div className="map-wrap">
        <MapListSwitch />
        <Map
          ref={childMapRef}
          markers={markers}
          location={this.props.location}
        />
      </div>
    )
  }
}

const mapState = (state) => {
  const { common } = state
  return {
    business: common.businesses,
  }
}

const mapDispatch = (dispatch) => {
  return {
    businessActions: bindActionCreators(businessActions, dispatch),
  }
}
export default connect(mapState, mapDispatch)(Index)
