import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Geocode from 'react-geocode'
import {
  TextField as MUITextField,
  Button as MUIButton,
  Snackbar as MUISnackbarContent,
  withStyles,
  Checkbox as MUICheckbox,
  FormControlLabel,
  Select,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControl,
  OutlinedInput,
  Collapse,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Grid,
  InputLabel,
  CircularProgress,
} from '@material-ui/core'
import { withRouter, Prompt } from 'react-router-dom'
import { createStyles } from '@material-ui/core/styles/'
import clsx from 'clsx'
import {
  Info,
  DirectionsRounded,
  CheckCircle,
  ExpandMore,
  ExpandLess,
  Done,
  FiberManualRecord,
} from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CancelCircle from '@material-ui/icons/CancelRounded'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import { UsaStates } from 'usa-states'
import ProgramTiming from 'components/ProgramForm/ProgramTiming/ProgramTiming'
import { getAllCategories } from '../../actions/category.action.js'
import { saveBusiness } from '../../actions/business.action'
import FieldPopup from '../FieldPopup/FieldPopup'
import SwipeableViews from 'react-swipeable-views'
import './ProgramForm.css'
import { findFlagUrlByCountryName } from 'country-flags-svg'
import ReactQuill from 'react-quill'
import _ from 'lodash'
import HeaderMenu from '../Menu/Menu'
import HealthIcon from '../../assets/images/health_icon.png'
import FoodIcon from '../../assets/images/food_icon.png'
import HousingIcon from '../../assets/images/house_icon.png'
import OthersIcon from '../../assets/images/other_icon.png'
import {
  faArrowLeft,
  faArrowRight,
  faDirections,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'
import { GOOGLE_KEY } from '../../utils/Const'

const categoryIconArr = [HealthIcon, FoodIcon, HousingIcon, OthersIcon]

const Checkbox = withStyles({
  root: {
    '&$checked': {
      color: '#245497',
    },
  },
  checked: {},
})(MUICheckbox)

const TextField = withStyles({
  root: {
    marginTop: '2%',
  },
})(MUITextField)

const RemoveIcon = withStyles({
  root: {
    color: '#c70b1b',
  },
})(RemoveCircle)

const Snackbar = withStyles({
  root: {
    maxWidth: '700px',
  },
})(MUISnackbarContent)

const styles = () => ({
  pc: {
    width: '100%',
    height: '45px',
    borderColor: 'rgb(0, 0, 0) !important',
  },
  phone: {
    width: '100%',
    minHeight: '45px',
    borderColor: 'rgb(0, 0, 0)',
  },
  nameInput: {
    width: '100%',
    height: '45px',
  },
  successNotif: {
    backgroundColor: '#43a047',
  },
  errorNotif: {
    backgroundColor: '#d32f2f',
  },
  divider: {
    backgroundColor: '#D3D3D3',
    width: '100%',
    margin: '1% 0 5% 0',
  },
  btnPrev: {
    backgroundColor: '#245397',
    width: '12%',
    height: '40px',
    fontWeight: 'bolder',
    border: '3px solid #245397',
    color: '#fff !important',
    '&:hover': {
      background: '#fff',
      color: '#245497 !important',
      borderColor: '#245497',
    },
  },
  btnMobile: {
    width: '25%',
  },
  addLogo: {
    color: '#000',
    paddingRight: '5px',
  },
  daysAppTab: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottom: '2px solid #c5c5c4',
  },
  AntTabs: {
    backgroundColor: 'transparent',
  },
  AntTabsRoot: {
    justifyContent: 'space-evenly',
  },
  antEachTab: {
    flex: 1,
  },
  horizontal: {
    padding: '0 !important',
  },
  radioGroupWrap: {
    flexDirection: 'row',
  },
  checkCircle: {
    fontSize: 115,
  },
  checkCirclePhone: {
    fontSize: 70,
  },
  inputField: {
    padding: '10px 14px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  chipRoot: {
    height: '25px',
  },
  icon: {
    color: '#fff !important',
    borderRadius: '10px',
  },
})

const Button = withStyles({
  root: {
    background: '#fff',
    width: '12%',
    // height: '40px',
    fontWeight: 'bolder',
    // border: '3px solid #245397',
    color: '#245397',
    '&:hover': {
      background: '#fff',
      color: '#245497',
      borderColor: '#245497',
    },
  },
})(MUIButton)

const ColorlibConnector = withStyles({
  alternativeLabel: {
    // padding:0
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useQontoStepIconStyles = createStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 4,
    alignItems: 'center',
    padding: 0,
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    backgroundColor: '#245397',
  },
  completed: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: '#1fb400',
    zIndex: 1,
    boxShadow: '0px 0px 5px 0px #1fb400',
  },
})

const AntTab = withStyles({
  root: {
    minWidth: 72,
    color: 'black',
    flex: 1,
    '&:hover': {},
    '&$selected': {
      color: 'white',
      backgroundColor: '#1fb400',
    },
    '&:focus': {},
  },
  labelContainer: {
    padding: '6px 0px',
    fontSize: '12px',
  },
  selected: {},
})((props) => <Tab {...props} />)

function QontoStepIcon(props) {
  const classes = props.classes
  const { active, completed, icon } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {active && <FiberManualRecord className={'active-icon-circle'} />}
      {completed && <Done className={'active-icon-done'} />}
      {!active && !completed && (
        <div className={'uncomplete-icon-div'}>{icon}</div>
      )}
    </div>
  )
}

const GreenCheckbox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  )
}

const CustomStepIcon = withStyles(useQontoStepIconStyles)(QontoStepIcon)

class ProgramForm extends Component {
  state = {
    business: {
      // firstName: "",
      // lastName: "",
      // userPhone: "",
      // companyTitle: "",
      companyLogo: undefined,
      primaryEmail: '',
      // password: "",
      businessName: '',
      programName: '',
      organizationName: '',
      programWebsite: '',
      financialInformation: '',
      address: {
        address_1: '',
        address_2: '',
        city: '',
        state: 'AZ',
        postal_code: '',
        lat: null,
        lng: null,
        PlaceId: '',
      },
      programPhone: '',
      crisis_phone_num: '',
      programTollFreePhone: '',
      crisisPhone: '',
      timing: [
        {
          day: 'Monday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Tuesday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Wednesday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Thursday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Friday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Saturday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
        {
          day: 'Sunday',
          split_hours: false,
          full_day_open: false,
          timingSelected: true,
          open_time: '',
          close_time: '',
        },
      ],
      programEligibility: '',
      category: {
        category: [],
        sub_category: [],
      },
      county: '',
      languages: ['English'],
      programShortDesc: '',
      programLongDesc: '',
      programServices: '',
      gallery_images: [],
      // programStatus: "",
      googleDirectionUrl: '',
      internalNotes: '',
      youtubeVideos: '',
      pdf_file: '',
      otherCategory: [],
    },
    pdf_file: '',
    categoryTab: 0,
    categories: [],
    display_logo: '',
    display_images: [],
    valid: true,
    blankError: false,
    error: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    },
    notification: ``,
    open: false,
    success: false,
    validZip: true,
    anchorEl: undefined,
    anchor: '',
    editNumber: false,
    editCrisisNumber: false,
    numberError: { programPhone: false, crisis_phone_num: false },
    link: false,
    linkObject: {},
    showAnotherProgramButton: false,
    editUserNumber: false,
    loading: false,
    activeStep: 0,
    steps: [0, 1, 2, 3],
    Days: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    daysActiveTab: 0,
    showError: {},
    eachTabFields: [
      ['programName'],
      ['programTiming'],
      ['languages', 'programShortDesc'],
      ['category.category'],
      [],
    ],
    mainError: false,
    customOrder: ['health', 'food', 'housing', 'education'],
    flagUrl: findFlagUrlByCountryName('United States'),
    isMobile: false,
    windowWidth: null,
    shouldBlockNavigation: false,
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    this.setState({ isMobile: isMobile.any(), shouldBlockNavigation: true })
    if (this.props.auth.token === '') {
      this.props.history.push('/all')
    }
    this.props.getAllCategories().then(() => {
      if (this.props.category.error === '') {
        this.setState({ categories: this.props.category.allCategories })
      }
    })
    if (this.props.auth.token !== '') {
      let { user } = this.props.auth
      let isUser = user !== 'undefined' && Object.keys(user).length
      let admin =
        this.props.auth.accessType === 'Admin' ||
        this.props.auth.accessType === 'Super Admin'
      this.setState((state) => ({
        business: {
          ...state.business,
          primaryEmail: admin ? '' : this.props.auth.email,
          // firstName: isUser && !admin ? user.firstName : "", lastName: isUser && !admin ? user.lastName : "",
          // userPhone: isUser && !admin ? user.userPhone : "", companyTitle: isUser && !admin ? user.companyTitle : "",
          businessName: isUser && !admin ? user.businessName : '',
        },
        // eachTabFields:admin ? [[],...state.eachTabFields.slice(1,state.eachTabFields.length)]:[...state.eachTabFields]
      }))
    }
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    if (this.state.isMobile !== isMobile.any()) {
      this.setState({
        isMobile: isMobile.any(),
      })
    }
    if (this.props.auth.token !== newProps.auth.token) {
      let { user } = newProps.auth
      let notUser = Object.keys(user).length === 0
      let admin =
        this.props.auth.accessType === 'Admin' ||
        this.props.auth.accessType === 'Super Admin'
      this.setState((state) => {
        return {
          blankError: false,
          numberError: { programPhone: false, crisis_phone_num: false },
          error: {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false,
          },
          business: {
            ...state.business,
            primaryEmail: newProps.auth.email,
            // firstName: notUser && "", lastName: notUser && "",companyTitle: notUser && "",
            businessName: notUser && '',
            // userPhone: notUser && ""
          },
          // eachTabFields: admin ? [[],...state.eachTabFields.slice(1,state.eachTabFields.length)] : [...state.eachTabFields]
        }
      })
    }
  }

  checkValidZip = () => {
    let { business } = this.state
    let validZip = true
    if (business.address.postal_code !== '') {
      validZip = Boolean(
        business.address.postal_code.match(/^[0-9]{5}(?:-[0-9]{4})?$/)
      )
    }
    this.setState({ validZip })
    return validZip
  }

  handleGetAddressAndPhone = () => {
    let { auth } = this.props
    let { business } = this.state

    if (
      business.address.address_1 !== '' &&
      !(business.address.city === 'Online resources')
    ) {
      let address = `${business.address.address_1}, ${
        business.address.address_2
      }, ${
        Array.isArray(business.address.city)
          ? business.address.city[0]
          : business.address.city
      }, ${business.address.state}`
      Geocode.setApiKey(GOOGLE_KEY)
      let newAddress = address.replace(/[^a-zA-Z0-9 ]/g, '')
      Geocode.fromAddress(newAddress)
        .then((response) => {
          if (response.results.length > 0) {
            const { geometry, place_id } = response.results[0]
            business.address = {
              ...business.address,
              lat: geometry.location.lat,
              lng: geometry.location.lng,
              PlaceId: place_id,
            }
          }
          this.setState({ business }, () => {
            this.handleLoggedInUser()
          })
        })
        .catch((e) => {
          console.log(e)
          business.address = {
            ...business.address,
            lat: 0,
            lng: 0,
            PlaceId: null,
          }
          this.setState({ business }, () => {
            this.handleLoggedInUser()
          })
        })
    } else {
      this.setState({ ...this.state, loading: true }, () => {
        this.handleLoggedInUser()
      })
    }
  }

  handleLoggedInUser = async () => {
    await this.handleNumberError('programPhone')
    await this.handleNumberError('crisis_phone_num')
    let { business, numberError } = this.state
    let { auth } = this.props
    let isAdmin =
      auth.accessType === 'Admin' || auth.accessType === 'Super Admin'
    // let error = await this.checkValidTime();
    let error = false
    let validZip = this.checkValidZip()
    if (
      !error &&
      validZip &&
      (isAdmin || auth.user) &&
      business.programName !== '' &&
      business.languages.length !== 0 &&
      // && business.address.city.length!==0
      // && business.programStatus!==""
      !numberError.programPhone &&
      !numberError.crisis_phone_num
    ) {
      let { saveBusiness } = this.props
      let formData = new FormData()
      formData.append('email', business.primaryEmail.toLowerCase())

      if (business.companyLogo) {
        for (let i = 0; i < business.companyLogo.length; i++) {
          formData.append('company_logo', business.companyLogo[i])
        }
      }

      formData.append(
        'business_name',
        business.businessName ? business.businessName.replace(/^\s+/g, '') : ''
      )
      formData.append(
        'program_name',
        business.programName ? business.programName.replace(/^\s+/g, '') : ''
      )
      formData.append(
        'organization_name',
        business.organizationName ? business.organizationName.toLowerCase() : ''
      )
      formData.append('address', JSON.stringify(business.address))
      // formData.append('cities', JSON.stringify(business.cities));
      formData.append('program_website', business.programWebsite)
      formData.append('phone_num', business.programPhone)
      formData.append('crisis_phone_num', business.crisis_phone_num)
      formData.append('category', JSON.stringify(business.category))
      formData.append('timing', JSON.stringify(business.timing))
      // formData.append('toll_free_num', JSON.stringify(business.programTollFreePhone));
      // formData.append('crisis_phone_num', JSON.stringify(business.crisisPhone));
      // formData.append('program_status', business.programStatus);
      formData.append('program_eligibility', business.programEligibility)
      formData.append('short_description', business.programShortDesc)
      formData.append('long_description', business.programLongDesc)
      // formData.append('services', business.programServices);
      formData.append('internal_notes', business.internalNotes)
      formData.append('pdf_file', business.pdf_file)

      if (business.youtubeVideos && business.youtubeVideos.length > 0) {
        for (let i = 0; i < business.youtubeVideos.length; i++) {
          formData.append('youtubeVideos[]', business.youtubeVideos[i])
        }
      }
      let searchKeywords =
        business.searchKeywords != undefined ? business.searchKeywords : ''
      formData.append('searchKeywords', searchKeywords)
      // formData.append('firstName',business.firstName);
      // formData.append('lastName',business.lastName);
      // formData.append('userPhone',business.userPhone);
      // formData.append('companyTitle',business.companyTitle);
      formData.append('financialInformation', business.financialInformation)
      formData.append(
        'google_direction_url',
        'https://www.google.com/maps/dir//' +
          business.address.address_1 +
          ', ' +
          business.address.city +
          ', ' +
          business.address.state +
          ' ' +
          business.address.postal_code
      )

      if (business.youtubeVideos && business.youtubeVideos.length > 0) {
        for (let i = 0; i < business.youtubeVideos.length; i++) {
          formData.append('youtubeVideos[]', business.youtubeVideos[i])
        }
      }

      if (business.gallery_images && business.gallery_images.length > 0) {
        for (let i = 0; i < business.gallery_images.length; i++) {
          formData.append(
            `gallery_images[${i}]`,
            business.gallery_images[i].approved
              ? JSON.stringify(business.gallery_images[i])
              : business.gallery_images[i]
          )
        }
      } else {
        formData.append('gallery_images[]', '')
      }

      if (business.languages && business.languages.length > 0) {
        for (let i = 0; i < business.languages.length; i++) {
          formData.append('languages[]', business.languages[i])
        }
      }

      saveBusiness(formData).then(() => {
        if (this.props.business.error === '') {
          if (this.props.auth.accessType.toLowerCase() === 'admin')
            this.setState({
              loading: false,
              // open: true,
              // notification: `The program has been successfully added`,
              // success: true,
              showAnotherProgramButton: true,
              shouldBlockNavigation: false,
            })
          else
            this.setState({
              loading: false,
              // open: true,
              // notification: 'Thank you for adding your program. If you have more programs to add,' +
              //   ' you will need to fill out a new program form for each one.',
              // // notification: ['Thank you for adding your program,  if you have more programs to add, you will need to ',<Link to="/new/program" style={{color:'white',textDecoration:'underline'}} onClick={() => window.scrollTo(0, 0)}>repeat this</Link>,' step for each one.You will receive an email shortly to set up your password.'],
              // success: true,
              showAnotherProgramButton: true,
              shouldBlockNavigation: false,
            })
          this.resetForm(business.primaryEmail)
          setTimeout(() => {
            this.props.history.push('/my-listing')
          }, 700)
        } else {
          this.setState({
            open: true,
            notification: `Something went wrong. Please try after some time.`,
            success: false,
          })
        }
      })
    } else {
      this.setState({ blankError: true, loading: false }, () => {
        /*window.scrollTo({
          top: document.getElementsByName('error').item(0).offsetTop-450,
          behavior: 'smooth',
        });*/
      })
    }
  }

  onPhotoUpload = (e) => {
    const { files } = e.target
    if (files.length > 0 && files[0].size < 3000000) {
      let { business, display_images } = this.state
      for (let i = 0; i < files.length; i++) {
        display_images.push(URL.createObjectURL(files[i]))
        let image = files[i]
        let id = 0
        if (business.gallery_images.length > 0)
          id =
            business.gallery_images
              .map((img, index) => (img ? img.id : index))
              .sort()[business.gallery_images.length - 1] + 1
        image['id'] = id
        business['gallery_images'] = [...business.gallery_images, image]
      }
      this.setState({ business, display_images })
    } else {
      this.setState({
        open: true,
        notification:
          'Image is too large, It must be less than 3 MB. Try again.',
      })
    }
  }

  resetForm = (email) => {
    const { activeStep } = this.state
    this.setState({
      business: {
        ...this.state.business,
        // firstName: !email && "",
        // lastName: !email && "",
        // companyTitle: !email && "",
        // userPhone: !email && "",
        businessName: !email && '',
        companyLogo: undefined,
        primaryEmail: email || '',
        // password: "",
        programName: '',
        programWebsite: '',
        financialInformation: '',
        programTollFreePhone: '',
        crisisPhone: '',
        address: {
          address_1: '',
          address_2: '',
          city: ['Flagstaff', 'Online resources'],
          state: 'AZ',
          postal_code: '',
          lat: null,
          lng: null,
          PlaceId: '',
        },
        programPhone: '',
        crisis_phone_num: '',
        timing: [
          {
            day: 'Monday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Tuesday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Wednesday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Thursday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Friday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Saturday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Sunday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
        ],
        programEligibility: '',
        category: {
          category: [],
          sub_category: [],
        },
        county: '',
        cities: ['Flagstaff', 'Online resources'],
        languages: ['English'],
        programShortDesc: '',
        programLongDesc: '',
        programServices: '',
        internalNotes: '',
        gallery_images: [],
        // programStatus: "",
        googleDirectionUrl: '',
        youtubeVideos: '',
        searchKeywords: '',
      },
      display_logo: '',
      display_images: [],
      valid: true,
      blankError: false,
      validZip: true,
      error: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      },
      activeStep: activeStep + 1,
    })
  }

  removeImage = (file, image) => {
    let { business, display_images } = this.state
    business.gallery_images = business.gallery_images.filter(
      (img) => img.id !== file.id
    )
    display_images = display_images.filter((img) => img !== image)
    this.setState({ business, display_images })
  }

  handlePhotos = () => {
    document.getElementById('photo-upload').click()
  }

  handleLanguage = (lang, selected) => {
    this.setState((state) => {
      let newState
      let { business } = state
      if (selected) {
        business.languages = [...business.languages, lang]
        newState = { business, blankError: false }
      } else {
        business.languages = business.languages.filter((l) => l !== lang)
        newState = { business }
      }
      return newState
    })
  }

  handleSplitHours = (day, checked) => {
    let { business } = this.state
    business.timing.find((t) => t.day === day).split_hours = checked
    business.timing.find((t) => t.day === day).timingSelected = true
    business.timing.find((t) => t.day === day).shift_one = {
      open_time: '',
      close_time: '',
    }
    business.timing.find((t) => t.day === day).shift_two = {
      open_time: '',
      close_time: '',
    }
    business.timing.find((t) => t.day === day).open_time = ''
    business.timing.find((t) => t.day === day).close_time = ''
    this.setState({ business })
  }

  checkEmptyField = (e) => {
    let { business } = this.state
    if (e.target.name === 'city') {
      if (
        business.address[e.target.name]
          ? !business.address[e.target.name].trim()
          : !business.address[e.target.name]
      ) {
        this.setState({ blankError: true })
      }
    } else if (
      business[e.target.name]
        ? !business[e.target.name].trim()
        : !business[e.target.name]
    ) {
      this.setState({ blankError: true })
    }
  }

  handleBusinessChange = (e) => {
    let { business } = this.state
    let email = false
    let value = e.target.value
    let greater = false
    if (e.target.name === 'primaryEmail') email = true
    business[e.target.name] = value
    this.setState((state) => {
      if (email) return { business, blankError: false, valid: true }
      else if (greater) {
        business[e.target.name] = state.business[e.target.name]
        return { business, blankError: false }
      } else return { business, blankError: false }
    })
  }

  handleChangeAddress = (e) => {
    let { business } = this.state
    let zip = false
    if (e.target.name === 'postal_code') zip = true
    business.address[e.target.name] = e.target.value
    this.setState((state) => {
      if (zip) return { business, validZip: true }
      else return { business }
    })
  }

  handleFullDayOpen = (day, selected) => {
    const { business } = this.state
    let index = business.timing.findIndex((t) => t.day === day)
    let selectedDay = business.timing[index]
    selectedDay = {
      ...selectedDay,
      full_day_open: selected,
      timingSelected: !selected,
      split_hours: false,
      shift_one: { open_time: '', close_time: '' },
      shift_two: { open_time: '', close_time: '' },
      open_time: '',
      close_time: '',
    }
    // let {business} = this.state;
    //
    // business.timing.find(t=>t.day===day).full_day_open = selected;
    // business.timing.find(t=>t.day===day).timingSelected = !selected;
    // business.timing.find(t=>t.day===day).split_hours = false;
    // business.timing.find(t=>t.day===day).shift_one = {open_time: "", close_time: ""};
    // business.timing.find(t=>t.day===day).shift_two = {open_time: "", close_time: ""};
    // business.timing.find(t=>t.day===day).open_time = "";
    // business.timing.find(t=>t.day===day).close_time = "";
    // if(selected) {
    //   this.changeActiveDaysTab(day)
    // }
    // this.setState({business});

    if (selected) this.changeActiveDaysTab(day)
    this.setState({
      business: {
        ...business,
        timing: [
          ...business.timing.slice(0, index),
          { ...selectedDay },
          ...business.timing.slice(index + 1, business.timing.length),
        ],
      },
    })
  }

  changeActiveDaysTab = (selectedDay) => {
    let { Days } = this.state
    setTimeout(() => {
      let FindIndex = Days.findIndex(
        (value) => value.toLowerCase() === selectedDay.toLowerCase()
      )
      if (FindIndex + 1 < Days.length)
        this.setState({ ...this.state, daysActiveTab: FindIndex + 1 })
    }, 500)
  }

  handleFullDayClose = (day, selected) => {
    const { business } = this.state
    let index = business.timing.findIndex((t) => t.day === day)
    let selectedDay = business.timing[index]
    selectedDay = {
      ...selectedDay,
      full_day_open: selected,
      timingSelected: !selected,
      split_hours: false,
      shift_one: { open_time: '', close_time: '' },
      shift_two: { open_time: '', close_time: '' },
      open_time: '',
      close_time: '',
    }

    if (selected) this.changeActiveDaysTab(day)
    this.setState({
      business: {
        ...business,
        timing: [
          ...business.timing.slice(0, index),
          { ...selectedDay },
          ...business.timing.slice(index + 1, business.timing.length),
        ],
      },
    })
  }

  checkValidTime = async () => {
    let { error } = this.state
    let { timing } = this.state.business
    await this.setState((state) => {
      timing.forEach((day) => {
        if (day.timingSelected) {
          if (day.split_hours) {
            if (
              day.shift_one.open_time === '' ||
              day.shift_one.close_time === '' ||
              day.shift_two.open_time === '' ||
              day.shift_two.close_time === ''
            ) {
              error[day.day] = true
            } else {
              error[day.day] = false
            }
          } else {
            if (day.open_time === '' || day.close_time === '') {
              error[day.day] = true
            } else {
              error[day.day] = false
            }
          }
        } else {
          error[day.day] = false
        }
      })
      return { error: { ...state.error, ...error } }
    })
    return Object.values(this.state.error).includes(true)
  }

  handleChangeTime = (day, e) => {
    let { business, error } = this.state
    let ind = business.timing.findIndex((t) => t.day === day)
    let timing = business.timing[ind]
    if (timing.split_hours) {
      if (e.target.name.match('one')) {
        if (e.target.name.match('open')) {
          timing['shift_one'] = {
            ...timing['shift_one'],
            open_time: e.target.value,
          }
        } else if (e.target.name.match('close')) {
          timing['shift_one'] = {
            ...timing['shift_one'],
            close_time: e.target.value,
          }
        }
      } else if (e.target.name.match('two')) {
        if (e.target.name.match('open')) {
          timing['shift_two'] = {
            ...timing['shift_two'],
            open_time: e.target.value,
          }
        } else if (e.target.name.match('close')) {
          timing['shift_two'] = {
            ...timing['shift_two'],
            close_time: e.target.value,
          }
        }
      }
    } else {
      if (e.target.name.match('open')) {
        timing['open_time'] = e.target.value
        timing['shift_one'] = {
          open_time: '',
          close_time: '',
        }
        timing['shift_two'] = {
          open_time: '',
          close_time: '',
        }
      } else if (e.target.name.match('close')) {
        timing['close_time'] = e.target.value
        timing['shift_one'] = {
          open_time: '',
          close_time: '',
        }
        timing['shift_two'] = {
          open_time: '',
          close_time: '',
        }
      }
    }
    error[day] = false
    if (timing['open_time'] && timing['close_time'])
      this.changeActiveDaysTab(day)
    timing.timingSelected = true
    business.timing[ind] = timing
    this.setState({ business, error })
  }

  handleRSelectChangeTime = (day, e, name) => {
    debugger
    let { business, error } = this.state
    let ind = business.timing.findIndex((t) => t.day === day)
    let timing = business.timing[ind]
    if (timing.split_hours) {
      if (name.match('one')) {
        if (name.match('open')) {
          timing['shift_one'] = {
            ...timing['shift_one'],
            open_time: e.value,
          }
        } else if (name.match('close')) {
          timing['shift_one'] = {
            ...timing['shift_one'],
            close_time: e.value,
          }
        }
      } else if (name.match('two')) {
        if (name.match('open')) {
          timing['shift_two'] = {
            ...timing['shift_two'],
            open_time: e.value,
          }
        } else if (name.match('close')) {
          timing['shift_two'] = {
            ...timing['shift_two'],
            close_time: e.value,
          }
        }
      }
    } else {
      if (name.match('open')) {
        timing['open_time'] = e.value
        timing['shift_one'] = {
          open_time: '',
          close_time: '',
        }
        timing['shift_two'] = {
          open_time: '',
          close_time: '',
        }
      } else if (name.match('close')) {
        timing['close_time'] = e.value
        timing['shift_one'] = {
          open_time: '',
          close_time: '',
        }
        timing['shift_two'] = {
          open_time: '',
          close_time: '',
        }
      }
    }
    error[day] = false
    if (timing['open_time'] && timing['close_time'])
      this.changeActiveDaysTab(day)
    timing.timingSelected = true
    business.timing[ind] = timing
    this.setState({ business, error })
  }

  handleClose = () => {
    this.setState({
      open: false,
      notification: '',
      link: false,
      linkObject: {},
    })
  }

  openPopup = (e) => {
    this.setState({ anchorEl: e.target, anchor: e.target.id })
  }

  closePopup = () => {
    this.setState({ anchorEl: undefined, anchor: '' })
  }

  phoneNumberFormatter = (phoneNumber) => {
    var match
    if (phoneNumber) {
      if (phoneNumber.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)) {
        if (phoneNumber.toString().match(/^\d{10}$/)) {
          match = phoneNumber
            .toString()
            .replace(/\D /g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          if (match) {
            return match
          }
        }
        return phoneNumber
      }
      return phoneNumber
    }
  }

  toggleEditNumber = (e) => {
    let { value, name } = e.target
    this.setState((state) => {
      if (
        value &&
        !value.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        return {
          editNumber: !state.editNumber,
          editUserNumber: !state.editUserNumber,
          numberError: { ...state.numberError, [name]: true },
        }
      } else {
        return {
          editNumber: !state.editNumber,
          editUserNumber: !state.editUserNumber,
        }
      }
    })
  }

  toggleEditCrisis = () => {
    let { crisis_phone_num } = this.state.business
    this.setState((state) => {
      if (
        crisis_phone_num &&
        !crisis_phone_num
          .toString()
          .match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        return {
          editCrisisNumber: !state.editCrisisNumber,
          numberError: { ...state.numberError, crisis_phone_num: true },
        }
      } else {
        return { editCrisisNumber: !state.editCrisisNumber }
      }
    })
  }

  handleNumberError = (name) => {
    let { business } = this.state
    if (name === 'userPhone') {
      if (
        business[name] &&
        !business[name].toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        this.setState((state) => ({
          numberError: { ...state.numberError, [name]: true },
        }))
      }
    }
    if (
      business[name] &&
      !business[name].toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
    ) {
      this.setState((state) => ({
        numberError: { ...state.numberError, [name]: true },
      }))
    }
  }

  handlePhoneNumber = (e) => {
    let { name, value } = e.target
    this.setState((state) => ({
      business: {
        ...state.business,
        [name]: value,
      },
      numberError: {
        ...state.numberError,
        [name]: false,
      },
    }))
  }

  handleNextStep = async () => {
    await this.validateFullForm()
    this.setState(
      (prevState, props) => {
        let numError = Object.keys(prevState.numberError).filter((value) => {
          if (prevState.numberError[value]) return value
        })
        if (
          !prevState.mainError &&
          prevState.valid &&
          numError.length === 0 &&
          prevState.validZip
        ) {
          return {
            ...this.state,
            blankError: false,
            showError: {},
            activeStep: this.state.activeStep + 1,
          }
        } else {
          return { ...this.state }
        }
      },
      () => {
        window.scrollTo(0, 0)
      }
    )
  }

  handleBackStep = () => {
    this.setState({ ...this.state, activeStep: this.state.activeStep - 1 })
  }

  handleResetStep = () => {
    this.setState({ ...this.state, activeStep: 0 })
  }

  validateFullForm = async () => {
    let { activeStep, showError, eachTabFields, business } = this.state
    let mainError = false
    if (eachTabFields[activeStep][0] === 'programTiming') {
      // let error = await this.checkValidTime();
      // if(error) {
      //   showError['programTiming'] = true;
      //   mainError = true;
      // } else {
      showError['programTiming'] = false
      // }
    } else {
      eachTabFields[activeStep].forEach((value) => {
        let fetchValue = this.getValue(business, value)
        if (!Array.isArray(fetchValue) && fetchValue.trim('') === '') {
          value = value.split('.')
          let index = value.length > 1 ? value[1] : value[0]
          showError[index] = true
          mainError = true
        } else if (Array.isArray(fetchValue) && fetchValue.length === 0) {
          value = value.split('.')
          let index = value.length > 1 ? value[1] : value[0]
          showError[index] = true
          mainError = true
        } else {
          showError[value] = false
        }
      })
    }
    this.setState({
      ...this.state,
      mainError,
      showError: { ...this.state.showError, ...showError },
    })
  }

  getValue = (obj, path) => {
    let split = path.split('.')
    let finalValue = path === 'city' ? { ...obj.address } : { ...obj }
    split.forEach((value) => {
      if (finalValue[value]) {
        finalValue = finalValue[value]
      } else {
        finalValue = ''
        return false
      }
    })
    return finalValue
  }

  handleChangeDaysTab = (event, value) => {
    this.setState({ ...this.state, daysActiveTab: value })
  }

  handleBusinessAboutChange = (filedName, fieldValue) => {
    let { business } = this.state
    fieldValue = fieldValue === '<p><br></p>' ? '' : fieldValue
    business = { ...business, [filedName]: fieldValue }
    this.setState((state) => {
      return { business, blankError: false }
    })
  }

  handlePdf = () => {
    document.getElementById('pdf-upload').click()
  }

  onPdfUpload = (e) => {
    const { files } = e.target
    if (files[0] && files[0].size && files[0].size < 3000000) {
      let { business } = this.state
      business['pdf_file'] = files[0]
      this.setState({ business, pdf_file: files[0] })
    } else {
      this.setState({
        open: true,
        notification: 'Something went wrong. Try again.',
      })
    }
  }

  handleVideos = (e) => {
    const value = e.target.value

    this.setState((prevState) => ({
      ...prevState,
      business: { ...prevState.business, youtubeVideos: [value] },
    }))
  }

  handlesearchKeywords = (e) => {
    let { business } = this.state
    business['searchKeywords'] = e.target.value
    this.setState({ business })
  }

  getStepContent = (stepIndex) => {
    const usaStates = new UsaStates().arrayOf('abbreviations')
    const {
      business,
      categories,
      display_images,
      valid,
      blankError,
      error,
      validZip,
      anchorEl,
      anchor,
      numberError,
      editCrisisNumber,
      categoryTab,
      editUserNumber,
      editNumber,
      Days,
      daysActiveTab,
      showError,
      customOrder,
      pdf_file,
    } = this.state
    const { matches, classes, auth } = this.props
    const isAdmin =
      Object.keys(auth).length &&
      (auth.accessType === 'Admin' || auth.accessType === 'Super Admin')
    const token = Object.keys(auth).length && auth.token !== ''
    switch (stepIndex) {
      case 0:
        return (
          <div className="div-wrapper">
            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="new-program-form-group right-padding-5">
                  <div style={{ display: 'flex' }}>
                    <label>
                      Program/Event/Service Name{' '}
                      <span className="required-label">*</span>
                    </label>
                  </div>
                  <TextField
                    onBlur={this.checkEmptyField}
                    type="text"
                    className="new-program-form-input"
                    onChange={this.handleBusinessChange}
                    name="programName"
                    value={business.programName}
                    variant="outlined"
                    classes={{ root: matches ? classes.phone : classes.pc }}
                  />
                  {blankError || showError.programName ? (
                    <p name="error" className="error-message">
                      Please enter the name of your program.
                    </p>
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="new-program-form-group ">
                  <div style={{ display: 'flex' }}>
                    <label>Name of Organization</label>
                  </div>
                  <TextField
                    type="text"
                    className="new-program-form-input"
                    onChange={this.handleBusinessChange}
                    name="organizationName"
                    variant="outlined"
                    value={business.organizationName}
                    classes={{ root: matches ? classes.phone : classes.pc }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="new-program-form-group right-padding-5">
                  <div style={{ display: 'flex' }}>
                    <label>Website</label>
                  </div>
                  <TextField
                    style={{ marginTop: '3px' }}
                    type="text"
                    className="new-program-form-input"
                    onChange={this.handleBusinessChange}
                    name="programWebsite"
                    variant="outlined"
                    value={business.programWebsite}
                    classes={{ root: matches ? classes.phone : classes.pc }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={3}>
                <div className="new-program-form-group right-padding-5">
                  <div style={{ display: 'flex' }}>
                    <label>Phone Number</label>
                  </div>
                  {editNumber ? (
                    <TextField
                      name="programPhone"
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      className="new-program-form-input"
                      type="text"
                      onChange={this.handlePhoneNumber}
                      onBlur={this.toggleEditNumber}
                      variant="outlined"
                      value={business.programPhone || ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                src={this.state.flagUrl}
                                className="country-flag"
                                alt="image"
                              ></img>
                              <div style={{ marginLeft: '5px' }}>+1</div>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      readOnly
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      type="text"
                      className="new-program-form-input"
                      variant="outlined"
                      onFocus={this.toggleEditNumber}
                      value={
                        this.phoneNumberFormatter(business.programPhone) || ''
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                src={this.state.flagUrl}
                                className="country-flag"
                                alt="image"
                              ></img>
                              <div style={{ marginLeft: '5px' }}>+1</div>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  {numberError.programPhone ? (
                    <p name="error" className="error-message">
                      This phone number is not valid
                    </p>
                  ) : null}
                </div>
              </Grid>

              <Grid item xs={12} lg={3}>
                <div className="new-program-form-group">
                  <div style={{ display: 'flex' }}>
                    <label>Crisis Number</label>
                  </div>
                  {editCrisisNumber ? (
                    <TextField
                      name="crisis_phone_num"
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      type="text"
                      className="new-program-form-input"
                      onChange={this.handlePhoneNumber}
                      onBlur={this.toggleEditCrisis}
                      variant="outlined"
                      value={business.crisis_phone_num || ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                src={this.state.flagUrl}
                                className="country-flag"
                                alt="image"
                              ></img>
                              <div style={{ marginLeft: '5px' }}>+1</div>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      readOnly
                      name="crisis_phone_num"
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      type="text"
                      className="new-program-form-input"
                      variant="outlined"
                      onFocus={this.toggleEditCrisis}
                      value={
                        this.phoneNumberFormatter(business.crisis_phone_num) ||
                        ''
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                src={this.state.flagUrl}
                                className="country-flag"
                                alt="image"
                              ></img>
                              <div style={{ marginLeft: '5px' }}>+1</div>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  {numberError.crisis_phone_num ? (
                    <p name="error" className="error-message">
                      This phone number is not valid
                    </p>
                  ) : null}
                </div>
              </Grid>
            </Grid>

            <Grid container className="program-wrap">
              <Grid item xs={12} lg={12}>
                <div className="new-program-form-group">
                  <div style={{ display: 'flex' }}>
                    <label>Address</label>
                  </div>
                  <TextField
                    name="address_1"
                    value={business.address.address_1}
                    onChange={this.handleChangeAddress}
                    className="new-program-form-input"
                    // label="Address Line 1"
                    type="text"
                    variant="outlined"
                    margin="dense"
                    classes={{ root: matches ? classes.phone : classes.pc }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="new-program-form-group right-padding-5">
                  <div>
                    <label>City</label>
                  </div>
                  <TextField
                    name="city"
                    value={business.address.city}
                    onChange={this.handleChangeAddress}
                    className="new-program-form-input"
                    // label="City"
                    type="text"
                    variant="outlined"
                    margin="dense"
                    classes={{ root: matches ? classes.phone : classes.pc }}
                    onBlur={this.checkEmptyField}
                  />
                  {/*{(showError.city) ?*/}
                  {/*  <p name="error" className="error-message">Please enter a city.</p> : null}*/}
                </div>
              </Grid>
              <Grid item xs={12} lg={3}>
                <div className="new-program-form-group right-padding-5">
                  <div>
                    <label>State</label>
                  </div>
                  <FormControl style={{ textAlign: 'left', width: '100%' }}>
                    {/*<InputLabel htmlFor="state-input">State</InputLabel>*/}
                    <Select
                      name="state"
                      className="new-program-form-input"
                      onChange={this.handleChangeAddress}
                      value={business.address.state}
                      input={
                        <OutlinedInput
                          classes={{
                            root: matches ? classes.phone : 'without-padding',
                          }}
                          id="state-input"
                          style={{ width: '100%', marginTop: '2%' }}
                          labelWidth={0}
                        />
                      }
                    >
                      <MenuItem value="">None</MenuItem>
                      {usaStates.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} lg={3}>
                <div className="new-program-form-group">
                  <div>
                    <label>Zip Code</label>
                  </div>
                  <TextField
                    onBlur={this.checkValidZip}
                    className="new-program-form-input"
                    name="postal_code"
                    value={business.address.postal_code}
                    onChange={this.handleChangeAddress}
                    // label="Zip/Postal Code"
                    type="number"
                    variant="outlined"
                    margin="dense"
                    classes={{ root: matches ? classes.phone : classes.pc }}
                  />
                  {!validZip ? (
                    <p name="error" className="error-message">
                      Please enter a valid postal code.
                    </p>
                  ) : null}
                </div>
              </Grid>
            </Grid>
            <div className={'validation-text'}>
              <p>
                <span className="required-label">*</span> These fields are
                required
              </p>
            </div>
            {/*{(blankError||showError.programName) ?*/}
            {/*  <div className={'error-div'}><p name="error" className="error-message">Please fill all required fields.</p></div> : null}*/}
          </div>
        )
      case 1:
        return (
          <div className="div-wrapper p-0">
            <div className="new-program-form-group">
              <div className="schedule-pc">
                {Days.map((day, index) => {
                  let timing = business.timing.find((t) => t.day === day)
                  return (
                    <div key={index} className={'operation'}>
                      <FormControlLabel
                        className={
                          (!timing.timingSelected ||
                            (timing.open_time && timing.close_time)) &&
                          'checkbox-div'
                        }
                        control={
                          <Checkbox
                            // disabled={true}
                            disabled={
                              timing.timingSelected &&
                              !timing.open_time &&
                              !timing.close_time
                            }
                            onChange={(e, selected) =>
                              this.handleFullDayClose(day, selected)
                            }
                            checked={
                              !timing.timingSelected ||
                              (timing.open_time && timing.close_time)
                            }
                          />
                        }
                        label={day}
                      />
                      <Collapse className="timing-collapse" in={true}>
                        <ProgramTiming
                          day={day}
                          error={error[day]}
                          timing={timing}
                          handleSplitHours={this.handleSplitHours}
                          handleChangeTime={this.handleChangeTime}
                          // handleRSelectChangeTime = {this.handleRSelectChangeTime}
                          handleFullDayOpen={this.handleFullDayOpen}
                          handleFullDayClose={this.handleFullDayClose}
                          mobile={true}
                          full_day_open={timing.full_day_open || false}
                          timingSelected={timing.timingSelected || false}
                        />
                      </Collapse>
                    </div>
                  )
                })}
              </div>
            </div>
            {blankError || showError.programTiming ? (
              <div className={'error-div'}>
                <p
                  name="error"
                  style={{ textAlign: 'center' }}
                  className="error-message"
                >
                  Please select hours for all days.
                </p>
              </div>
            ) : null}
          </div>
        )
      case 2:
        return (
          <div className="div-wrapper">
            <div className="new-program-form-group">
              <div className="composite-language-field">
                {['English', 'Navajo', 'Spanish', 'Sign'].map((lang) => (
                  <FormControlLabel
                    key={lang}
                    label={lang}
                    control={
                      <Checkbox
                        checked={business.languages.includes(lang)}
                        onChange={(e, selected) =>
                          this.handleLanguage(lang, selected)
                        }
                      />
                    }
                  />
                ))}
              </div>
              {(blankError || showError.languages) &&
              business.languages.length === 0 ? (
                <p
                  name="error"
                  style={{ textAlign: 'center' }}
                  className="error-message"
                >
                  Please choose one of the above options.
                </p>
              ) : null}
            </div>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <div className="new-program-form-group">
                  <div style={{ display: 'flex', minHeight: '40px' }}>
                    <label>
                      What is this service?{' '}
                      <span className="required-label">*</span>
                    </label>
                  </div>
                  <ReactQuill
                    style={{ height: 130, marginBottom: 60, width: '90%' }}
                    value={business.programShortDesc}
                    onChange={(content) =>
                      this.handleBusinessAboutChange(
                        'programShortDesc',
                        content
                      )
                    }
                    modules={{
                      toolbar: [['link']],
                    }}
                    formats={[
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'list',
                      'bullet',
                      'indent',
                      'link',
                    ]}
                  />
                  {business.programShortDesc.length === 245 ? (
                    <p name="error" className="error-message">
                      You have reached to maximum characters limit allowed.
                    </p>
                  ) : null}
                  {(blankError || showError.programShortDesc) &&
                  business.programShortDesc === '' ? (
                    <p name="error" className="error-message">
                      Please enter a Short Description that explains your
                      program best.
                    </p>
                  ) : null}
                </div>
              </Grid>

              <Grid item xs={12} lg={12}>
                <div className="new-program-form-group">
                  <div style={{ display: 'flex', minHeight: '40px' }}>
                    <label>What is the first step?</label>
                  </div>
                  <ReactQuill
                    style={{ height: 130, marginBottom: 60, width: '90%' }}
                    value={business.programLongDesc}
                    onChange={(content) =>
                      this.handleBusinessAboutChange('programLongDesc', content)
                    }
                    modules={{
                      toolbar: [['link']],
                    }}
                    formats={[
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'list',
                      'bullet',
                      'indent',
                      'link',
                    ]}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <div className="new-program-form-group">
                  <div style={{ display: 'flex' }}>
                    <label>Who is this service for?</label>
                  </div>
                  <ReactQuill
                    style={{ height: 130, marginBottom: 60, width: '90%' }}
                    value={business.programEligibility}
                    onChange={(content) =>
                      this.handleBusinessAboutChange(
                        'programEligibility',
                        content
                      )
                    }
                    modules={{
                      toolbar: [['link']],
                    }}
                    formats={[
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'list',
                      'bullet',
                      'indent',
                      'link',
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={12}>
                <div className="new-program-form-group">
                  <div style={{ display: 'flex' }}>
                    <label>What is the cost, do you accept insurance?</label>
                  </div>
                  <ReactQuill
                    style={{ height: 130, marginBottom: 60, width: '90%' }}
                    value={business.financialInformation}
                    onChange={(content) =>
                      this.handleBusinessAboutChange(
                        'financialInformation',
                        content
                      )
                    }
                    modules={{
                      toolbar: [['link']],
                    }}
                    formats={[
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'list',
                      'bullet',
                      'indent',
                      'link',
                    ]}
                  />
                </div>
              </Grid>
            </Grid>
            <div className={'validation-text'}>
              <p>
                <span className="required-label">*</span> These fields are
                required
              </p>
            </div>
          </div>
        )
      case 3:
        return (
          <div className="div-wrapper 222">
            <p className={'validation-text'}>All the fields are optional</p>
            <Grid container={12}>
              <Grid xs={12} md={6}>
                <div className="new-program-form-group add-more">
                  <div className="company-logo">
                    <label>Add Logo/Photos</label>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      style={{ width: '100%' }}
                      className={classes.addLogo + ' upload-btn-div'}
                      onClick={this.handlePhotos}
                    >
                      <div className="button-label">
                        Add a photo
                        <div className={'up-icon'}>
                          <FontAwesomeIcon icon="upload" />
                        </div>
                      </div>
                    </Button>
                  </div>
                  <input
                    onChange={(e) => this.onPhotoUpload(e)}
                    multiple={true}
                    accept="image/*"
                    type="file"
                    id="photo-upload"
                    style={{ display: 'none' }}
                  />
                </div>
                {
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
                  >
                    {display_images.length > 0 &&
                      display_images.map((image, i) => (
                        <div
                          key={i}
                          className="uploaded-image"
                          style={{ margin: '0 0 1% 1%' }}
                        >
                          <div className="thumbnail-container">
                            <img
                              src={image}
                              alt="logo"
                              className="thumbnail-image"
                            />
                            <div className="image-overlay">
                              <div className="remove-image">
                                <IconButton
                                  onClick={() =>
                                    this.removeImage(
                                      business.gallery_images[i],
                                      image
                                    )
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                }
              </Grid>
              <Grid xs={12} md={6}>
                {/*<div className="new-program-form-group add-more">*/}
                {/*<div style={{display:"flex"}} >*/}
                {/*  <label>Add directions</label>*/}
                {/*  /!*<FieldPopup id="google-url-field-popup" open={anchor === "google-url-field"}*!/*/}
                {/*  /!*            anchorEl={anchor === "google-url-field" ? anchorEl : undefined} onClose={this.closePopup}/>*!/*/}
                {/*  /!*<Info className="info-icon" alt=""*!/*/}
                {/*  /!*      aria-owns={anchor === "google-url-field" ? 'field-popup' : undefined}*!/*/}
                {/*  /!*      onMouseEnter={this.openPopup} id="google-url-field"*!/*/}
                {/*  /!*/
                /*/}
                {/*</div>*/}
                {/*<TextField*/}
                {/*  name="googleDirectionUrl"*/}
                {/*  value={business.googleDirectionUrl}*/}
                {/*  onChange={this.handleBusinessChange}*/}
                {/*  type="text" variant="outlined"*/}
                {/*  classes={{root: matches ? classes.phone + ' direction-icon-padding' : classes.pc + " directions-div"}}*/}
                {/*  InputProps={{*/}
                {/*    endAdornment: (*/}
                {/*      <InputAdornment position="end">*/}
                {/*        <div className={'direction-icon'}><FontAwesomeIcon icon={faDirections} /></div>*/}
                {/*      </InputAdornment>*/}
                {/*    ),*/}
                {/*  }}*/}
                {/*/>*/}
                {/*</div>*/}

                <div className="new-program-form-group add-more">
                  {/*<div className="admin-program-form-group add-more">*/}
                  <div className="company-logo">
                    <label>Attach a Program Flyer</label>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      style={{ width: '100%' }}
                      className={classes.addLogo}
                      onClick={this.handlePdf}
                    >
                      <div className="button-label">
                        Add a pdf
                        <div className={'up-icon'}>
                          <FontAwesomeIcon icon="upload" />
                        </div>
                      </div>
                    </Button>
                  </div>
                  <input
                    onChange={(e) => {
                      this.onPdfUpload(e)
                      e.target.value = null
                    }}
                    multiple={false}
                    accept="application/pdf"
                    type="file"
                    id="pdf-upload"
                    style={{ display: 'none' }}
                  />
                  {pdf_file && (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                      }}
                    >
                      <div
                        className="uploaded-image"
                        style={{ margin: '0 0 1% 1%' }}
                      >
                        <div className="thumbnail-container">
                          {/*<img src={image} alt="logo" className="thumbnail-image"/>*/}
                          <div className="image-overlay" style={{ bottom: 0 }}>
                            <div className="remove-image">
                              <IconButton
                                onClick={() => this.setState({ pdf_file: '' })}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid xs={12} md={6}>
                {/*<div className="admin-program-form-group add-more">*/}
                {/*  <div className="company-logo">*/}
                {/*    <label>Attach a Program Flyer</label>*/}
                {/*  </div>*/}
                {/*  <div>*/}
                {/*    <Button variant="outlined" style={{width: "100%"}} className={classes.addLogo}*/}
                {/*            onClick={this.handlePdf} >*/}
                {/*      <div className="button-label">*/}
                {/*        Add a pdf*/}
                {/*        <div className={'up-icon'}><FontAwesomeIcon icon="upload"/></div>*/}
                {/*      </div>*/}
                {/*    </Button>*/}
                {/*  </div>*/}
                {/*  <input*/}
                {/*    onChange={(e) => {*/}
                {/*      this.onPdfUpload(e)*/}
                {/*      e.target.value=null*/}
                {/*    }}*/}
                {/*    multiple={false}*/}
                {/*    accept="application/pdf"*/}
                {/*    type="file"*/}
                {/*    id="pdf-upload"*/}
                {/*    style={{display: "none"}}*/}
                {/*  />*/}
                {/*  {*/}
                {/*    pdf_file && <div style={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>*/}
                {/*      <div className="uploaded-image" style={{margin: '0 0 1% 1%'}}>*/}
                {/*        <div className="thumbnail-container">*/}
                {/*          /!*<img src={image} alt="logo" className="thumbnail-image"/>*!/*/}
                {/*          <div className="image-overlay" style={{bottom: 0}}>*/}
                {/*            <div className="remove-image">*/}
                {/*              <IconButton onClick={() => this.setState({pdf_file: ''})}>*/}
                {/*                <RemoveIcon/>*/}
                {/*              </IconButton>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  }*/}
                {/*</div>*/}

                <div className="admin-program-form-group add-more">
                  <div className="company-logo">
                    <label>Link to a video on YouTube</label>
                  </div>
                  <TextField
                    id="youtubeVideos"
                    name="youtubeVideos"
                    value={
                      business.youtubeVideos ? business.youtubeVideos[0] : ''
                    }
                    onChange={this.handleVideos}
                    type="text"
                    variant="outlined"
                    classes={{
                      root: matches
                        ? classes.phone
                        : classes.pc + ' youtube-div',
                    }}
                  />
                </div>
              </Grid>
              {/*<Grid xs={12} md={6}>*/}
              {/*<div className="admin-program-form-group add-more">*/}
              {/*  <div className="company-logo">*/}
              {/*    <label>Link to a video on YouTube</label>*/}
              {/*  </div>*/}
              {/*  <TextField*/}
              {/*    id="youtubeVideos"*/}
              {/*    name="youtubeVideos"*/}
              {/*    value={(business.youtubeVideos).toString().replace(/,/g, '\n')}*/}
              {/*    onChange={this.handleVideos}*/}
              {/*    type="text" variant="outlined"*/}
              {/*    classes={{root: matches ? classes.phone : classes.pc + " youtube-div"}}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*</Grid>*/}
              <Grid xs={12} md={6}>
                <div className="admin-program-form-group add-more">
                  <div className="company-logo">
                    <label>Search Keywords</label>
                  </div>
                  <TextField
                    id="searchKeywords"
                    name="searchKeywords"
                    value={business.searchKeywords}
                    onChange={this.handlesearchKeywords}
                    type="text"
                    variant="outlined"
                    classes={{
                      root: matches
                        ? classes.phone
                        : classes.pc + ' youtube-div',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )
      default:
        return 'Uknown stepIndex'
    }
  }

  getHeaders = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="div-wrapper emp-title">
            <div className="title-each">Information</div>
          </div>
        )
      case 1:
        return (
          <div className="div-wrapper emp-title">
            <div className="title-each">What are your hours of operation?</div>
          </div>
        )
      case 2:
        return (
          <div className="div-wrapper emp-title">
            <div className="title-each">About Your Program</div>
          </div>
        )
      // case 3:
      //   return (
      //     <div className="div-wrapper emp-title">
      //       <div className="title-each ">Check all that apply</div>
      //     </div>
      //   )
      case 3:
        return (
          <div className="div-wrapper emp-title">
            <div className="title-each">Add-more...</div>
          </div>
        )
      default:
        return 'Uknown stepIndex'
    }
  }

  render() {
    const { open, notification, success, activeStep, steps, isMobile } =
      this.state
    const { classes, auth, matches, business } = this.props

    {
      activeStep === steps.length
        ? localStorage.setItem('is_thankyou_page_active', 1)
        : localStorage.setItem('is_thankyou_page_active', 0)
    }
    if (business.loadingBusiness) {
      return (
        <div className="loader">
          <CircularProgress
            className={classes.progress}
            style={{ color: '#FFFFFF' }}
          />
        </div>
      )
    }
    return (
      <div className="program-form-container" id="container-element">
        <React.Fragment>
          <Prompt
            when={this.state.shouldBlockNavigation}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment>
        <div className={classes.root}>
          <div
            className="header-wrap d-flex header-display"
            style={{ background: 'transparent' }}
          >
            <HeaderMenu matches={matches} svgSize="25" paddingReduce={true} />
          </div>
          <div className={'newlist-title'}>ADD A NEW LISTING</div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {
                root: success ? classes.successNotif : classes.errorNotif,
              },
              style: { maxWidth: '631px' },
            }}
            message={
              <span>
                {Array.isArray(notification)
                  ? notification.map((x, key) => <span key={key}>{x}</span>)
                  : notification}
              </span>
            }
            action={
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CancelCircle />
              </IconButton>
            }
          />
        </div>
        {/*<div className="custom-stepper custom-stepper-wrap">*/}
        <Stepper activeStep={activeStep} className={'stepper-width'}>
          {steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}
            return (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{ classes: { root: classes.icon } }}
                  StepIconComponent={CustomStepIcon}
                />
              </Step>
            )
          })}
        </Stepper>
        <div className="form-container">
          <div>
            <div className="new-program-form">
              {activeStep === steps.length ? (
                <div className="outer-div-wrapper-submit">
                  <div className={classes.instructions}>
                    <p className="submit-title">Your listing has been added!</p>
                    <p className="check-mark">
                      <CheckCircle
                        classes={{
                          root: matches
                            ? classes.checkCirclePhone
                            : classes.checkCircle,
                        }}
                      />
                    </p>
                    <p className="submit-message">
                      You will be receiving an email shortly with a link to set
                      up your password. When you login, you will be able to
                      manage and edit your listing, add any additional programs,
                      add special events, and more... If you have any questions
                      please email andrea.nazresources@gmail.com
                    </p>
                  </div>
                  {/*<Button onClick={this.handleResetStep} className={classes.button}>
                  Reset
                </Button>*/}
                </div>
              ) : (
                <div className="outer-div-wrapper">
                  <div>{this.getHeaders(activeStep)}</div>
                  <div className={classes.instructions}>
                    {this.getStepContent(activeStep)}
                  </div>
                  <div
                    className={
                      activeStep !== 0 ? 'btn-weapper' : 'btn-single-weapper'
                    }
                  >
                    {activeStep !== 0 && (
                      <Button
                        className={'next'}
                        disabled={activeStep === 0}
                        onClick={this.handleBackStep}
                        className={classes.btnPrev + ' back-btn btnMobile'}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} />
                        BACK
                      </Button>
                    )}
                    <Button
                      onClick={
                        activeStep === steps.length - 1
                          ? this.handleGetAddressAndPhone
                          : this.handleNextStep
                      }
                      className={classes.button + ' btnMobile' + ' next'}
                    >
                      {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                      {!(activeStep === steps.length - 1) && (
                        <FontAwesomeIcon icon={faArrowRight} />
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="bottom-title"></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  return {
    auth: state.auth,
    category: state.category,
    business: state.business,
  }
}

const mapDispatch = (dispatch) => {
  return {
    getAllCategories: bindActionCreators(getAllCategories, dispatch),
    saveBusiness: bindActionCreators(saveBusiness, dispatch),
  }
}

export default connect(
  mapState,
  mapDispatch
)(withRouter(withStyles(styles)(ProgramForm)))
