import { initState } from './initialState'
import * as types from '../constants/action.constants'

export default (state = initState.auth, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        id: action.id,
        email: action.email,
        accessType: action.access,
        user: action.user,
        error: '',
      }
    case types.LOGIN_FAIL:
      return { ...state, error: action.error }
    case types.LOGOUT:
      return {
        ...state,
        token: '',
        id: '',
        email: '',
        accessType: '',
        user: {},
      }
    case types.CHECK_PASSWORD:
      return { ...state, error: '' }
    case types.CHECK_PASSWORD_FAIL:
      return { ...state, error: action.error }
    case types.CHANGE_PASSWORD:
      return { ...state, error: '' }
    case types.CHANGE_PASSWORD_FAIL:
      return { ...state, error: action.error }
    case types.SET_PASSWORD:
      return { ...state, error: '' }
    case types.SET_PASSWORD_FAIL:
      return { ...state, error: action.error }
    case types.SEND_OTP:
      return { ...state, error: '' }
    case types.SEND_OTP_FAIL:
      return { ...state, error: action.error }
    case types.RESET_MODAL:
      return { ...state, reset: action.payload }
    case types.AUTH_MODAL:
      return {
        ...state,
        modal: action.payload,
        reset: !action.payload ? '' : state.reset,
      }
    default:
      return state
  }
}
