import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookF,
  faWhatsapp,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import {
  faHome,
  faUtensils,
  faClinicMedical,
  faTimesCircle,
  faAngleRight,
  faAngleLeft,
  faFilter,
  faMapMarker,
  faPhone,
  faClock,
  faGlobeAmericas,
  faDirections,
  faMinusCircle,
  faPen,
  faBookmark,
  faMapMarkerAlt,
  faPlusCircle,
  faSearch,
  faBriefcaseMedical,
  faTooth,
  faGlasses,
  faBaby,
  faHandsHelping,
  faUserFriends,
  faPills,
  faBoxes,
  faFemale,
  faDrumstickBite,
  faBed,
  faTshirt,
  faDoorOpen,
  faFileInvoiceDollar,
  faMapMarkedAlt,
  faGlobe,
  faUpload,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faHandPaper
} from '@fortawesome/free-solid-svg-icons'

export default () =>
  library.add(
    faFacebookF,
    faWhatsapp,
    faTwitter,
    faHome,
    faUtensils,
    faClinicMedical,
    faTimesCircle,
    faAngleRight,
    faFilter,
    faAngleLeft,
    faMapMarker,
    faPhone,
    faClock,
    faGlobeAmericas,
    faMinusCircle,
    faPen,
    faDirections,
    faBookmark,
    faMapMarkerAlt,
    faPlusCircle,
    faSearch,
    faBriefcaseMedical,
    faTooth,
    faGlasses,
    faBaby,
    faHandsHelping,
    faUserFriends,
    faPills,
    faBoxes,
    faFemale,
    faDrumstickBite,
    faBed,
    faTshirt,
    faDoorOpen,
    faFileInvoiceDollar,
    faMapMarkedAlt,
    faGlobe,
    faUpload,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faHandPaper
  )
