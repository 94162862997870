import 'antd/dist/antd.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import faInit from 'utils/initFontAwesome'
import 'react-app-polyfill/ie11';

faInit();

ReactDOM.render(<App />, document.querySelector('#root'));
