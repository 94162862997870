import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import _ from 'lodash'
import { withStyles } from '@material-ui/core'
import PageTemplate from '../PageTemplate/PageTemplate'
import * as businessActions from '../../actions/business.action'
import './Home.css'
import { withRouter } from 'react-router-dom'
import ContentComponent from '../Content/Content'
const styles = () => ({
  link: {
    color: '#fff',
    textDecoration: 'none',
    '&:hover': {
      opacity: '0.7',
      color: '#fff',
    },
  },
})

class Content extends React.Component {
  constructor() {
    super()
    this.state = {
      search: '',
      queryFound: false,
      resources: [],
      isMobile: false,
    }
  }

  componentDidMount() {
    const { business, location } = this.props
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    // if (business.businesses.length <= 0) {
    //   this.props.businessActions.getBusinesses()
    // }
    // if (this.props.match.params.query) {
    //   this.props.businessActions
    //     .searchBusiness(this.props.match.params.query)
    //     .then(() => {
    //       if (this.props.business.error === '') {
    //         this.setState({
    //           resources: business.filteredBusiness,
    //           // queryFound:true
    //         })
    //       }
    //     })
    // } else if (!this.props.business.successfullyFetchBusiness) {
    //   /*this.props.businessActions.getBusinesses();*/
    //   // this.setState({queryFound:false})
    // }
    // this.setState({ search: location.search, isMobile: isMobile.any() })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // if (this.props.location.search !== newProps.location.search) {
    //   if (newProps.location.search !== '')
    //     this.setState({ search: newProps.location.search })
    // }
    // if (
    //   this.props.location.pathname.includes('/search') &&
    //   newProps.location.pathname.includes('/search') &&
    //   this.props.location.pathname.match(/\/([^\/]+)\/?$/)[1] !==
    //     newProps.location.pathname.match(/\/([^\/]+)\/?$/)[1]
    // ) {
    //   this.setState({ queryFound: true })
    // } else {
    //   this.setState({ queryFound: false })
    // }
    // if (
    //   this.props.match.params.query !== newProps.match.params.query &&
    //   newProps.match.params.query
    // ) {
    //   this.props.businessActions
    //     .searchBusiness(newProps.match.params.query)
    //     .then(() => {
    //       if (this.props.business.error === '') {
    //         this.setState({
    //           resources: this.props.business.filteredBusiness,
    //           queryFound: true,
    //         })
    //       }
    //     })
    //   this.setState({ search: newProps.location.search })
    // } else if (!newProps.business.successfullyFetchBusiness) {
    //   /*this.props.businessActions.getBusinesses();*/
    //   // this.setState({queryFound:false})
    // }

    if (
      this.props.location.pathname === '/search' ||
      this.props.location.pathname === '/search/' ||
      newProps.location.pathname === '/search/' ||
      newProps.location.pathname === '/search'
    ) {
      this.props.history.push('/all')
    }
    if (
      newProps.location.search === '' &&
      this.props.location.pathname.includes('/search')
    ) {
      this.setState({
        search: '?cities=Flagstaff&',
      })
    }
    if (this.props.location.search !== newProps.location.search) {
      this.setState({ search: newProps.location.search })
    }
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    if (this.state.isMobile !== isMobile.any()) {
      this.setState({ isMobile: isMobile.any() })
    }
  }

  renderComponent = () => {
    const {
      business,
      matches,
      toggleTip,
      toggleToolTip,
      childMapRef,
      onPointerLeaveResource,
      onPointerEnterResource,
    } = this.props
    const { search, queryFound, resources, isMobile } = this.state

    return (
      <div>
        <PageTemplate
          category={'All'}
          icon={queryFound ? 'search' : 'filter'}
          resources={queryFound ? resources : business.businesses}
          subcategories={[]}
          history={this.props.history}
          loading={business.loadingBusiness}
          search={search}
          queryFound={queryFound}
          matches={matches}
          toggleTip={toggleTip}
          toggleToolTip={toggleToolTip}
          childMapRef={childMapRef}
          onPointerEnterResource={onPointerEnterResource}
          onPointerLeaveResource={onPointerLeaveResource}
        />
      </div>
    )
  }

  render() {
    const { isMobile } = this.state
    return (
      <div
        className={
          this.props.location.pathname === '/all' ||
          this.props.location.pathname.includes('/search')
            ? 'web-side-panel'
            : 'm-side-panel'
        }
      >
        {this.renderComponent()}
      </div>
    )
  }
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapState = (state) => {
  return {
    business: state.business,
    common: state.common,
  }
}

const mapDispatch = (dispatch) => {
  return {
    businessActions: bindActionCreators(businessActions, dispatch),
  }
}

export default withRouter(
  connect(mapState, mapDispatch)(withStyles(styles)(Content))
)
