import React, { Component } from 'react'
import {
  Button as MUIButton,
  Modal,
  withStyles,
  InputBase,
  IconButton,
  Snackbar,
  CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseRounded'
import { sendOTP } from '../../actions/auth.action'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Close } from '@material-ui/icons'

const Input = withStyles({
  root: {
    width: '80%',
    border: '1px solid #aaa',
    margin: 12,
    padding: '1%',
    borderRadius: 1,
    '&:hover': {
      borderColor: '#000',
    },
    '&$focused': {
      borderColor: '#245497',
    },
  },
  focused: {},
})(InputBase)

const Button = withStyles({
  root: {
    background: '#245497',
    color: '#fff',
    borderColor: '#245497',
    borderRadius: 1,
    '&:hover': {
      background: '#224989',
    },
  },
})(MUIButton)

const styles = () => ({
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '40% 6% 6% 6%',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    padding: '6%',
    marginLeft: '420px',
  },
  error: {
    backgroundColor: '#d32f2f',
  },
  success: {
    backgroundColor: '#32b241',
  },
})

class ForgotPassword extends Component {
  state = {
    email: '',
    valid: true,
    blankError: { email: false },
    loginError: '',
    message: '',
    showMessage: false,
    success: false,
    loading: false,
  }
  handleClose = () => {
    this.props.onClose(true)
  }

  handleChange = (e) => {
    const { value } = e.target
    this.setState((state) => {
      return {
        email: value,
        valid: true,
        blankError: { ...state.blankError, email: false },
      }
    })
  }

  handleBlankError = (field) => {
    this.setState((state) => ({
      blankError: { ...state.blankError, [field]: true },
    }))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.auth.reset !== this.props.auth.reset) {
      this.setState({ email: this.props.auth.reset })
    }
  }

  failCallback = (message) => {
    this.setState({
      loading: false,
      showMessage: true,
      message: message,
      success: false,
    })
  }

  successCallback = () => {
    this.setState({
      loading: false,
    })
  }

  send = async () => {
    this.setState({
      loading: false,
      showMessage: false,
      message: '',
      success: false,
    })
    const { email } = this.state
    if (email === '') await this.handleBlankError('email')
    await this.checkValidMail()
    const { valid, blankError } = this.state
    if (valid && !blankError.email) {
      this.setState({ ...this.state, loading: true })
      this.props.sendOTP(
        email.toLowerCase(),
        this.failCallback,
        this.successCallback
      )
    }
  }

  hideNotification = () => {
    const { success } = this.state
    this.setState({ showMessage: false }, () => {
      if (success) {
        setTimeout(() => this.props.history.push('/'), 2000)
      }
    })
  }
  checkValidMail = () => {
    let { email, valid } = this.state
    if (email !== '') {
      valid = Boolean(
        email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      )
      this.setState({ valid })
    } else {
      this.handleBlankError('email')
    }
  }
  render() {
    const { open, matches, classes } = this.props
    const { email, valid, blankError, message, showMessage, success } =
      this.state
    return (
      <div>
        <Modal
          open={open}
          onClose={this.handleClose}
          classes={{
            root: matches
              ? classes.mobileModal
              : classes.modal + ' modal-main-div',
          }}
        >
          <div className="login">
            <div className={'modal-title'}>
              <h2>FORGOT PASSWORD</h2>
            </div>
            <div className="close-icon">
              <IconButton onClick={this.handleClose}>
                <Close />
              </IconButton>
            </div>
            <div className="user-login">
              <div className="field-container">
                <label>Email</label>
                <Input
                  variant="outlined"
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={this.handleChange}
                  onBlur={this.checkValidMail}
                />
                {!valid && (
                  <p className="error">
                    The email address you have entered is invalid.
                  </p>
                )}
                {blankError['email'] && (
                  <p className="error">Please enter your email address.</p>
                )}
              </div>
              <div className={'btn-section'}>
                <Button
                  variant="contained"
                  className="login-btn"
                  onClick={this.send}
                  disabled={this.state.loading}
                >
                  {this.state.loading ? (
                    <CircularProgress
                      style={{
                        height: '25px',
                        width: '25px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    'Send a code'
                  )}
                </Button>
                {this.props.auth.error === 'Your email is not approved.' && (
                  <p className="error">This email does not exist</p>
                )}
              </div>
            </div>
            <Snackbar
              open={showMessage}
              message={<span>{message}</span>}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              ContentProps={{
                classes: { root: success ? classes.success : classes.error },
              }}
              // autoHideDuration={5000}
              onClose={this.hideNotification}
              action={[
                <IconButton
                  key="close"
                  color="inherit"
                  onClick={this.hideNotification}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </div>
        </Modal>
      </div>
    )
  }
}

const mapState = (state) => ({ auth: state.auth })

const mapDispatch = (dispatch) => ({
  sendOTP: bindActionCreators(sendOTP, dispatch),
})

export default connect(
  mapState,
  mapDispatch
)(withStyles(styles)(ForgotPassword))
