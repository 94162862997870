import React, {Component} from 'react';
import {Modal, Button as MUIButton, withStyles, InputBase, Collapse, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import StarRatings from 'react-star-ratings';
import * as reviewActions from '../../../actions/review.action';
import './ReviewPopup.css';

const Button = withStyles({
  root: {
    background: "#245497",
    color: '#fff',
    width: 'fit-content',
    padding: '1%',
    margin: '1% 1% 0 0',
    '&:hover': {
      background: "#224684",
    }
  }
})(MUIButton);

const Input = withStyles({
  root: {
    border: '1px solid #d2d2d2',
    boxShadow: 'inset 0 0 1px #000',
    padding: '1%',
    alignItems: 'flex-start',
    height: 69,
    overflowY: 'scroll'
  }
})(InputBase);

const styles = () => ({
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '0% 6% 6% 6%',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    padding: '6%',
    marginLeft: '420px',
  },
});

class ReviewPopup extends Component {
  state={
    review: {},
    reply: "",
    showReply: false,
    editReply: false
  };

  componentDidMount(){
    if(this.props.review){
      this.setState({review: this.props.review});
      if(this.props.review.reply)
        this.setState({reply: this.props.review.reply.reply});
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(this.props.review._id !== newProps.review._id){
      this.setState({review: newProps.review});
      if(newProps.review.reply)
        this.setState({reply: newProps.review.reply.reply});
      else
        this.setState({reply: ""});
    }
  }

  toggleReply = () => {
    this.setState(state=>({showReply: !state.showReply}));
  };

  handleReply = (e) => {
    this.setState({reply: e.target.value});
  };

  postReply = () => {
    this.props.reviewActions.postReply({
      reply: this.state.reply,
      email: this.props.auth.email,
      id: this.props.review._id
    }).then(review => {
      // console.log("review then",review)
      this.setState({review, reply: review.reply.reply, editReply: false})
    })
  };

  handleClose = () => {
    this.setState({
      showReply: false,
      editReply: false
    });
    this.props.onClose();
  };

  toggleEdit = () => {
    this.setState(state=>({
      editReply: !state.editReply,
      reply: this.props.review.reply.reply
    }));
  };

  render(){
    const {open, classes, matches} = this.props;
    const {showReply, reply, editReply, review} = this.state;
    return(
      <Modal open={open} onClose={this.handleClose} classes={{root: matches?classes.mobileModal:classes.modal + " modal-main-div" }}>
        <div className="main-modal">
          <IconButton onClick={this.handleClose} style={{float:'right'}}>
            <CloseIcon/>
          </IconButton>
          <div className="review-modal">
            <div className="review-sub-container">
              <div className="review-subject">
                {review.review_subject}
              </div>
              <div className="review-text">
                <StarRatings
                  rating={review.rating}
                  starDimension="15px"
                  starSpacing="6px"
                  starRatedColor="orange"
                  disabled={true}
                />
                <p align="justify">{review.review_text}</p>
              </div>
            </div>
            <Button onClick={this.toggleReply}>
              {showReply
                ?"Hide"
                :(review.reply)?"Show Reply":"Post a Reply"}
            </Button>
            <Collapse in={showReply}>
              <div className="reply-container">
                {review.reply && !editReply
                  ? <div className="review-sub-container">
                    <div className="review-subject">
                      {review.reply.user.email}
                    </div>
                    <div className="review-text">
                      <p align="justify">{reply}</p>
                    </div>
                  </div>
                  :<Input type="text" value={reply} onChange={this.handleReply} multiline={true}/>
                }
                {!review.reply?<Button onClick={this.postReply}>Submit</Button>
                  :!editReply
                ?<Button onClick={this.toggleEdit}>Edit</Button>
                : <div className="reply-actions">
                    <Button onClick={this.postReply}>Update</Button>
                    <Button onClick={this.toggleEdit}>Cancel</Button>
                  </div>}
              </div>
            </Collapse>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapDispatch = (dispatch) => ({
  reviewActions: bindActionCreators(reviewActions, dispatch)
});

export default connect(null, mapDispatch)(withStyles(styles)(ReviewPopup));
