import * as types from '../constants/action.constants'
import * as services from '../service/business.service'
import { COPY_BUSINESS } from '../constants/action.constants'

export const getBusinesses = (search) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_BUSINESS })
    return services
      .getBusinesses(search)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_BUSINESSES_SUCCESS,
            businesses: resp.data.data,
          })
          dispatch({
            type: types.SET_BUSINESSES,
            payload: resp.data.data,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_BUSINESSES_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const getBusinessByCategory = (category) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_BUSINESS })
    return services
      .getBusinessesByCategory(category)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_BUSINESS_BY_CAT_SUCCESS,
            payload: { category, businesses: resp.data },
          })

          return resp.data
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_BUSINESS_BY_CAT_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const getBusinessById = (id) => {
  return (dispatch) => {
    return services
      .getBusinessDetails(id)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_BUSINESS_BY_ID,
            business: resp.data,
          })
          return resp.data
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_BUSINESS_BY_ID_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const getBusinessWithFilters = (filters) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_BUSINESS })
    return services
      .getBusinessWithFilters(filters)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_BUSINESS_WITH_FILTERS,
            businesses: resp.data,
          })
          return resp.data
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_BUSINESS_WITH_FILTERS_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const uploadImage = (imageArr) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_BUSINESS })
    return services
      .uploadImages(imageArr)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.UPLOAD_IMAGES,
            business: resp.data,
          })
          return resp.data
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.UPLOAD_IMAGES_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const searchBusiness = (keyword, filters) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_BUSINESS })
    return services
      .searchBusiness(keyword, filters)
      .then((resp) => {
        if (resp.status === 200) {
          if (filters) {
            dispatch({
              type: types.GET_BUSINESS_WITH_FILTERS,
              businesses: resp.data,
            })
            return resp.data
          } else {
            dispatch({
              type: types.SEARCH_BUSINESS_SUCCESS,
              businesses: resp.data,
            })
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.SEARCH_BUSINESS_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const saveBusiness = (business) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_BUSINESS })
    return services
      .saveBusiness(business)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.SAVE_BUSINESS,
            business: resp.data,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.SAVE_BUSINESS_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const copyBusiness = (business) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_BUSINESS })
    return services
      .saveBusiness(business)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.COPY_BUSINESS,
            business: resp.data,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.SAVE_BUSINESS_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}
export const updateApproval = (id, approvalStatus) => {
  return (dispatch) => {
    return services
      .updateApproval(id, approvalStatus)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.UPDATE_APPROVAL,
            ...approvalStatus,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.UPDATE_APPROVAL_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const getAllBusiness = (entries, pageNum, order, sortBy, search) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_BUSINESS })
    return services
      .getAllBusinesses(entries, pageNum, order, sortBy, search)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_ALL_BUSINESS,
            businesses: resp.data.business,
            count: resp.data.count,
          })
          return resp.data
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_ALL_BUSINESS_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const getUnfilteredBusiness = (email) => {
  return (dispatch) => {
    return services
      .getAllBusinesses(0, 1, 1, 'program_name', email)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_UNFILTERED_BUSINESS,
            businesses: resp.data.businesses,
            count: resp.data.count,
          })
          return resp.data
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_UNFILTERED_BUSINESS_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const approveImage = (image, id) => {
  return (dispatch) => {
    return services
      .approveImage(image, id)
      .then((resp) => {
        dispatch({
          type: types.APPROVE_IMAGE,
          key: image.key,
        })
        return resp.data
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.APPROVE_IMAGE_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const deleteBusiness = (id) => {
  return (dispatch) => {
    return services
      .deleteBusiness(id)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.DELETE_BUSINESS,
            id,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.DELETE_BUSINESS_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const removeImage = (image, id) => {
  return (dispatch) => {
    return services
      .removeImage(image, id)
      .then((resp) => {
        dispatch({
          type: types.REMOVE_IMAGE,
          key: image.key,
        })
        return resp.data
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.REMOVE_IMAGE_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const getAdminBusiness = (id, email) => {
  return (dispatch) => {
    return services
      .adminBusiness(id, email)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_BUSINESS_BY_ID,
            business: resp.data,
          })
          return resp.data
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_BUSINESS_BY_ID_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const updateBusiness = (id, businessData, business) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_BUSINESS })
    return services
      .updateBusiness(id, businessData)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.UPDATE_BUSINESS,
            business,
          })
        }
        return resp.data
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.UPDATE_BUSINESS_FAIL,
            error: err.response.data,
          })
        }
        return err
      })
  }
}

export const getDisclaimer = () => {
  return (dispatch) => {
    return services
      .getDisclaimer()
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_DISCLAIMER,
            disclaimer: resp.data,
          })
        }
        return resp.data
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_DISCLAIMER_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const editDisclaimer = (id, disclaimer) => {
  return (dispatch) => {
    return services
      .editDisclaimer(id, disclaimer)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.UPDATE_DISCLAIMER,
            disclaimer: resp.data,
          })
        }
        return resp.data
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.UPDATE_DISCLAIMER_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const approveClaim = (id, email) => {
  return (dispatch) => {
    return services
      .approveClaim(id, email)
      .then((resp) => {
        if (resp.data.status === false) {
          dispatch({
            type: types.APPROVE_CLAIM_FAIL,
            error: resp.data.data,
          })
        }
        if (resp.status === 200) {
          dispatch({
            type: types.APPROVE_CLAIM,
            id,
            email: email.email,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.APPROVE_CLAIM_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const reorderImages = (id, gallery_images) => {
  return (dispatch) => {
    return services
      .reorderImages(id, gallery_images)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_BUSINESS_BY_ID,
            business: resp.data,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.REORDER_IMAGES_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const setPaginatedData = (page_no, order, field_name, search) => {
  return (dispatch) => {
    dispatch({
      type: types.PAGINATED_DATA,
      page_no,
      order,
      field_name,
      search,
    })
  }
}

export const sendContactForm = (form) => {
  return (dispatch) => {
    return services
      .sendContactForm(form)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.SEND_CONTACT_FORM,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.SEND_CONTACT_FORM_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const sendPasswordSetupEmail = (form) => {
  return (dispatch) => {
    return services
      .sendPasswordSetupEmail(form)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.SEND_PASSWORD_SETUP_EMAIL,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.SEND_PASSWORD_SETUP_EMAIL,
            error: err.response.data,
          })
        }
      })
  }
}
