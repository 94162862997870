import React, { useEffect, useState } from 'react'
import logoIcon from '../../assets/images/logo.png'
import './ConfirmEmail.css'
import { bindActionCreators } from 'redux'
import { sendOTP } from '../../actions/auth.action'
import { connect } from 'react-redux'
import { IconButton, Modal, Snackbar, withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseRounded'
import { Alert } from 'antd'

const classes = {
  error: 'Content-error-331',
  success: 'Content-success-332',
}

const ConfirmEmail = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.onClose()
    }, 5000)
  })
  return <></>
}

const mapState = (state) => {
  return {
    auth: state.auth,
  }
}

const mapDispatch = (dispatch) => ({
  sendOTP: bindActionCreators(sendOTP, dispatch),
})

export default connect(mapState, mapDispatch)(ConfirmEmail)
