import React, { useState } from 'react'
import GreenClock from '../../assets/images/green_clock_icon.png'
import RedClock from '../../assets/images/red_clock_icon.png'
import webIcon from '../../assets/images/web.svg'
import phoneIcon from '../../assets/images/phone.svg'
import defaultMark from '../../assets/images/default_mark.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeart,
  faPizzaSlice,
  faHome,
  faThLarge,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { openItemFromModal } from '../../actions/common.action'

const setTitleCase = (title) => {
  return title
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const time = (resource) => {
  if (resource === 'Open 24 hours today') {
    return (
      <div className={'now-open-div'}>
        <img src={GreenClock} className={'clock-img'} alt={'image'} />
        <p className={'now-open-text today-open'}>Now opened</p>
      </div>
    )
  } else if (resource === 'Closed') {
    return (
      <div className={'now-open-div'}>
        <img src={RedClock} className={'clock-img'} alt={'image'} />
        <p className={'now-open-text today-close'}>Now closed</p>
      </div>
    )
  } else {
    return <p className={'now-open-text'}>{resource}</p>
  }
}

const ResourceItem = ({
  resource,
  onPointerEnterResource,
  onPointerLeaveResource,

  onHandleClick,
}) => {
  const {
    short_description,
    donation_info,
    business_name,
    program_name,
    phone_num,
    address,
  } = resource

  const handleLink = (e) => {
    e.stopPropagation()
  }
  return (
    <div
      className={'resource'}
      onMouseEnter={(e) => onPointerEnterResource(e, resource)}
      onMouseLeave={onPointerLeaveResource}
      onClick={onHandleClick}
    >
      {program_name && (
        <p className="resource-title"> {setTitleCase(program_name)}</p>
      )}
      <p className="resource-subTitle">{business_name}</p>
      {short_description && (
        <div
          className="resource-description"
          dangerouslySetInnerHTML={{ __html: short_description }}
        ></div>
      )}
      {/*{console.log('resource', resource)}*/}
      <div className="resource-links">
        <a
          href={donation_info}
          target="_blank"
          rel="noreferrer"
          onClick={handleLink}
        >
          <img src={webIcon} alt="" />
          <span>website.com</span>
        </a>
        <a href={`tel:${phone_num}`} onClick={handleLink}>
          <img src={phoneIcon} alt="" />
          <span>{phone_num}</span>
        </a>
        {address && (address.address_1 || address.address_2) && (
          <div>
            <img src={defaultMark} alt="" width={'16px'} />
            <span>{address.address_1 || address.address_2}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export const ResourceModal = ({ resource }) => {
  const dispatch = useDispatch()

  const handleLink = (e) => {
    e.stopPropagation()
  }

  const handleClick = () => {
    dispatch(openItemFromModal(resource._id))
  }

  return (
    <div className={'resource resource-modal-item'} onClick={handleClick}>
      {resource.name && (
        <p className="resource-title"> {setTitleCase(resource.name)}</p>
      )}
      <p className="resource-subTitle">{resource.business_name}</p>
      <div className="resource-links">
        <a
          href={resource.donation_info}
          target="_blank"
          rel="noreferrer"
          onClick={handleLink}
        >
          <img src={webIcon} alt="" />
          <span>website.com</span>
        </a>
        <a href={`tel:${resource.phone_num}`} onClick={handleLink}>
          <img src={phoneIcon} alt="" />
          <span>{resource.phone_num}</span>
        </a>
        {resource.address && (resource.address.address_1 || resource.address.address_2) && (
            <div>
              <img src={defaultMark} alt="" width={'16px'} />
              <span>{resource.address.address_1 || resource.address.address_2}</span>
            </div>
        )}
      </div>
    </div>
  )
}

export default ResourceItem
