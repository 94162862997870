import * as services from '../service/auth.service'
import * as types from '../constants/action.constants'
import { AUTH_MODAL, RESET_MODAL } from '../constants/action.constants'
import { codeConfirm } from '../service/auth.service'
export const googleLogin = (email) => {
  return (dispatch) => {
    return services
      .googleLogin(email)
      .then((response) => {
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('email', email)
        localStorage.setItem('accessType', response.data.access_type)
        dispatch({
          type: types.LOGIN_SUCCESS,
          access: response.data.access_type,
          email,
          token: response.data.token,
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.LOGIN_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const login = (credentials) => {
  return (dispatch) => {
    return services
      .login(credentials)
      .then((response) => {
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('id', response.data._id)
        localStorage.setItem('email', credentials.email)
        localStorage.setItem('accessType', response.data.access_type)
        localStorage.setItem('user', JSON.stringify(response.data.user))
        dispatch({
          type: types.LOGIN_SUCCESS,
          id: response.data._id,
          access: response.data.access_type,
          email: credentials.email.toLowerCase(),
          token: response.data.token,
          user: response.data.user || {},
        })
        dispatch({
          type: types.SET_LOGIN,
          payload:
            {
              ...response.data.user,
              _id: response.data._id,
              password: response.data.password,
            } || {},
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.LOGIN_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const signup = (credentials) => {
  return (dispatch) => {
    return services
      .signup({ ...credentials, password_confirmation: credentials.password })
      .then((response) => {
        return { success: true }
      })
      .catch((err) => {
        if (typeof err.response.data === 'string') {
          return { err: err.response.data || 'Something went wrong' }
        } else {
          const res = Object.values(err.response.data || {})
          const errorMessage = res[0] ? res[0][0] : 'Something went wrong'
          return { err: errorMessage }
        }
      })
  }
}

export const logout = () => {
  return (dispatch) => {
    services.logout()
    localStorage.removeItem('token')
    localStorage.removeItem('id')
    localStorage.removeItem('email')
    localStorage.removeItem('accessType')
    localStorage.removeItem('user')
    dispatch({
      type: types.LOGOUT,
    })
  }
}

export const checkPassword = (email, password) => {
  return (dispatch) => {
    return services
      .checkPassword(email, password)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.CHECK_PASSWORD,
          })
        }
        return resp.data
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.CHECK_PASSWORD_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const changePassword = (passwords, code) => {
  return async (dispatch) => {
    try {
      const codeResult = await services.codeConfirm({
        email: passwords.email,
        code: code,
      })

      const passResult = await services.changePassword(passwords)

      if (passResult.status === 200) {
        dispatch({
          type: types.CHANGE_PASSWORD,
        })
        dispatch(setAuthModal({ email: '', modal: null }))
      }
    } catch (err) {
      if (err.response) {
        let message = 'Something went wrong'
        if (typeof err.response.data === 'string') {
          message = err.response.data
        } else {
          const res = Object.values(err.response.data || {})
          const errorMessage = res[0] ? res[0][0] : 'Something went wrong'
          message = errorMessage
        }
        dispatch({
          type: types.CHANGE_PASSWORD_FAIL,
          error: message,
        })
      }
    }
  }
}

export const setPassword = (credentials) => {
  return (dispatch) => {
    return services
      .setPassword(credentials)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.SET_PASSWORD,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.SET_PASSWORD_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const sendOTP = (email, callBackFail, successCall) => {
  return (dispatch) => {
    return services
      .sendOTP(email)
      .then((resp) => {
        if (resp.status === 200) {
          successCall()
          dispatch({
            type: types.SEND_OTP,
          })
          dispatch({
            type: types.RESET_MODAL,
            payload: { email },
          })
          dispatch({
            type: types.AUTH_MODAL,
            payload: 'R',
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          callBackFail(err.response.data)
          // dispatch({
          //   type: types.SEND_OTP_FAIL,
          //   error: err.response.data,
          // })
          return Promise.reject(err.response.data)
        }
      })
  }
}

export const setAuthModal = (item) => ({
  type: types.AUTH_MODAL,
  payload: item,
})

export const resetModal = (email) => {
  return {
    type: types.RESET_MODAL,
    payload: { email },
  }
}
