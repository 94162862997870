import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import CloseIcon from '@material-ui/icons/Close';
import {
  Paper,
  Table,
  Button as MUIButton,
  TableBody,
  TableCell as MUITableCell,
  TableRow,
  TableHead,
  withStyles,
  Typography as MUITypography,
  Grid,
  Snackbar,
  IconButton
} from '@material-ui/core';
import CheckMark from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';
import parse from 'html-react-parser';
import * as businessActions from '../../actions/business.action';
import './ProgramApproval.css'

const ApproveIcon = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#245497'
  }
})(CheckMark);

const RejectIcon = withStyles({
  root: {
    color: '#fff',
    backgroundColor: '#245497'
  }
})(CancelIcon);

const Button = withStyles({
  root: {
    backgroundColor: '#245497',
    color: '#fff',
    fontSize: 12,
    width: '100px',
    '&:hover': {
      backgroundColor: '#245497',
      color: '#fff',
    }
  }
})(MUIButton);

const styles = () => ({
  successNotif: {
    backgroundColor: '#43a047'
  },
  errorNotif: {
    backgroundColor: '#d32f2f'
  }
});
class ProgramApproval extends Component {

  state={
    program: {},
    open: false,
    notifMessage: '',
    error: false
  };

  componentDidMount(){
    if(this.props.match.params.id){
      this.props.getBusiness(this.props.match.params.id).then(()=>{
        if(this.props.business.error===''){
          this.setState({program: this.props.business.business})
        }
      })
    }
  }

  handleApprove = (id) => {
    this.props.updateApproval(id, {approval_status: 'Approved'}).then(()=>{
      if(this.props.business.error===''){
        this.setState({
          // open: true,
          // notifMessage: 'This program was successfully approved.'
        })
      }
      else {
        this.setState({
          open: true,
          notifMessage: 'Something went wrong.',
          error: true
        })
      }
    });
  };

  handleClose = () => {
    this.setState({open: false, notifMessage: '', error: false})
  };

  handleReject = (id) => {
    this.props.updateApproval(id, {approval_status: 'Rejected'}).then(()=>{
      if(this.props.business.error===''){
        this.setState({
          // open: true,
          // notifMessage: 'This program was successfully rejected.'
        })
      }
      else {
        this.setState({
          open: true,
          notifMessage: 'Something went wrong.',
          error: true
        })
      }
    });
  };

  phoneNumberFormatter = (phoneNumber) => {
    if (phoneNumber){
      let phoneNumberString = phoneNumber.toString();
      phoneNumberString = phoneNumberString.substr(1, phoneNumberString.length-1);
      let match = phoneNumberString.replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      if (match) {
        return match
      }
    }
    return null;
  };

  sortDays = (timing) => {
    const sortDays = {'sunday':1, 'monday':2, 'tuesday':3, 'wednesday':4, 'thursday':6, 'friday':6, 'saturday':7};
    return timing.sort((a, b) => {
      let day1 = a.day.toLowerCase();
      let day2 = b.day.toLowerCase();
      return sortDays[day1] - sortDays[day2];
    });
  };

  render(){
    const {program, open, notifMessage, error} = this.state;
    const {classes, matches} = this.props;
    const TableCell = withStyles({
      root: {
        width: matches?'50%':'auto',
        fontSize: matches?12:14,
        wordBreak: 'break-all',
      }
    })(MUITableCell);
    const Typography = withStyles({
      root: {
        fontSize: matches?12:14,
      }
    })(MUITypography);
    return(
      <div className="outer-div">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          // autoHideDuration={4000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: {root: error?classes.errorNotif: classes.successNotif}
          }}
          message={<span id="message-id">{notifMessage}</span>}
           action={[
             <IconButton
               key="close"
               aria-label="Close"
               color="inherit"
               onClick={this.handleClose}
             >
               <CloseIcon />
             </IconButton>
          ]}
        />
        <div className="program-container">
        <div className="action-btn">
          <Button onClick={()=>this.handleApprove(program.id)} variant={'contained'}>
            Approve
            <ApproveIcon/>
          </Button>
          <Button onClick={()=>this.handleReject(program.id)} variant={'contained'}>
            Reject
            <RejectIcon/>
          </Button>
        </div>
        <Paper style={{width:'100%', margin: 'auto'}}>

          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell style={{fontSize: 16}} component={'td'} colSpan={2} align={'center'}>Program Information</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {program.email!=="" && <TableRow>
                <TableCell align={'justify'}>Business Email</TableCell>
                <TableCell align={'justify'}>{program.email}</TableCell>
              </TableRow>}

              <TableRow>
                <TableCell align={'justify'}>Business Name</TableCell>
                <TableCell align={'justify'}>{program.business_name}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align={'justify'}>Program Name</TableCell>
                <TableCell align={'justify'}>{program.program_name}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align={'justify'}>Address</TableCell>
                <TableCell align={'justify'}>
                  {program.address && program.address.address_1!==''&&program.address.address_1.concat(', ')}
                  {program.address && program.address.address_2!==''&&program.address.address_2.concat(', ')}
                  {program.address && program.address.city!==''&&program.address.city.concat(', ')}
                  {program.address && program.address.state!==''&&program.address.state.concat(', ')}
                  {program.address && program.address.postal_code!==''&&program.address.postal_code}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align={'justify'}>County</TableCell>
                <TableCell align={'justify'}>{program.county}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align={'justify'}>Program's Website</TableCell>
                <TableCell align={'justify'}>
                  <a target="_blank" rel="noopener noreferrer" href={program.program_website}>
                    {program.program_website}
                  </a>
                </TableCell>
              </TableRow>

              {program.phone_num && <TableRow>
                <TableCell align={'justify'}>Phone Number</TableCell>
                <TableCell align={'justify'}>
                  <a href={`tel:${program.phone_num}`}>
                    {this.phoneNumberFormatter(program.phone_num)}
                  </a>
                </TableCell>
              </TableRow>}

              {program.crisis_toll_free_num &&
              <TableRow>
                <TableCell align={'justify'}>CRISIS Toll Free Phone Number</TableCell>
                <TableCell align={'justify'}>
                  <a href={`tel:${program.crisis_toll_free_num}`}>
                    {this.phoneNumberFormatter(program.crisis_toll_free_num)}
                  </a>
                </TableCell>
              </TableRow>}

              <TableRow>
                <TableCell align={'justify'}>Main Category(s)</TableCell>
                <TableCell align={'justify'}>
                  {program.category && program.category.category.join(', ')}
                </TableCell>
              </TableRow>

              {program.category && program.category.sub_category.length>0 && <TableRow>
                <TableCell align={'justify'}>Subcategory(s)</TableCell>
                <TableCell align={'justify'}>
                  {program.category && program.category.sub_category.join(', ')}
                </TableCell>
              </TableRow>}

              {program.timing && <TableRow>
                <TableCell align={'justify'}>Program's Hours of Operation</TableCell>
                <TableCell align={'justify'}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                      this.sortDays(program.timing).map((sh, ind) =>{
                        const { day, full_day_open, split_hours } = sh;
                        let openTime1, closeTime1, openTime2, closeTime2, closed;
                        if (split_hours) {
                          const { shift_one, shift_two } = sh;
                          openTime1 = new Date(`${new Date().toDateString()} ${shift_one.open_time.split(':')[0]}:${shift_one.open_time.split(':')[1]}:00`).toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                          });
                          closeTime1 = new Date(`${new Date().toDateString()} ${shift_one.close_time.split(':')[0]}:${shift_one.close_time.split(':')[1]}:00`).toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                          });
                          openTime2 = new Date(`${new Date().toDateString()} ${shift_two.open_time.split(':')[0]}:${shift_two.open_time.split(':')[1]}:00`).toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                          });
                          closeTime2 = new Date(`${new Date().toDateString()} ${shift_two.close_time.split(':')[0]}:${shift_two.close_time.split(':')[1]}:00`).toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                          });
                        } else if (sh.open_time && sh.close_time) {
                          const { open_time, close_time } = sh;
                          openTime1 = new Date(`${new Date().toDateString()} ${open_time.split(':')[0]}:${open_time.split(':')[1]}:00`).toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                          });

                          closeTime1 = new Date(`${new Date().toDateString()} ${close_time.split(':')[0]}:${close_time.split(':')[1]}:00`).toLocaleString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                          });
                        } else if (full_day_open) {
                          openTime1 = 'Open 24 hours'
                        } else {
                          closed = true;
                        }
                        return(
                          <Grid key={ind} container direction="row" justify="flex-start" alignItems="center">
                            {!closed?<div style={{display:'flex'}}>
                              <Grid>
                                <Typography style={{width:40}}>
                                          <span>
                                            {day.substring(0,3)}
                                          </span>
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography style={{width:'auto'}}>
                                  <span>{openTime1 && openTime1} {closeTime1 && `- ${closeTime1}`} {openTime2 && 'and'} {openTime2 && openTime2} {closeTime2 && `- ${closeTime2}`}</span>
                                </Typography>
                              </Grid>
                            </div>:<div style={{display:'flex'}}>
                              <Grid>
                                <Typography style={{width:40}}>
                                          <span>
                                            {day.substring(0,3)}
                                          </span>
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography style={{width:'auto'}}>
                                  <span>Closed on this day</span>
                                </Typography>
                              </Grid>
                            </div>}
                          </Grid>
                        )})
                    }
                  </div>
                </TableCell>
              </TableRow>}

              <TableRow>
                <TableCell align={'justify'}>Eligibility</TableCell>
                <TableCell align={'justify'}>{program.program_eligibility}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align={'justify'}>Language(s)</TableCell>
                <TableCell align={'justify'}>{program.languages && program.languages.join(', ')}</TableCell>
              </TableRow>

              {program.short_description!==''&&<TableRow>
                <TableCell align={'justify'}>Short Description</TableCell>
                <TableCell align={'justify'}>{parse(program.short_description)}</TableCell>
              </TableRow>}

              {program.long_description!==''&&<TableRow>
                <TableCell align={'justify'}>Additional Information</TableCell>
                <TableCell align={'justify'}>{parse(program.long_description)}</TableCell>
              </TableRow>}

              {program.services!==''&&<TableRow>
                <TableCell align={'justify'}>Services Provided</TableCell>
                <TableCell align={'justify'}>{program.services}</TableCell>
              </TableRow>}

            </TableBody>
          </Table>
        </Paper>
      </div>
      </div>
    )
  }
}

const mapState = state => {
  return {
    business: state.business
  }
};

const mapDispatch = dispatch => {
  return {
    getBusiness: bindActionCreators(businessActions.getBusinessById, dispatch),
    updateApproval: bindActionCreators(businessActions.updateApproval, dispatch)
  }
};
export default connect(mapState, mapDispatch)(withStyles(styles)(ProgramApproval));