import baseService from './baseService';

export const addQuestion = question => {
  return baseService.post(`/add/question`, question);
};

export const approveQuestion = (id) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.put(`/admin/approve/question`,{id}, config);
};

export const removeQuestion = (question) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.put(`/admin/remove/question`,question, config);
};

export const postAnswer = (answer) => {
  const config = {
    'headers': {
      'authorization': localStorage.getItem('token')}
  };
  return baseService.put('/admin/answer/question', answer, config);
};