export const GET_BUSINESSES_SUCCESS = 'GET_BUSINESSES_SUCCESS'
export const GET_BUSINESSES_FAIL = 'GET_BUSINESSES_FAIL'
export const GET_BUSINESS_BY_CAT_SUCCESS = 'GET_BUSINESS_BY_CAT_SUCCESS'
export const GET_BUSINESS_BY_CAT_FAIL = 'GET_BUSINESS_BY_CAT_FAIL'
export const GET_BUSINESS_BY_ID = 'GET_BUSINESS_BY_ID'
export const GET_BUSINESS_BY_ID_FAIL = 'GET_BUSINESS_BY_ID_FAIL'
export const GET_BUSINESS_WITH_FILTERS = 'GET_BUSINESS_WITH_FILTERS'
export const GET_BUSINESS_WITH_FILTERS_FAIL = 'GET_BUSINESS_WITH_FILTERS_FAIL'
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL'
export const LOADING_BUSINESS = 'LOADING_BUSINESS'
export const UPLOAD_IMAGES = 'UPLOAD_IMAGES'
export const UPLOAD_IMAGES_FAIL = 'UPLOAD_IMAGES_FAIL'
export const SEARCH_BUSINESS_SUCCESS = 'SEARCH_BUSINESS_SUCCESS'
export const SEARCH_BUSINESS_FAIL = 'SEARCH_BUSINESS_FAIL'
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES'
export const GET_ALL_CATEGORIES_FAIL = 'GET_ALL_CATEGORIES_FAIL'
export const SAVE_BUSINESS = 'SAVE_BUSINESS'
export const SAVE_BUSINESS_FAIL = 'SAVE_BUSINESS_FAIL'
export const UPDATE_APPROVAL = 'UPDATE_APPROVAL'
export const UPDATE_APPROVAL_FAIL = 'UPDATE_APPROVAL_FAIL'
export const GET_ALL_BUSINESS = 'GET_ALL_BUSINESS'
export const GET_ALL_BUSINESS_FAIL = 'GET_ALL_BUSINESS_FAIL'
export const GET_UNFILTERED_BUSINESS = 'GET_UNFILTERED_BUSINESS'
export const GET_UNFILTERED_BUSINESS_FAIL = 'GET_UNFILTERED_BUSINESS_FAIL'
export const UPDATE_BUSINESS = 'UPDATE_BUSINESS'
export const UPDATE_BUSINESS_FAIL = 'UPDATE_BUSINESS_FAIL'
export const GET_DISCLAIMER = 'GET_DISCLAIMER'
export const GET_DISCLAIMER_FAIL = 'GET_DISCLAIMER_FAIL'
export const UPDATE_DISCLAIMER = 'UPDATE_DISCLAIMER'
export const UPDATE_DISCLAIMER_FAIL = 'UPDATE_DISCLAIMER_FAIL'
export const APPROVE_CLAIM = 'APPROVE_CLAIM'
export const APPROVE_CLAIM_FAIL = 'APPROVE_CLAIM_FAIL'
export const REORDER_IMAGES = 'REORDER_IMAGES'
export const REORDER_IMAGES_FAIL = 'REORDER_IMAGES_FAIL'
export const PAGINATED_DATA = 'PAGINATED_DATA'
export const SEND_PASSWORD_SETUP_EMAIL = 'SEND_PASSWORD_SETUP_EMAIL'

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'
export const PAGINATED_USER_DATA = 'PAGINATED_USER_DATA'
export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
export const GET_UNFILTERED_USER = 'GET_UNFILTERED_USER'
export const GET_UNFILTERED_USER_FAIL = 'GET_UNFILTERED_USER_FAIL'
export const LOADING_USER = 'LOADING_USER'

export const GET_ALERTS = 'GET_ALERTS'
export const GET_ALERTS_FAIL = 'GET_ALERTS_FAIL'
export const GET_ALERTS_BUSINESS = 'GET_ALERTS_BUSINESS'
export const GET_ALERTS_BUSINESS_FAIL = 'G ET_ALERTS_BUSINESS_FAIL'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const REMOVE_ALERT_FAIL = 'REMOVE_ALERT_FAIL'
export const CLEAR_ALERT = 'CLEAR_ALERT'
export const CLEAR_ALERT_FAIL = 'CLEAR_ALERT_FAIL'
export const ADD_ALERT = 'ADD_ALERT'
export const ADD_ALERT_FAIL = 'ADD_ALERT_FAIL'
export const GET_NOTIFICATION_ALERTS = 'GET_NOTIFICATION_ALERTS'
export const GET_NOTIFICATION_ALERTS_FAIL = 'GET_NOTIFICATION_ALERTS_FAIL'
export const LOADING_ALERT = 'LOADING_ALERT'

export const APPROVE_IMAGE = 'APPROVE_IMAGE'
export const APPROVE_IMAGE_FAIL = 'APPROVE_IMAGE_FAIL'
export const DELETE_BUSINESS = 'DELETE_BUSINESS'
export const DELETE_BUSINESS_FAIL = 'DELETE_BUSINESS_FAIL'
export const REMOVE_IMAGE = 'REMOVE_IMAGE'
export const REMOVE_IMAGE_FAIL = 'REMOVE_IMAGE_FAIL'

export const ADD_REVIEW = 'ADD_REVIEW'
export const ADD_REVIEW_FAIL = 'ADD_REVIEW_FAIL'
export const APPROVE_REVIEW = 'APPROVE_REVIEW'
export const APPROVE_REVIEW_FAIL = 'APPROVE_REVIEW_FAIL'
export const REMOVE_REVIEW = 'REMOVE_REVIEW'
export const REMOVE_REVIEW_FAIL = 'REMOVE_REVIEW_FAIL'
export const POST_REPLY = 'POST_REPLY'
export const POST_REPLY_FAIL = 'POST_REPLY_FAIL'
export const REMOVE_FLYER = 'REMOVE_FLYER'
export const REMOVE_FLYER_FAIL = 'REMOVE_FLYER_FAIL'

export const ADD_QUESTION = 'ADD_QUESTION'
export const ADD_QUESTION_FAIL = 'ADD_QUESTION_FAIL'
export const APPROVE_QUESTION = 'APPROVE_QUESTION'
export const APPROVE_QUESTION_FAIL = 'APPROVE_QUESTION_FAIL'
export const REMOVE_QUESTION = 'REMOVE_QUESTION'
export const REMOVE_QUESTION_FAIL = 'REMOVE_QUESTION_FAIL'
export const POST_ANSWER = 'POST_ANSWER'
export const POST_ANSWER_FAIL = 'POST_ANSWER_FAIL'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const CHECK_PASSWORD = 'CHECK_PASSWORD'
export const CHECK_PASSWORD_FAIL = 'CHECK_PASSWORD_FAIL'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'
export const SET_PASSWORD = 'SET_PASSWORD'
export const SET_PASSWORD_FAIL = 'SET_PASSWORD_FAIL'
export const SEND_OTP = 'SEND_OTP'
export const SEND_OTP_FAIL = 'SEND_OTP_FAIL'

export const SEND_CONTACT_FORM = 'SEND_CONTACT_FORM'
export const SEND_CONTACT_FORM_FAIL = 'SEND_CONTACT_FORM_FAIL'

export const FOCUSED_ITEM = 'FOCUSED_ITEM'
export const OPEN_RESOURCE = 'OPEN_RESOURCE'
export const CHANGE_VIEW = 'CHANGE_VIEW'
export const MENU_TOGGLE = 'MENU_TOGGLE'
export const SET_BUSINESSES = 'SET_BUSINESSES'
export const LOADED_BUSINESS = 'LOADED_BUSINESS'
export const COPY_BUSINESS = 'COPY_BUSINESS'
export const RESET_MODAL = 'RESET_MODAL'
export const AUTH_MODAL = 'AUTH_MODAL'
export const SET_LOGIN = 'SET_LOGIN'
