import * as services from '../service/category.service';
import * as types from '../constants/action.constants';


export const getAllCategories = () => {
  return (dispatch) => {
    return services.getAllCategories().then(resp => {
      if (resp.status === 200) {
        dispatch({
          type: types.GET_ALL_CATEGORIES,
          categories: resp.data
        });
        return resp.data;
      }
    }).catch(err => {
      if (err.response) {
        dispatch({
          type: types.GET_ALL_CATEGORIES_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const getCategories = (category) => {
    return (dispatch) => {
        return services.getCategories(category).then(resp => {
            if (resp.status === 200) {
                dispatch({
                    type: types.GET_CATEGORIES,
                    categories: resp.data
                });
              return resp.data;
            }
        }).catch(err => {
            if (err.response) {
                dispatch({
                    type: types.GET_CATEGORIES_FAIL,
                    error: err.response.data
                })
            }
        })
    }
};