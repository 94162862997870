import * as types from '../constants/action.constants'
import { initState } from './initialState'

export default (state = initState.business, action) => {
  switch (action.type) {
    case types.GET_BUSINESSES_SUCCESS:
      return {
        ...state,
        businesses: [...action.businesses],
        successfullyFetchBusiness: true,
        loadingBusiness: false,
      }
    case types.GET_BUSINESSES_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.GET_BUSINESS_BY_CAT_SUCCESS: {
      const { categoryWiseBusiness } = state
      const { category, businesses } = action.payload
      if (
        !Object.keys(categoryWiseBusiness).includes(action.payload.category)
      ) {
        categoryWiseBusiness[category] = []
      }
      categoryWiseBusiness[category] = [...businesses]
      return {
        ...state,
        categoryWiseBusiness,
        loadingBusiness: false,
      }
    }
    case types.GET_BUSINESS_BY_CAT_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.GET_BUSINESS_BY_ID:
      return {
        ...state,
        business: { ...action.business },
      }
    case types.GET_BUSINESS_BY_ID_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.GET_BUSINESS_WITH_FILTERS:
      return {
        ...state,
        filteredBusiness: [...action.businesses],
        loadingBusiness: false,
        isFiltered: true,
      }
    case types.GET_BUSINESS_WITH_FILTERS_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
        isFiltered: false,
      })

    case types.LOADING_BUSINESS:
      return {
        ...state,
        loadingBusiness: true,
      }
    case types.LOADED_BUSINESS:
      return {
        ...state,
        loadingBusiness: false,
      }

    case types.SEARCH_BUSINESS_SUCCESS:
      return {
        ...state,
        filteredBusiness: [...action.businesses],
        loadingBusiness: false,
      }
    case types.SEARCH_BUSINESS_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.SAVE_BUSINESS:
      return {
        ...state,
        businesses: [action.business, ...state.businesses],
        allBusinesses: [action.business, ...state.allBusinesses],
        loadingBusiness: false,
        error: '',
      }

    case types.COPY_BUSINESS:
      return {
        ...state,
        businesses: [action.business, ...state.businesses],
        allBusinesses: [action.business, ...state.allBusinesses],
        loadingBusiness: false,
        error: '',
      }
    case types.SAVE_BUSINESS_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.UPDATE_APPROVAL:
      let { business } = state
      business.approval_status = action.approval_status
      return { ...state, business: { ...business }, error: '' }
    case types.UPDATE_APPROVAL_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.GET_ALL_BUSINESS:
      return {
        ...state,
        businesses: [...action.businesses],
        loadingBusiness: false,
      }
    case types.GET_ALL_BUSINESS_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.GET_UNFILTERED_BUSINESS:
      return {
        ...state,
        allBusinesses: [...action.businesses],
        loadingBusiness: false,
      }
    case types.GET_UNFILTERED_BUSINESS_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.DELETE_BUSINESS:
      let businesses_del = state.businesses.filter(
        (business) => business._id !== action.id
      )
      let allBusinesses_del = state.allBusinesses.filter(
        (business) => business._id !== action.id
      )
      return {
        ...state,
        businesses: [...businesses_del],
        allBusinesses: allBusinesses_del,
        loadingBusiness: false,
        error: '',
        isDelete: true,
      }
    case types.DELETE_BUSINESS_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.APPROVE_IMAGE:
      let business_app_img = state.business
      let idx = business_app_img.gallery_images.findIndex(
        (img) => img._id === action.key
      )
      business_app_img.gallery_images[idx].approved = true
      return { ...state, business: { ...business_app_img }, error: '' }
    case types.APPROVE_IMAGE_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.REMOVE_IMAGE:
      let business_rem_img = state.business
      let i = business_rem_img.gallery_images.findIndex(
        (img) => img._id === action.key
      )
      business_rem_img.gallery_images.splice(i, 1)
      return { ...state, business: { ...business_rem_img }, error: '' }
    case types.REMOVE_IMAGE_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        loadingBusiness: false,
      })

    case types.UPLOAD_IMAGES:
      return {
        ...state,
        business: { ...action.business },
        loadingBusiness: false,
        error: '',
      }
    case types.UPLOAD_IMAGES_FAIL:
      return { ...state, error: action.error, loadingBusiness: false }

    case types.APPROVE_REVIEW:
      let business_rev_app = state.business
      let ind = business_rev_app.reviews.findIndex(
        (review) => review._id === action.id
      )
      business_rev_app.reviews[ind].status = 'Approved'
      return { ...state, business: { ...business_rev_app }, error: '' }
    case types.APPROVE_REVIEW_FAIL:
      return { ...state, error: action.error, loadingBusiness: false }

    case types.REMOVE_REVIEW:
      let business_rev_rem = state.business
      let k = business_rev_rem.reviews.findIndex(
        (review) => review._id === action.review.reviewId
      )
      business_rev_rem.reviews.splice(k, 1)
      return { ...state, business: { ...business_rev_rem } }
    case types.REMOVE_REVIEW_FAIL:
      return { ...state, error: action.error, loadingBusiness: false }

    case types.REMOVE_FLYER:
      let business_flyer_rem = state.business
      business_flyer_rem.pdf_file = ''
      return { ...state, business: { ...business_flyer_rem } }
    case types.REMOVE_FLYER_FAIL:
      return { ...state, error: action.error, loadingBusiness: false }

    case types.POST_REPLY:
      let business_post_rep = state.business
      let j = business_post_rep.reviews.findIndex(
        (review) => review._id === action.review._id
      )
      business_post_rep.reviews[j].reply = { ...action.review.reply }
      return { ...state, business: { ...business_post_rep } }
    case types.POST_REPLY_FAIL:
      return { ...state, error: action.error, loadingBusiness: false }

    case types.ADD_REVIEW:
      return { ...state, error: '' }
    case types.ADD_REVIEW_FAIL:
      return { ...state, error: action.error }

    case types.ADD_QUESTION:
      return { ...state, error: '' }
    case types.ADD_QUESTION_FAIL:
      return { ...state, error: action.error }

    case types.APPROVE_QUESTION:
      let business_que_app = state.business
      let a = business_que_app.questions.findIndex(
        (question) => question._id === action.id
      )
      business_que_app.questions[a].status = 'Approved'
      return { ...state, business: { ...business_que_app }, error: '' }
    case types.APPROVE_QUESTION_FAIL:
      return { ...state, error: action.error, loadingBusiness: false }

    case types.REMOVE_QUESTION:
      let business_que_rem = state.business
      let b = business_que_rem.questions.findIndex(
        (question) => question._id === action.question.questionId
      )
      business_que_rem.questions.splice(b, 1)
      return { ...state, business: { ...business_que_rem }, error: '' }
    case types.REMOVE_QUESTION_FAIL:
      return { ...state, error: action.error, loadingBusiness: false }

    case types.POST_ANSWER:
      let business_post_ans = state.business
      let c = business_post_ans.questions.findIndex(
        (question) => question._id === action.question._id
      )
      business_post_ans.questions[c].answer = { ...action.question.answer }
      return { ...state, business: { ...business_post_ans }, error: '' }
    case types.POST_ANSWER_FAIL:
      return { ...state, error: action.error, loadingBusiness: false }

    case types.UPDATE_BUSINESS:
      let newBusiness = { ...action.business }
      return {
        ...state,
        business: { ...newBusiness },
        loadingBusiness: false,
        error: '',
      }
    case types.UPDATE_BUSINESS_FAIL:
      return { ...state, error: action.error, loadingBusiness: false }

    case types.GET_DISCLAIMER:
      return { ...state, disclaimer: { ...action.disclaimer }, error: '' }
    case types.GET_DISCLAIMER_FAIL:
      return { ...state, error: action.error }

    case types.UPDATE_DISCLAIMER:
      return { ...state, disclaimer: { ...action.disclaimer }, error: '' }
    case types.UPDATE_DISCLAIMER_FAIL:
      return { ...state, error: action.error }

    case types.REORDER_IMAGES:
      let reorder_business = state.business
      reorder_business.gallery_images = [...action.gallery_images]
      return { ...state, business: { ...reorder_business }, error: '' }
    case types.REORDER_IMAGES_FAIL:
      return { ...state, error: action.error }

    case types.APPROVE_CLAIM:
      return { ...state, business: { ...state.business, email: action.email } }
    case types.APPROVE_CLAIM_FAIL:
      return { ...state, error: action.error }

    case types.PAGINATED_DATA:
      return {
        ...state,
        paginatedData: {
          pageNum: action.page_no,
          order: action.order,
          sortBy: action.field_name,
          search: action.search,
        },
      }

    case types.SEND_CONTACT_FORM:
      return { ...state, error: '' }
    case types.SEND_CONTACT_FORM_FAIL:
      return { ...state, error: action.error, loadingBusiness: false }

    case types.SEND_PASSWORD_SETUP_EMAIL:
      return { ...state, error: '' }
    default:
      return state
  }
}
