import React, { useEffect } from 'react'
import './mapListSwitch.css'
import { useDispatch, useSelector } from 'react-redux'
import {changeView, openedItem} from '../../actions/common.action'

export const MapListSwitch = () => {
  const isMapView = useSelector((state) => state.common.viewMap)
  const dispatch = useDispatch()

  const handleClick = (isMap) => () => {
    dispatch(changeView(isMap))
      dispatch(openedItem(null))
  }

  useEffect(() => {
    if (isMapView) {
      document.body.classList.add('open_map')
    } else {
      document.body.classList.remove('open_map')
    }
  }, [isMapView])
  return (
    <div className="view-switch">
      <button
        className={isMapView ? '' : 'active-switch'}
        onClick={handleClick(false)}
      >
        List View
      </button>
      <button
        className={isMapView ? 'active-switch' : ''}
        onClick={handleClick(true)}
      >
        Map View
      </button>
    </div>
  )
}
