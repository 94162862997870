import React from 'react'
import { Modal, withStyles, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import './imagePopup.css'

const styles = () => ({
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '30% 4.5% 6% 6%',
  },
  modal: {
    margin: 'auto',
    width: '50%',
    height: 'auto',
    padding: '6%',
  },
})

const ImagePopup = ({
  open,
  url,
  onClose,
  matches,
  classes,
  alert,
  approveImage,
  removeImage,
  businessId,
  showApproveButton
}) => {
  // console.log('alert popup image', alert)
  return (
    <Modal
      open={open}
      onClose={onClose}
      classes={{ root: matches ? classes.mobileModal : classes.modal }}
    >
      <div className="main-modal-container">
        <IconButton onClick={onClose} style={{ float: 'right' }}>
          <CloseIcon />
        </IconButton>
        <div className="popup-image-container">
          <img className="popup-image" src={url} alt="" />
        </div>
        <div style={{ padding: '10px', textAlign: 'center' }}>
          {alert && !alert.approved && showApproveButton !== false? (
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '10px' }}
              onClick={async () => {
                // console.log('alert inside image popup', alert)
                if (alert && alert['image']) {
                  await approveImage(
                    { key: alert['image']['_id'] },
                    alert['business']['id']
                  )
                  onClose()
                } else if (alert && alert['url'] && businessId) {
                  await approveImage({ key: alert['_id'] }, businessId)
                  onClose()
                }
              }}
            >
              Approve
            </Button>
          ) : null}
          {alert && !alert.approved  && showApproveButton !== false ? (
            <Button
              onClick={async () => {
                if (alert && alert['image']) {
                  await removeImage(
                    { key: alert['image']['_id'] },
                    alert['business']['id']
                  )
                  onClose()
                } else if (alert && alert['url'] && businessId) {
                  await removeImage({ key: alert['image']['_id'] }, businessId)
                  onClose()
                }
              }}
              variant="contained"
              color="secondary"
            >
              Reject
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default withStyles(styles)(ImagePopup)
