import React, {Component} from 'react';
import {Modal, Button as MUIButton, withStyles, InputBase, Collapse, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import * as questionActions from '../../../actions/question.action';
import './QuestionPopup.css';

const styles = () => ({
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '40% 6% 6% 6%',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    padding: '6%',
    marginLeft: '420px',
  },
});

const Button = withStyles({
  root: {
    background: "#245497",
    color: '#fff',
    width: 'fit-content',
    padding: '1%',
    margin: '1% 1% 0 0',
    '&:hover': {
      background: "#224684",
    }
  }
})(MUIButton);

const Input = withStyles({
  root: {
    border: '1px solid #d2d2d2',
    boxShadow: 'inset 0 0 1px #000',
    padding: '1%',
    alignItems: 'flex-start',
    height: 69,
    overflowY: 'scroll'
  }
})(InputBase);

class QuestionPopup extends Component {
  state={
    question: {},
    answer: "",
    showAnswer: false,
    editAnswer: false
  };

  componentDidMount(){
    if(this.props.question){
      this.setState({question: this.props.question});
      if(this.props.question.answer)
        this.setState({answer: this.props.question.answer});
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    if(this.props.question._id !== newProps.question._id){
      this.setState({question: newProps.question});
      if(newProps.question.answer)
        this.setState({answer: newProps.question.answer.answer});
      else
        this.setState({answer: ""});
    }
  }

  toggleAnswer = () => {
    this.setState(state=>({showAnswer: !state.showAnswer}));
  };

  handleAnswer = (e) => {
    this.setState({answer: e.target.value});
  };

  postAnswer = () => {
    this.props.questionActions.postAnswer({
      answer: this.state.answer,
      email: this.props.auth.email,
      id: this.props.question._id
    }).then(question => {
      // console.log("question then",question)
      this.setState({question, answer: question.answer.answer, editAnswer: false})
    })
  };

  handleClose = () => {
    this.setState({
      showAnswer: false,
      editAnswer: false
    });
    this.props.onClose();
  };

  toggleEdit = () => {
    this.setState(state=>({
      editAnswer: !state.editAnswer,
      answer: this.props.question.answer.answer
    }));
  };

  render(){
    const {open, matches, classes} = this.props;
    const {showAnswer, answer, editAnswer, question} = this.state;
    return(
      <Modal open={open} onClose={this.handleClose} classes={{root: matches?classes.mobileModal:classes.modal + " modal-main-div" }}>
        <div className="main-modal">
          <IconButton onClick={this.handleClose} style={{float:'right'}}>
            <CloseIcon/>
          </IconButton>
          <div className="question-modal">
            <div className="question-sub-container">
              <div className="question-text">
                <p align="justify">{question.question}</p>
              </div>
            </div>
            <Button onClick={this.toggleAnswer}>
              {showAnswer
                ?"Hide"
                :(question.answer)?"Show Answer":"Answer Question"}
            </Button>
            <Collapse in={showAnswer}>
              <div className="reply-container">
                {question.answer && !editAnswer
                  ? <div className="question-sub-container">
                    <div className="answer-user">
                      {question.answer.user.email}
                    </div>
                    <div className="question-text">
                      <p align="justify">{answer}</p>
                    </div>
                  </div>
                  :<Input type="text" value={answer} onChange={this.handleAnswer} multiline={true}/>
                }
                {!question.answer?<Button onClick={this.postAnswer}>Submit</Button>
                  :!editAnswer
                    ?<Button onClick={this.toggleEdit}>Edit</Button>
                    : <div className="answer-actions">
                      <Button onClick={this.postAnswer}>Update</Button>
                      <Button onClick={this.toggleEdit}>Cancel</Button>
                    </div>}
              </div>
            </Collapse>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapDispatch = (dispatch) => ({
  questionActions: bindActionCreators(questionActions, dispatch)
});

export default connect(null, mapDispatch)(withStyles(styles)(QuestionPopup));
