import React, { Component } from 'react'
import {
  Modal,
  withStyles,
  InputBase,
  Button as MUIButton,
  IconButton,
  Snackbar,
} from '@material-ui/core'
import { CancelRounded, Close, Error } from '@material-ui/icons'
import { connect } from 'react-redux'
import * as businessActions from '../../actions/business.action'
import { bindActionCreators } from 'redux'

import './ContactForm.css'

const Input = withStyles({
  root: {
    border: '1px solid #d2d2d2',
    boxShadow: 'inset 0 0 1px #000',
    padding: '0 1%',
  },
})(InputBase)

const Button = withStyles({
  root: {
    background: '#245497',
    color: '#fff',
    width: 'fit-content',
    padding: '1%',
    marginTop: '1%',
    '&:hover': {
      background: '#224684',
    },
  },
})(MUIButton)

const styles = () => ({
  mobileModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    marginLeft: '420px',
    padding: '6%',
  },
  reviewText: {
    minHeight: 69,
    maxHeight: 150,
    overflowY: 'scroll',
    alignItems: 'flex-start',
    padding: '1%',
  },
  successNotif: {
    backgroundColor: '#43a047',
  },
  errorNotif: {
    backgroundColor: '#d32f2f',
  },
})

class ContactForm extends Component {
  state = {
    email: '',
    program_name: '',
    request: '',
    valid: true,
    blankError: { email: false, program_name: false, request: false },
    notification: false,
    message: '',
  }

  componentDidMount() {
    // console.log("this.state",this.state,this.props)
    if (this.props.auth.token) {
      this.setState({
        email: this.props.auth.email || '',
      })
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {}

  checkValidMail = () => {
    let { email, valid } = this.state
    if (email !== '') {
      valid = Boolean(
        email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      )
      this.setState({ valid })
    } else {
      this.handleBlankError('email')
    }
  }

  handleBlankError = (name) => {
    this.setState((state) => ({
      blankError: { ...state.blankError, [name]: true },
    }))
  }

  submitReview = async () => {
    await this.checkValidMail()
    let { valid, program_name, request } = this.state
    let { businessActions, business } = this.props
    if (program_name === '') {
      await this.handleBlankError('program_name')
    }
    if (request === '') {
      await this.handleBlankError('request')
    }
    const { blankError } = this.state
    if (
      valid &&
      !blankError.email &&
      !blankError.program_name &&
      !blankError.request
    ) {
      businessActions
        .sendContactForm({
          email: this.state.email,
          program_name: this.state.program_name,
          req: this.state.request,
          businessId: business && business.business && business.business._id,
        })
        .then(() => {
          if (this.props.business.error === '') {
            this.setState({
              email: '',
              program_name: '',
              request: '',
              valid: true,
              blankError: { email: false, program_name: false, request: false },
            })
            // this.openNotification("Thank you for your submission", true);
            this.handleClose()
          } else {
            this.openNotification('Something went wrong', false)
          }
        })
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target
    this.setState((state) => {
      if (name === 'email') {
        return {
          email: value,
          blankError: { ...state.blankError, email: false },
          valid: true,
        }
      } else {
        return {
          [name]: value,
          blankError: { ...state.blankError, [name]: false },
        }
      }
    })
  }

  toggleReviews = () => {
    this.setState((state) => ({ addReview: !state.addReview }))
  }

  handleClose = () => {
    this.setState(
      {
        email: this.props.auth.email || '',
        program_name: '',
        request: '',
        valid: true,
        blankError: { email: false, program_name: false, request: false },
      },
      () => {
        this.props.onClose()
      }
    )
  }

  openNotification = (message, success) => {
    this.setState({
      notification: true,
      message: message,
      success: success,
    })
  }

  closeNotification = () => {
    this.setState({
      notification: false,
      message: '',
    })
  }

  render() {
    const { open, matches, classes } = this.props
    const {
      email,
      program_name,
      request,
      valid,
      blankError,
      success,
      notification,
      message,
    } = this.state

    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={3000}
          open={notification}
          onClose={this.closeNotification}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
              root: success ? classes.successNotif : classes.errorNotif,
            },
          }}
          message={message}
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.closeNotification}
            >
              <CancelRounded />
            </IconButton>
          }
        />
        <Modal
          open={open}
          classes={{
            root: matches
              ? classes.mobileModal
              : classes.modal + ' modal-main-div',
          }}
        >
          <div className="main-contactform-container">
            <div className="main-modal">
              <div className={'modal-title'}>
                <h2 id="modal-title">
                  Suggest Edit
                  {/* Suggest an Edit */}
                </h2>
              </div>
              <div className="close-icon">
                <IconButton onClick={this.handleClose}>
                  <Close />
                </IconButton>
              </div>
              <div className="contactform-container">
                <div className="field-container">
                  <label>Your Name</label>
                  <Input
                    placeholder={'What is your name?'}
                    type="text"
                    onChange={this.handleChange}
                    name="program_name"
                    value={program_name}
                  />
                  {blankError.program_name && (
                    <div className={'error'}>
                      <Error style={{ color: 'red', fontSize: 15 }} />
                      <p className="failed">Please enter the program name.</p>
                    </div>
                  )}
                </div>
                <div className="field-container">
                  <label>Your Email Address</label>
                  <Input
                    placeholder={'hello@yourmail.com'}
                    onBlur={this.checkValidMail}
                    type="email"
                    onChange={this.handleChange}
                    name="email"
                    value={email}
                  />
                  {!valid && (
                    <div style={{ display: 'flex' }}>
                      <Error style={{ color: 'red', fontSize: 15 }} />
                      <p className="failed">
                        The email address you entered is not valid.
                      </p>
                    </div>
                  )}
                  {blankError.email && (
                    <p className="failed">Please enter your email address.</p>
                  )}
                </div>
                <div className="field-container">
                  <label>Request</label>
                  <Input
                    placeholder={'I would like to suggest...'}
                    classes={{ root: classes.reviewText }}
                    type="text"
                    onChange={this.handleChange}
                    name="request"
                    value={request}
                    multiline
                  />
                  {blankError.request && (
                    <div className={'error'}>
                      <Error style={{ color: 'red', fontSize: 15 }} />
                      <p className="failed">Please enter your request.</p>
                    </div>
                  )}
                </div>
                <Button onClick={this.submitReview}>Submit</Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

const mapState = (state) => ({
  auth: state.auth,
  business: state.business,
})

const mapDispatch = (dispatch) => ({
  businessActions: bindActionCreators(businessActions, dispatch),
})

export default connect(mapState, mapDispatch)(withStyles(styles)(ContactForm))
