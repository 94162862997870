import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Prompt, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Geocode from 'react-geocode'
import {
  TextField as MUITextField,
  Button as MUIButton,
  Snackbar,
  withStyles,
  Checkbox as MUICheckbox,
  FormControlLabel,
  Select,
  InputAdornment,
  IconButton,
  MenuItem,
  FormControl,
  OutlinedInput,
  Collapse,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  AppBar,
  Tabs,
  Tab,
  Grid,
  Typography,
  Paper as MUIPaper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  InputLabel,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CancelCircle from '@material-ui/icons/CancelRounded'
import { DirectionsRounded, Info } from '@material-ui/icons/'
import DragIcon from '@material-ui/icons/DragHandleRounded'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import SwipeableViews from 'react-swipeable-views'
import { UsaStates } from 'usa-states'
import { infoIcon } from '../../../constants/imagePath'
import ProgramTiming from 'components/ProgramForm/ProgramTiming/ProgramTiming'
import { getAllCategories } from '../../../actions/category.action.js'
import { updateBusiness, approveClaim } from '../../../actions/business.action'
import FieldPopup from '../../FieldPopup/FieldPopup'
import './ProgramInfo.css'
import { findFlagUrlByCountryName } from 'country-flags-svg'
import Reorder, { reorder } from 'react-reorder'
import * as businessActions from '../../../actions/business.action'
import * as reviewActions from '../../../actions/review.action'
import * as questionActions from '../../../actions/question.action'
import * as userActions from '../../../actions/user.action'
import ImagePopup from '../ImagePopup/ImagePopup'
import ReviewPopup from '../ReviewPopup/ReviewPopup'
import QuestionPopup from '../QuestionPopup/QuestionPopup'
import EditsPopup from '../EditsPopup/EditsPopup'
import _ from 'lodash'
import ReactQuill from 'react-quill'
import HealthIcon from '../../../assets/images/health_icon.png'
import FoodIcon from '../../../assets/images/food_icon.png'
import HousingIcon from '../../../assets/images/house_icon.png'
import OthersIcon from '../../../assets/images/other_icon.png'
import {
  faArrowLeft,
  faSave,
  faSearch,
  faDirections,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import moment from 'moment'
import { GOOGLE_KEY } from '../../../utils/Const'
import { getAllUsers } from '../../../service/user.services'

const categoryIconArr = [HealthIcon, FoodIcon, HousingIcon, OthersIcon]
const Paper = withStyles({
  root: {
    padding: '1%',
    width: '100%',
    marginTop: '2%',
    borderRadius: 0,
    marginBottom: '4px',
  },
})(MUIPaper)

const Checkbox = withStyles({
  root: {
    '&$checked': {
      color: '#245497',
    },
  },
  checked: {},
})(MUICheckbox)

const TextField = withStyles({
  root: {
    marginTop: '2%',
  },
})(MUITextField)

const RemoveIcon = withStyles({
  root: {
    color: '#c70b1b',
  },
})(RemoveCircle)

const styles = () => ({
  pc: {
    width: '100%',
    height: '45px',
  },
  phone: {
    width: '100%',
    height: 'auto',
  },
  successNotif: {
    backgroundColor: '#43a047',
  },
  errorNotif: {
    backgroundColor: '#d32f2f',
  },
  pcbutton: {
    backgroundColor: '#43a047',
    width: '25%',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#3b8e3f',
      color: 'white',
    },
  },
  categoryHeight: {
    maxHeight: 400,
    overflowY: 'auto',
  },
  addLogo: {
    color: '#000',
    paddingRight: '5px',
  },
})

const Button = withStyles({
  root: {
    background: '#fff',
    width: '100%',
    height: '47px',
    '&:hover': {
      background: '#fff',
      color: '#245497',
      borderColor: '#245497',
    },
  },
})(MUIButton)

const AntTab = withStyles({
  root: {
    minWidth: 72,
    color: 'black',
    flex: 1,
    '&:hover': {},
    '&$selected': {
      color: 'white',
      backgroundColor: '#1fb400',
    },
    '&:focus': {},
  },
  labelContainer: {
    padding: '6px 0px',
    fontSize: '12px',
  },
  selected: {},
})((props) => (
  <Tab
    {...props}
    className={props.activeTab === props.index ? 'activeTabClass' : ''}
  />
))

const AntTab1 = withStyles({
  root: {
    minWidth: 72,
    color: 'black',
    flex: 1,
    '&:hover': {},
    '&$selected': {
      color: 'white',
      backgroundColor: '#1fb400',
    },
    '&:focus': {},
  },
  labelContainer: {
    // padding: "6px 0px",
    // fontSize: "12px",
    width: 'auto',
    padding: 0,
  },
  iconLabelWrapper: {
    flexDirection: 'row',
  },
  selected: {},
})((props) => (
  // <Tab {...props} className={props.activeTab === props.index ? 'activeTab' : '' } />
  <Tab
    {...props}
    classes={{
      wrapper: props.classes.iconLabelWrapper,
      labelContainer: props.classes.labelContainer,
    }}
    className={props.activeTab === props.index ? 'activeTab' : ''}
  />
))

const SortableItem = SortableElement((props) => {
  const {
    classes,
    image,
    disable,
    approveImage,
    businessId,
    openImagePopup,
    handleImageClickEvent,
  } = props
  return (
    <li className="image-list-item">
      <DragHandle />
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {image.name} | {(image.size / (1024 * 1024)).toFixed(2)}Mb
      </div>
      <div className={`classes.actionCell d-flex`}>
        {!image.approved && (
          <Button
            disabled={disable}
            style={{ color: 'red' }}
            onClick={() => approveImage(image._id, businessId, image)}
            classes={{ root: classes.imageActions }}
          >
            Approve
          </Button>
        )}
        <Button
          disabled={disable}
          style={{ color: 'blue' }}
          onClick={() => handleImageClickEvent(image._id, businessId)}
          classes={{ root: classes.imageActions }}
        >
          Remove
        </Button>
        <Button
          style={{ color: 'blue' }}
          onClick={() => openImagePopup(image.url, businessId, image)}
          classes={{ root: classes.imageActions }}
        >
          View
        </Button>
      </div>
    </li>
  )
})
const DragHandle = sortableHandle(() => <DragIcon className="drag-icon" />)

const SortableList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((image, index) => (
        <SortableItem
          key={`item-${image.name}`}
          index={index}
          image={image}
          classes={props.classes}
          disable={props.disable}
          businessId={props.businessId}
          openImagePopup={props.openImagePopup}
          handleImageClickEvent={props.handleImageClickEvent}
          approveImage={props.approveImage}
        />
      ))}
    </div>
  )
})

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  )
}

class ProgramInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      business: {
        // firstName: "",
        // lastName: "",
        // userPhone: "",
        // companyTitle: "",
        company_logo: undefined,
        email: '',
        password: '',
        business_name: '',
        program_name: '',
        program_website: '',
        financialInformation: '',
        address: {
          address_1: '',
          address_2: '',
          city: '',
          state: 'AZ',
          postal_code: '',
          lat: null,
          lng: null,
          PlaceId: '',
        },
        phone_num: '',
        crisis_phone_num: '',
        timing: [
          {
            day: 'Monday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Tuesday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Wednesday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Thursday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Friday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Saturday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
          {
            day: 'Sunday',
            split_hours: false,
            full_day_open: false,
            timingSelected: true,
            open_time: '',
            close_time: '',
          },
        ],
        program_eligibility: '',
        category: {
          category: [],
          sub_category: [],
        },
        county: '',
        languages: [],
        short_description: '',
        long_description: '',
        services: '',
        gallery_images: [],
        // program_status: "",
        google_direction_url: '',
        pdf_file: '',
        youtubeVideos: '',
        final_url: [],
        donation_info: '',
        otherCategory: [],
        additionalNotes: '',
        searchKeywords: '',
      },
      categories: [],
      display_logo: '',
      display_images: [],
      pdf_file: '',
      blankError: false,
      error: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      },
      notification: ``,
      open: false,
      success: false,
      validZip: true,
      valid: true,
      anchorEl: undefined,
      anchor: '',
      openDialog: false,
      editNumber: false,
      editCrisisNumber: false,
      numberError: { phone_num: false, crisis_phone_num: false },
      unClaim: false,
      clearFields: false,
      editUserNumber: false,
      loading: false,
      allTabs: ['Information', 'Hours', 'About', 'Misc'],
      activeTab: 0,
      categoryTab: 0,
      Days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      customOrder: ['health', 'food', 'housing', 'education'],
      showError: {},
      validationField: [
        'program_name',
        'address.city',
        'languages',
        'short_description',
        'category.category',
      ],
      validationFieldAdmin: [
        'email',
        'program_name',
        'address.city',
        'languages',
        'short_description',
        'category.category',
      ],
      validationError: {},
      daysActiveTab: 0,
      flagUrl: findFlagUrlByCountryName('United States'),
      viewEdits: false,
      review: {},
      question: {},
      openQuestion: false,
      openpop: false,
      url: '',
      openReview: false,
      allUsers: [],
      windowWidth: null,
      deleteItemName: '',
      deleteId: -1,
      businessId: -1,
      shouldBlockNavigation: false,
      selectedImageAlert: null,
    }
  }

  componentDidMount() {
    this.updateDimensions()
    this.setState({ shouldBlockNavigation: true })
    window.addEventListener('resize', this.updateDimensions)
    console.log('this', this.props, this.state)
    if (this.props.auth.accessType === 'Super Admin') {
      getAllUsers(30000, 1, 1, 'email').then((res) => {
        console.log('LIST USRES FROP RES', res)
        this.setState({ userList: res.data.userListing })
      })
    }
    // this.props.getAllCategories().then(() => {
    //   if (this.props.category.error === '') {
    //     this.setState({ categories: this.props.category.allCategories })
    //   }
    // })
    this.props.userActions.getUnfilteredUser().then((data) => {
      if (data) {
        this.setState({
          allUsers: data.userListing,
        })
      }
    })
    if (Object.keys(this.props.business).length && this.props.business._id) {
      let display_logo = ''
      let display_images = []
      const { business, category } = this.props
      let other_cat_array = []

      this.setState({
        business: {
          ...business,
          address: { ...business.address },
          gallery_images: [],
          otherCategory: other_cat_array,
        },
        display_logo,
        display_images,
      })
    }
  }

  goToHomePage(action) {
    if (
      !this.props.history.location.pathname.includes('/details') ||
      action === 'Close'
    ) {
      const test = window.confirm(
        'You have unsaved changes, are you sure you want to leave?'
      )
      if (
        test ||
        (this.detectiOS() && action === 'Back' && this.props.isMobile)
      ) {
        this.props.history.replace('/my-listing')
        this.props.history.go()
      } else {
        this.props.history.goForward()
      }
    }
  }
  componentDidUpdate(prevProps) {
    this.swipeableActions && this.swipeableActions.updateHeight()
    if (
      prevProps.business &&
      prevProps.business._id !== this.props.business._id
    ) {
      if (this.props.business && Object.keys(this.props.business).length > 0) {
        this.setState({ ...this.state, business: this.props.business })
      }
    } else if (!(prevProps.business && prevProps.business._id)) {
      if (this.props.business && Object.keys(this.props.business).length > 0) {
        this.setState({ ...this.state, business: this.props.business })
      }
    }
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    window.onpopstate = () => {
      this.goToHomePage('Back')
    }
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
    if (
      this.props.businessState.gallery_images !==
      newProps.businessState.gallery_images
    ) {
      this.setState({ gallery_images: [] })
    }
    if (
      Object.keys(newProps.business).length &&
      this.props.business._id !== newProps.business._id
    ) {
      const { business, category } = newProps
      let other_cat_array = []
      //&& business.category.sub_category.length === 0
      if (business.category.category && business.category.category.length > 0) {
        let subCatArray = []
        category.allCategories.map((cat, index) => {
          business.category.category.forEach((itm, idx) => {
            if (cat.category === itm) {
              subCatArray = business.category.sub_category.filter((item) => {
                return cat.sub_category.includes(item)
              })
              if (subCatArray.length === 0) {
                other_cat_array.push(itm)
              }
            }
          })
        })
      }
      this.setState({
        business: {
          ...business,
          youtubeVideos: business.youtubeVideos,
          address: { ...business.address },
          gallery_images: [],
          phone_num: business.phone_num || '',
          crisis_phone_num: business.crisis_phone_num || '',
          otherCategory: other_cat_array,
          searchKeywords:
            business.searchKeywords && business.searchKeywords.length > 0
              ? business.searchKeywords.join(',')
              : '',
        },
      })
    }
  }
  detectiOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  checkValidZip = () => {
    let { business } = this.state
    let validZip = true
    if (business.address.postal_code) {
      validZip = Boolean(
        business.address.postal_code.match(/^[0-9]{5}(?:-[0-9]{4})?$/)
      )
    }
    this.setState({ validZip })
    return validZip
  }

  handleGetAddress = async () => {
    let { business } = this.state
    if (business.address.address_1 !== '' && business.county !== 'Online') {
      let address = `${business.address.address_1}, ${
        business.address.address_2
      }, ${
        Array.isArray(business.address.city)
          ? business.address.city[0]
          : business.address.city
      }, ${business.address.state}`
      Geocode.setApiKey(GOOGLE_KEY)
      await Geocode.fromAddress(address)
        .then(async (response) => {
          if (response.results.length > 0) {
            const { geometry, place_id } = response.results[0]
            business.address = {
              ...business.address,
              lat: geometry.location.lat,
              lng: geometry.location.lng,
              PlaceId: place_id,
            }
            await this.setState({ business })
          }
        })
        .catch(async (err) => {
          business.address = {
            ...business.address,
            lat: 0,
            lng: 0,
            PlaceId: null,
          }
          await this.setState({ business })
        })
    }
    return true
  }

  onPhotoUpload = (e) => {
    const { files } = e.target
    if (files.length > 0 && files[0].size < 3000000) {
      let { business, display_images } = this.state
      for (let i = 0; i < files.length; i++) {
        display_images.push(URL.createObjectURL(files[i]))
        let image = files[i]
        let id = 0
        if (business.gallery_images.length > 0)
          id =
            business.gallery_images
              .map((img, index) => (img ? img.id : index))
              .sort()[business.gallery_images.length - 1] + 1
        image['id'] = id
        business['gallery_images'] = [...business.gallery_images, image]
      }
      this.setState({ business, display_images })
    } else {
      this.setState({
        open: true,
        notification:
          'Image is too large, It must be less than 3 MB. Try again.',
      })
    }
  }

  handleVideos = (e) => {
    const value = e.target.value

    this.setState((prevState) => ({
      ...prevState,
      business: { ...prevState.business, youtubeVideos: [value] },
    }))
  }

  handleAdditionalNotes = (e) => {
    let { business } = this.state
    business['additionalNotes'] = e.target.value
    this.setState({ business })
  }

  handlesearchKeywords = (e) => {
    let { business } = this.state
    business['searchKeywords'] = e.target.value
    this.setState({ business })
  }

  handleSubCategoryChange = (sub, selected, category) => {
    this.setState((state) => {
      let { business, categories } = state
      if (selected) {
        if (sub === 'Other') {
          business.category.category = !business.category.category.includes(
            category
          )
            ? [...business.category.category, category]
            : business.category.category
          business.otherCategory = !business.otherCategory.includes(category)
            ? [...business.otherCategory, category]
            : business.otherCategory
        } else {
          business.category.category = !business.category.category.includes(
            category
          )
            ? [...business.category.category, category]
            : business.category.category
          business.category.sub_category = [
            ...business.category.sub_category,
            sub,
          ]
        }
      } else {
        if (sub === 'Other') {
          let subCatArray = []
          categories.map((cat, index) => {
            if (cat.category === category) {
              subCatArray = business.category.sub_category.filter((item) => {
                return cat.sub_category.includes(item)
              })
            }
          })
          if (subCatArray.length === 0) {
            business.category.category = business.category.category.filter(
              (cat) => cat !== category
            )
          }
          business.otherCategory = business.otherCategory.filter(
            (cat) => cat !== category
          )
        } else {
          // business.category.category = (business.category.sub_category.length === 0)
          //   ? business.category.category.filter(cat => cat !== category)
          //   : [...business.category.category];
          business.category.sub_category =
            business.category.sub_category.filter((s) => s !== sub)
          let subCatArray = []
          categories.map((cat, index) => {
            if (cat.category === category) {
              subCatArray = business.category.sub_category.filter((item) => {
                return cat.sub_category.includes(item)
              })
            }
          })
          if (
            subCatArray.length === 0 &&
            business.otherCategory &&
            business.otherCategory.length > 0 &&
            !business.otherCategory.includes(category)
          ) {
            business.category.category = business.category.category.filter(
              (cat) => cat !== category
            )
          }
        }
      }
      return { business }
    })
  }

  removeImage = (file, image) => {
    let { business, display_images } = this.state
    business.gallery_images = business.gallery_images.filter(
      (img) => img.id !== file.id
    )
    display_images = display_images.filter((img) => img !== image)
    this.setState({ business, display_images })
  }

  handlePhotos = () => {
    document.getElementById('photo-upload').click()
  }
  handlePdf = () => {
    document.getElementById('pdf-upload').click()
  }
  onPdfUpload = (e) => {
    const { files } = e.target
    if (files[0] && files[0].size && files[0].size < 3000000) {
      let { business } = this.state
      business['pdf_file'] = files[0]
      this.setState({ business, pdf_file: files[0] })
    } else {
      this.setState({
        open: true,
        notification: 'Something went wrong. Try again.',
      })
    }
  }

  handleLanguage = (lang, selected) => {
    this.setState((state) => {
      let newState
      let { business } = state
      if (selected) {
        business.languages = [...business.languages, lang]
        newState = { business, blankError: false }
      } else {
        business.languages = business.languages.filter((l) => l !== lang)
        newState = { business }
      }
      return newState
    })
  }

  handleSplitHours = (day, checked) => {
    let { business } = this.state
    business.timing.find((t) => t.day === day).split_hours = checked
    business.timing.find((t) => t.day === day).timingSelected = true
    business.timing.find((t) => t.day === day).shift_one = {
      open_time: '',
      close_time: '',
    }
    business.timing.find((t) => t.day === day).shift_two = {
      open_time: '',
      close_time: '',
    }
    business.timing.find((t) => t.day === day).open_time = ''
    business.timing.find((t) => t.day === day).close_time = ''
    this.setState({ business })
  }

  handleCategoryChange = (id) => {
    this.setState((state) => {
      let newState
      let { business } = state
      let selected = ''
      selected =
        Boolean(
          _.find(business.category.category, function (o) {
            return o === id
          })
        ) === true
          ? false
          : true

      if (selected) {
        business.category.category = [...business.category.category, id]
        newState = { business, blankError: false }
      } else {
        let { categories } = state
        business.category.category = business.category.category.filter(
          (cat) => cat !== id
        )
        let category = categories.find((cat) => cat.category === id)
        category.sub_category.forEach((sub) => {
          business.category.sub_category =
            business.category.sub_category.filter((s) => s !== sub)
        })
        newState = { business }
      }
      return newState
    })
  }

  checkEmptyField = (e) => {
    let { business } = this.state
    if (business[e.target.name] === '') {
      this.setState({ blankError: true })
    }
  }

  handleBusinessChange = (e) => {
    let { business, allUsers } = this.state
    let businessName = ''
    let email = false
    if (e && e.target && e.target.name === 'email') {
      email = true
      if (this.state.userList) {
        this.state.userList.map((u) => {
          if (u.email === e.target.value) {
            businessName = u.user.businessName
          }
        })
      }
      business['business_name'] = businessName
    }
    business[e.target.name] = e.target.value
    this.setState((state) => {
      if (email) return { business, blankError: false, valid: true }
      else return { business, blankError: false }
    })
  }

  handleBusinessAboutChange = (filedName, fieldValue) => {
    let { business } = this.state
    fieldValue = fieldValue === '<p><br></p>' ? '' : fieldValue
    business = { ...business, [filedName]: fieldValue }
    this.setState((state) => {
      return { business, blankError: false }
    })
  }

  handleChangeAddress = (e) => {
    let { business } = this.state
    let zip = false
    if (e.target.name === 'postal_code') zip = true
    business.address[e.target.name] = e.target.value
    this.setState((state) => {
      if (zip) return { business, validZip: true }
      else return { business }
    })
  }

  handleFullDayOpen = (day, selected) => {
    // let {business} = this.state;
    // business.timing.find(t => t.day === day).full_day_open = selected;
    // business.timing.find(t => t.day === day).timingSelected = !selected;
    // business.timing.find(t => t.day === day).split_hours = false;
    // business.timing.find(t => t.day === day).shift_one = {open_time: "", close_time: ""};
    // business.timing.find(t => t.day === day).shift_two = {open_time: "", close_time: ""};
    // business.timing.find(t => t.day === day).open_time = "";
    // business.timing.find(t => t.day === day).close_time = "";
    // this.setState({business});
    const { business } = this.state
    let index = business.timing.findIndex((t) => t.day === day)
    let selectedDay = business.timing[index]
    selectedDay = {
      ...selectedDay,
      full_day_open: selected,
      timingSelected: !selected,
      split_hours: false,
      shift_one: { open_time: '', close_time: '' },
      shift_two: { open_time: '', close_time: '' },
      open_time: '',
      close_time: '',
    }
    this.setState({
      business: {
        ...business,
        timing: [
          ...business.timing.slice(0, index),
          { ...selectedDay },
          ...business.timing.slice(index + 1, business.timing.length),
        ],
      },
    })
  }

  handleFullDayClose = (day, selected) => {
    // let {business} = this.state;
    // business.timing.find(t => t.day === day).full_day_open = !selected;
    // business.timing.find(t => t.day === day).timingSelected = !selected;
    // business.timing.find(t => t.day === day).split_hours = false;
    // business.timing.find(t => t.day === day).shift_one = {open_time: "", close_time: ""};
    // business.timing.find(t => t.day === day).shift_two = {open_time: "", close_time: ""};
    // business.timing.find(t => t.day === day).open_time = "";
    // business.timing.find(t => t.day === day).close_time = "";
    // this.setState({business})
    const { business } = this.state
    let index = business.timing.findIndex((t) => t.day === day)
    let selectedDay = business.timing[index]
    selectedDay = {
      ...selectedDay,
      full_day_open: selected,
      timingSelected: !selected,
      split_hours: false,
      shift_one: { open_time: '', close_time: '' },
      shift_two: { open_time: '', close_time: '' },
      open_time: '',
      close_time: '',
    }

    this.setState({
      business: {
        ...business,
        timing: [
          ...business.timing.slice(0, index),
          { ...selectedDay },
          ...business.timing.slice(index + 1, business.timing.length),
        ],
      },
    })
  }

  checkValidTime = async () => {
    let { error } = this.state
    let { timing } = this.state.business
    await this.setState((state) => {
      timing.forEach((day) => {
        if (day.timingSelected) {
          if (day.split_hours) {
            if (
              day.shift_one.open_time === '' ||
              day.shift_one.close_time === '' ||
              day.shift_two.open_time === '' ||
              day.shift_two.close_time === ''
            ) {
              error[day.day] = true
            } else {
              error[day.day] = false
            }
          } else {
            if (day.open_time === '' || day.close_time === '') {
              error[day.day] = true
            } else {
              error[day.day] = false
            }
          }
        } else {
          error[day.day] = false
        }
      })
      return { error: { ...state.error, ...error } }
    })
    return Object.values(this.state.error).includes(true)
  }

  handleChangeTime = (day, e) => {
    let { business, error } = this.state
    let ind = business.timing.findIndex((t) => t.day === day)
    let timing = business.timing[ind]
    if (timing.split_hours) {
      if (e.target.name.match('one')) {
        if (e.target.name.match('open')) {
          timing['shift_one'] = {
            ...timing['shift_one'],
            open_time: e.target.value,
          }
        } else if (e.target.name.match('close')) {
          timing['shift_one'] = {
            ...timing['shift_one'],
            close_time: e.target.value,
          }
        }
      } else if (e.target.name.match('two')) {
        if (e.target.name.match('open')) {
          timing['shift_two'] = {
            ...timing['shift_two'],
            open_time: e.target.value,
          }
        } else if (e.target.name.match('close')) {
          timing['shift_two'] = {
            ...timing['shift_two'],
            close_time: e.target.value,
          }
        }
      }
    } else {
      if (e.target.name.match('open')) {
        timing['open_time'] = e.target.value
        timing['shift_one'] = {
          open_time: '',
          close_time: '',
        }
        timing['shift_two'] = {
          open_time: '',
          close_time: '',
        }
      } else if (e.target.name.match('close')) {
        timing['close_time'] = e.target.value
        timing['shift_one'] = {
          open_time: '',
          close_time: '',
        }
        timing['shift_two'] = {
          open_time: '',
          close_time: '',
        }
      }
    }

    error[day] = false
    timing.timingSelected = true
    business.timing[ind] = timing
    this.setState({ business, error })
  }

  confirmDelete = () => {
    this.setState({ openDialog: true })
  }

  closeDialog = () => {
    this.setState({
      openDialog: false,
      deleteItemName: '',
      deleteId: -1,
      businessId: -1,
    })
  }

  openPopup = (e) => {
    this.setState({ anchorEl: e.target, anchor: e.target.id })
  }

  closePopup = () => {
    this.setState({ anchorEl: undefined, anchor: '' })
  }

  phoneNumberFormatter = (phoneNumber) => {
    var match
    if (phoneNumber) {
      if (phoneNumber.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)) {
        if (phoneNumber.toString().match(/^\d{10}$/)) {
          match = phoneNumber
            .toString()
            .replace(/\D /g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          if (match) {
            return match
          }
        }
        return phoneNumber
      }
      return phoneNumber
    }
  }

  updateProgram = async (approval_status) => {
    this.setState({ ...this.state, loading: true })
    // let error = await this.checkValidTime();
    let error = false
    let validZip = this.checkValidZip()
    await this.handleNumberError('phone_num')
    await this.handleNumberError('crisis_phone_num')
    await this.handleGetAddress()
    let isAdmin =
      this.props.auth.accessType === 'Admin' ||
      this.props.auth.accessType === 'Super Admin'
    if (isAdmin) {
      this.validateEmptyFieldAdmin()
    }
    let { business, numberError, validationError } = this.state

    if (
      !numberError.crisis_phone_num &&
      !numberError.phone_num &&
      !error &&
      validZip &&
      !validationError.short_description &&
      (isAdmin ? !validationError.email : true)
    ) {
      let { updateBusiness } = this.props
      let formData = new FormData()
      formData.append('email', business.email && business.email.toLowerCase())
      formData.append(
        'business_name',
        business.business_name
          ? business.business_name.replace(/^\s+/g, '')
          : ''
      )
      formData.append(
        'program_name',
        business.program_name ? business.program_name.replace(/^\s+/g, '') : ''
      )
      formData.append(
        'organization_name',
        business.organization_name
          ? business.organization_name.replace(/^\s+/g, '')
          : ''
      )
      console.log('business.crisis_phone_num', business.crisis_phone_num)
      formData.append('address', JSON.stringify(business.address))
      formData.append('county', business.county)
      formData.append('program_website', business.program_website)
      formData.append('phone_num', business.phone_num)
      formData.append('crisis_phone_num', business.crisis_phone_num)
      formData.append('category', JSON.stringify(business.category))
      formData.append('timing', JSON.stringify(business.timing))
      // formData.append('program_status', business.program_status);
      formData.append('program_eligibility', business.program_eligibility)
      formData.append('approval_status', approval_status)
      formData.append('short_description', business.short_description)
      formData.append('long_description', business.long_description)
      // formData.append('services', business.services);
      // formData.append('google_direction_url', business.google_direction_url);
      formData.append('financialInformation', business.financialInformation)
      formData.append('pdf_file', business.pdf_file)

      formData.append('donation_info', business.donation_info)
      formData.append('additionalNotes', business.additionalNotes)
      let searchKeywords =
        business.searchKeywords != undefined ? business.searchKeywords : ''
      formData.append('searchKeywords', searchKeywords)

      if (business.youtubeVideos && business.youtubeVideos.length > 0) {
        for (let i = 0; i < business.youtubeVideos.length; i++) {
          formData.append('youtubeVideos[]', business.youtubeVideos[i])
        }
      }

      if (business.gallery_images && business.gallery_images.length > 0) {
        for (let i = 0; i < business.gallery_images.length; i++) {
          formData.append(
            `gallery_images[${i}]`,
            business.gallery_images[i] && business.gallery_images[i].approved
              ? JSON.stringify(business.gallery_images[i])
              : business.gallery_images[i]
          )
        }
      } else {
        formData.append('gallery_images[]', '')
      }

      if (business.languages && business.languages.length > 0) {
        for (let i = 0; i < business.languages.length; i++) {
          formData.append('languages[]', business.languages[i])
        }
      }

      updateBusiness(business._id, formData, business).then((business) => {
        if (business._id) {
          this.props.updateProgram(business)
          this.setState(
            (state) => ({
              business: {
                ...state.business,
                phone_num: (business.phone_num && business.phone_num) || '',
                crisis_phone_num:
                  (business.crisis_phone_num && business.crisis_phone_num) ||
                  '',
                youtubeVideos: '',
                pdf_file: '',
                donation_info: '',
              },
              display_logo: '',
              display_images: [],
              blankError: false,
              pdf_file: '',
              error: {
                Monday: false,
                Tuesday: false,
                Wednesday: false,
                Thursday: false,
                Friday: false,
                Saturday: false,
                Sunday: false,
              },
              // notification: `Updated successfully.`,
              // open: true,
              // success: true,
              validZip: true,
              loading: false,
              validationField: [
                'program_name',
                'address.city',
                'languages',
                'short_description',
                'category.category',
              ],
              validationFieldAdmin: [
                'email',
                'program_name',
                'address.city',
                'languages',
                'short_description',
                'category.category',
              ],
              validationError: {},
              shouldBlockNavigation: false,
            }),
            () => {
              setTimeout(() => {
                this.props.history.push('/my-listing')
              }, 500)
            }
          )
        } else {
          this.setState({
            notification: `Something went wrong. Try again.`,
            open: true,
            success: false,
            loading: false,
            shouldBlockNavigation: false,
          })
        }
      })
    } else {
      this.setState({
        ...this.state,
        loading: false,
        notification: `Please fill out all the required fields.`,
        open: true,
        success: false,
      })
    }
  }

  closeNotification = () => {
    this.setState({
      notification: ``,
      open: false,
    })
  }

  toggleEditNumber = (e) => {
    let { value, name } = e.target
    this.setState((state) => {
      if (
        value &&
        !value.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        return {
          editNumber: !state.editNumber,
          editUserNumber: !state.editUserNumber,
          numberError: { ...state.numberError, [name]: true },
        }
      } else {
        return {
          editNumber: !state.editNumber,
          editUserNumber: !state.editUserNumber,
        }
      }
    })
  }

  toggleEditCrisis = () => {
    let { crisis_phone_num } = this.state.business
    this.setState((state) => {
      if (
        crisis_phone_num &&
        !crisis_phone_num
          .toString()
          .match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
      ) {
        return {
          editCrisisNumber: !state.editCrisisNumber,
          numberError: { ...state.numberError, crisis_phone_num: true },
        }
      } else {
        return { editCrisisNumber: !state.editCrisisNumber }
      }
    })
  }

  handleNumberError = (name) => {
    let { business } = this.state
    if (
      business[name] &&
      !business[name].toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)
    ) {
      this.setState((state) => ({
        numberError: { ...state.numberError, [name]: true },
      }))
    }
  }

  handlePhoneNumber = (e) => {
    let { name, value } = e.target
    this.setState((state) => ({
      business: {
        ...state.business,
        [name]: value,
      },
      numberError: {
        ...state.numberError,
        [name]: false,
      },
    }))
  }

  openUnClaimDialog = () => {
    this.setState({ ...this.state, unClaim: true })
  }

  closeUnClaimDialog = () => {
    this.setState({ ...this.state, unClaim: false })
  }

  unClaimBusiness = (id) => {
    const { approveClaim } = this.props
    approveClaim(id, { email: '' }).then(() => {
      if (this.props.businessState.error === '') {
        this.setState({
          ...this.state,
          unClaim: false,
          open: true,
          notification: 'Your program has been Unclaimed.',
          success: true,
          business: { ...this.state.business, email: '' },
          clearFields: true,
        })
      } else {
        this.setState({
          ...this.state,
          open: true,
          success: false,
          notification: 'Something went wrong.',
        })
      }
    })
  }

  handleChangeTab = (event, value) => {
    event.preventDefault()
    this.setState({ ...this.state, activeTab: value })
    let { auth } = this.props
    const isAdmin =
      Object.keys(auth).length &&
      (auth.accessType === 'Admin' || auth.accessType === 'Super Admin')

    if (auth.accessType !== 'Read Only') {
      if (isAdmin) this.validateEmptyFieldAdmin()
      else this.validateEmptyField()
    }
  }

  handleChangeCategoryTab = (event, value) => {
    this.setState({ ...this.state, categoryTab: value })
    let { auth } = this.props
    const isAdmin =
      Object.keys(auth).length &&
      (auth.accessType === 'Admin' || auth.accessType === 'Super Admin')

    if (auth.accessType !== 'Read Only') {
      if (isAdmin) this.validateEmptyFieldAdmin()
      else this.validateEmptyField()
    }
  }

  handleChangeCategoryIndex = (index) => {
    this.setState({
      categoryTab: index,
    })
  }

  getValue = (obj, path) => {
    let split = path.split('.')
    let finalValue = path === 'city' ? { ...obj.address } : { ...obj }
    split.forEach((value) => {
      if (finalValue[value]) {
        finalValue = finalValue[value]
      } else {
        finalValue = ''
        return false
      }
    })
    return finalValue
  }

  validateEmptyFieldAdmin = () => {
    const { validationFieldAdmin, business, validationError } = this.state
    validationFieldAdmin.forEach((value) => {
      let errorKey = value.split('.')[1]
        ? value.split('.')[1]
        : value.split('.')[0]
      if (value === 'languages' || value === 'category.category') {
        validationError[errorKey] = !this.getValue(business, value).length
      } else {
        validationError[errorKey] = !this.getValue(business, value)
      }
    })

    this.setState({ validationError: { ...validationError } })
    return !Object.keys(validationError).filter((value) => {
      if (validationError[value]) return value
    }).length
  }

  validateEmptyField = () => {
    const { validationField, business, validationError } = this.state
    validationField.forEach((value) => {
      let errorKey = value.split('.')[1]
        ? value.split('.')[1]
        : value.split('.')[0]
      if (value === 'languages' || value === 'category.category') {
        validationError[errorKey] = !this.getValue(business, value).length
      } else {
        validationError[errorKey] = !this.getValue(business, value)
      }
    })

    this.setState({ validationError: { ...validationError } })
    return !Object.keys(validationError).filter((value) => {
      if (validationError[value]) return value
    }).length
  }

  approveImage = (key, id, image) => {
    this.setState({
      selectedImageAlert: image,
    })
    this.props.businessActions.approveImage({ key }, id)
  }

  viewEdits = () => {
    this.setState({ viewEdits: true })
  }

  closeEdits = () => {
    this.setState({ viewEdits: false })
  }

  openImagePopup = (url, businessId, alert) => {
    this.setState({ openpop: true, businessId, url, selectedImageAlert: alert })
  }

  closeImagePopup = () => {
    this.setState({ openpop: false })
  }

  renderImages = () => {
    const dImages = this.state.business.gallery_images
      ? this.state.business.gallery_images.filter((el) => {
          if (el) {
            return el.approved
          } else return false
        })
      : []

    const handleRemove = (item) => () => {
      this.setState((prevState) => ({
        ...prevState,
        business: {
          ...prevState.business,
          gallery_images: prevState.business.gallery_images.filter(
            (el) => el.url !== item.url
          ),
        },
      }))
    }
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        {dImages.length > 0 &&
          dImages.map((image, i) => (
            <div
              key={i}
              className="uploaded-image"
              style={{ margin: '0 0 1% 1%' }}
            >
              <div className="thumbnail-container">
                <img src={image.url} alt="logo" className="thumbnail-image" />
                <div className="image-overlay ">
                  <div className="remove-image">
                    <IconButton onClick={handleRemove(image)}>
                      <RemoveIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  }

  getMobileComponents = (index) => {
    const {
      business,
      categories,
      display_images,
      blankError,
      error,
      validZip,
      valid,
      numberError,
      clearFields,
      anchorEl,
      anchor,
      editNumber,
      editCrisisNumber,
      categoryTab,
      daysActiveTab,
      Days,
      customOrder,
      showError,
      editUserNumber,
      validationError,
      pdf_file,
      allUsers,
    } = this.state
    const { matches, classes, auth, clearFormFields } = this.props
    const gallery_image_condition = _.compact(
      this.props.business.gallery_images
    )
    if (!(business && Object.keys(business).length > 0)) {
      return <></>
    }
    const clearForm = clearFormFields || clearFields
    const googleDirectionUrl =
      business.google_direction_url === 'undefined' ||
      business.google_direction_url === ''
        ? ''
        : business.google_direction_url
    const disabled = auth.accessType === 'Read Only' ? true : false

    const usaStates = new UsaStates().arrayOf('abbreviations')
    if (
      business &&
      business.phone_num &&
      business.phone_num.length === 11 &&
      business.phone_num[0] === '1'
    ) {
      business.phone_num = business.phone_num.substr(1)
    }
    if (
      business &&
      business.crisis_phone_num &&
      business.crisis_phone_num.length === 11 &&
      business.crisis_phone_num[0] === '1'
    ) {
      business.crisis_phone_num = business.crisis_phone_num.substr(1)
    }
    let pdf
    if (typeof business.pdf_file === 'string') {
      let a = business.pdf_file.split('/')
      pdf = a[a.length - 1]
    }

    switch (index) {
      case 0:
        return (
          <div className="div-wrapper">
            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="admin-program-form-group">
                  <div style={{ display: 'flex' }} className={'company-logo'}>
                    <label>
                      Program/Event/Service Name{' '}
                      <span className="required-label">*</span>
                    </label>
                    <FieldPopup
                      id="program-name-field-popup"
                      open={anchor === 'program-name-field'}
                      anchorEl={
                        anchor === 'program-name-field' ? anchorEl : undefined
                      }
                      onClose={this.closePopup}
                    />
                  </div>

                  <TextField
                    disabled={disabled}
                    onBlur={this.checkEmptyField}
                    type="text"
                    onChange={this.handleBusinessChange}
                    name="program_name"
                    className="without-padding"
                    value={!clearForm ? business.program_name : ''}
                    variant="outlined"
                    classes={{ root: matches ? classes.phone : classes.pc }}
                  />
                  {/*{blankError && business.program_name === "" ?
                 <p name="error" className="admin-error-message">Please enter the name of your program.</p> : null}*/}
                  {validationError.program_name && (
                    <p name="error" className="error-message">
                      Please enter the name of your program.
                    </p>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="admin-program-form-group">
                  <div style={{ display: 'flex' }} className={'company-logo'}>
                    <label>Website</label>
                    {/*<FieldPopup id="website-field-popup" open={anchor === "website-field"}
                   anchorEl={anchor === "website-field" ? anchorEl : undefined} onClose={this.closePopup}/>
                   <Info
                   className="info-icon" alt=""
                   aria-owns={anchor === "website-field" ? 'website-field-popup' : undefined}
                   onMouseEnter={this.openPopup} onMouseLeave={this.closePopup} id="website-field"/>*/}
                  </div>
                  <TextField
                    disabled={disabled}
                    type="text"
                    onChange={this.handleBusinessChange}
                    name="program_website"
                    variant="outlined"
                    className="without-padding"
                    value={!clearForm ? business.program_website : ''}
                    classes={{ root: matches ? classes.phone : classes.pc }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="admin-program-form-group">
                  <div style={{ display: 'flex' }} className={'company-logo'}>
                    <label>Phone Number</label>
                  </div>
                  {editNumber ? (
                    <TextField
                      disabled={disabled}
                      name="phone_num"
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      type="text"
                      onChange={this.handlePhoneNumber}
                      onBlur={this.toggleEditNumber}
                      variant="outlined"
                      className="without-padding"
                      value={!clearForm ? business.phone_num || '' : ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                src={this.state.flagUrl}
                                style={{ width: '29px' }}
                                alt="image"
                              />
                              <div style={{ marginLeft: '5px' }}>+1</div>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      readOnly
                      disabled={disabled}
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      type="text"
                      variant="outlined"
                      className="without-padding"
                      onFocus={this.toggleEditNumber}
                      value={
                        !clearForm
                          ? this.phoneNumberFormatter(business.phone_num) || ''
                          : ''
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                src={this.state.flagUrl}
                                style={{ width: '29px' }}
                                alt="image"
                              />
                              <div style={{ marginLeft: '5px' }}>+1</div>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  {numberError.phone_num ? (
                    <p name="error" className="admin-error-message">
                      This phone number is not valid
                    </p>
                  ) : null}
                </div>
              </Grid>

              <Grid container>
                <Grid item xs={12} lg={6}>
                  <div className="admin-program-form-group">
                    <div style={{ display: 'flex' }} className={'company-logo'}>
                      <label>donation_info</label>
                    </div>
                    <TextField
                      disabled={disabled}
                      type="text"
                      onChange={this.handleBusinessChange}
                      name="donation_info"
                      variant="outlined"
                      className="without-padding"
                      value={!clearForm ? business.donation_info : ''}
                      classes={{ root: matches ? classes.phone : classes.pc }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="admin-program-form-group">
                  <div style={{ display: 'flex' }} className={'company-logo'}>
                    <label>Address</label>
                  </div>
                  <TextField
                    disabled={disabled}
                    name="address_1"
                    value={!clearForm ? business.address.address_1 : ''}
                    onChange={this.handleChangeAddress}
                    // label="Address Line 1"
                    type="text"
                    variant="outlined"
                    margin="dense"
                    className="without-padding"
                    classes={{ root: matches ? classes.phone : classes.pc }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} lg={6}>
                <div className="admin-program-form-group">
                  <div className={'company-logo'}>
                    <label>City</label>
                  </div>
                  <TextField
                    disabled={disabled}
                    name="city"
                    value={business.address.city}
                    onChange={this.handleChangeAddress}
                    className="without-padding"
                    // label="City"
                    type="text"
                    variant="outlined"
                    margin="dense"
                    classes={{ root: matches ? classes.phone : classes.pc }}
                    onBlur={this.checkEmptyField}
                  />
                  {/*{validationError.city &&*/}
                  {/*<p name="error" className="error-message">Please enter a city.</p>}*/}
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="admin-program-form-group">
                  <div className={'company-logo'}>
                    <label>State</label>
                  </div>
                  <FormControl
                    className={'state'}
                    style={{ textAlign: 'left', width: '100%' }}
                  >
                    {/*<InputLabel htmlFor="state-input">State</InputLabel>*/}
                    <Select
                      disabled={disabled}
                      name="state"
                      onChange={this.handleChangeAddress}
                      value={!clearForm ? business.address.state : ''}
                      classes={{
                        selectMenu: 'without-padding-select-cities',
                        root: 'select-div',
                      }}
                      input={
                        <OutlinedInput
                          classes={{
                            root: matches ? classes.phone : 'without-padding',
                          }}
                          style={{ width: '100%' }}
                          id="state-input"
                          labelWidth={0}
                        />
                      }
                    >
                      <MenuItem value="">None</MenuItem>
                      {usaStates.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12} lg={6}>
                <div className="admin-program-form-group">
                  <div className={'company-logo'}>
                    <label>Zip Code</label>
                  </div>
                  <TextField
                    disabled={disabled}
                    onBlur={this.checkValidZip}
                    name="postal_code"
                    value={!clearForm ? business.address.postal_code : ''}
                    onChange={this.handleChangeAddress}
                    className="without-padding"
                    // label="Zip/Postal Code"
                    type="number"
                    variant="outlined"
                    margin="dense"
                    classes={{ root: matches ? classes.phone : classes.pc }}
                  />
                  {!validZip ? (
                    <p name="error" className="admin-error-message">
                      Please enter a valid postal code.
                    </p>
                  ) : null}
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="admin-program-form-group">
                  <div style={{ display: 'flex' }} className={'company-logo'}>
                    <label>Crisis Number</label>
                  </div>
                  {editCrisisNumber ? (
                    <TextField
                      disabled={disabled}
                      name="crisis_phone_num"
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      type="text"
                      onChange={this.handlePhoneNumber}
                      onBlur={this.toggleEditCrisis}
                      variant="outlined"
                      className="without-padding"
                      value={!clearForm ? business.crisis_phone_num || '' : ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                src={this.state.flagUrl}
                                style={{ width: '29px' }}
                                alt="image"
                              />
                              <div style={{ marginLeft: '5px' }}>+1</div>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <TextField
                      disabled={disabled}
                      readOnly
                      name="crisis_phone_num"
                      classes={{ root: matches ? classes.phone : classes.pc }}
                      type="text"
                      variant="outlined"
                      className="without-padding"
                      onFocus={this.toggleEditCrisis}
                      value={
                        !clearForm
                          ? this.phoneNumberFormatter(
                              business.crisis_phone_num
                            ) || ''
                          : ''
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                src={this.state.flagUrl}
                                style={{ width: '29px' }}
                                alt="image"
                              />
                              <div style={{ marginLeft: '5px' }}>+1</div>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  {numberError.crisis_phone_num ? (
                    <p name="error" className="admin-error-message">
                      This phone number is not valid
                    </p>
                  ) : null}
                </div>
              </Grid>
              {(auth.accessType === 'Super Admin' ||
                auth.accessType === 'Admin') && (
                <Grid item xs={12} lg={6}>
                  <div className="admin-program-form-group">
                    <div className={'company-logo'}>
                      <label>Email</label>
                    </div>
                    <FormControl style={{ textAlign: 'left', width: '100%' }}>
                      {/*<InputLabel htmlFor="state-input">State</InputLabel>*/}
                      <Select
                        disabled={disabled}
                        style={{ width: '100%' }}
                        name="email"
                        onChange={this.handleBusinessChange}
                        value={!clearForm ? business.email.toLowerCase() : ''}
                        classes={{
                          selectMenu: 'without-padding-select-cities',
                          root: 'select-div',
                        }}
                        input={
                          <OutlinedInput
                            classes={{
                              root: matches ? classes.phone : 'without-padding',
                            }}
                            style={{ width: '100%', marginTop: '2%' }}
                            id="state-input"
                            labelWidth={0}
                          />
                        }
                      >
                        <MenuItem value="">None</MenuItem>
                        {this.state.userList &&
                          this.state.userList.map((u) => (
                            <MenuItem key={u._id} value={u.email.toLowerCase()}>
                              {u.email.toLowerCase()}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              )}
            </Grid>
            <div className={'validation-text'}>
              <p>
                <span className="required-label">*</span> These fields are
                required
              </p>
            </div>
          </div>
        )
      case 1:
        return (
          <div className="div-wrapper">
            <div className="schedule-phone admin-page">
              {Days.map((day, index) => {
                let timing = business.timing.find((t) => t.day === day)
                return (
                  <div key={index} className={'data-set'}>
                    <FormControlLabel
                      className={
                        (!timing.timingSelected ||
                          (timing.open_time && timing.close_time)) &&
                        'checkbox-div'
                      }
                      control={
                        <Checkbox
                          // disabled={true}
                          disabled={
                            timing.timingSelected &&
                            !timing.open_time &&
                            !timing.close_time
                          }
                          onChange={(e, selected) =>
                            this.handleFullDayClose(day, selected)
                          }
                          checked={
                            !timing.timingSelected ||
                            (timing.open_time && timing.close_time)
                          }
                        />
                      }
                      label={day}
                    />
                    <Collapse className="timing-collapse" in={true}>
                      <ProgramTiming
                        day={day}
                        error={error[day]}
                        timing={timing}
                        handleSplitHours={this.handleSplitHours}
                        handleChangeTime={this.handleChangeTime}
                        handleFullDayOpen={this.handleFullDayOpen}
                        handleFullDayClose={this.handleFullDayClose}
                        mobile={true}
                        full_day_open={timing.full_day_open || false}
                        timingSelected={timing.timingSelected || false}
                      />
                    </Collapse>
                  </div>
                )
              })}
              {Object.keys(error).filter((value) => {
                if (error[value]) {
                  return value
                }
              }).length > 0 && (
                <p className="schedule-error-message" name="error">
                  Please select valid hours of operations for your program.
                </p>
              )}
            </div>
          </div>
        )
      case 2:
        return (
          <div className="div-wrapper">
            <div className="admin-program-form-group">
              <div className="composite-language-field-program-info">
                {['English', 'Navajo', 'Spanish', 'Sign'].map((lang) => (
                  <FormControlLabel
                    key={lang}
                    label={lang}
                    control={
                      <Checkbox
                        disabled={disabled}
                        checked={
                          !clearForm ? business.languages.includes(lang) : false
                        }
                        onChange={(e, selected) =>
                          this.handleLanguage(lang, selected)
                        }
                      />
                    }
                  />
                ))}
              </div>
              {validationError.languages && (
                <p name="error" className="error-message">
                  Please choose one of the above options.
                </p>
              )}
            </div>

            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="admin-program-form-group">
                  <div
                    style={{ display: 'flex', minHeight: '40px' }}
                    className={'company-logo'}
                  >
                    <label>
                      What is this service?{' '}
                      <span className="required-label">*</span>
                    </label>
                  </div>
                  <ReactQuill
                    style={{ height: 130, marginBottom: 60 }}
                    value={!clearForm ? business.short_description : ''}
                    onChange={(content) =>
                      this.handleBusinessAboutChange(
                        'short_description',
                        content
                      )
                    }
                    modules={
                      ({
                        toolbar: [['link']],
                        clipboard: {
                          matchVisual: false,
                        },
                      },
                      {
                        toolbar: [['unlink']],
                        clipboard: {
                          matchVisual: false,
                        },
                      })
                    }
                    readOnly={disabled}
                    formats={[
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'list',
                      'bullet',
                      'indent',
                      'link',
                    ]}
                  />
                  {business.short_description &&
                  business.short_description.length === 275 ? (
                    <p name="error" className="error-message">
                      You have reached to maximum characters limit allowed.
                    </p>
                  ) : null}
                  {/*{(blankError|| showError.programShortDesc) && business.programShortDesc==="" ?
                 <p name="error" className="error-message">Please Please Provide a Description of your Program.</p> : null}*/}
                  {validationError.short_description && (
                    <p name="error" className="error-message">
                      Please enter a Short Description that explains your
                      program best.
                    </p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} lg={6}>
                <div className="admin-program-form-group">
                  <div
                    style={{ display: 'flex', minHeight: '40px' }}
                    className={'company-logo'}
                  >
                    <label>What is the first step?</label>
                  </div>
                  <ReactQuill
                    style={{ height: 130, marginBottom: 60 }}
                    value={!clearForm ? business.long_description : ''}
                    onChange={(content) =>
                      this.handleBusinessAboutChange(
                        'long_description',
                        content
                      )
                    }
                    modules={{
                      toolbar: [['link']],
                    }}
                    readOnly={disabled}
                    formats={[
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'list',
                      'bullet',
                      'indent',
                      'link',
                    ]}
                  />
                  {business.long_description &&
                  business.long_description.length === 275 ? (
                    <p name="error" className="error-message">
                      You have reached to maximum characters limit allowed.
                    </p>
                  ) : null}
                </div>
              </Grid>
            </Grid>

            <div className="admin-program-form-group">
              <div style={{ display: 'flex' }} className={'company-logo'}>
                <label>Who is this service for?</label>
              </div>
              <ReactQuill
                style={{ height: 130, marginBottom: 60 }}
                value={!clearForm ? business.program_eligibility : ''}
                onChange={(content) =>
                  this.handleBusinessAboutChange('program_eligibility', content)
                }
                modules={{
                  toolbar: [['link']],
                }}
                readOnly={disabled}
                formats={[
                  'size',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                ]}
              />
              {/*<TextField*/}
              {/*multiline={true}*/}
              {/*style={{height: 'auto', width: '100%'}}*/}
              {/*type="text" onChange={this.handleBusinessChange}*/}
              {/*name="program_eligibility"*/}
              {/*variant="outlined"*/}
              {/*value={!clearForm ? business.program_eligibility : ""}*/}
              {/*/>*/}
            </div>
            <div className="admin-program-form-group">
              <div style={{ display: 'flex' }} className={'company-logo'}>
                <label>What is the cost, do you accept insurance?</label>
              </div>
              <ReactQuill
                style={{ minHeight: 130, marginBottom: 60 }}
                value={
                  !clearForm
                    ? business.financialInformation != undefined &&
                      business.financialInformation !== ''
                      ? business.financialInformation
                      : ''
                    : ''
                }
                onChange={(content) =>
                  this.handleBusinessAboutChange(
                    'financialInformation',
                    content
                  )
                }
                modules={{
                  toolbar: [['link']],
                }}
                readOnly={disabled}
                formats={[
                  'size',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                ]}
              />
              {/*<TextField*/}
              {/*multiline={true}*/}
              {/*style={{height: 'auto', width: '100%'}}*/}
              {/*type="text" onChange={this.handleBusinessChange}*/}
              {/*name="financialInformation"*/}
              {/*variant="outlined"*/}
              {/*value={!clearForm ? (business.financialInformation != 'undefined' && business.financialInformation != '') ? business.financialInformation : "" : ""}*/}
              {/*/>*/}
            </div>
            <div className={'validation-text'}>
              <p>
                <span className="required-label">*</span> These fields are
                required
              </p>
            </div>
          </div>
        )
      case 3:
        return (
          <div className="div-wrapper">
            <p className={'validation-text'}>All the fields are optional</p>
            <div className="admin-program-form-group">
              <div className="company-logo">
                <label>Add Logo/Photos</label>
              </div>
              <div>
                <Button
                  variant="outlined"
                  style={{ width: '100%' }}
                  className={classes.addLogo}
                  onClick={this.handlePhotos}
                  disabled={disabled}
                >
                  <div className="button-label">
                    Add a photo
                    <div className={'up-icon'}>
                      <FontAwesomeIcon icon="upload" />
                    </div>
                  </div>
                </Button>
              </div>
              <input
                disabled={disabled}
                onChange={(e) => this.onPhotoUpload(e)}
                multiple={true}
                accept="image/*"
                type="file"
                id="photo-upload"
                style={{ display: 'none' }}
              />
              {this.renderImages()}
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
              {display_images.length > 0 &&
                display_images.map((image, i) => (
                  <div
                    key={i}
                    className="uploaded-image"
                    style={{ margin: '0 0 1% 1%' }}
                  >
                    <div className="thumbnail-container">
                      <img src={image} alt="logo" className="thumbnail-image" />
                      <div className="image-overlay">
                        <div className="remove-image">
                          <IconButton
                            onClick={() =>
                              this.removeImage(
                                business.gallery_images[i],
                                image
                              )
                            }
                          >
                            <RemoveIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="admin-program-form-group">
              <div className="admin-program-form-group">
                <div className="company-logo">
                  <label>Attach a Flyer or Application</label>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    style={{ width: '100%' }}
                    className={classes.addLogo}
                    onClick={this.handlePdf}
                    disabled={disabled}
                  >
                    <div className="button-label">
                      Add a pdf
                      <div className={'up-icon'}>
                        <FontAwesomeIcon icon="upload" />
                      </div>
                    </div>
                  </Button>
                </div>
                <input
                  disabled={disabled}
                  onChange={(e) => {
                    this.onPdfUpload(e)
                    e.target.value = null
                  }}
                  multiple={false}
                  accept="application/pdf"
                  type="file"
                  id="pdf-upload"
                  style={{ display: 'none' }}
                />
                {pdf_file && (
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
                  >
                    <div
                      className="uploaded-image"
                      style={{ margin: '0 0 1% 1%' }}
                    >
                      <div className="thumbnail-container">
                        {/*<img src={image} alt="logo" className="thumbnail-image"/>*/}
                        <div className="image-overlay" style={{ bottom: 0 }}>
                          <div className="remove-image">
                            <IconButton
                              onClick={() => this.setState({ pdf_file: '' })}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {business.pdf_file !== '' &&
                  typeof business.pdf_file === 'string' && (
                    <div className={'company-logo'}>
                      <h4>Flyer</h4>
                      <Paper className={'border-radius-paper'}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div className={'pdf-name'}>{decodeURI(pdf)}</div>
                          <Button
                            disabled={disabled}
                            style={{
                              color: 'blue',
                              justifyContent: 'flex-end',
                              width: '50%',
                            }}
                            onClick={() =>
                              this.setState({
                                openDialog: true,
                                businessId: business.id,
                                deleteItemName: 'flyer',
                              })
                            }
                            classes={{ root: classes.imageActions }}
                          >
                            Remove
                          </Button>
                        </div>
                      </Paper>
                    </div>
                  )}
              </div>
              <div className="admin-program-form-group">
                <div className="company-logo">
                  <label>Link to a video on YouTube</label>
                </div>
                <TextField
                  id="youtubeVideos"
                  disabled={disabled}
                  name="youtubeVideos"
                  value={
                    business.youtubeVideos ? business.youtubeVideos[0] : ''
                  }
                  onChange={this.handleVideos}
                  type="text"
                  variant="outlined"
                  classes={{ root: matches ? classes.phone : classes.pc }}
                />
              </div>
              {(auth.accessType === 'Super Admin' ||
                auth.accessType === 'Admin' ||
                (auth.email.toLowerCase() === business.email.toLowerCase() &&
                  auth.accessType === 'User')) && (
                <div className="admin-program-form-group">
                  <div className="company-logo">
                    <label>Notes for Admin</label>
                  </div>
                  <TextField
                    id="additionalNotes"
                    disabled={disabled}
                    name="additionalNotes"
                    multiline
                    value={business.additionalNotes}
                    onChange={this.handleAdditionalNotes}
                    type="text"
                    variant="outlined"
                    classes={{ root: matches ? classes.phone : classes.pc }}
                  />
                </div>
              )}
              <div className="admin-program-form-group">
                <div className="company-logo">
                  <label>Search Keywords</label>
                </div>
                <TextField
                  id="searchKeywords"
                  disabled={disabled}
                  name="searchKeywords"
                  value={business.searchKeywords}
                  onChange={this.handlesearchKeywords}
                  type="text"
                  variant="outlined"
                  classes={{ root: matches ? classes.phone : classes.pc }}
                />
              </div>
            </div>
          </div>
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  removeImagePhoto = (key, id) => {
    this.props.businessActions.removeImage({ key }, id).then(() => {
      if (this.props.business.error === '') {
        let { gallery_images } = this.state.business
        gallery_images = gallery_images.filter((img) => img._id !== key)
        this.setState((state) => ({
          business: { ...state.business, gallery_images: [...gallery_images] },
        }))
      }
    })
  }

  approveReview = (id) => {
    this.props.reviewActions.approveReview(id)
  }

  removeReview = (reviewId, businessId) => {
    this.props.reviewActions.removeReview({ reviewId, businessId }).then(() => {
      if (this.props.business.error === '') {
        let { reviews } = this.state.business
        reviews = reviews.filter((review) => review._id !== reviewId)
        this.setState((state) => ({
          business: { ...state.business, reviews: [...reviews] },
        }))
      }
    })
  }

  removeFlyer = (businessId) => {
    this.props.reviewActions.removeFlyer({ businessId }).then(() => {
      this.setState((state) => ({
        business: { ...state.business, pdf_file: '' },
      }))
    })
  }

  openReviewPopup = (review) => {
    this.setState({
      review,
      openReview: true,
    })
  }

  closeReviewPopup = () => {
    this.setState({
      openReview: false,
    })
  }

  approveQuestion = (id) => {
    this.props.questionActions.approveQuestion(id)
  }

  removeQuestion = (questionId, businessId) => {
    this.props.questionActions
      .removeQuestion({ questionId, businessId })
      .then(() => {
        if (this.props.business.error === '') {
          let { questions } = this.state.business
          questions = questions.filter(
            (question) => question._id !== questionId
          )
          this.setState((state) => ({
            business: { ...state.business, questions: [...questions] },
          }))
        }
      })
  }

  openQuestionPopup = (question) => {
    this.setState({
      question,
      openQuestion: true,
    })
  }

  closeQuestionPopup = () => {
    this.setState({
      openQuestion: false,
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let gallery_images = _.compact(this.props.business.gallery_images)
    let { id } = this.state.business
    let list = arrayMove(gallery_images, oldIndex, newIndex)
    this.props.businessActions.reorderImages(id, list).then(() => {
      this.setState({ business: this.props.businessState.business })
    })
  }

  handleImageClickEvent = (dltId, businessId) => {
    this.setState({
      openDialog: true,
      deleteId: dltId,
      businessId: businessId,
      deleteItemName: 'image',
    })
  }

  renderProgramForm = () => {
    const {
      business,
      categories,
      display_images,
      blankError,
      error,
      validZip,
      valid,
      numberError,
      clearFields,
      loading,
      anchorEl,
      anchor,
      editNumber,
      editCrisisNumber,
      allTabs,
      activeTab,
      categoryTab,
      daysActiveTab,
      Days,
      customOrder,
      editUserNumber,
      validationError,
      pdf_file,
      allUsers,
    } = this.state
    const {
      matches,
      classes,
      auth,
      clearFormFields,
      isMobile,
      setTitleForEdit,
    } = this.props
    const gallery_image_condition = _.compact(
      this.props.businessState.business.gallery_images
    )
    const isAdmin =
      Object.keys(auth).length &&
      (auth.accessType === 'Admin' || auth.accessType === 'Super Admin')
    const clearForm = clearFormFields || clearFields
    const usaStates = new UsaStates().arrayOf('abbreviations')
    const googleDirectionUrl =
      business.google_direction_url === 'undefined'
        ? ''
        : business.google_direction_url
    const disabled = auth.accessType === 'Read Only' ? true : false
    if (
      business &&
      business.phone_num &&
      business.phone_num.length === 11 &&
      business.phone_num[0] === '1'
    ) {
      business.phone_num = business.phone_num.substr(1)
    }
    if (
      business &&
      business.crisis_phone_num &&
      business.crisis_phone_num.length === 11 &&
      business.crisis_phone_num[0] === '1'
    ) {
      business.crisis_phone_num = business.crisis_phone_num.substr(1)
    }
    let pdf
    if (typeof business.pdf_file === 'string') {
      let a = business.pdf_file.split('/')
      pdf = a[a.length - 1]
    }
    setTitleForEdit(!clearForm ? business.program_name : '')
    return (
      <div className="admin-program-form-group">
        {(!isMobile && (
          <div className="schedule-pc-desktop">
            <AppBar
              classes={{ root: classes.daysAppTab }}
              position="static"
              color="default"
            >
              <Tabs
                classes={{
                  root: classes.antEachTab,
                  indicator: classes.AntTabs,
                  flexContainer: classes.AntTabsRoot,
                }}
                value={activeTab}
                onChange={this.handleChangeTab}
              >
                {allTabs.map((data1, index) => (
                  <AntTab
                    key={data1 + index}
                    label={data1}
                    activeTab={activeTab}
                    index={index}
                  />
                ))}
              </Tabs>
            </AppBar>
            <SwipeableViews
              className="swipable-view"
              index={activeTab}
              action={(actions) => {
                this.swipeableActions = actions
              }}
              animateHeight
            >
              <TabContainer>
                <div className="div-wrapper">
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <div className="admin-program-form-group">
                        <div className="admin-program-inputbox company-logo">
                          <label>
                            Program/Event/Service Name{' '}
                            <span className="required-label">*</span>
                          </label>
                          <FieldPopup
                            className="required-label"
                            id="program-name-field-popup"
                            open={anchor === 'program-name-field'}
                            anchorEl={
                              anchor === 'program-name-field'
                                ? anchorEl
                                : undefined
                            }
                            onClose={this.closePopup}
                          />
                        </div>
                        <div className={'input-box'}>
                          <TextField
                            disabled={disabled}
                            onBlur={this.checkEmptyField}
                            type="text"
                            onChange={this.handleBusinessChange}
                            name="program_name"
                            className="without-padding"
                            value={!clearForm ? business.program_name : ''}
                            variant="outlined"
                            classes={{
                              root: matches ? classes.phone : classes.pc,
                            }}
                          />
                          {validationError.program_name && (
                            <p name="error" className="error-message">
                              Please enter the name of your program.
                            </p>
                          )}
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <div className="admin-program-form-group">
                        <div
                          style={{ display: 'flex' }}
                          className={'company-logo'}
                        >
                          <label>Program webpage URL</label>
                        </div>
                        <div className={'input-box'}>
                          <TextField
                            disabled={disabled}
                            type="text"
                            onChange={this.handleBusinessChange}
                            name="program_website"
                            variant="outlined"
                            className="without-padding"
                            value={!clearForm ? business.program_website : ''}
                            classes={{
                              root: matches ? classes.phone : classes.pc,
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <div className="admin-program-form-group">
                        <div className="admin-program-inputbox company-logo">
                          <label>
                            Organization Name
                            <span className="required-label"></span>
                          </label>
                          <FieldPopup
                            className="required-label"
                            id="program-name-field-popup"
                            open={anchor === 'program-name-field'}
                            anchorEl={
                              anchor === 'program-name-field'
                                ? anchorEl
                                : undefined
                            }
                            onClose={this.closePopup}
                          />
                        </div>
                        <div className={'input-box'}>
                          <TextField
                            disabled={disabled}
                            onBlur={this.checkEmptyField}
                            type="text"
                            onChange={this.handleBusinessChange}
                            name="organization_name"
                            className="without-padding"
                            value={!clearForm ? business.organization_name : ''}
                            variant="outlined"
                            classes={{
                              root: matches ? classes.phone : classes.pc,
                            }}
                          />
                          {/* {validationError.program_name && (
                            <p name="error" className="error-message">
                              Please enter the name of your program.
                            </p>
                          )} */}
                        </div>
                      </div>
                    </Grid>
                    {(auth.accessType === 'Super Admin' ||
                      auth.accessType === 'Admin') && (
                      // <Grid container>
                      <Grid item xs={12} lg={6}>
                        <div className="admin-program-form-group">
                          <div
                            style={{ display: 'flex' }}
                            className={'company-logo'}
                          >
                            <label className="program-form-lable">Email</label>
                          </div>
                          <FormControl
                            style={{ textAlign: 'left', width: '86%' }}
                          >
                            {/*<InputLabel htmlFor="state-input">State</InputLabel>*/}
                            <Select
                              disabled={disabled}
                              name="email"
                              onChange={this.handleBusinessChange}
                              value={
                                !clearForm ? business.email.toLowerCase() : ''
                              }
                              classes={{
                                selectMenu: 'without-padding-select-cities',
                                root: 'select-div',
                              }}
                              input={
                                <OutlinedInput
                                  classes={{
                                    root: matches
                                      ? classes.phone
                                      : 'without-padding',
                                  }}
                                  style={{ width: '100%' }}
                                  id="state-input"
                                  labelWidth={0}
                                />
                              }
                            >
                              {this.state.userList &&
                                this.state.userList.map((u) => (
                                  <MenuItem
                                    key={u._id}
                                    value={u.email.toLowerCase()}
                                  >
                                    {u.email.toLowerCase()}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          {!valid ? (
                            <p className="error-message" name="error">
                              The email address you have entered is invalid.
                            </p>
                          ) : null}

                          {blankError && business.email === '' && !isAdmin ? (
                            <p name="error" className="error-message">
                              Please enter your email address.
                            </p>
                          ) : null}
                          {validationError.email && (
                            <p name="error" className="error-message">
                              Please enter a valid email address.
                            </p>
                          )}
                        </div>
                      </Grid>
                      // </Grid>
                    )}
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <div className="admin-program-form-group">
                        <div
                          style={{ display: 'flex' }}
                          className={'company-logo'}
                        >
                          <label>Phone Number</label>
                        </div>
                        <div className={'input-box'}>
                          {editNumber ? (
                            <TextField
                              disabled={disabled}
                              name="phone_num"
                              classes={{
                                root: matches ? classes.phone : classes.pc,
                              }}
                              type="text"
                              onChange={this.handlePhoneNumber}
                              onBlur={this.toggleEditNumber}
                              variant="outlined"
                              className="without-padding"
                              value={!clearForm ? business.phone_num || '' : ''}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img
                                        src={this.state.flagUrl}
                                        style={{ width: '29px' }}
                                        alt="image"
                                      />
                                      <div style={{ marginLeft: '5px' }}>
                                        +1
                                      </div>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : (
                            <TextField
                              disabled={disabled}
                              readOnly
                              classes={{
                                root: matches ? classes.phone : classes.pc,
                              }}
                              type="text"
                              variant="outlined"
                              className="without-padding"
                              onFocus={this.toggleEditNumber}
                              value={
                                !clearForm
                                  ? this.phoneNumberFormatter(
                                      business.phone_num
                                    ) || ''
                                  : ''
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img
                                        src={this.state.flagUrl}
                                        style={{ width: '29px' }}
                                        alt="image"
                                      />
                                      <div style={{ marginLeft: '5px' }}>
                                        +1
                                      </div>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                          {numberError.phone_num ? (
                            <p name="error" className="admin-error-message">
                              This phone number is not valid
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Grid>
                    {/*<Grid className='w-100'>*/}
                    <Grid item xs={12} lg={6}>
                      <div className="admin-program-form-group">
                        <div
                          style={{ display: 'flex' }}
                          className={'company-logo'}
                        >
                          <label>Crisis Number</label>
                          {/*<Info*/}
                          {/*    className="info-icon" alt=""*/}
                          {/*    aria-owns={Boolean(anchorEl) && anchorEl.id === "crisis-number-field" ? 'crisis-number-field-popup' : undefined}*/}
                          {/*    onMouseEnter={this.openPopup} onMouseLeave={this.closePopup} id="crisis-number-field"/>*/}
                        </div>
                        <div className={'input-box'}>
                          {editCrisisNumber ? (
                            <TextField
                              disabled={disabled}
                              name="crisis_phone_num"
                              classes={{
                                root: matches ? classes.phone : classes.pc,
                              }}
                              type="text"
                              onChange={this.handlePhoneNumber}
                              onBlur={this.toggleEditCrisis}
                              variant="outlined"
                              className="without-padding"
                              value={
                                !clearForm
                                  ? business.crisis_phone_num || ''
                                  : ''
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img
                                        src={this.state.flagUrl}
                                        style={{ width: '29px' }}
                                        alt="image"
                                      />
                                      <div style={{ marginLeft: '5px' }}>
                                        +1
                                      </div>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : (
                            <TextField
                              disabled={disabled}
                              readOnly
                              name="crisis_phone_num"
                              classes={{
                                root: matches ? classes.phone : classes.pc,
                              }}
                              type="text"
                              variant="outlined"
                              className="without-padding"
                              onFocus={this.toggleEditCrisis}
                              value={
                                !clearForm
                                  ? this.phoneNumberFormatter(
                                      business.crisis_phone_num
                                    ) || ''
                                  : ''
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img
                                        src={this.state.flagUrl}
                                        style={{ width: '29px' }}
                                        alt="image"
                                      />
                                      <div style={{ marginLeft: '5px' }}>
                                        +1
                                      </div>
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                          {numberError.crisis_phone_num ? (
                            <p name="error" className="admin-error-message">
                              This phone number is not valid
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Grid>

                    {/*</Grid>*/}
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <div className="admin-program-form-group">
                        <div
                          style={{ display: 'flex' }}
                          className={'company-logo'}
                        >
                          <label>Address</label>
                          {/*<FieldPopup id="address-field-popup" open={anchor === "address-field"}
                       anchorEl={anchor === "address-field" ? anchorEl : undefined} onClose={this.closePopup}/>
                       <Info
                       className="info-icon" alt=""
                       aria-owns={anchor === "address-field" ? 'address-field-popup' : undefined}
                       onMouseEnter={this.openPopup} onMouseLeave={this.closePopup} id="address-field"
                       />*/}
                        </div>
                        <div className={'input-box'}>
                          <TextField
                            disabled={disabled}
                            name="address_1"
                            value={!clearForm ? business.address.address_1 : ''}
                            onChange={this.handleChangeAddress}
                            // label="Address Line 1"
                            type="text"
                            variant="outlined"
                            margin="dense"
                            className="without-padding"
                            classes={{
                              root: matches ? classes.phone : classes.pc,
                            }}
                          />
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <div className="admin-program-form-group">
                        <div
                          style={{ display: 'flex' }}
                          className={'company-logo'}
                        >
                          <label>Donation webpage URL</label>
                        </div>
                        <div className={'input-box'}>
                          <TextField
                            disabled={disabled}
                            type="text"
                            onChange={this.handleBusinessChange}
                            name="donation_info"
                            variant="outlined"
                            className="without-padding"
                            value={!clearForm ? business.donation_info : ''}
                            classes={{
                              root: matches ? classes.phone : classes.pc,
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      <div className="admin-program-form-group">
                        <div className={'company-logo'}>
                          <label>City</label>
                        </div>
                        <div className={'input-box'}>
                          <TextField
                            disabled={disabled}
                            name="city"
                            value={business.address.city}
                            onChange={this.handleChangeAddress}
                            className="without-padding"
                            // label="City"
                            type="text"
                            variant="outlined"
                            margin="dense"
                            classes={{
                              root: matches ? classes.phone : classes.pc,
                            }}
                            onBlur={this.checkEmptyField}
                          />
                          {/*{validationError.city &&*/}
                          {/*<p name="error" className="error-message">Please select a city.</p>}*/}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <div className="admin-program-form-group">
                        <div className={'company-logo'}>
                          <label>State</label>
                        </div>
                        <div className={'input-box'}>
                          <FormControl style={{ textAlign: 'left' }}>
                            {/*<InputLabel htmlFor="state-input">State</InputLabel>*/}
                            <Select
                              disabled={disabled}
                              name="state"
                              onChange={this.handleChangeAddress}
                              value={!clearForm ? business.address.state : ''}
                              classes={{
                                selectMenu: 'without-padding-select-cities',
                                root: 'select-div',
                              }}
                              input={
                                <OutlinedInput
                                  classes={{
                                    root: matches
                                      ? classes.phone
                                      : 'without-padding',
                                  }}
                                  style={{ width: '100%', marginTop: '2%' }}
                                  id="state-input"
                                  labelWidth={0}
                                />
                              }
                            >
                              <MenuItem value="">None</MenuItem>
                              {usaStates.map((state) => (
                                <MenuItem key={state} value={state}>
                                  {state}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                      <div className="admin-program-form-group">
                        <div className={'company-logo'}>
                          <label>Zip Code</label>
                        </div>
                        <div className={'input-box'}>
                          <TextField
                            disabled={disabled}
                            onBlur={this.checkValidZip}
                            name="postal_code"
                            value={
                              !clearForm ? business.address.postal_code : ''
                            }
                            onChange={this.handleChangeAddress}
                            className="without-padding"
                            // label="Zip/Postal Code"
                            type="number"
                            variant="outlined"
                            margin="dense"
                            classes={{
                              root: matches ? classes.phone : classes.pc,
                            }}
                          />
                          {!validZip ? (
                            <p name="error" className="admin-error-message">
                              Please enter a valid postal code.
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <div className={'validation-text'}>
                    <p>
                      <span className="required-label">*</span> These fields are
                      required
                    </p>
                  </div>
                </div>
              </TabContainer>
              <TabContainer>
                <div className="div-wrapper p-0">
                  <div className="admin-program-form-group">
                    <div className="schedule-pc bg-white">
                      {Days.map((day, index) => {
                        let timing = business.timing.find((t) => t.day === day)
                        return (
                          <div
                            key={index}
                            className={'operation'}
                            style={{ display: 'flex' }}
                          >
                            <FormControlLabel
                              className={
                                (!timing.timingSelected ||
                                  (timing.open_time && timing.close_time)) &&
                                'checkbox-div'
                              }
                              control={
                                <Checkbox
                                  // disabled={true}
                                  disabled={
                                    timing.timingSelected &&
                                    !timing.open_time &&
                                    !timing.close_time
                                  }
                                  onChange={(e, selected) =>
                                    this.handleFullDayClose(day, selected)
                                  }
                                  checked={
                                    !timing.timingSelected ||
                                    (timing.open_time && timing.close_time)
                                  }
                                />
                              }
                              label={day}
                            />
                            <Collapse className="timing-collapse" in={true}>
                              <ProgramTiming
                                day={day}
                                error={error[day]}
                                timing={timing}
                                handleSplitHours={this.handleSplitHours}
                                handleChangeTime={this.handleChangeTime}
                                handleFullDayOpen={this.handleFullDayOpen}
                                handleFullDayClose={this.handleFullDayClose}
                                mobile={true}
                                full_day_open={timing.full_day_open || false}
                                timingSelected={timing.timingSelected || false}
                              />
                            </Collapse>
                          </div>
                        )
                      })}
                      {Object.keys(error).filter((value) => {
                        if (error[value]) {
                          return value
                        }
                      }).length > 0 && (
                        <p className="schedule-error-message" name="error">
                          Please select valid hours of operations for your
                          program.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </TabContainer>
              <TabContainer>
                <div className="div-wrapper">
                  <div className="admin-program-form-group">
                    <div className="composite-language-field-program-info">
                      {['English', 'Navajo', 'Spanish', 'Sign'].map((lang) => (
                        <FormControlLabel
                          key={lang}
                          label={lang}
                          control={
                            <Checkbox
                              disabled={disabled}
                              checked={
                                !clearForm
                                  ? business.languages.includes(lang)
                                  : false
                              }
                              onChange={(e, selected) =>
                                this.handleLanguage(lang, selected)
                              }
                            />
                          }
                        />
                      ))}
                    </div>
                    {/*{blankError && business.languages.length === 0 ?
                 <p name="error" className="admin-error-message">Please choose one of the above options.</p> : null}*/}
                    {validationError.languages && (
                      <p name="error" className="error-message">
                        Please choose one of the above options.
                      </p>
                    )}
                  </div>

                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <div className="about-description-box">
                        <div
                          style={{ display: 'flex', minHeight: '41px' }}
                          className={'company-logo'}
                        >
                          <label>
                            What is this service?
                            <span className="required-label">*</span>
                          </label>
                        </div>
                        {/*{*/}
                        <ReactQuill
                          style={{ height: 130, marginBottom: 60 }}
                          readOnly={disabled}
                          value={!clearForm ? business.short_description : ''}
                          onChange={(content) =>
                            this.handleBusinessAboutChange(
                              'short_description',
                              content
                            )
                          }
                          modules={{
                            toolbar: [['link']],
                          }}
                          formats={[
                            'size',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                          ]}
                        />
                        {/*}*/}

                        {business.programShortDesc &&
                        business.programShortDesc.length === 245 ? (
                          <p name="error" className="error-message">
                            You have reached to maximum characters limit
                            allowed.
                          </p>
                        ) : null}
                        {/*{(blankError || showError.programShortDesc) && business.programShortDesc === "" ?
                     <p name="error" className="error-message">Please enter a Short Description that explains your
                     program best.</p> : null}*/}
                        {validationError.short_description && (
                          <p name="error" className="error-message">
                            Please enter a Short Description that explains your
                            program best.
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <div className="about-description-box">
                        <div
                          style={{ display: 'flex', minHeight: '40px' }}
                          className={'company-logo'}
                        >
                          <label>What is the first step?</label>
                        </div>
                        <ReactQuill
                          value={!clearForm ? business.long_description : ''}
                          style={{ height: 130, marginBottom: 60 }}
                          readOnly={disabled}
                          onChange={(content) =>
                            this.handleBusinessAboutChange(
                              'long_description',
                              content
                            )
                          }
                          modules={{
                            toolbar: [['link']],
                          }}
                          formats={[
                            'size',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                          ]}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <div className="about-description-box">
                        <div
                          style={{ display: 'flex', minHeight: '40px' }}
                          className={'company-logo'}
                        >
                          <label>Who is this service for?</label>
                        </div>
                        <ReactQuill
                          value={!clearForm ? business.program_eligibility : ''}
                          style={{ height: 130, marginBottom: 60 }}
                          onChange={(content) =>
                            this.handleBusinessAboutChange(
                              'program_eligibility',
                              content
                            )
                          }
                          modules={{
                            toolbar: [['link']],
                          }}
                          readOnly={disabled}
                          formats={[
                            'size',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                          ]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      {' '}
                      <div className="about-description-box">
                        <div
                          style={{ display: 'flex', minHeight: '40px' }}
                          className={'company-logo'}
                        >
                          <label>
                            What is the cost, do you accept insurance?
                          </label>
                        </div>
                        <ReactQuill
                          style={{ minHeight: 130 }}
                          value={
                            !clearForm
                              ? business.financialInformation != undefined &&
                                business.financialInformation !== ''
                                ? business.financialInformation
                                : ''
                              : ''
                          }
                          onChange={(content) =>
                            this.handleBusinessAboutChange(
                              'financialInformation',
                              content
                            )
                          }
                          modules={{
                            toolbar: [['link']],
                          }}
                          readOnly={disabled}
                          formats={[
                            'size',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                          ]}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className={'validation-text'}>
                    <p>
                      <span className="required-label">*</span> These fields are
                      required
                    </p>
                  </div>
                </div>
              </TabContainer>
              <TabContainer>
                <div className="div-wrapper">
                  <p className={'validation-text'}>
                    All the fields are optional
                  </p>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      {' '}
                      <div className="admin-program-form-group">
                        <div className="company-logo">
                          <label>Add Logo/Photos</label>
                        </div>
                        <div className={'input-box'}>
                          <Button
                            variant="outlined"
                            style={{ width: '100%' }}
                            className={classes.addLogo}
                            onClick={this.handlePhotos}
                            disabled={disabled}
                          >
                            <div className="button-label">
                              Add a photo
                              <div className={'up-icon'}>
                                <FontAwesomeIcon icon="upload" />
                              </div>
                            </div>
                          </Button>
                        </div>
                        <input
                          disabled={disabled}
                          onChange={(e) => this.onPhotoUpload(e)}
                          multiple={true}
                          accept="image/*"
                          type="file"
                          id="photo-upload"
                          style={{ display: 'none' }}
                        />
                      </div>
                      {this.renderImages()}
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          width: '100%',
                        }}
                      >
                        {display_images.length > 0 &&
                          display_images.map((image, i) => (
                            <div
                              key={i}
                              className="uploaded-image"
                              style={{ margin: '0 0 1% 1%' }}
                            >
                              <div className="thumbnail-container">
                                <img
                                  src={image}
                                  alt="logo"
                                  className="thumbnail-image"
                                />
                                <div className="image-overlay">
                                  <div className="remove-image">
                                    <IconButton
                                      onClick={() =>
                                        this.removeImage(
                                          business.gallery_images[i],
                                          image
                                        )
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {/*<div className="admin-program-form-group">*/}
                      {/*<div className={'company-logo'}>*/}
                      {/*  <label>Add directions</label>*/}
                      {/*  <FieldPopup id="google-url-field-popup" open={anchor === "google-url-field"}*/}
                      {/*              anchorEl={anchor === "google-url-field" ? anchorEl : undefined}*/}
                      {/*              onClose={this.closePopup}/>*/}
                      {/*  /!*<Info className="info-icon" alt=""*/}
                      {/*      aria-owns={anchor === "google-url-field" ? 'field-popup' : undefined}*/}
                      {/*      onMouseEnter={this.openPopup} id="google-url-field"*/}
                      {/*/>*!/*/}
                      {/*</div>*/}
                      {/*<div className={'input-box'}>*/}
                      {/*  <TextField*/}
                      {/*      disabled={disabled}*/}
                      {/*      name="google_direction_url"*/}
                      {/*      value={!clearForm ? (googleDirectionUrl || "") : ""}*/}
                      {/*      onChange={this.handleBusinessChange}*/}
                      {/*      type="text" variant="outlined"*/}
                      {/*      classes={{root: matches ? classes.phone : classes.pc + ' direction-parent-div'}}*/}
                      {/*      InputProps={{*/}
                      {/*        endAdornment: (*/}
                      {/*            <InputAdornment position="end">*/}
                      {/*              <div className={'direction-icon'}><FontAwesomeIcon icon={faDirections} /></div>*/}
                      {/*            </InputAdornment>*/}
                      {/*        ),*/}
                      {/*      }}*/}
                      {/*  />*/}
                      {/*</div>*/}
                      {/*</div>*/}
                      <div className="admin-program-form-group">
                        <div className="company-logo">
                          <label>Attach a Flyer or Application2</label>
                        </div>
                        <div className={'input-box'}>
                          <div className="custom-upload">
                            <Button
                              variant="outlined"
                              style={{ width: '100%' }}
                              className={classes.addLogo}
                              onClick={this.handlePdf}
                              disabled={disabled}
                            >
                              <div className="button-label">
                                Add a pdf
                                <div className={'up-icon'}>
                                  <FontAwesomeIcon icon="upload" />
                                </div>
                              </div>
                            </Button>
                          </div>
                          <input
                            disabled={disabled}
                            onChange={(e) => {
                              this.onPdfUpload(e)
                              e.target.value = null
                            }}
                            multiple={false}
                            accept="application/pdf"
                            type="file"
                            id="pdf-upload"
                            style={{ display: 'none' }}
                          />
                          {pdf_file && (
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: '100%',
                              }}
                            >
                              <div
                                className="uploaded-image"
                                style={{ margin: '0 0 1% 1%' }}
                              >
                                <div className="thumbnail-container">
                                  {/*<img src={image} alt="logo" className="thumbnail-image"/>*/}
                                  <div className="image-overlay img-overlay">
                                    <div className="remove-image">
                                      <IconButton
                                        onClick={() =>
                                          this.setState({ pdf_file: '' })
                                        }
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {business.pdf_file !== '' &&
                            typeof business.pdf_file === 'string' && (
                              <div className={'company-logo'}>
                                <h4>Flyer</h4>
                                <Paper className={'border-radius-paper'}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <div className={'pdf-name'}>
                                      {decodeURI(pdf)}
                                    </div>
                                    <Button
                                      disabled={disabled}
                                      style={{
                                        color: 'blue',
                                        justifyContent: 'flex-end',
                                        width: '50%',
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          openDialog: true,
                                          businessId: business.id,
                                          deleteItemName: 'flyer',
                                        })
                                      }
                                      classes={{ root: classes.imageActions }}
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                </Paper>
                              </div>
                            )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      {/*  <div className="admin-program-form-group">*/}
                      {/*  <div className="company-logo">*/}
                      {/*    <label>Attach a Program Flyer</label>*/}
                      {/*  </div>*/}
                      {/*  <div className={'input-box'}>*/}
                      {/*    <div className="custom-upload">*/}
                      {/*      <Button variant="outlined" style={{width: "100%"}} className={classes.addLogo}*/}
                      {/*              onClick={this.handlePdf} disabled={disabled}>*/}
                      {/*        <div className="button-label">*/}
                      {/*          Add a pdf*/}
                      {/*          <div className={'up-icon'}><FontAwesomeIcon icon="upload"/></div>*/}
                      {/*        </div>*/}
                      {/*      </Button>*/}
                      {/*    </div>*/}
                      {/*    <input*/}
                      {/*        disabled={disabled}*/}
                      {/*        onChange={(e) => {*/}
                      {/*          this.onPdfUpload(e)*/}
                      {/*          e.target.value=null*/}
                      {/*        }}*/}
                      {/*        multiple={false}*/}
                      {/*        accept="application/pdf"*/}
                      {/*        type="file"*/}
                      {/*        id="pdf-upload"*/}
                      {/*        style={{display: "none"}}*/}
                      {/*    />*/}
                      {/*    {*/}
                      {/*      pdf_file && <div style={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>*/}
                      {/*        <div className="uploaded-image" style={{margin: '0 0 1% 1%'}}>*/}
                      {/*          <div className="thumbnail-container">*/}
                      {/*            /!*<img src={image} alt="logo" className="thumbnail-image"/>*!/*/}
                      {/*            <div className="image-overlay img-overlay">*/}
                      {/*              <div className="remove-image">*/}
                      {/*                <IconButton onClick={() => this.setState({pdf_file: ''})}>*/}
                      {/*                  <RemoveIcon/>*/}
                      {/*                </IconButton>*/}
                      {/*              </div>*/}
                      {/*            </div>*/}
                      {/*          </div>*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    }*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      <div className="admin-program-form-group">
                        <div className="company-logo">
                          <label>Link to a video on YouTube</label>
                        </div>
                        <div className={'input-box'}>
                          <TextField
                            id="youtubeVideos"
                            disabled={disabled}
                            name="youtubeVideos"
                            value={
                              business.youtubeVideos
                                ? business.youtubeVideos[0]
                                : ''
                            }
                            onChange={this.handleVideos}
                            type="text"
                            variant="outlined"
                            classes={{
                              root: matches ? classes.phone : classes.pc,
                            }}
                          />
                        </div>
                      </div>
                    </Grid>

                    {(auth.accessType === 'Super Admin' ||
                      auth.accessType === 'Admin' ||
                      (auth.email.toLowerCase() ===
                        business.email.toLowerCase() &&
                        auth.accessType === 'User')) && (
                      <Grid item xs={12} lg={6}>
                        <div className="admin-program-form-group">
                          <div className="company-logo">
                            <label>Notes for Admin</label>
                          </div>
                          <div className={'input-box'}>
                            <TextField
                              id="additionalNotes"
                              disabled={disabled}
                              multiline
                              name="additionalNotes"
                              value={business.additionalNotes}
                              onChange={this.handleAdditionalNotes}
                              type="text"
                              variant="outlined"
                              classes={{
                                root: matches ? classes.phone : classes.pc,
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12} lg={6}>
                      <div className="admin-program-form-group">
                        <div className="company-logo">
                          <label>Search Keywords</label>
                        </div>
                        <div className={'input-box'}>
                          <TextField
                            id="searchKeywords"
                            disabled={disabled}
                            name="searchKeywords"
                            value={business.searchKeywords}
                            onChange={this.handlesearchKeywords}
                            type="text"
                            variant="outlined"
                            classes={{
                              root: matches ? classes.phone : classes.pc,
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  {Object.keys(error).filter((value) => {
                    if (error[value]) {
                      return value
                    }
                  }).length > 0 && (
                    <p className="schedule-error-message" name="error">
                      Please select valid hours of operations for your program.
                    </p>
                  )}
                </div>
              </TabContainer>
            </SwipeableViews>
          </div>
        )) || (
          <div className="schedule-phone">
            {allTabs.map((data1, index) => {
              return (
                <div className="program-category" key={data1}>
                  <div onClick={(e) => this.handleChangeTab(e, index)}>
                    <FormControlLabel
                      className={
                        activeTab == index
                          ? 'schedule-title-active'
                          : 'schedule-title'
                      }
                      label={<span className="category-title">{data1}</span>}
                      control={
                        <FontAwesomeIcon
                          icon={
                            activeTab == index ? faChevronDown : faChevronUp
                          }
                        />
                      }
                    />
                  </div>
                  <Collapse className="timing-collapse" in={activeTab == index}>
                    {this.getMobileComponents(index)}
                  </Collapse>
                </div>
              )
            })}
            {Object.keys(error).filter((value) => {
              if (error[value]) {
                return value
              }
            }).length > 0 && (
              <p className="schedule-error-message" name="error">
                Please select valid hours of operations for your program.
              </p>
            )}
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      business,
      clearFields,
      loading,
      anchorEl,
      anchor,
      openpop,
      success,
      notification,
      openDialog,
      unClaim,
      viewEdits,
      review,
      openReview,
      question,
      url,
      openQuestion,
      alert,
      open,
      deleteId,
      businessId,
      deleteItemName,
    } = this.state
    const {
      matches,
      classes,
      auth,
      deleteProgram,
      clearFormFields,
      history,
      isMobile,
      businessState,
    } = this.props
    const isAdmin = Object.keys(auth).length && auth.accessType === 'Admin'
    const clearForm = clearFormFields || clearFields
    let alertNew = {}
    const disabled = auth.accessType === 'Read Only' ? true : false
    if (businessState.loadingBusiness) {
      return (
        <div className="edit_loader">
          <CircularProgress
            className={classes.progress}
            style={{ color: '#FFFFFF' }}
          />
        </div>
      )
    }
    return (
      <div className="program-info-admin-container" id="container-element">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.closeNotification}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
              root: success ? classes.successNotif : classes.errorNotif,
            },
          }}
          message={notification}
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.closeNotification}
            >
              <CancelCircle />
            </IconButton>
          }
        />

        <EditsPopup
          open={viewEdits}
          alert={alertNew}
          matches={matches}
          onClose={this.closeEdits}
        />
        <ReviewPopup
          review={review}
          open={openReview}
          onClose={this.closeReviewPopup}
          auth={auth}
          matches={matches}
        />
        <QuestionPopup
          question={question}
          open={openQuestion}
          onClose={this.closeQuestionPopup}
          auth={auth}
          matches={matches}
        />
        <ImagePopup
          showApproveButton={false}
          open={openpop}
          url={url}
          onClose={this.closeImagePopup}
          matches={matches}
          alert={this.state.selectedImageAlert}
          businessId={this.state.businessId}
          approveImage={this.approveImage}
        />

        <div className="program-info-admin-form-container">
          {isMobile ? (
            <label>
              <span
                className="close-listing"
                onClick={() => this.goToHomePage('Close')}
              >
                &times;
              </span>
            </label>
          ) : null}
          {this.renderProgramForm()}
          <div className="admin-admin-program-form-group">
            <div className="admin-program-button-group btn-wrap-mobile btn-weapper">
              <MUIButton
                hidden={auth.accessType === 'Read Only'}
                onClick={() => this.updateProgram('Draft')}
                style={{ textTransform: 'none' }}
                variant="contained"
                className={'btn-saveanddra'}
              >
                <FontAwesomeIcon icon={faSave} />
                Save for later
              </MUIButton>
              {auth.accessType !== 'Read Only' && (
                <MUIButton
                  onClick={() => this.updateProgram('Published')}
                  variant="contained"
                  disabled={loading}
                  className={'btn-saveandpub'}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ height: '25px', width: '25px', color: 'white' }}
                    />
                  ) : (
                    'Go Live Now!'
                  )}
                </MUIButton>
              )}
            </div>
          </div>

          <Dialog open={openDialog} onClose={this.closeDialog}>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this {deleteItemName}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MUIButton autoFocus onClick={this.closeDialog} color="primary">
                No
              </MUIButton>
              <MUIButton
                onClick={() => {
                  if (deleteItemName === 'image') {
                    this.removeImagePhoto(deleteId, businessId)
                  } else if (deleteItemName === 'review') {
                    this.removeReview(deleteId, businessId)
                  } else if (deleteItemName === 'question') {
                    this.removeQuestion(deleteId, businessId)
                  } else if (deleteItemName === 'flyer') {
                    this.removeFlyer(businessId)
                  }
                  this.closeDialog()
                }}
                color="primary"
              >
                Yes
              </MUIButton>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  return {
    auth: state.auth,
    category: state.category,
    businessState: state.business,
    user: state.user.allUsers,
  }
}

const mapDispatch = (dispatch) => {
  return {
    getAllCategories: bindActionCreators(getAllCategories, dispatch),
    updateBusiness: bindActionCreators(updateBusiness, dispatch),
    approveClaim: bindActionCreators(approveClaim, dispatch),
    businessActions: bindActionCreators(businessActions, dispatch),
    reviewActions: bindActionCreators(reviewActions, dispatch),
    questionActions: bindActionCreators(questionActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  }
}

export default connect(
  mapState,
  mapDispatch
)(withRouter(withStyles(styles)(ProgramInfo)))
