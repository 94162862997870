import React, { Component } from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  withStyles,
  Select,
  IconButton,
  MenuItem,
  Input,
  Paper as MUIPaper,
  TableFooter,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar as MUISnackbarContent,
  CircularProgress,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CancelRounded } from '@material-ui/icons'
import { ExportToCsv } from 'export-to-csv'
import 'react-quill/dist/quill.snow.css'
import * as businessActions from '../../../actions/business.action'
import './MainWindow.css'
import HeaderMenu from '../../Menu/Menu'
import add_new_program from '../../../assets/images/add_new_program.png'
import export_to_csv from '../../../assets/images/export_to_csv.png'
import green_check from '../../../assets/images/green_check.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy,
  faEye,
  faSearch,
  faTrashAlt,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import htmlToText from 'html-to-text'
import moment from 'moment'
import { searchAllBusinesses } from '../../../service/business.service'
import { copyBusiness } from '../../../actions/business.action'

var _ = require('lodash')

const styles = () => ({
  csvButton: {
    background: '#245497',
    fontWeight: 'bold',
    color: '#fff',
    marginRight: '30px',
    '&:hover': {
      background: '#1f447e',
    },
  },
  viewBtn: {
    color: '#fff',
    background: '#32B241',
    marginRight: '5%',
    '&:hover': {
      background: '#2f973e',
    },
  },
  deleteBtn: {
    color: '#fff',
    background: '#bf1a05',
    '&:hover': {
      background: '#aa1905',
    },
  },
  duplicateBtn: {
    color: '#fff',
    background: '#245497',
    marginRight: '5%',
    minWidth: 'fit-content',
    '&:hover': {
      background: '#1f447e',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  formControl: {
    marginRight: '30px',
    minWidth: 120,
    maxWidth: 300,
    verticalAlign: 'unset',
  },
  successNotif: {
    backgroundColor: '#43a047',
  },
  errorNotif: {
    backgroundColor: '#d32f2f',
  },
})

const TableHeadCell = withStyles({
  root: {
    fontSize: 14,
    color: '#3c3c3c',
    fontWeight: 800,
  },
})(TableCell)

const Paper = withStyles({
  root: {
    padding: '1%',
    width: '100%',
    marginTop: '2%',
    borderRadius: 0,
  },
})(MUIPaper)

const Snackbar = withStyles({
  root: {
    maxWidth: '700px',
  },
})(MUISnackbarContent)

class MyListing extends Component {
  state = {
    businesses: [],
    entries: 10,
    count: 0,
    pageNum: 1,
    order: 1,
    sortBy: 'program_name',
    search: '',
    searching: false,
    allBusinesses: [],
    openDialog: false,
    businessId: -1,
    FilterValue: [
      { id: 'address_1', value: 'Address' },
      { id: 'state', value: 'State' },
      { id: 'city', value: 'City' },
      { id: 'postal_code', value: 'Zip' },
      { id: 'short_description', value: 'Short Description' },
      { id: 'long_description', value: 'Long Description' },
      { id: 'program_eligibility', value: 'Eligibility' },
      { id: 'financialInformation', value: 'Financial information' },
      { id: 'languages', value: 'Language' },
    ],
    SelectedValue: [],
    notification: ``,
    open: false,
    success: false,
    dialogText: '',
    businessUsers: [],
    isMobile: false,
    sampleSearch: '',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let allBusinesses
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    if (prevState.isMobile !== isMobile.any()) {
      return {
        isMobile: isMobile.any(),
      }
    }

    return {}
  }

  componentDidMount() {
    let isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i)
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i)
      },
      any: function () {
        return Boolean(
          isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
      },
    }
    this.setState({ isMobile: isMobile.any() })
    if (this.props.auth.token !== '') {
      let { pageNum, order, sortBy, search } = this.props.business.paginatedData
      const { auth } = this.props
      this.setState(
        {
          ...this.state,
          pageNum,
          order,
          sortBy,
          search,
          searching: search ? true : false,
        },
        () => {
          let { entries, pageNum, order, sortBy } = this.state

          this.getAllBusinessApi(
            entries,
            pageNum,
            order,
            sortBy,
            this.props.auth.email
          )
        }
      )
      if (search.trim()) {
        let allBusinesses = this.props.business.allBusinesses.filter(
          (b) =>
            Boolean(
              b.business_name &&
                b.business_name.toUpperCase().match(search.toUpperCase())
            ) ||
            Boolean(
              b.program_name &&
                b.program_name.toUpperCase().match(search.toUpperCase())
            ) ||
            Boolean(
              b.email && b.email.toUpperCase().match(search.toUpperCase())
            )
        )
        this.setState((prevState) => {
          return {
            ...prevState,
            businesses: allBusinesses,
            allBusinesses,
          }
        })
      }
      let { entries } = this.state
      if (this.props.auth.accessType) {
        this.getAllBusinessApi(
          entries,
          pageNum,
          order,
          sortBy,
          this.props.auth.email
        )
      } else {
        this.props.history.push('/')
      }
    }
  }

  getAllBusinessApi = (entries, pageNum, order, sortBy, email) => {
    this.props.businessActions
      .getAllBusiness(entries, pageNum, order, sortBy, this.state.search)
      .then((data) => {
        if (data) {
          this.setState({
            businesses: data.business,
            count: data.count,
          })
        }
      })
  }

  getUnfilteredBusinessApi = (email) => {
    if (email) {
      this.props.businessActions
        .getUnfilteredBusiness(this.props.auth.email)
        .catch((err) => {
          console.log('err', err)
        })
    } else {
      this.props.businessActions.getUnfilteredBusiness().then((data) => {
        this.setState({
          allBusinesses: data.business,
        })
      })
    }
  }

  confirmDelete = (businessId, dialogText) => {
    this.setState({ openDialog: true, businessId, dialogText })
  }

  closeDialog = () => {
    this.setState({ openDialog: false, businessId: -1 })
  }

  renderPagination = (pageNum, pages) => {
    const { isMobile } = this.state
    if (pages.length <= (isMobile ? 3 : 6)) {
      return pages.map((a, i) => (
        <div
          style={{ backgroundColor: pageNum === i + 1 ? '#ececec' : null }}
          onClick={() => this.changePage(i + 1)}
          className="page-btn"
          key={i}
        >
          {i + 1}
        </div>
      ))
    } else {
      let pagination = ''
      if (isMobile) {
        if (pageNum < 2) {
          pagination = (
            <div>
              {pages.map((a, i) =>
                i < 2 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changePage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
              <div className="page-btn">...</div>
              <div
                style={{
                  backgroundColor: pageNum === pages.length ? '#ececec' : null,
                }}
                onClick={() => this.changePage(pages.length)}
                className="page-btn"
              >
                {pages.length}
              </div>
            </div>
          )
        } else if (pageNum >= 2 && pageNum < pages.length - 1) {
          pagination = (
            <div>
              <div
                style={{ backgroundColor: pageNum === 1 ? '#ececec' : null }}
                onClick={() => this.changePage(1)}
                className="page-btn"
              >
                {1}
              </div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i > pageNum - 2 && i < pageNum + 1 && i < pages.length - 1 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changePage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
              <div className="page-btn">...</div>
              <div
                style={{
                  backgroundColor: pageNum === pages.length ? '#ececec' : null,
                }}
                onClick={() => this.changePage(pages.length)}
                className="page-btn"
              >
                {pages.length}
              </div>
            </div>
          )
        } else if (pageNum >= pages.length - 2) {
          pagination = (
            <div>
              <div
                style={{ backgroundColor: pageNum === 1 ? '#ececec' : null }}
                onClick={() => this.changePage(1)}
                className="page-btn"
              >
                {1}
              </div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i >= pages.length - 2 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changePage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
            </div>
          )
        }
      } else {
        if (pageNum < 5) {
          pagination = (
            <div>
              {pages.map((a, i) =>
                i < 5 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changePage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
              <div className="page-btn">...</div>
              <div
                style={{
                  backgroundColor: pageNum === pages.length ? '#ececec' : null,
                }}
                onClick={() => this.changePage(pages.length)}
                className="page-btn"
              >
                {pages.length}
              </div>
            </div>
          )
        } else if (pageNum >= 5 && pageNum < pages.length - 4) {
          pagination = (
            <div>
              <div
                style={{ backgroundColor: pageNum === 1 ? '#ececec' : null }}
                onClick={() => this.changePage(1)}
                className="page-btn"
              >
                {1}
              </div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i > pageNum - 2 && i < pageNum + 3 && i < pages.length - 1 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changePage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
              <div className="page-btn">...</div>
              <div
                style={{
                  backgroundColor: pageNum === pages.length ? '#ececec' : null,
                }}
                onClick={() => this.changePage(pages.length)}
                className="page-btn"
              >
                {pages.length}
              </div>
            </div>
          )
        } else if (pageNum >= pages.length - 5) {
          pagination = (
            <div>
              <div
                style={{ backgroundColor: pageNum === 1 ? '#ececec' : null }}
                onClick={() => this.changePage(1)}
                className="page-btn"
              >
                {1}
              </div>
              <div className="page-btn">...</div>
              {pages.map((a, i) =>
                i >= pages.length - 5 ? (
                  <div
                    style={{
                      backgroundColor: pageNum === i + 1 ? '#ececec' : null,
                    }}
                    onClick={() => this.changePage(i + 1)}
                    className="page-btn"
                    key={i}
                  >
                    {i + 1}
                  </div>
                ) : null
              )}
            </div>
          )
        }
      }
      return pagination
    }
  }

  onChangeEntries = (e) => {
    let { entries, pageNum, count, order, sortBy } = this.state
    let { auth } = this.props
    let email = null
    if (auth.accessType === 'User') email = auth.email
    let pages = Math.ceil(count / e.target.value)
    if (pageNum > pages) {
      pageNum = pages
    }
    if (entries !== e.target.value) {
      this.setState(
        {
          entries: e.target.value,
          pageNum,
        },
        () => {
          this.getAllBusinessApi(
            e.target.value,
            this.state.pageNum,
            order,
            sortBy,
            email
          )
        }
      )
    }
  }

  changePage = (page) => {
    let { entries, pageNum, order, sortBy } = this.state
    let { auth } = this.props
    let email = null
    if (auth.accessType === 'User') email = auth.email
    if (pageNum !== page) {
      this.setState(
        {
          pageNum: page,
        },
        () => {
          this.getAllBusinessApi(entries, page, order, sortBy, email)
        }
      )
    }
  }

  sortPrograms = (orderArg, sortByArg) => {
    const { order, sortBy, entries, pageNum, allBusinesses } = this.state
    let { auth } = this.props
    let email = null
    if (auth.accessType === 'User') email = auth.email
    if (order !== orderArg || sortBy !== sortByArg) {
      this.setState(
        {
          order: orderArg,
          sortBy: sortByArg,
        },
        () => {
          this.getAllBusinessApi(entries, pageNum, orderArg, sortByArg, email)

          this.props.businessActions
            .getAllBusiness(allBusinesses.length, 1, orderArg, sortByArg, email)
            .then((data) => {
              this.setState({
                pageNum: 1,
                allBusinesses: data.business,
              })
            })
        }
      )
    }
  }

  searchProgramSubmit = () => {
    let { entries, pageNum, order, sortBy, search } = this.state
    this.props.businessActions
      .getAllBusiness(entries, 1, order, sortBy, search)
      .then((data) => {
        this.setState({
          pageNum: 1,
          businesses: data.business,
          count: data.count,
          searching: false,
        })
      })
  }

  searchProgram = (e) => {
    let { value } = e.target
    this.setState((prevState) => ({
      ...prevState,
      search: value,
    }))
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.searchProgramSubmit()
    }
  }

  addProgram = () => {
    this.props.history.push('/new/program')
  }

  // componentDidUpdate(prevProps) {
  //   console.log('nextProps', prevProps)
  //   this.setState({
  //     business: prevProps.business,
  //     allBusinesses: prevProps.allBusinesses,
  //   })
  // }

  viewDetails = (id, alert) => {
    const { pageNum, order, sortBy, search } = this.state
    if (alert) {
      this.props.history.push({
        pathname: `/my-listing/details/${id}`,
        state: { alert },
      })
    } else {
      this.props.businessActions.setPaginatedData(
        pageNum,
        order,
        sortBy,
        search
      )
    }
    this.props.history.push(`/my-listing/details/${id}`)
  }

  deleteBusiness = (id) => {
    this.closeDialog()
    this.props.businessActions.deleteBusiness(id).then(() => {
      this.setState({
        businesses: this.props.business.businesses,
      })
    })
  }

  duplicateDetails = (bussines) => {
    let duplicateData = _.cloneDeep(bussines)
    duplicateData.id = ''

    this.props.businessActions
      .copyBusiness(duplicateData)
      .then(() => {
        this.setState({
          open: true,
          notification: `Item added to 1 position`,
          success: true,
          pageNum: 1,
          order: 1,
          sortBy: 'program_name',
          search: '',
          searching: false,
          businesses: this.props.business.businesses,
          allBusinesses: this.props.business.allBusinesses,
        })
      })
      .catch((error) =>
        this.setState({
          open: true,
          notification: `Listing duplication failed, please try again...`,
          success: false,
        })
      )
  }

  handleClose = () => {
    this.setState({ open: false, notification: '' })
  }

  FilterhandleChange = (e) => {
    this.setState({ ...this.state, SelectedValue: [...e.target.value] })
  }

  phoneNumberFormatter = (phoneNumber) => {
    var match
    if (phoneNumber) {
      if (phoneNumber.toString().match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)) {
        if (phoneNumber.toString().match(/^\d{10}$/)) {
          match = phoneNumber
            .toString()
            .replace(/\D /g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          if (match) {
            return match
          }
        }
        return phoneNumber
      }
      return phoneNumber
    }
  }

  exportToCSV = (data, businessUSer) => {
    let formattedData = []
    const { SelectedValue } = this.state
    // const keys = ["business_name", "program_name", "approval_status", "category", "email",...SelectedValue.map(value=>value.id)];
    const keys = [
      'updatedAt',
      'business_name',
      'program_name',
      'program_website',
      'phone_num',
      'crisis_toll_free_num',
      'address',
      'city',
      'state',
      'postal_code',
      'email',
      'donation_info',
      'short_description',
      'long_description',
      'program_eligibility',
      'financialInformation',
      'youtubeVideos',
      'pdf_file',
      'approval_status',
      'category',
      'sub_category',
      'additionalNotes',
      ...SelectedValue.map((value) => value.id),
    ]
    data.forEach((data1) => {
      Object.keys(data1)
        .map((value) => {
          if (!Array.isArray(data1[value]) && typeof data1[value] === 'object')
            data1 = { ...data1, ...data1[value] }
        })
        .filter((key) => !keys.includes(key))
        .forEach((key) => delete data1[key])
      data1.category = data1.category ? data1.category.join(',') : []
      data1.sub_category = data1.sub_category
        ? data1.sub_category.join(',')
        : []
      let newAddress = []
      // console.log("data1.youtubeVideos",data1.youtubeVideos)
      data1.phone_num = this.phoneNumberFormatter(data1.phone_num) || ''
      data1.city = data1.address.city
      data1.state = data1.address.state
      if (data1.address.address_1) newAddress.push(data1.address.address_1)
      if (data1.address.address_2) newAddress.push(data1.address.address_2)
      data1.address = newAddress.length > 0 ? newAddress.join(',') : ''
      data1.financialInformation = htmlToText.fromString(
        data1.financialInformation
      )
      data1.short_description = htmlToText.fromString(data1.short_description)
      data1.long_description = htmlToText.fromString(data1.long_description)
      data1.program_eligibility = htmlToText.fromString(
        data1.program_eligibility
      )
      data1.youtubeVideos =
        data1.youtubeVideos && !data1.youtubeVideos.includes('false')
          ? data1.youtubeVideos.join(',')
          : ''
      data1.pdf_file = data1.pdf_file
        ? data1.pdf_file.split('/')[data1.pdf_file.split('/').length - 1]
        : ''
      data1.updatedAt = data1.updatedAt
        ? moment.utc(data1.updatedAt).local().format('MM/DD/YYYY')
        : ''
      let orderedData = {}
      keys.forEach((key) => {
        orderedData[key] = data1[key]
      })
      // _.find(businessUSer, (o) => {
      //   if(o.email === data1.email){
      //     orderedData['business_name'] = o.user && o.user.businessName || '';
      //   }
      // })
      orderedData['email'] =
        data1['email'] !== 'andrea.labelle@me.com' ? data1['email'] : ''
      formattedData.push(orderedData)
    })

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      // showTitle: true,
      filename: 'ProgramEntries',
      // title: 'Program Entries CSV',
      useTextFile: false,
      useBom: true,
      headers: [
        'Timestamp',
        'Organization Name',
        'Program Name',
        'Program Website',
        'Phone Number',
        'Crisis number',
        'Address',
        'City',
        'State',
        'Zip',
        'Email',
        'Donation Info',
        'Program Description',
        'Addtional Information',
        'Eligibility',
        'Financial Information',
        'Youtube Videos',
        'Flyer PDF',
        'Approval Status',
        'Category',
        'Sub Category',
        'Notes for Admin',
        ...SelectedValue.map((value) => value.value),
      ],
      // headers: ['Business Name', 'Program Name', 'Approval Status', 'Category', 'Email',...SelectedValue.map(value=>value.value)],
    }
    const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(formattedData)
  }

  render() {
    const { classes, auth, matches, business } = this.props
    const {
      businesses,
      entries,
      count,
      pageNum,
      order,
      sortBy,
      searching,
      search,
      allBusinesses,
      isMobile,
      openDialog,
      businessId,
      FilterValue,
      SelectedValue,
      notification,
      open,
      success,
      dialogText,
      businessUsers,
    } = this.state
    const data = searching ? allBusinesses : businesses
    let pages = new Array(Math.ceil(count / entries)).fill('a')
    if (business.loadingBusiness) {
      return (
        <div className="loader">
          <CircularProgress
            className={classes.progress}
            style={{ color: '#FFFFFF' }}
          />
        </div>
      )
    }
    return (
      <div className="admin-main-container">
        <div className={classes.root}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {
                root: success ? classes.successNotif : classes.errorNotif,
              },
              style: { maxWidth: '631px' },
            }}
            message={
              <span>
                {Array.isArray(notification)
                  ? notification.map((x, key) => <span key={key}>{x}</span>)
                  : notification}
              </span>
            }
            action={
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CancelRounded />
              </IconButton>
            }
          />
        </div>
        <div className="admin-main">
          <div
            className="header-wrap d-flex header-display"
            style={{ background: 'transparent' }}
          >
            <HeaderMenu matches={matches} svgSize="25" paddingReduce={true} />
          </div>
          <Dialog open={openDialog} onClose={this.closeDialog}>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this {dialogText}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={this.closeDialog} color="primary">
                No
              </Button>
              <Button
                onClick={() => this.deleteBusiness(businessId)}
                color="primary"
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <div className={'my-account-text'}>MY ACCOUNT</div>
          <Paper
            style={{
              padding: matches ? 10 : '0%',
              overflowX: 'auto',
              width: 'auto',
              borderRadius: '5px',
            }}
          >
            <div className={'data-title'}>
              <h4 className={'program-entries-header'}>My Listings</h4>
              <div className="table-header">
                <div className="export-wrap">
                  <Button
                    classes={{ root: classes.csvButton + ' web-btn' }}
                    onClick={() => {
                      this.props.history.push('/new/program')
                    }}
                  >
                    <img
                      src={add_new_program}
                      className={'btn-img'}
                      alt={'image'}
                    />
                    {'ADD NEW PROGRAM'}
                  </Button>
                  <Button
                    classes={{ root: classes.csvButton + ' m-btn' }}
                    onClick={() => {
                      this.props.history.push('/new/program')
                    }}
                  >
                    <img
                      src={add_new_program}
                      className={'btn-img'}
                      alt={'image'}
                    />
                    {'ADD'}
                  </Button>

                  <Button
                    classes={{ root: classes.csvButton + ' web-btn' }}
                    onClick={() => {
                      this.exportToCSV(businesses, businessUsers)
                    }}
                  >
                    <img
                      src={export_to_csv}
                      className={'btn-img'}
                      alt={'image'}
                    />
                    {'EXPORT TO CSV'}
                  </Button>
                  <Button
                    classes={{ root: classes.csvButton + ' m-btn' }}
                    onClick={() => {
                      this.exportToCSV(businesses, businessUsers)
                    }}
                  >
                    <img
                      src={export_to_csv}
                      className={'btn-img'}
                      alt={'image'}
                    />
                    {'EXPORT'}
                  </Button>
                  <label className={'show-page-label'}>
                    Show per Page
                    <Select
                      disabled={searching}
                      onChange={this.onChangeEntries}
                      value={entries}
                      input={
                        <Input type="number" style={{ fontSize: '14px' }} />
                      }
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </label>
                  <div className={'search-box'}>
                    <Input
                      type={`text`}
                      value={this.state.search}
                      disabled={false}
                      onChange={this.searchProgram}
                      endAdornment={<FontAwesomeIcon icon={faSearch} />}
                      onKeyDown={this.handleKeyDown}
                      placeholder={'Search'}
                      className={'label-name'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={'data-table'}>
              {data && data.length > 0 ? (
                <Table style={{ fontFamily: 'Roboto' }}>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell
                        className={'number-font-color mobile-view-hide '}
                      >
                        #
                      </TableHeadCell>
                      <TableHeadCell className={'m-view-hide'}>
                        <div className="header-sort">Time Stamp</div>
                      </TableHeadCell>
                      <TableHeadCell className={'m-view-hide'}>
                        <div className="header-sort">
                          Name or Organization
                          <div className="sort-icon-group">
                            <FontAwesomeIcon
                              className={
                                order === 1 && sortBy === 'organization_name'
                                  ? 'active-sort-icon'
                                  : 'sort-icon'
                              }
                              icon={'long-arrow-alt-up'}
                              onClick={() =>
                                this.sortPrograms(1, 'organization_name')
                              }
                            />
                            <FontAwesomeIcon
                              className={
                                order === -1 && sortBy === 'organization_name'
                                  ? 'active-sort-icon'
                                  : 'sort-icon'
                              }
                              icon={'long-arrow-alt-down'}
                              onClick={() =>
                                this.sortPrograms(-1, 'organization_name')
                              }
                            />
                          </div>
                        </div>
                      </TableHeadCell>
                      <TableHeadCell>
                        <div className="header-sort">
                          Program/Event/Service
                          <div className="sort-icon-group">
                            <FontAwesomeIcon
                              className={
                                order === 1 && sortBy === 'program_name'
                                  ? 'active-sort-icon'
                                  : 'sort-icon'
                              }
                              icon={'long-arrow-alt-up'}
                              onClick={() =>
                                this.sortPrograms(1, 'program_name')
                              }
                            />
                            <FontAwesomeIcon
                              className={
                                order === -1 && sortBy === 'program_name'
                                  ? 'active-sort-icon'
                                  : 'sort-icon'
                              }
                              icon={'long-arrow-alt-down'}
                              onClick={() =>
                                this.sortPrograms(-1, 'program_name')
                              }
                            />
                          </div>
                        </div>
                      </TableHeadCell>
                      <TableHeadCell>
                        <div className="header-sort">
                          Status
                          <div className="sort-icon-group">
                            <FontAwesomeIcon
                              className={
                                order === 1 && sortBy === 'approval_status'
                                  ? 'active-sort-icon'
                                  : 'sort-icon'
                              }
                              icon={'long-arrow-alt-up'}
                              onClick={() =>
                                this.sortPrograms(1, 'approval_status')
                              }
                            />
                            <FontAwesomeIcon
                              className={
                                order === -1 && sortBy === 'approval_status'
                                  ? 'active-sort-icon'
                                  : 'sort-icon'
                              }
                              icon={'long-arrow-alt-down'}
                              onClick={() =>
                                this.sortPrograms(-1, 'approval_status')
                              }
                            />
                          </div>
                        </div>
                      </TableHeadCell>

                      <TableHeadCell align={'center'}>
                        <div className="action-div">Action</div>
                      </TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((business, i) => {
                      let temp = ''
                      _.find(businessUsers, function (o) {
                        if (o.email === business.email) {
                          temp = (o.user && o.user.businessName) || ''
                          return temp
                        }
                      })

                      return (
                        <TableRow key={business._id + i}>
                          <TableCell
                            className={
                              'tableCell-font-style number-font-color mobile-view-hide'
                            }
                          >
                            {i + 1 + (pageNum - 1) * entries}
                          </TableCell>
                          <TableCell
                            align="justify"
                            className={'tableCell-font-style m-view-hide'}
                            style={{
                              color: 'green',
                            }}
                          >
                            {business.updated_at
                              ? moment(business.updated_at).format('MM/DD/YYYY')
                              : ''}
                          </TableCell>
                          <TableCell
                            align="justify"
                            className={'tableCell-font-style m-view-hide'}
                          >
                            {business.organization_name}
                          </TableCell>
                          {/*<TableCell align="justify">{temp}</TableCell>*/}
                          <TableCell
                            className={
                              'tableCell-font-style program-font-color'
                            }
                          >
                            {business.program_name}
                          </TableCell>
                          <TableCell className={'tableCell-font-style'}>
                            {(business.approval_status === 'Published' && (
                              <div className={'status-wrap'}>
                                <img
                                  src={green_check}
                                  alt={'image'}
                                  className={'btn-img-done'}
                                />
                                <div
                                  className={business.approval_status
                                    .toLowerCase()
                                    .replace(' ', '-')}
                                >
                                  {business.approval_status}
                                </div>
                              </div>
                            )) || (
                              <div
                                className={business.approval_status
                                  .toLowerCase()
                                  .replace(' ', '-')}
                              >
                                {business.approval_status}
                              </div>
                            )}
                          </TableCell>

                          {/*<TableCell className={'tableCell-font-style'}>{business.email !== "andrea.labelle@me.com" ? (business.email && business.email.toLowerCase() || '') : ''}</TableCell>*/}
                          <TableCell className={'tableCell-font-style'}>
                            <div className={'btn-section'}>
                              {auth.accessType !== 'Read Only' && (
                                <div style={{ display: 'flex' }}>
                                  <Button
                                    className={
                                      isMobile
                                        ? 'mobile-view-hide btn-duplocate'
                                        : 'btn-duplocate'
                                    }
                                    onClick={() =>
                                      this.duplicateDetails(business)
                                    }
                                    classes={{ root: classes.duplicateBtn }}
                                  >
                                    <FontAwesomeIcon icon={faCopy} />
                                    {isMobile ? '' : ' Duplicate'}
                                  </Button>
                                </div>
                              )}
                              <div style={{ display: 'flex' }}>
                                <Button
                                  className={
                                    isMobile
                                      ? 'mobile-view-hide btn-view'
                                      : 'btn-view'
                                  }
                                  onClick={() =>
                                    this.viewDetails(business._id, null)
                                  }
                                  classes={{
                                    root: classes.viewBtn + ' mobile-view-hide',
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                  {isMobile ? '' : 'View'}
                                </Button>
                              </div>
                              {auth.accessType && (
                                <div
                                  onClick={() =>
                                    this.confirmDelete(business._id, 'program')
                                  }
                                  className={'delete-icon-div'}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                  <TableFooter hidden={searching}>
                    <TableRow>
                      <TableCell
                        component="td"
                        align="left"
                        colSpan={isMobile ? data.length + 2 : 6}
                      >
                        <div className={'showing-page-div'}>
                          Showing {1 + (pageNum - 1) * entries} to{' '}
                          {entries * pageNum < count
                            ? entries * pageNum
                            : count}{' '}
                          of {count} entries
                        </div>
                        <div className="pagination">
                          {this.renderPagination(pageNum, pages)}
                          <div
                            onClick={() =>
                              this.changePage(pageNum !== 1 ? pageNum - 1 : 1)
                            }
                            className={
                              pageNum === 1 ? 'disabled-p-btn' : 'p-btn'
                            }
                          >
                            <FontAwesomeIcon icon={faArrowLeft} />
                          </div>
                          <div
                            onClick={() =>
                              this.changePage(
                                pageNum !== pages.length
                                  ? pageNum + 1
                                  : pages.length
                              )
                            }
                            className={
                              pageNum === pages.length
                                ? 'disabled-page-btn'
                                : 'n-btn'
                            }
                          >
                            <FontAwesomeIcon icon={faArrowRight} />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              ) : (
                <div className={'no-data-div'}>No data to display</div>
              )}
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
    auth: state.auth,
    alert: state.alert,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    businessActions: bindActionCreators(businessActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MyListing))
