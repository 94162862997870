import React, { createRef, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import logo from 'assets/images/logo-2.svg'
import search from 'assets/images/search-2.svg'
import { Link, withRouter } from 'react-router-dom'
import { DropdownCustom } from '../Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearSearch,
  searchBusinesses,
  toggleMenu,
} from '../../actions/common.action'
import './headerBar.css'
import { useWindowWidth } from '../../utils/useWindowWidth'
import { MapListSwitch } from '../MapListSwitch'

const HeaderBarComponent = ({ location }) => {
  const isOpenedMenu = useSelector((state) => state.common.menu)
  const auth = useSelector((state) => state.auth.id)
  const dispatch = useDispatch()
  const width = useWindowWidth()
  const isHome = location.pathname === '/all'

  useEffect(() => {
    if (isHome) {
      dispatch(toggleMenu(false))
    }
    if (window.innerWidth < 1200) {
      dispatch(toggleMenu(false))
    }
  }, [isHome])

  const handleMenu = () => {
    dispatch(toggleMenu(!isOpenedMenu))
  }

  const isMobile = width !== 'desktop'
  const burgerButton = (
    <div
      className={`header-bar-burger ${
        isOpenedMenu ? 'header-bar-burger__active' : ''
      }`}
      onClick={handleMenu}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  )

  return (
    <>
      <div className={`header-bar ${isOpenedMenu ? 'header-bar_fixed' : ''}`}>
        <div className="header-bar-section">
          {isMobile
            ? burgerButton
            : (isHome || (!isOpenedMenu && !isHome)) && burgerButton}

          <Link to="/all" className="header-bar-logo">
            <img src={logo} alt="logo" />
          </Link>
          {isHome && <HeaderSearch dispatch={dispatch} />}
        </div>
        <div className="header-bar-section">
          {!auth && <DropdownCustom />}
        </div>
      </div>
      {isHome && isMobile && (
        <>
          {<HeaderSearch isMobile={isMobile} dispatch={dispatch} />}
          <div className="map-switch_mob">
            <MapListSwitch />
          </div>
        </>
      )}
    </>
  )
}

const HeaderSearch = ({ isMobile, dispatch }) => {
  const [value, setValue] = useState('')

  const handleSubmit = () => {
    dispatch(searchBusinesses(value))
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleClear = () => {
    setValue('')
    dispatch(clearSearch())
  }

  return (
    <div
      className={`header-bar-search ${isMobile ? 'header-bar-search_mob' : ''}`}
    >
      <button onClick={handleSubmit}>
        <img src={search} alt="search-icon" />
      </button>
      <input
        type="text"
        placeholder="Search..."
        onKeyDown={handleKeyDown}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      {value && (
        <div className="header-bar-search_clear" onClick={handleClear}>
          <span></span>
          <span></span>
        </div>
      )}
    </div>
  )
}

export const HeaderBar = withRouter(HeaderBarComponent)
