import * as services from '../service/alert.service';
import * as types from '../constants/action.constants';

export const getAlerts = (entries, pageNum, order, sortBy, email) => {
  return dispatch => {
    dispatch({ type: types.LOADING_ALERT });
    return services.getAlerts(entries, pageNum, order, sortBy, email).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.GET_ALERTS,
          alerts: resp.data,
          count: resp.data.count
        });
        return resp.data;
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.GET_ALERTS_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const getNotificationAlerts = (entries, pageNum, order, sortBy, email) => {
  return dispatch => {
    return services.getNotificationAlerts(entries, pageNum, order, sortBy, email).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.GET_NOTIFICATION_ALERTS,
          alerts: resp.data,
          count: resp.data.count
        });
        return resp.data;
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.GET_NOTIFICATION_ALERTS_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const getAlertsByBusiness = (id) => {
  return dispatch => {
    return services.getAlertsByBusiness(id).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.GET_ALERTS_BUSINESS,
          alerts: resp.data
        });
        return resp.data;
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.GET_ALERTS_BUSINESS_FAIL,
          error: err.response.data
        })
      }
    })
  }
};

export const removeAlert = (id) => {
  return dispatch => {
    return services.removeAlert(id).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.REMOVE_ALERT,
          alert: resp.data
        })
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.REMOVE_ALERT_FAIL,
          error: err.response.data
        })
      }
    })
  }
};
export const deleteAllAlert = (id,email) => {
  return dispatch => {
    return services.deleteAllAlert(id,email).then(resp=>{
      // if(resp.status===200){
      //   dispatch({
      //     type: types.REMOVE_ALERT,
      //     alert: resp.data
      //   })
      // }
    }).catch(err=>{
      // if(err.response){
      //   dispatch({
      //     type: types.REMOVE_ALERT_FAIL,
      //     error: err.response.data
      //   })
      // }
    })
  }
};

export const addAlert = (alert) => {
  return dispatch => {
    return services.addAlert(alert).then(resp=>{
      if(resp.status===200){
        dispatch({
          type: types.ADD_ALERT,
          alert: resp.data
        })
      }
    }).catch(err=>{
      if(err.response){
        dispatch({
          type: types.ADD_ALERT_FAIL,
          error: err.response.data
        })
      }
    })
  }
};