import React, { Component } from 'react'
import {
  Button as MUIButton,
  Modal,
  withStyles,
  InputBase,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
// import { GoogleLogin } from 'react-google-login';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'
import CheckCircle from '@material-ui/icons/CheckCircleOutlineTwoTone'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import {client_id} from '../../service/config';
import * as authActions from '../../actions/auth.action'
import './Login.css'
import { Close } from '@material-ui/icons'

const CheckIcon = withStyles({
  root: {
    background: '#fff',
    color: '#32B241',
    fontSize: 70,
  },
})(CheckCircle)

const Input = withStyles({
  root: {
    width: '80%',
    border: '1px solid #aaa',
    margin: 12,
    padding: '1%',
    borderRadius: 1,
    '&:hover': {
      borderColor: '#000',
    },
    '&$focused': {
      borderColor: '#245497',
    },
  },
  focused: {},
})(InputBase)

const Button = withStyles({
  root: {
    background: '#245497',
    color: '#fff',
    borderColor: '#245497',
    borderRadius: 1,
    '&:hover': {
      background: '#224989',
    },
  },
})(MUIButton)

const styles = () => ({
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '6%',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    padding: '6%',
    marginLeft: '420px',
  },
})

class Login extends Component {
  state = {
    email: '',
    password: '',
    showPassword: false,
    login: false,
    valid: true,
    blankError: { email: false, password: false },
    loginError: '',
  }

  checkValidMail = () => {
    let { email, valid } = this.state
    if (email !== '') {
      valid = Boolean(
        email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      )
      this.setState({ valid })
    } else {
      this.handleBlankError('email')
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState((state) => {
      if (name === 'email') {
        return {
          email: value,
          valid: true,
          blankError: { ...state.blankError, email: false },
        }
      } else {
        return {
          password: value,
          blankError: { ...state.blankError, password: false },
        }
      }
    })
  }

  toggleVisibility = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }))
  }

  handleBlankError = (field) => {
    this.setState((state) => ({
      blankError: { ...state.blankError, [field]: true },
    }))
  }

  handleClose = () => {
    this.setState({
      email: '',
      password: '',
      showPassword: false,
      login: false,
      valid: true,
      blankError: { email: false, password: false },
    })
    this.props.onClose()
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.login()
    }
  }

  login = async () => {
    const { email, password } = this.state
    if (email === '') await this.handleBlankError('email')
    if (password === '') await this.handleBlankError('password')
    await this.checkValidMail()
    const { valid, blankError } = this.state
    if (valid && !blankError.email && !blankError.password) {
      await this.props.login({ email, password })
      if (this.props.auth.error === '' && this.props.auth.token !== '') {
        await this.setState(
          {
            login: true,
            email: '',
            password: '',
            loginError: '',
          },
          () => {
            setTimeout(() => {
              this.handleClose()
            }, 2000)
          }
        )
        // this.props.goToAdmin();
      } else {
        this.setState({ loginError: this.props.auth.error })
      }
    }
  }

  openForgotPassword = () => {
    this.setState({
      email: '',
      password: '',
      showPassword: false,
      login: false,
      valid: true,
      blankError: { email: false, password: false },
    })
    this.props.onClose()
    this.props.openForgotPassword(false)
  }

  handleSignUp = () => {
    this.setState({
      email: '',
      password: '',
      showPassword: false,
      login: false,
      valid: true,
      blankError: { email: false, password: false },
    })
    this.props.onClose()
    this.props.openSignUp(false)
  }

  render() {
    const { open, classes, matches } = this.props
    const {
      email,
      password,
      showPassword,
      login,
      valid,
      blankError,
      loginError,
    } = this.state
    return (
      <Modal
        open={open}
        onClose={this.handleClose}
        classes={{
          root: matches
            ? classes.mobileModal
            : classes.modal + ' modal-main-div',
        }}
      >
        {!login ? (
          <div className="login">
            <div className={'modal-title'}>
              <h2>Log In</h2>
            </div>
            <div className="close-icon">
              <IconButton onClick={this.handleClose}>
                <Close />
              </IconButton>
            </div>
            <div className="user-login">
              <div className="field-container">
                <label>Email</label>
                <Input
                  placeholder={'hello@yourmail.com'}
                  variant="outlined"
                  name="email"
                  type="email"
                  value={email}
                  onChange={this.handleChange}
                  onBlur={this.checkValidMail}
                  onKeyDown={this.handleKeyDown}
                />
                {!valid && (
                  <p className="error">
                    The email address you have entered is invalid.
                  </p>
                )}
                {blankError['email'] && (
                  <p className="error">Please enter your email address.</p>
                )}
              </div>
              <div className="field-container">
                <label>Password</label>
                <Input
                  name="password"
                  value={password}
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  placeholder="Password"
                  onChange={this.handleChange}
                  onBlur={() => {
                    password === '' && this.handleBlankError('password')
                  }}
                  onKeyDown={this.handleKeyDown}
                  endAdornment={
                    <InputAdornment>
                      <IconButton onClick={this.toggleVisibility}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {blankError['password'] && (
                  <p className="error">Please enter your password.</p>
                )}
              </div>
              <div className={'btn-section'}>
                <Button
                  variant="contained"
                  className="login-btn"
                  onClick={this.login}
                >
                  Login
                </Button>
              </div>
            </div>
            <div className={'modal-footer'}>
              {loginError !== '' && <p className="error">{loginError}</p>}
              <div className="forgot" onClick={this.handleSignUp}>
                Don't have an account? Sign up here
              </div>
              <div className="forgot" onClick={this.openForgotPassword}>
                Forgot your password
              </div>
            </div>

            {/*<div className="user-login google">*/}
            {/*<h3>OR</h3>*/}
            {/*<GoogleLogin*/}
            {/*clientId={client_id}*/}
            {/*buttonText="LOGIN WITH GOOGLE"*/}
            {/*onSuccess={this.googleResponse}*/}
            {/*onFailure={this.onGoogleFail}*/}
            {/*/>*/}
            {/*</div>*/}
          </div>
        ) : (
          <div className="login">
            <div className="user-login">
              <CheckIcon />
              <h3>Logged in successfully</h3>
            </div>
          </div>
        )}
      </Modal>
    )
  }
}

const mapState = (state) => ({ auth: state.auth })

const mapDispatch = (dispatch) => ({
  googleLogin: bindActionCreators(authActions.googleLogin, dispatch),
  login: bindActionCreators(authActions.login, dispatch),
})

export default connect(
  mapState,
  mapDispatch
)(withRouter(withStyles(styles)(Login)))
