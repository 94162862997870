import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  LoadScript,
  GoogleMap,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from '@react-google-maps/api'
import defaultMark from '../../assets/images/default_mark.svg'
import activeMark from '../../assets/images/markAct.svg'
import { style2 } from './styles'
import { ResourceModal } from '../ListResources/ResourceItem'
import { GOOGLE_KEY } from '../../utils/Const'
import {openedItem} from "../../actions/common.action";

const containerStyle = {
  width: '100%',
  height: '100%',
  zIndex: '3',
}

const center = {
  lat: 35.198284,
  lng: -111.651299,
}

const clustOpt = {
  styles: [
    {
      height: 40,
      width: 40,
      background: '#C64D18',
      borderRadius: '50%',
      className: 'custom-cluster',
    },
  ],
}

const Mapf = (props) => {
  const common = useSelector((state) => state.common)
  const map = useRef(null)
  const dispatch = useDispatch()

  const [active, setActive] = useState(null)

  useEffect(() => {
    const focused = common.focused

    if (
      map &&
      focused &&
      focused.address &&
      typeof focused.address.lat === 'number'
    ) {
      const position = {
        lat: focused.address.lat,
        lng: focused.address.lng,
      }

      map.current.panTo(position)
      map.current.setZoom(16)
    }
  }, [common.focused, map])

  const handleMark = (mark) => () => {
    if (!(mark && mark.position)) {
      setActive(null)
      return null
    }
   dispatch(openedItem(null))

    const getRangePos = (position) => ({
      min: position - 0.00000001,
      max: position + 0.00000001,
    })

    const markLat = getRangePos(mark.position.lat)
    const markLng = getRangePos(mark.position.lng)

    map.current.panTo({ lat: mark.position.lat, lng: mark.position.lng })

    const res = props.markers.filter((el) => {
      if (!el.position) {
        return false
      }
      const lat = el.position.lat
      const lng = el.position.lng
      return (
        lat > markLat.min &&
        lat < markLat.max &&
        lng > markLng.min &&
        lng < markLng.max
      )
    })

    if (res.length > 0) {
      setActive(res)
    } else {
      setActive(null)
    }
  }

  const isHome = props.location.pathname === '/all'

  useEffect(() => {
    if (isHome) {
      document.body.classList.add('template-withMap')
    }
    return () => {
      document.body.classList.remove('template-withMap')
    }
  }, [])

  useEffect(() => {
    if (isHome) document.body.classList.add('template-withMap')
  }, [isHome])

  const onMapLoad = React.useCallback((mapInst) => {
    map.current = mapInst
  }, [])

  useEffect(() => {
    if (map && map.current) {
    }
  }, [map, map.current])

  const renderMarkers = () => {
    let { markers } = props

    const icon = {
      url: defaultMark,
      scaledSize: { width: 25, height: 40 },
    }

    const focusedIcon = (markerId) => {

      if (common.focused && common.focused._id === markerId) {
        console.log(markerId,common.focused)
        return { ...icon,url:activeMark, scaledSize: { width: 60, height: 80 } }
      }
      return icon
    }

    return (
      <MarkerClusterer
        key={'cluster'}
        maxZoom={15}
        minimumClusterSize={3}
        options={clustOpt}
      >
        {(clusterer) =>
          markers.map((marker) => (
            <Marker
              id={marker.id}
              ref={marker.id}
              name={marker.name}
              key={marker.id}
              position={marker.position}
              style={{ height: 10, width: '10px' }}
              clusterer={clusterer}
              icon={focusedIcon(marker._id)}
              onClick={handleMark(marker)}
            ></Marker>
          ))
        }
      </MarkerClusterer>
    )
  }
  return (
    <>
      <LoadScript googleMapsApiKey={GOOGLE_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          options={{ styles: style2 }}
          onLoad={onMapLoad}
        >
          {renderMarkers()}

          {active && (
            <InfoWindow
              position={active[0].position}
              options={{ pixelOffset: { height: -28 } }}
            >
              <div className="resource-modal">
                <div className="resource-modal-list">
                  {active.map((el) => (
                    <ResourceModal resource={el} key={`modal_${el.id}`} />
                  ))}
                </div>

                <button className="close-modal-map" onClick={handleMark(null)}>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </>
  )
}

export default Mapf
