import React, { Component } from 'react'
import {
  Modal,
  Button as MUIButton,
  InputBase,
  withStyles,
  Snackbar,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseRounded'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as authActions from '../../actions/auth.action'
import './PasswordReset.css'

const Input = withStyles({
  root: {
    border: '1px solid #d2d2d2',
    boxShadow: 'inset 0 0 1px #000',
    padding: '0 1%',
  },
})(InputBase)

const Button = withStyles({
  root: {
    background: '#245497',
    color: '#fff',
    width: 'fit-content',
    padding: '1%',
    marginTop: '1%',
    '&:hover': {
      background: '#224684',
    },
  },
})(MUIButton)

const styles = () => ({
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '40% 6% 6% 6%',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    padding: '6%',
    marginLeft: '420px',
  },
  error: {
    backgroundColor: '#d32f2f',
  },
  success: {
    backgroundColor: '#32b241',
  },
})

class PasswordReset extends Component {
  state = {
    email: '',
    currentPassword: '',
    newPassword: '',
    blankError: { currentPassword: false, newPassword: false },
    wrongPassword: false,
    showMessage: false,
    message: '',
    success: false,
  }

  isPassResetPath = () => {
    const currentPath = this.props.location.pathname
    return currentPath.includes('pass-reset')
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.auth.email !== newProps.auth.email) {
      this.setState({ email: newProps.auth.email })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.auth.error !== this.props.auth.error) {
      if (this.props.auth.error) {
        this.setState({
          showMessage: true,
          message: this.props.auth.error,
          success: false,
        })
      }
    }
  }

  handlePassword = (e) => {
    let { name, value } = e.target
    this.setState((state) => ({
      [name]: value,
      blankError: { ...state.blankError, [name]: false },
      wrongPassword: name === 'currentPassword' ? false : state.wrongPassword,
    }))
  }

  checkPassword = async () => {
    const { currentPassword, email, blankError } = this.state
    if (currentPassword === '') {
      this.setState({ blankError: { blankError, currentPassword: true } })
    }
  }

  changePassword = async () => {
    await this.checkPassword()
    const { email, currentPassword, newPassword, wrongPassword, blankError } =
      this.state
    if (
      !wrongPassword &&
      !blankError.currentPassword &&
      !blankError.newPassword
    ) {
      this.props
        .changePassword(
          {
            password: currentPassword,
            password_confirmation: newPassword,
            email: this.props.auth.reset.email,
          },
          this.state.code
        )
        .then(() => {
          const { auth } = this.props
          if (auth.error === '') {
            this.setState({
              // showMessage: true,
              // message: "Password changed successfully",
              currentPassword: '',
              newPassword: '',
              wrongPassword: false,
              // success: true,
              blankError: { currentPassword: false, newPassword: false },
            })
          } else {
            this.setState({
              showMessage: true,
              message: this.props.auth.error,
              success: false,
            })
          }
        })
    }
  }

  closePassword = () => {
    this.setState(
      {
        currentPassword: '',
        newPassword: '',
        blankError: { currentPassword: false, newPassword: false },
        wrongPassword: false,
        showMessage: false,
        message: '',
      },
      () => {
        this.props.onClose()
      }
    )
  }

  hideNotification = () => {
    this.setState({ showMessage: false })
  }
  render() {
    const { classes, matches, open } = this.props
    const {
      currentPassword,
      newPassword,
      blankError,
      wrongPassword,
      showMessage,
      message,
      success,
    } = this.state
    return (
      <Modal
        open={open}
        classes={{
          root: matches
            ? classes.mobileModal
            : classes.modal + ' modal-main-div',
        }}
        onClose={this.closePassword}
      >
        <div>
          <Snackbar
            open={showMessage}
            message={<span>{message}</span>}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            ContentProps={{
              classes: { root: success ? classes.success : classes.error },
            }}
            // autoHideDuration={5000}
            onClose={this.hideNotification}
            action={[
              <IconButton
                key="close"
                color="inherit"
                onClick={this.hideNotification}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
          <div className="password-main-container">
            <IconButton onClick={this.closePassword}>
              <CloseIcon />
            </IconButton>
            <div className="password-modal-container">
              <div className="modal-field">
                <label>Your New Password</label>
                <Input
                  type="password"
                  name="currentPassword"
                  onChange={this.handlePassword}
                  onBlur={this.checkPassword}
                  value={currentPassword}
                />
                {wrongPassword && (
                  <p className="password-error">
                    The password you entered was wrong
                  </p>
                )}
                {blankError.currentPassword && (
                  <p className="password-error">
                    Please enter your current password
                  </p>
                )}
              </div>
              <div className="modal-field">
                <label>Confirm Your New Password</label>
                <Input
                  type="password"
                  name="newPassword"
                  onChange={this.handlePassword}
                  value={newPassword}
                />
                {blankError.newPassword && (
                  <p className="password-error">Please enter a new password</p>
                )}
              </div>
              <div className="modal-field">
                <label>Code confirmation from email</label>
                <Input
                  type="number"
                  name="code"
                  onChange={(e) => {
                    this.setState({ code: e.target.value })
                  }}
                  value={this.state.code}
                />
              </div>
              <Button onClick={this.changePassword}>Change Password</Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapState = (state) => ({
  auth: state.auth,
})

const mapDispatch = (dispatch) => ({
  checkPassword: bindActionCreators(authActions.checkPassword, dispatch),
  changePassword: bindActionCreators(authActions.changePassword, dispatch),
})

export default connect(mapState, mapDispatch)(withStyles(styles)(PasswordReset))
