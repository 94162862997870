import React, { Component } from 'react'
import GalleryGrid from './GalleryGrid/GalleryGrid'
import GalleryImage from './GalleryImage/GalleryImage'
import { Snackbar, withStyles, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import _ from 'lodash'
import { getBusinessDetails } from '../../service/business.service'
const styles = () => ({
  error: {
    backgroundColor: '#d32f2f',
  },
  success: {
    backgroundColor: '#32b241',
  },
})

class Gallery extends Component {
  state = {
    displayImageIdx: 0,
    isMobile: false,
    showImage: false,
    resource: {},
    message: '',
    success: false,
    notification: false,
  }

  handleBackClick = () => {
    this.props.onClose()
    // this.props.history.push({pathname:'/all', search:'?query='+this.props.history.location.state.listId+'}'});
    //   this.props.history.goBack();
  }

  handleImageClick = (idx) => {
    let { isMobile } = this.state
    if (isMobile) {
      this.setState({ displayImageIdx: idx, showImage: true })
    } else this.setState({ displayImageIdx: idx })
  }

  handleArrowClick = () => {
    this.setState({ showImage: false })
    this.props.history.goBack()
  }

  showMessage = (message, success) => {
    this.setState({ message, success, notification: true })
  }

  hideMessage = () => {
    this.setState({ message: '', notification: false })
  }

  renderGallery = (displayImageIdx, resource, gallery_images) => {
    return (
      <div style={{ display: 'flex' }}>
        {/*<GalleryGrid*/}
        {/*  cols={1}*/}
        {/*  displayImageIdx={displayImageIdx}*/}
        {/*  handleBackClick={this.handleBackClick}*/}
        {/*  resource={resource}*/}
        {/*  galleryImages={gallery_images}*/}
        {/*  showMessage={this.showMessage}*/}
        {/*  handleImageClick={this.handleImageClick}*/}
        {/*/>*/}
        <GalleryImage
          displayImageIdx={displayImageIdx}
          resource={resource}
          handleImageClick={this.handleImageClick}
          handleArrowClick={this.handleArrowClick}
          handleBackClick={this.handleBackClick}
          galleryImages={gallery_images}
        />
      </div>
    )
  }

  renderMobileGallery = (
    displayImageIdx,
    resource,
    showImage,
    gallery_images
  ) => {
    return (
      <div>
        {!showImage ? (
          <GalleryGrid
            cols={2}
            displayImageIdx={displayImageIdx}
            handleBackClick={this.handleBackClick}
            resource={resource}
            galleryImages={gallery_images}
            handleImageClick={this.handleImageClick}
            showMessage={this.showMessage}
          />
        ) : (
          <GalleryImage
            resource={resource}
            displayImageIdx={displayImageIdx}
            handleArrowClick={this.handleArrowClick}
            handleImageClick={this.handleImageClick}
            handleBackClick={this.handleBackClick}
            galleryImages={gallery_images}
          />
        )}
      </div>
    )
  }

  UNSAFE_componentWillMount() {
    // let { resource, isMobile } = this.props.history.location.state
    // this.setState({ isMobile, resource })
  }

  render() {
    let {
      displayImageIdx,
      isMobile,
      showImage,
      notification,
      message,
      success,
    } = this.state

    const { classes, resource } = this.props

    console.log('GG resource', resource)

    let gallery_images = _.compact(resource.gallery_images)
    console.log('gallery_images', gallery_images)
    return (
      <div className={'g-wrap'}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={notification}
          // autoHideDuration={4000}
          onClose={this.hideMessage}
          ContentProps={{
            'aria-describedby': 'message-id',
            classes: { root: success ? classes.success : classes.error },
          }}
          message={<span id="message-id">{message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.hideMessage}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        {/*this.renderMobileGallery(*/}
        {/*      displayImageIdx,*/}
        {/*      resource,*/}
        {/*      showImage,*/}
        {/*      gallery_images*/}
        {/*    )*/}
        {this.renderGallery(displayImageIdx, resource, gallery_images)}
      </div>
    )
  }
}

export default withStyles(styles)(Gallery)
