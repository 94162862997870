import React, { Component } from 'react';
import {connect} from 'react-redux';
import CloseIcon from '@material-ui/icons/CloseRounded'
import Question from '../Question/Question';
import './QuestionList.css'

import {
  Modal,
  withStyles,
  Button as MUIButton,
  IconButton
} from '@material-ui/core';
import QuestionMark from "../../assets/images/question_mark_icon.png";

const Button = withStyles({
  root: {
    background: '#245497',
    color: '#fff',
    borderColor: '#245497',
    borderRadius: 1,
    '&:hover': {
      background: '#224989',
    }
  }
})(MUIButton);

const styles = () => ({
  reviewText: {
    minHeight: 69,
    maxHeight: 150,
    overflowY: 'scroll',
    alignItems: "flex-start",
    padding: '1%'
  },
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '40% 6% 6% 6%',
  },
  modal: {
    width: 'calc(100% - 420px)',
    height: 'auto',
    padding: '6%',
    marginLeft: '420px',
  },
});
class QuestionList extends Component {

  state = {
    openQuestion: false,
    review: {},
    notification: false,
    success: false,
    message: '',
    closeModal: true
  };

  closeQuestionPopup = () => {
    this.setState({
      openQuestion: false,
      closeModal: true
    })
  };

  openQuestionPopup = () => {
    this.setState({
      openQuestion: true,
      closeModal: false
    })
  };

  showMessage = (message, success) => {
    this.setState({message, success, notification: true})
  };

  render() {
    const {open, resource, matches, onClose, classes, showMessage} = this.props;
    let {openQuestion, closeModal} = this.state;
    let questionList = resource.questions.length>0 && resource.questions.map((question, i)=>(
      <div key={i} className="single-question">
         <p className="list-question-text">{question.question}</p>
         <p className="by-line italic-font">Asked by {question.name===""?question.email:question.name}</p>
        {question.answer && question.answer.answer!==""
          ?<div>
            {question.answer.answer}
            <p className="by-line">Answered by {question.answer.user.email}</p>
          </div>
          :null}
          <div className="divider"></div>
      </div>
    ));
    return (
      <div>
          <div className="main-review-container ask-Question">
            <div className="close-icon">
              <IconButton onClick={onClose}><CloseIcon/></IconButton>
            </div>
            <div className="titlebar">
              <div className="main-question-title-header">
                <h2>{resource.program_name}</h2>
                <div className={'div-wrap-img'} onClick={this.openQuestionPopup}>
                  <img src={QuestionMark} alt={'image'} className={'img-size'}/>
                  ASK A QUESTION
                </div>
              </div>
            </div>
            HERE LIST
            <div className="list-container">
              <div className="questions-total-list">
                {questionList.length ? questionList : <center>Nothing to show.</center>}
              </div>
            </div>
            END
          </div>
        <Question
          open={openQuestion}
          onClose={this.closeQuestionPopup}
          resource={resource}
          matches={matches}
          showMessage={showMessage}
        />
      </div>
    )
  }
}


const mapState = (state) => ({});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(withStyles(styles)(QuestionList));