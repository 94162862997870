import * as types from '../constants/action.constants'
import * as services from '../service/user.services'

export const getAllUser = (entries, pageNum, order, sortBy, email) => {
  return (dispatch) => {
    dispatch({ type: types.LOADING_USER })
    return services
      .getAllUsers(entries, pageNum, order, sortBy, email)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_ALL_USERS,
            userListing: resp.data.userListing,
            count: resp.data.count,
          })
          return resp.data
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_ALL_USERS_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const deleteUser = (id) => {
  return (dispatch) => {
    return services
      .deleteUser(id)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.DELETE_USER,
            id,
          })
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.DELETE_USER_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const updateUser = (userData, errCall) => {
  return (dispatch) => {
    return services
      .updateUser(userData)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.UPDATE_USER,
            users: userData,
          })
        }
        return resp.data
      })
      .catch((err) => {
        if (err.response) {
          if (errCall) {
            errCall(err)
          }
          dispatch({
            type: types.UPDATE_USER_FAIL,
            error: err.response.data,
          })
        }
        return err
      })
  }
}

export const setUserPaginatedData = (page_no, order, field_name, search) => {
  return (dispatch) => {
    dispatch({
      type: types.PAGINATED_USER_DATA,
      page_no,
      order,
      field_name,
      search,
    })
  }
}

export const getAdminUser = () => {
  return (dispatch) => {
    return services
      .adminUser()
      .then((resp) => {
        if (resp.status === 200) {
          const { user, ...rest } = resp.data

          dispatch({
            type: types.GET_USER_BY_ID,
            user: { ...rest, ...user, email: rest.email },
          })
          return resp.data
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_USER_BY_ID_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}

export const getUnfilteredUser = (email) => {
  return (dispatch) => {
    return services
      .getAllUsers(0, 1, 1, 'email', email)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: types.GET_UNFILTERED_USER,
            userListing: resp.data.userListing,
            count: resp.data.count,
          })
          return resp.data
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: types.GET_UNFILTERED_USER_FAIL,
            error: err.response.data,
          })
        }
      })
  }
}
