import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { Divider } from '@material-ui/core'
import SidePanelHeader from 'components/SidePanelHeader/SidePanelHeader'
import ListResources from '../ListResources/ListResources'
import './SidePanel.css'

const SidePanel = (props) => {
  let renderSidePanelHeader,
    renderResources,
    renderFilters,
    onApplyFilters,
    onsidePanelChange,
    onRemoveAllFilters,
    onFilterRemove
  let [showFilters, setShowFilters] = useState(false)
  let [activeFilter, setActiveFilter] = useState('')
  let [categories, setCategories] = useState(
    (props.selectedCategories.category.length > 0 &&
      props.selectedCategories.category) ||
      []
  )

  useEffect(() => {
    if (
      props.selectedCategories.category.length === 0 &&
      activeFilter === 'more'
    ) {
      setActiveFilter('')
      setShowFilters('')
      setCategories(props.selectedCategories)
    }
    if (props.selectedCategories.category.length === 0) {
      setCategories(props.selectedCategories.category)
    }
  })

  onsidePanelChange = (name) => {
    if (activeFilter === name) {
      setShowFilters(false)
      setActiveFilter('')
    } else {
      setShowFilters(true)
      setActiveFilter(name)
    }
  }

  renderFilters = () => {
    return <></>
  }

  renderResources = () => {
    const {
      subcategories,
      filteredResources,
      selectedSubCategories,
      selectedCity,
      removeCityFilter,
      onRemoveFilter,
      changeRating,
      onPointerEnterResource,
      onPointerLeaveResource,
      openDetailBox,
      onHandleClick,
      closeDetailBox,
      selectedResource,
      loading,
      markerHovered,
      onRemoveSubcatFilter,
      category,
      history,
      selectedPin,
      hoveredPin,
      matches,
      showMessage,
      hideMessage,
      selectedForm,
      selectedCategories,
      onRemoveCategory,
      auth,
    } = props
    return (
      <ListResources
        auth={auth}
        matches={matches}
        selectedPin={selectedPin}
        category={category}
        subcategories={subcategories}
        selectedSubCategories={selectedSubCategories}
        selectedCity={selectedCity}
        removeCityFilter={removeCityFilter}
        onRemoveFilter={onRemoveFilter}
        changeRating={changeRating}
        filteredResources={filteredResources}
        onsidePanelChange={onsidePanelChange}
        onPointerEnterResource={onPointerEnterResource}
        onPointerLeaveResource={onPointerLeaveResource}
        onHandleClick={onHandleClick}
        openDetailBox={openDetailBox}
        closeDetailBox={closeDetailBox}
        selectedResource={selectedResource}
        loading={loading}
        markerHovered={markerHovered}
        onRemoveSubcatFilter={onRemoveSubcatFilter}
        history={history}
        hoveredPin={hoveredPin}
        showMessage={showMessage}
        hideMessage={hideMessage}
        selectedForm={selectedForm}
        removeIdAndForm={props.removeIdAndForm}
        selectedCategories={selectedCategories}
        onRemoveCategory={onRemoveCategory}
      />
    )
  }

  renderSidePanelHeader = () => {
    const { history, isMobile, selectedCategories, matches, closeDetailBox } =
      props
    return (
      <SidePanelHeader
        history={history}
        onsidePanelChange={onsidePanelChange}
        selectedCategories={selectedCategories}
        isMobile={isMobile}
        activeFilter={activeFilter}
        matches={matches}
        closeDetailBox={closeDetailBox}
      />
    )
  }

  return (
    <div
      className="side-panel-content"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {renderSidePanelHeader()}
      <Divider component="div" />
      {showFilters ? renderFilters() : renderResources()}
    </div>
  )
}

export default withRouter(SidePanel)
