import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

function OrganizationsMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        className="dropDown"
      >
        {props.auth && props.auth.token && props.auth.token !== '' ? (
          <a
            onClick={() => {
              props.history.push('/learn-more');
            }}
            className="youtube-channel"
          >
            Learn More
          </a>
        ) : (
          <>
            <a>Add a Listing </a>
            <FontAwesomeIcon icon={faChevronDown} />
          </>
        )}
      </Button>

      {props.auth && props.auth.token && props.auth.token !== '' ? null : (
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem primary="Learn More" className="Organizations-link">
            <a
              onClick={() => {
                props.history.push('/learn-more')
                // props.isLearMoreVideo()
              }}
              className="youtube-channel"
            >
              Learn More
            </a>
          </MenuItem>
          <MenuItem className="Organizations-link">
            <a
              primary="Sign up"
              onClick={() => {
                props.showSignUp()
              }}
            >
              Sign up
            </a>
            {/* <ListItemText primary="Sign up" /> */}
          </MenuItem>

          <MenuItem className="Organizations-link">
            <a
              onClick={() => {
                props.open()
              }}
              primary="Log in"
            >
              Log in
            </a>
            {/* <ListItemText primary="Log in" /> */}
          </MenuItem>
        </StyledMenu>
      )}
    </div>
  )
}

export default withRouter(OrganizationsMenu)

// <StyledMenu
// id="customized-menu"
// anchorEl={anchorEl}
// keepMounted
// open={Boolean(anchorEl)}
// onClose={handleClose}
// >
// <MenuItem primary="Learn More" className="Organizations-link">
// <a
//   onClick={() => {
//     props.history.push("/learn-more")
//     // props.isLearMoreVideo()
//   }}
//   className="youtube-channel"
// >
//   Learn More
// </a>
// </MenuItem>
// {props.auth  && props.auth.token && props.auth.token !== ''  ? null :  <>
// <MenuItem className="Organizations-link">
//   <a
//     primary="Sign up"
//     onClick={() => {
//       props.showSignUp()
//     }}
//   >
//     Sign up
//   </a>
//   {/* <ListItemText primary="Sign up" /> */}
// </MenuItem>

// <MenuItem className="Organizations-link">
//   <a
//   onClick={() => {
//     props.open()
//   }}
//     primary="Log in"
//   >
//     Log in
//   </a>
//   {/* <ListItemText primary="Log in" /> */}
// </MenuItem>
// </>}

// </StyledMenu>
