import React from 'react';
import {Modal, withStyles, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';

const styles = () => ({
  mobileModal: {
    margin: 'auto',
    width: 'auto',
    height: 'auto',
    padding: '20% 4.5% 6% 6%',
  },
  modal: {
    margin: 'auto',
    width: '40%',
    height: 'auto',
    padding: '6%',
  },
  main: {
    background: "#fff"
  },
  editMain: {
    padding: '1% 6% 6% 6%',
    display: 'flex',
    flexDirection: 'column'
  },
  editText: {
    textAlign: 'justify',
    border: '1px solid #a8a8a8',
    borderRadius: 8,
    padding: '2%'
  },
  closeIcon: {
    float: 'right',
  }
});
const EditsPopup = ({open, onClose, matches, alert, classes}) => (
  <Modal open={open} classes={{root: matches?classes.mobileModal:classes.modal}} onClose={onClose}>
    <div className={classes.main}>
      <IconButton onClick={onClose} classes={{root: classes.closeIcon}}>
        <CloseIcon/>
      </IconButton>
      <div className={classes.editMain}>
        <h3>EDIT</h3>
        <div className={classes.editText}>
          {alert.edits}
        </div>
      </div>
    </div>
  </Modal>
);

export default withStyles(styles)(EditsPopup);